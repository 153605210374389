<template>
  <span
      v-tippy="{
      content: tooltipContent,
      arrow: true,
      allowHTML: true,
      theme: tooltipclass
    }"
  >
    <slot></slot>
  </span>
</template>

<script setup>
import { computed, useSlots } from 'vue'

const props = defineProps({
  tooltipclass: {
    type: String,
    default: ''
  }
})

const slots = useSlots()

const getSlotContent = (slotFn) => {
  if (!slotFn) return ''
  const content = slotFn()

  // Si nous avons plusieurs éléments, les joindre en préservant les balises p
  if (Array.isArray(content)) {
    return content.map(node => {
      if (node.type === 'p') {
        return `<p class="mb-2">${node.children}</p>`  // Ajout de margin-bottom
      }
      if (node.children) return node.children
      if (node.el) return node.el.outerHTML
      return ''
    }).join('')
  }

  // Pour un seul élément
  if (content[0]?.type === 'p') {
    return `<p class="mb-2">${content[0].children}</p>`
  }
  if (content[0]?.children) {
    return content[0].children
  }
  if (content[0]?.el) {
    return content[0].el.outerHTML
  }
  return ''
}

const tooltipContent = computed(() => {
  const headerContent = getSlotContent(slots.header)
  const mainContent = getSlotContent(slots.content)
  const imgContent = getSlotContent(slots.img)

  return `
    <div class="tooltip-content">
      ${headerContent ? `<div class="px-4 py-4 font-semibold rouned text-base tooltip-header bg-netlinking text-center text-white">${headerContent}</div>` : ''}
      <div class="tooltip-body float-left bg-white text-dark text-xs px-4 py-2">
      <p class="tooltip-text">
      <div class="inline-block float-left w-[70%]">
                  ${mainContent || ''}
        </div>
        ${imgContent ? `<img class="w-[75px] h-[75px] float-right" src="${imgContent}" />` : ''}
      </p>
      </div>
    </div>
  `
})
</script>

<style>
.tippy-box[data-theme~='netlinkingimg'] {
  @apply !shadow-3 !rounded
}
.tippy-box[data-theme~='netlinkingimg']  > .tippy-content {
  @apply !p-0
}
.tippy-box[data-theme~='netlinkingimg']  >.tippy-content{
  padding: 0px !important;
}
  /* Existing styles */
.tippy-box[data-theme~='netlinkingimg']  {
  @apply text-white;
}

.lucide-info:hover {
  stroke: #4B5563;
}

.tippy-box[data-theme~='netlinkingimg'] {
  background-color: #FFF;
  color: #FFF;
}

.tippy-box[data-theme~='netlinkingimg'][data-placement^='top'] > .tippy-arrow::before {
  border-top-color: #C06;
}

.tippy-box[data-theme~='netlinkingimg'][data-placement^='bottom'] > .tippy-arrow::before {
  border-bottom-color: #C06;
}

.tippy-box[data-theme~='netlinkingimg'][data-placement^='left'] > .tippy-arrow::before {
  border-left-color: #C06;
}

.tippy-box[data-theme~='netlinkingimg'][data-placement^='right'] > .tippy-arrow::before {
  border-right-color: #C06;
}
</style>