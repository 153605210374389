<template>
  <!-- Container with ref for click-outside detection -->
  <div ref="containerRef" class="relative w-full inline-block text-left">
    <!-- Button that shows the number of selected items and toggles the dropdown -->
    <button
        @click="toggleDropdown"
        type="button"
        class="relative text-left w-full rounded-md border border-stroke text-gray-700 px-3 py-2 text-base"
    >
      <span>{{ selectedIds.length }} campagnes sélectionnées</span>
      <div  class="!text-gray-400 absolute top-[12px] right-0 cursor-pointer pr-4 ">
        <svg
            :class="isOpen?'':'-rotate-90'"
            class="fill-current stroke-current"
            fill="none"
            height="16"
            viewBox="0 0 16 16"
            width="16"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path
              d="M2.29635 5.15354L2.29632 5.15357L2.30055 5.1577L7.65055 10.3827L8.00157 10.7255L8.35095 10.381L13.701 5.10603L13.701 5.10604L13.7035 5.10354C13.722 5.08499 13.7385 5.08124 13.7499 5.08124C13.7613 5.08124 13.7778 5.08499 13.7963 5.10354C13.8149 5.12209 13.8187 5.13859 13.8187 5.14999C13.8187 5.1612 13.815 5.17734 13.7973 5.19552L8.04946 10.8433L8.04945 10.8433L8.04635 10.8464C8.01594 10.8768 7.99586 10.8921 7.98509 10.8992C7.97746 10.8983 7.97257 10.8968 7.96852 10.8952C7.96226 10.8929 7.94944 10.887 7.92872 10.8721L2.20253 5.2455C2.18478 5.22733 2.18115 5.2112 2.18115 5.19999C2.18115 5.18859 2.18491 5.17209 2.20346 5.15354C2.222 5.13499 2.2385 5.13124 2.2499 5.13124C2.2613 5.13124 2.2778 5.13499 2.29635 5.15354Z"
              fill=""
              stroke=""
          />
        </svg>
      </div>
    </button>

    <!-- Dropdown panel -->
    <div
        v-if="isOpen"
        class="origin-top-right z-30 absolute mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
    >
      <!-- Search input -->
      <div class="px-4 py-2 border-b border-gray-200">
        <input
            type="text"
            v-model="searchQuery"
            placeholder="Rechercher..."
            class="w-full border rounded px-2 py-1 text-sm"
        />
      </div>

      <!-- Buttons to check/uncheck all -->
      <div class="flex justify-end space-x-4 px-4 py-2 border-b border-gray-200">
        <button
            type="button"
            class="link-gray flex items-center space-x-2 !text-xs"
            @click="checkAll"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check"><path d="M20 6 9 17l-5-5"/></svg>
          <span>Tout cocher</span>
        </button>
        <button
            type="button"
            class="link-gray flex items-center space-x-2 !text-xs"
            @click="uncheckAll"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-x"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg>
          <span>Tous décocher</span>
        </button>
      </div>

      <!-- List of items (filtered based on searchQuery) -->
      <div class="py-1 max-h-64 overflow-auto">
        <div
            v-for="item in filteredValues"
            :key="item.id"
            class="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
        >
          <input
              type="checkbox"
              :id="`checkbox-${item.id}`"
              class="form-checkbox h-4 w-4 text-netlinking"
              :checked="selectedIds.includes(item.id)"
              @change="toggleSelection(item.id)"
          />
          <label :for="`checkbox-${item.id}`" class="ml-2 block w-full !mb-0 text-sm text-gray-700">
            {{ item.label }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, onUnmounted } from 'vue'

/*
  Props:
  - values: Array of objects [{ id, label }]
  - modelValue: Array of selected ids (v-model). By default, if no value is provided, all items are selected.
*/
const props = defineProps({
  values: {
    type: Array,
    required: true
  },
  modelValue: {
    type: Array,
    default: () => []
  }
})

// To support v-model, emit the update event
const emit = defineEmits(['update:modelValue'])

// Control the visibility of the dropdown
const isOpen = ref(false)
// Ref for the container element (for click-outside detection)
const containerRef = ref(null)

// Internal state for selected ids. If the parent doesn’t provide an initial modelValue, select all.
const selectedIds = ref([])
if (props.modelValue && props.modelValue.length > 0) {
  selectedIds.value = [...props.modelValue]
} else {
  selectedIds.value = props.values.map(item => item.id)
  emit('update:modelValue', selectedIds.value)
}

// Toggle the dropdown open/close
function toggleDropdown() {
  isOpen.value = !isOpen.value
}

// Toggle the selection of a given id
function toggleSelection(id) {
  if (selectedIds.value.includes(id)) {
    selectedIds.value = selectedIds.value.filter(item => item !== id)
  } else {
    selectedIds.value.push(id)
  }
  emit('update:modelValue', selectedIds.value)
}

// Check all items
function checkAll() {
  selectedIds.value = props.values.map(item => item.id)
  emit('update:modelValue', selectedIds.value)
}

// Uncheck all items
function uncheckAll() {
  selectedIds.value = []
  emit('update:modelValue', selectedIds.value)
}

// Close the dropdown when clicking outside the component
function handleClickOutside(event) {
  if (containerRef.value && !containerRef.value.contains(event.target)) {
    isOpen.value = false
  }
}

onMounted(() => {
  document.addEventListener('click', handleClickOutside)
})

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside)
})

// Watch for changes in the modelValue prop and update the internal selection accordingly.
watch(
    () => props.modelValue,
    newVal => {
      selectedIds.value = newVal
    }
)

// Search query state
const searchQuery = ref('')

// Computed property to filter the values based on the search query
const filteredValues = computed(() => {
  if (searchQuery.value.trim() === '') {
    return props.values
  }
  return props.values.filter(item =>
      item.label.toLowerCase().includes(searchQuery.value.toLowerCase())
  )
})
</script>
