<script setup>
import { computed } from 'vue'
const props = defineProps({
  steps: {
    type: Object,
    default: () => [] // Default to an empty array
  },
  activestep: {
    type: Number,
    required: true
  }
})
const computedSteps = computed(() => {
  return Object.entries(props.steps).map(([key, step], index) => ({
    key,
    ...step,
    number: parseInt(step.number || index + 1),
    done: index < props.activestep // Determine if the step is done
  }))
})

</script>

<template>
  <section class="overflow-hidden py-0">
    <div class="container mx-auto">
      <div class="flex w-full flex-wrap items-center lg:justify-center">
        <template v-for="(step, index) in computedSteps" :key="index">
          <div class="group flex items-center">
            <!-- Étape avec le cercle numéroté -->
            <div class="mb-5 inline-flex items-center pr-4 lg:pr-0">
              <span
                  class="mr-3 flex h-[34px] w-[34px] items-center justify-center rounded-full border bg-gray-2 text-base font-medium text-dark "
                  :class="{
                  'border-netlinking border-opacity-50 bg-netlinking bg-opacity-50 text-white': step.done && step.number != activestep,
                  'border-netlinking bg-netlinking text-white': step.done && step.number == activestep,
                  'border-[#E7E7E7]': !step.done
                }"
              >
                {{ step.number }}
              </span>
              <!-- Nom de l'étape -->
              <p class="text-base text-dark ">{{ step.name }}</p>
            </div>
            <!-- Flèche entre les étapes -->
            <div
                class="mb-5 hidden px-8 group-last:hidden lg:block text-body-color "
            >
              <svg
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="fill-current"
              >
                <path
                    d="M25.2 13.3L16.1438 4.11251C15.75 3.71876 15.1375 3.71876 14.7438 4.11251C14.35 4.50626 14.35 5.11876 14.7438 5.51251L22.0938 12.9938H3.50001C2.97501 12.9938 2.53751 13.4313 2.53751 13.9563C2.53751 14.4813 2.97501 14.9625 3.50001 14.9625H22.1813L14.7438 22.5313C14.35 22.925 14.35 23.5375 14.7438 23.9313C14.9188 24.1063 15.1813 24.1938 15.4438 24.1938C15.7063 24.1938 15.9688 24.1063 16.1438 23.8875L25.2 14.7C25.5938 14.3063 25.5938 13.6938 25.2 13.3Z"
                />
              </svg>
            </div>
          </div>
        </template>
      </div>
    </div>
  </section>

</template>
