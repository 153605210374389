<template>
  <div class="relative" ref="dropdownRef">
    <div class="flex space-x-4 items-center border input-format focus-within-border cursor-pointer"
         @click="toggleDropdown">
      <img v-if="selectedCountry" :src="selectedCountry.flag" class="w-4 h-4 me-2" :alt="selectedCountry.label"/>
      <span>{{ $t(selectedCountry?.label ?? 'Pays') }}</span>
      <div class="w-full text-right flex justify-end">
        <svg class="w-2.5 h-2.5 ms-2.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1l4 4 4-4"/>
        </svg>
      </div>
    </div>

    <div v-if="isOpen" class="absolute w-full top-full left-0 z-20 mt-1 bg-white rounded-lg shadow-lg">
      <div class="p-2">
        <input
            v-model="searchQuery"
            type="text"
            class="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            :placeholder="$t('Rechercher')"
            @click.stop
        />
      </div>
      <ul class="py-2 max-h-60 overflow-y-auto">
        <li v-for="country in filteredCountries" :key="country.value">
          <button
              type="button"
              @click="selectCountry(country)"
              class="w-full px-4 py-2 text-left hover:bg-gray-100 flex items-center space-x-4"
          >
            <img :src="country.flag" class="w-4 h-4 me-2" :alt="country.label"/>
            <span>{{ t(country.label) }}</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const props = defineProps({
  modelValue: String,
  countries: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(['update:modelValue'])
const isOpen = ref(false)
const selectedCountry = ref(null)
const dropdownRef = ref(null)
const searchQuery = ref('')

const countriesArray = computed(() => Object.values(props.countries))

const filteredCountries = computed(() => {
  if (!searchQuery.value) return countriesArray.value
  const query = searchQuery.value.toLowerCase()
  return countriesArray.value.filter(country =>
      t(country.label).toLowerCase().includes(query)
  )
})

const toggleDropdown = () => {
  isOpen.value = !isOpen.value
  if (isOpen.value) searchQuery.value = ''
}

const closeDropdown = (event) => {
  if (!dropdownRef.value?.contains(event.target)) {
    isOpen.value = false
    searchQuery.value = ''
  }
}

const selectCountry = (country) => {
  selectedCountry.value = country
  isOpen.value = false
  searchQuery.value = ''
  emit('update:modelValue', country.value)
}

watch(
    () => props.modelValue,
    (newValue) => {
      selectedCountry.value = props.countries[newValue] || null
    },
    { immediate: true }
)

document.addEventListener('click', closeDropdown)
</script>

<style scoped>
.input-format {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>