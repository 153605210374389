// ask.js - Directive v-ask avec Tailwind CSS pour Vue 3
import { createApp, h, reactive, defineComponent } from 'vue'

// Composant AskDialog utilisant Tailwind CSS
const AskDialog = defineComponent({
  name: 'AskDialog',
  props: {
    visible: Boolean,
    message: {
      type: String,
      default: 'Êtes-vous sûr de vouloir continuer?'
    },
    title: {
      type: String,
      default: 'Confirmation'
    },
    confirmText: {
      type: String,
      default: 'Confirmer'
    },
    cancelText: {
      type: String,
      default: 'Annuler'
    },
    onConfirm: Function,
    onCancel: Function
  },
  setup(props) {
    const handleEscape = (e) => {
      if (e.key === 'Escape' && props.visible) {
        props.onCancel?.();
      }
    };

    return {
      handleEscape,
      handleOverlayClick(e) {
        if (e.target === e.currentTarget) {
          props.onCancel?.();
        }
      }
    };
  },
  mounted() {
    document.addEventListener('keydown', this.handleEscape);
    if (this.visible) {
      document.body.classList.add('overflow-hidden');
    }
  },
  updated() {
    if (this.visible) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.handleEscape);
    document.body.classList.remove('overflow-hidden');
  },
  render() {
    if (!this.visible) return null;

    return h('div', {
      class: 'fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50',
      onClick: this.handleOverlayClick
    }, [
      h('div', {
        class: 'bg-white rounded-lg shadow-xl max-w-md w-11/12 md:w-full mx-2 overflow-hidden transform transition-all',
        style: {
          animation: 'askFadeIn 0.2s ease-out forwards'
        }
      }, [
        h('div', { class: 'px-6 py-4 border-b border-gray-200 flex justify-between items-center' }, [
          h('h3', { class: 'text-lg font-medium text-gray-900' }, this.title),
          h('button', {
            class: 'text-gray-400 hover:text-gray-500 focus:outline-none',
            onClick: this.onCancel
          }, '×')
        ]),
        h('div', { class: 'px-6 py-4 text-gray-700' }, [
          h('p', { innerHTML: this.message })
        ]),
        h('div', { class: 'px-6 py-3 bg-gray-50 flex justify-end space-x-3' }, [
          h('button', {
            class: 'px-4 py-2 bg-white border border-gray-300 rounded-md font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
            onClick: this.onCancel
          }, this.cancelText),
          h('button', {
            class: 'btn btn-danger btn-sm',
            onClick: this.onConfirm
          }, this.confirmText)
        ])
      ])
    ]);
  }
});

// État global pour le dialog
const askState = reactive({
  visible: false,
  message: '',
  title: 'Confirmation',
  confirmText: 'Confirmer',
  cancelText: 'Annuler',
  callback: null,
  resolve: null,
  reject: null
});

// Créer le point de montage et l'application
const mountPoint = document.createElement('div');
document.body.appendChild(mountPoint);

// Injecter le CSS d'animation nécessaire (minimal, le reste est dans Tailwind)
const injectStyles = () => {
  const styleElement = document.createElement('style');
  styleElement.textContent = `
    @keyframes askFadeIn {
      from { opacity: 0; transform: translateY(-10px); }
      to { opacity: 1; transform: translateY(0); }
    }
  `;

  document.head.appendChild(styleElement);
};

// Créer l'application
const app = createApp({
  render() {
    return h(AskDialog, {
      visible: askState.visible,
      message: askState.message,
      title: askState.title,
      confirmText: askState.confirmText,
      cancelText: askState.cancelText,
      onConfirm: () => {
        if (askState.callback) {
          askState.callback();
        }
        if (askState.resolve) {
          askState.resolve(true);
        }
        askState.visible = false;
      },
      onCancel: () => {
        if (askState.reject) {
          askState.reject(false);
        }
        askState.visible = false;
      }
    });
  }
});

app.mount(mountPoint);
injectStyles();

// Fonction globale pour déclencher la boîte de dialogue
const ask = (message, title = 'Confirmation', options = {}) => {
  askState.message = message;
  askState.title = title;
  askState.confirmText = options.confirmText || 'Confirmer';
  askState.cancelText = options.cancelText || 'Annuler';
  askState.visible = true;

  return new Promise((resolve, reject) => {
    askState.resolve = resolve;
    askState.reject = reject;
  });
};

// Directive v-ask
const vAsk = {
  mounted(el, binding) {
    // Extraire les valeurs
    const value = binding.value;
    const message = typeof value === 'string'
      ? value
      : (value?.message || 'Êtes-vous sûr?');
    const title = binding.arg || (value?.title || 'Confirmation');
    const options = {
      confirmText: value?.confirmText || 'Confirmer',
      cancelText: value?.cancelText || 'Annuler'
    };

    // Gestionnaire d'événement pour le clic
    el.__vAskHandler = async (event) => {
      // Stocker le message et afficher la boîte de dialogue
      askState.message = message;
      askState.title = title;
      askState.confirmText = options.confirmText;
      askState.cancelText = options.cancelText;
      askState.visible = true;

      // Récupérer la fonction confirm
      const componentInstance = binding.instance;
      const confirmFn =
        componentInstance.$attrs?.confirm ||
        componentInstance.$attrs?.onConfirm ||
        (value?.onConfirm) ||
        (typeof value === 'object' && typeof value.confirm === 'function' ? value.confirm : null);

      // Stocker la fonction callback
      askState.callback = () => {
        if (typeof confirmFn === 'function') {
          confirmFn(event);
        }
      };

      // Pour la méthode promise-based
      return new Promise((resolve, reject) => {
        askState.resolve = resolve;
        askState.reject = reject;
      });
    };

    el.addEventListener('click', el.__vAskHandler);
  },

  updated(el, binding) {
    el.removeEventListener('click', el.__vAskHandler);
    vAsk.mounted(el, binding);
  },

  unmounted(el) {
    el.removeEventListener('click', el.__vAskHandler);
  }
};

// Plugin pour Vue
const AskPlugin = {
  install(app) {
    app.directive('ask', vAsk);
    app.config.globalProperties.$ask = ask;
  }
};

export default AskPlugin;
export { ask, vAsk };