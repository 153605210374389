<template>
  <div class="draw-header">
    <div class="w-full">Exporter un rapport</div>
    <div class="flex items-center space-x-2">
      <button :disabled="Object.keys(formValues.campaigns).length == 0 || loadingStore.isLoading"
              class="btn btn-sm btn-netlinking flex items-center justify-center space-x-2"
              form="userForm" type="submit"
              @click.prevent.stop="exportReport">
        <svg class="lucide lucide-download" fill="none" height="18" stroke="currentColor" stroke-linecap="round"
             stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="18"
             xmlns="http://www.w3.org/2000/svg">
          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"/>
          <polyline points="7 10 12 15 17 10"/>
          <line x1="12" x2="12" y1="15" y2="3"/>
        </svg>
        <span>Export</span>
      </button>
    </div>
  </div>
  <div class="mx-5 py-10">
    <label class="!text-base !font-semibold">Sélectionner une ou plusieurs campagnes</label>
    <Selectcheck v-model="formValues.campaigns" :values="values"/>
    <label class="!text-base !font-semibold mt-5">Sélectionner un jour / une période <small class="!font-normal">(facultatif)</small></label>
    <Datepicker v-model="formValues.datepicker"/>
  </div>
  <div class="mx-5">
    <div class="card-gray">
      <h3 class="text-netlinking">
        Datas présentes dans les rapports
      </h3>
      <p class="text-sm mb-3 mt-3">
        <span class="flex items-center space-x-2"><svg class="lucide lucide-check text-netlinking" fill="none"
                                                       height="18"
                                                       stroke="currentColor" stroke-linecap="round"
                                                       stroke-linejoin="round"
                                                       stroke-width="2" viewBox="0 0 24 24" width="18"
                                                       xmlns="http://www.w3.org/2000/svg"><path
            d="M20 6 9 17l-5-5"/></svg><span>La date de commande</span></span>
        <span class="flex items-center space-x-2"><svg class="lucide lucide-check text-netlinking" fill="none"
                                                       height="18"
                                                       stroke="currentColor" stroke-linecap="round"
                                                       stroke-linejoin="round"
                                                       stroke-width="2" viewBox="0 0 24 24" width="18"
                                                       xmlns="http://www.w3.org/2000/svg"><path
            d="M20 6 9 17l-5-5"/></svg><span>Le numéro de référence de la commande</span></span>
        <span class="flex items-center space-x-2"><svg class="lucide lucide-check text-netlinking" fill="none"
                                                       height="18"
                                                       stroke="currentColor" stroke-linecap="round"
                                                       stroke-linejoin="round"
                                                       stroke-width="2" viewBox="0 0 24 24" width="18"
                                                       xmlns="http://www.w3.org/2000/svg"><path
            d="M20 6 9 17l-5-5"/></svg><span>Le nom de la campagne</span></span>
        <span class="flex items-center space-x-2"><svg class="lucide lucide-check text-netlinking" fill="none"
                                                       height="18"
                                                       stroke="currentColor" stroke-linecap="round"
                                                       stroke-linejoin="round"
                                                       stroke-width="2" viewBox="0 0 24 24" width="18"
                                                       xmlns="http://www.w3.org/2000/svg"><path
            d="M20 6 9 17l-5-5"/></svg><span>Le statut de la commande</span></span>
        <span class="flex items-center space-x-2"><svg class="lucide lucide-check text-netlinking" fill="none"
                                                       height="18"
                                                       stroke="currentColor" stroke-linecap="round"
                                                       stroke-linejoin="round"
                                                       stroke-width="2" viewBox="0 0 24 24" width="18"
                                                       xmlns="http://www.w3.org/2000/svg"><path
            d="M20 6 9 17l-5-5"/></svg><span>Le lien vers l’URL de l’article (si publié)</span></span>
        <span class="flex items-center space-x-2 mb-5"><svg class="lucide lucide-check text-netlinking" fill="none"
                                                            height="18"
                                                            stroke="currentColor" stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            stroke-width="2" viewBox="0 0 24 24"
                                                            width="18"
                                                            xmlns="http://www.w3.org/2000/svg"><path
            d="M20 6 9 17l-5-5"/></svg><span>La date de publication</span></span>

        <span class="flex items-center space-x-2"><svg class="lucide lucide-check text-netlinking" fill="none"
                                                       height="18"
                                                       stroke="currentColor" stroke-linecap="round"
                                                       stroke-linejoin="round"
                                                       stroke-width="2" viewBox="0 0 24 24" width="18"
                                                       xmlns="http://www.w3.org/2000/svg"><path
            d="M20 6 9 17l-5-5"/></svg><span>Le titre de l’Article</span></span>
        <span class="flex items-center space-x-2"><svg class="lucide lucide-check text-netlinking" fill="none"
                                                       height="18"
                                                       stroke="currentColor" stroke-linecap="round"
                                                       stroke-linejoin="round"
                                                       stroke-width="2" viewBox="0 0 24 24" width="18"
                                                       xmlns="http://www.w3.org/2000/svg"><path
            d="M20 6 9 17l-5-5"/></svg><span>Le volume d’impressions de l’article à la date d’achat</span></span>
        <span class="flex items-center space-x-2"><svg class="lucide lucide-check text-netlinking" fill="none"
                                                       height="18"
                                                       stroke="currentColor" stroke-linecap="round"
                                                       stroke-linejoin="round"
                                                       stroke-width="2" viewBox="0 0 24 24" width="18"
                                                       xmlns="http://www.w3.org/2000/svg"><path
            d="M20 6 9 17l-5-5"/></svg><span>Le volume de DR à la date d’achat</span></span>
        <span class="flex items-center space-x-2 mb-5"><svg class="lucide lucide-check text-netlinking" fill="none"
                                                            height="18"
                                                            stroke="currentColor" stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            stroke-width="2" viewBox="0 0 24 24"
                                                            width="18"
                                                            xmlns="http://www.w3.org/2000/svg"><path
            d="M20 6 9 17l-5-5"/></svg><span>Le prix de l’Article</span></span>

        <span class="flex items-center space-x-2"><svg class="lucide lucide-check text-netlinking" fill="none"
                                                       height="18"
                                                       stroke="currentColor" stroke-linecap="round"
                                                       stroke-linejoin="round"
                                                       stroke-width="2" viewBox="0 0 24 24" width="18"
                                                       xmlns="http://www.w3.org/2000/svg"><path
            d="M20 6 9 17l-5-5"/></svg><span>La durée d’exclusivité de votre lien</span></span>
        <span class="flex items-center space-x-2"><svg class="lucide lucide-check text-netlinking" fill="none"
                                                       height="18"
                                                       stroke="currentColor" stroke-linecap="round"
                                                       stroke-linejoin="round"
                                                       stroke-width="2" viewBox="0 0 24 24" width="18"
                                                       xmlns="http://www.w3.org/2000/svg"><path
            d="M20 6 9 17l-5-5"/></svg><span>La durée de l’étalement des publications</span></span>
        <span class="flex items-center space-x-2"><svg class="lucide lucide-check text-netlinking" fill="none"
                                                       height="18"
                                                       stroke="currentColor" stroke-linecap="round"
                                                       stroke-linejoin="round"
                                                       stroke-width="2" viewBox="0 0 24 24" width="18"
                                                       xmlns="http://www.w3.org/2000/svg"><path
            d="M20 6 9 17l-5-5"/></svg><span>L’ancre renseignée par vos soins</span></span>
        <span class="flex items-center space-x-2"><svg class="lucide lucide-check text-netlinking" fill="none"
                                                       height="18"
                                                       stroke="currentColor" stroke-linecap="round"
                                                       stroke-linejoin="round"
                                                       stroke-width="2" viewBox="0 0 24 24" width="18"
                                                       xmlns="http://www.w3.org/2000/svg"><path
            d="M20 6 9 17l-5-5"/></svg><span>L’ancre renseignée par nos soins (selon paramétrage)</span></span>
        <span class="flex items-center space-x-2"><svg class="lucide lucide-check text-netlinking" fill="none"
                                                       height="18"
                                                       stroke="currentColor" stroke-linecap="round"
                                                       stroke-linejoin="round"
                                                       stroke-width="2" viewBox="0 0 24 24" width="18"
                                                       xmlns="http://www.w3.org/2000/svg"><path
            d="M20 6 9 17l-5-5"/></svg><span>L’URL cible renseignée</span></span>
        <span class="flex items-center space-x-2"><svg class="lucide lucide-check text-netlinking" fill="none"
                                                       height="18"
                                                       stroke="currentColor" stroke-linecap="round"
                                                       stroke-linejoin="round"
                                                       stroke-width="2" viewBox="0 0 24 24" width="18"
                                                       xmlns="http://www.w3.org/2000/svg"><path
            d="M20 6 9 17l-5-5"/></svg><span>Nom de l’utilisateur en charge de la campagne</span></span>
      </p>
    </div>
  </div>


</template>
<script setup>
import { reactive } from 'vue'
const emit = defineEmits(['cleared'])
import { useLoadingStore } from '@/js/stores/loading'
import Datepicker from '@/components/common/Datepicker.vue'
import Selectcheck from '@/components/common/Selectcheck.vue'

const loadingStore = useLoadingStore()
const props = defineProps({
  values: {
    type: Array,
    required: true
  }
})
const formValues = reactive({
  datepicker: '',
  campaigns: []
})

const exportReport = async () => {
  try {
    loadingStore.loadingPage = true

    // Create a URLSearchParams instance to build the query string
    const params = new URLSearchParams()

    // Add the datepicker value if present
    if (formValues.datepicker) {
      params.append('datepicker', formValues.datepicker)
    }

    // Add each campaign to the query parameters.
    // Note: We use the key 'campaigns[]' so the backend recognizes it as an array.
    if (formValues.campaigns && formValues.campaigns.length > 0) {
      formValues.campaigns.forEach((campaign) => {
        params.append('campaigns[]', campaign)
      })
    }
    emit('cleared')
    window.location.href = `/api/campaigns/export/?${params.toString()}`
  } catch (error) {
    console.error('Error exporting report:', error)
  } finally {
    loadingStore.loadingPage = false
  }
}
</script>