<template>
  <div class="draw-header">
    <div class="w-full">Client : {{ customer.firstName }} {{ customer.lastName }}</div>
    <div class="flex items-center space-x-2">
      <button :disabled="loadingStore.isLoading" class="btn btn-sm btn-netlinking" form="userForm" type="submit">
        <LoadingBtn :label="'Enregistrer'"/>
      </button>
    </div>
  </div>
  <Form id="userForm" v-slot="{ errors }" :validation-schema="schema_0" class="space-y-3" @submit="onSubmit_0">
    <div class="py-4 px-10 sm:px-4">

      <div class="mb-4 font-semibold flex items-start justify-start space-x-6">
      <span class="w-[600px] flex items-center space-x-2 mt-[7px]">
                                <svg class="lucide lucide-gift" fill="none" height="24" stroke="currentColor"
                                     stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24"
                                     width="24" xmlns="http://www.w3.org/2000/svg"><rect height="4" rx="1" width="18"
                                                                                              x="3" y="8"/><path
                                    d="M12 8v13"/><path d="M19 12v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-7"/><path
                                    d="M7.5 8a2.5 2.5 0 0 1 0-5A4.8 8 0 0 1 12 8a4.8 8 0 0 1 4.5-5 2.5 2.5 0 0 1 0 5"/></svg>

        <span>Crédits cadeau :</span>
      </span>
        <div class="relative w-full">
          <div>
            <Field v-model="formValues.gift" class="w-full" name="gift" type="text"/>
            <ErrorMessage class="error-message" name="gift"/>
          </div>
          <span class="absolute right-2 top-2">€</span>
        </div>
      </div>
      <div v-if="Object.keys(histories).length > 0" class="mt-5">
        <h4 class="text-gray-500 mb-3">Historique des opérations</h4>
        <table class="w-full table-sm table-striped">
          <thead>
          <tr>
            <th>Date</th>
            <th>Type</th>
            <th>Action par</th>
            <th>Montant</th>
            <th>Ancien solde</th>
            <th>Nouveau solde</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="history in histories">
            <td>{{ $filters.dateFormat(history.createdAt) }}</td>
            <td>
              <template v-if="balance_transaction_types[history.type]">
                {{ balance_transaction_types[history.type].label }}
              </template>
            </td>
            <td>{{ history.firstName }} {{ history.lastName }}</td>
            <td>{{ history.amount }}€</td>
            <td>{{ history.balanceAfter - history.amount }}€</td>
            <td>{{ history.balanceAfter }}€</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </Form>
</template>
<script setup>
import { computed, defineProps, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useLoadingStore } from '@/js/stores/loading'
import { useLocaleStore } from '@/js/stores/locale'
import { useAuthStore } from '@/js/stores/auth'
import LoadingBtn from '@/components/common/LoadingBtn.vue'
import * as yup from 'yup'
import { ErrorMessage, Field, Form } from 'vee-validate'
import axios from 'axios'

const histories = ref({})
const emit = defineEmits(['all-ok'])

const authStore = useAuthStore()

const path = '/internal/crm/ajax/'
const { t } = useI18n()
const localeStore = useLocaleStore()
const loadingStore = useLoadingStore()
const selectedRole = ref(null)
const props = defineProps({
  customer: {
    type: Object,
    required: true,
    default: { name: '' }
  },
  balance_transaction_types: {
    type: Object,
    required: true,
  }
})
const fetchHistory = async () => {
  axios.post(path, {
    action: 'get_history',
    datas: {
      id: props.customer.id
    },
  }).then((response) => {
    if (response.status === 200) {
      histories.value = response.data.histories
    }
  })
}
onMounted(async () => {
  await fetchHistory()
})
const formValues = ref({
  gift: 0
})

const schema_0 = computed(() => {
  return yup.object().shape({
    gift: yup
        .number()
        .typeError(t('validation.must_be_a_number')) // Vérifie que c'est un nombre
        .required(t('validation.required')) // Champ obligatoire
        .moreThan(0, t('validation.must_be_greater_than', { value: 0 })) // Doit être strictement > 50
  })
})
const onSubmit_0 = async (values) => {
  try {
    loadingStore.setLoading(true)
    const response = await axios.post(path, {
      action: 'add_gift',
      datas: {
        gift: formValues.value.gift, id: props.customer.id
      },
    })

    if (response.status === 200) {
      window.location.href = response.data.redirect
      emit('all-ok', {})
    }
  } catch (error) {
    if (error.response && error.response.status === 500) {
    }
  } finally {
    loadingStore.setLoading(false)
  }
}
</script>