<template>
  <div class="relative w-full" ref="dropdown" @click.stop>
    <!-- Dropdown trigger -->
    <div
        class="w-full p-2 !px-3 !py-2 border rounded bg-white flex items-center justify-between cursor-pointer"
        @click.prevent.stop="toggleDropdown"
    >
      <span class="inline-block" :class="selectedLabels.length ? 'text-gray-700' : 'text-gray-300'">
        <span v-html="selectedLabels.length ? selectedLabels.join('<br>') : placeholder"></span>
      </span>
      <button
          v-if="selectedLabels.length"
          @click.stop.prevent="clearSelection"
          class="text-gray-500 hover:text-gray-800"
          aria-label="Effacer la sélection"
      >
        ✕
      </button>
    </div>

    <!-- Dropdown Menu -->
    <div
        v-if="isDropdownOpen"
        class="absolute z-30 mt-2 w-full bg-white border rounded shadow-lg"
        style="min-width: 390px; background: #FFF;"
    >
      <!-- Search Input -->
      <div class="px-4 py-2 shadow border-b bg-netlinkingh border-b-gray-400">
        <input
            type="text"
            v-model="searchQuery"
            placeholder="Rechercher"
            class="w-full p-2"
        />
      </div>

      <!-- Dropdown Options -->
      <ul class="max-h-48 overflow-y-auto">
        <li
            v-for="option in filteredOptions"
            :key="option.id || option.label"
            class="p-2 px-4 text-sm hover:text-netlinking hover:bg-netlinkingh cursor-pointer border-b border-gray-200"
            @click="toggleOption(option)"
        >
          <label :for="'option_m'+option.id" class="flex items-center space-x-2">
            <input
                type="checkbox"
                :checked="isSelected(option.id)"
                class="form-checkbox"
                :name="'option_m'+option.id"
            />
            <span>{{ option.label }}</span>
          </label>
        </li>
        <li v-if="filteredOptions.length === 0" class="p-2 text-gray-700">
          Aucun résultat trouvé
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue'

const props = defineProps({
  options: {
    type: Array,
    required: true,
    default: () => [],
  },
  modelValue: {
    type: Array,
    default: () => [],
  },
  placeholder: {
    type: String,
    default: "-- Sélectionnez des options --",
  },
})

const emit = defineEmits(["update:modelValue"])

const isDropdownOpen = ref(false)
const searchQuery = ref("")
const selectedIds = ref(Array.isArray(props.modelValue) ? [...props.modelValue] : [])
const internalOptions = ref([...props.options])

const normalizedOptions = computed(() =>
    internalOptions.value.map((option) =>
        typeof option === "string" ? { id: option, label: option } : option
    )
)

const filteredOptions = computed(() =>
    normalizedOptions.value.filter((option) =>
        option.label.toLowerCase().includes(searchQuery.value.toLowerCase())
    )
)

const selectedLabels = computed(() =>
    normalizedOptions.value
        .filter((option) => selectedIds.value.includes(option.id))
        .map((option) => option.label)
)

function toggleDropdown() {
  isDropdownOpen.value = !isDropdownOpen.value
  if (isDropdownOpen.value) {
    searchQuery.value = ""
  }
}

function clearSelection() {
  selectedIds.value = []
  emit("update:modelValue", [])
}

function isSelected(id) {
  return selectedIds.value.includes(id)
}

function toggleOption(option) {
  const index = selectedIds.value.indexOf(option.id)
  if (index === -1) {
    selectedIds.value = [...selectedIds.value, option.id]
  } else {
    selectedIds.value = selectedIds.value.filter(id => id !== option.id)
  }
  emit("update:modelValue", selectedIds.value)
}

const dropdown = ref(null)

function handleClickOutside(event) {
  if (dropdown.value && !dropdown.value.contains(event.target)) {
    isDropdownOpen.value = false
  }
}

watch(
    () => props.modelValue,
    (newValue) => {
      selectedIds.value = Array.isArray(newValue) ? [...newValue] : []
    }
)

watch(
    () => props.options,
    (newOptions) => {
      internalOptions.value = [...newOptions]
    }
)

onMounted(() => {
  document.addEventListener("click", handleClickOutside)
})

onBeforeUnmount(() => {
  document.removeEventListener("click", handleClickOutside)
})
</script>