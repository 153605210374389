import { defineStore } from 'pinia';

export const useLocaleStore = defineStore('locale', {
  state: () => ({
    currentLocale: navigator.language || 'en', // Default to browser language or 'en' if not available
  }),
  actions: {
    setLocale(locale) {
      this.currentLocale = locale;
    },
  },
});

export default useLocaleStore;
