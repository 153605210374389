<template>
  <div class="bg-netlinking -mx-[40px] -my-[15px] pt-[16px] pb-[30px] px-[30px] xl:px-[40px]">
    <h1 class="title-simple lg:max-w-[600px] flex items-center justify-between">
      <span>TTF Finder</span>
      <span class="text-base">{{ count_histories }} / 5</span>
    </h1>
    <Form v-slot="{ errors }" :validation-schema="schema_0" class="space-y-3" @submit="onSubmit_0">
      <div class="block lg:flex lg:space-x-4 space-y-4 lg:space-y-0 mt-4">
        <div class="w-full  lg:max-w-[600px]">
          <label for="url">Renseigner votre URL</label>
          <div class="w-full">
            <Field
                v-model="datas.url"
                :class="[errors.url ? 'ring-2 ring-red-600' : '']"
                :name="'url'"
                type="text"
                :placeholder="'Ex. https://www.leseopourtous.fr'"
            ></Field>
            <ErrorMessage :name="'url'" class="error-message"/>
          </div>
        </div>
        <div class="w-full lg:max-w-[200px] pt-8">
          <!--          @click.prevent.stop="isModalOpen = true"-->
          <button :disabled="max_ttfs <= count_histories || loadingStore.isLoading" class="btn btn-white-netlinking btn-sm w-full btn-rounded" type="submit"

          >
            <Loadingbtn :label="'Analyser'"/>
          </button>
        </div>
      </div>
    </Form>

  </div>
  <div class="mt-16 w-full" v-if="data_response">
    <div class="w-full mx-auto lg:max-w-[1200px] flex lg:space-x-52 space-x-4">
      <div class="w-full">
        <span class="text-lg font-semibold mb-2">
          Les thématiques de votre contenu selon Google
        </span>
        <div class="bg-white shadow rounded space-y-2 p-3">
          <span class="flex items-center space-x-2 w-full" v-for="data in data_response.result">
            <span class="badge badge-info flex items-center text-white max-w-[100px]">
              {{data.score}}%
            </span>
            <span class="badge badge-gray-outline w-full">{{data.category}}</span>
          </span>
        </div>
      </div>
      <div class="w-full">
        <span class="text-lg font-semibold mb-2">
        Les Trust Flow à privilégier
        </span>
        <div class="bg-white shadow rounded space-y-2 p-3">
          <span class="flex items-center space-x-2 w-full" v-for="data in data_response.classificationNetlinking">
            <span class="badge flex items-center text-white max-w-[100px]" :style="{background:data.ttf_color}">
              {{data.percent}}
            </span>
            <span class="badge badge-gray-outline w-full">{{data.ttf_label}}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div
      v-if="errorData"
      class="border-red bg-red-light-6 flex items-center w-full rounded-lg border-l-[6px] px-4 py-4 shadow-[0px_2px_10px_0px_rgba(0,0,0,0.08)] md:p-4 mt-16"
  >
    <div
        class="bg-red mr-5 flex h-[34px] w-full max-w-[34px] items-center justify-center rounded-lg"
    >
      <svg
          fill="none"
          height="18"
          viewBox="0 0 18 18"
          width="18"
          xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_961_15645)">
          <path
              d="M9 0.506256C4.30313 0.506256 0.50625 4.30313 0.50625 9.00001C0.50625 13.6969 4.30313 17.5219 9 17.5219C13.6969 17.5219 17.5219 13.6969 17.5219 9.00001C17.5219 4.30313 13.6969 0.506256 9 0.506256ZM9 16.2563C5.00625 16.2563 1.77188 12.9938 1.77188 9.00001C1.77188 5.00626 5.00625 1.77188 9 1.77188C12.9938 1.77188 16.2563 5.03438 16.2563 9.02813C16.2563 12.9938 12.9938 16.2563 9 16.2563Z"
              fill="white"
          />
          <path
              d="M11.5875 6.38438C11.3344 6.13125 10.9406 6.13125 10.6875 6.38438L9 8.1L7.28438 6.38438C7.03125 6.13125 6.6375 6.13125 6.38438 6.38438C6.13125 6.6375 6.13125 7.03125 6.38438 7.28438L8.1 9L6.38438 10.7156C6.13125 10.9688 6.13125 11.3625 6.38438 11.6156C6.49688 11.7281 6.66563 11.8125 6.83438 11.8125C7.00313 11.8125 7.17188 11.7563 7.28438 11.6156L9 9.9L10.7156 11.6156C10.8281 11.7281 10.9969 11.8125 11.1656 11.8125C11.3344 11.8125 11.5031 11.7563 11.6156 11.6156C11.8688 11.3625 11.8688 10.9688 11.6156 10.7156L9.9 9L11.6156 7.28438C11.8406 7.03125 11.8406 6.6375 11.5875 6.38438Z"
              fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_961_15645">
            <rect fill="white" height="18" width="18"/>
          </clipPath>
        </defs>
      </svg>
    </div>
    <div class="w-full">
      <div class="text-base font-normal text-dark">
        <span>La page sélectionnée ne contient aucun contenu. Veuillez en choisir une autre.</span>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, defineProps, reactive, ref } from 'vue'
import { ErrorMessage, Field, Form } from 'vee-validate'
import * as yup from 'yup'
import { useLocaleStore } from '@/js/stores/locale'
import { useLoadingStore } from '@/js/stores/loading'
const localeStore = useLocaleStore()
const loadingStore = useLoadingStore()
const currentLocale = localeStore.currentLocale
import LoadingBtn from '@/components/common/LoadingBtn.vue'
import { useI18n } from 'vue-i18n'
import axios from 'axios'
import { integer } from 'maz-ui/modules/chunks/index.BgyjI1q8.mjs'
const { t } = useI18n()
const data_response = ref(null)
const errorData = ref(false)
const datas = reactive({
  url: null,
})
const props = defineProps({
  count_histories: {
    type: integer,
    required: true,
    default:5
  },
  max_ttfs: {
    type: integer,
    required: true,
    default:5
  }
})
const schema_0 = computed(() => {
  return yup.object().shape({
    url: yup
        .string()
        .url(t('validation.invalid_url_format'))
        .required(t('validation.required'))
  })
})
const path = '/ttf-finder/ajax/'
const onSubmit_0 = async (values) => {
  try {
    loadingStore.setLoadingPage(true)
    const response = await axios.post(path, {
      action: 'execute',
      datas: { url: datas.url },
    })
    // // Check response status
    // console.log(response.status)
    if (response.status === 200) {
      data_response.value = response.data.res
      props.count_histories = response.data.count_histories
    }
  } catch (error) {
    errorData.value = true
    // Handle errors
    if (error.response && error.response.status === 500) {
    } else {
    }
  } finally {
    // End loading
    loadingStore.setLoadingPage(false)
  }
}
</script>