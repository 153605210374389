<template>
  <div class="relative w-full">
    <input
        v-model="inputValue"
        :type="type"
        :placeholder="placeholder"
        class="w-full border rounded px-3 py-2 pr-10 focus:outline-none focus:ring-2 focus:ring-blue-500"
    />
    <button
        v-if="inputValue"
        @click="clearInput"
        class="absolute right-[0.7rem] top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-700"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-x"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg>
    </button>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";

const props = defineProps({
  modelValue: String,
  type: {
    type: String,
    default: "text",
  },
  placeholder: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["update:modelValue"]);

const inputValue = ref(props.modelValue || "");

watch(inputValue, (newValue) => {
  emit("update:modelValue", newValue);
});

const clearInput = () => {
  inputValue.value = "";
};
</script>
