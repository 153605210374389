<script setup>
import { defineProps, onMounted, onUnmounted, ref, watch } from 'vue'
import { useLocaleStore } from '@/js/stores/locale'

const localeStore = useLocaleStore()
const currentLocale = localeStore.currentLocale
const openDropDown = ref(null)
const sidebarOpen = ref(true)
const isMinimized = ref(false)

const dropdownOpen = ref(false)
const dropdownBOpen = ref(false)
const dropdownButtonRef = ref(null)
const dropdownButtonRefB = ref(null)

const toggleDropdownHeaderNav = () => {
  dropdownOpen.value = !dropdownOpen.value
}
const toggleDropdownBNav = () => {
  dropdownBOpen.value = !dropdownBOpen.value
}

// Custom composition function to handle click outside
const handleClickOutside = (event) => {
  if (dropdownButtonRef.value && !dropdownButtonRef.value.contains(event.target)) {
    dropdownOpen.value = false
  }
  if (dropdownButtonRefB.value && !dropdownButtonRefB.value.contains(event.target)) {
    dropdownBOpen.value = false
  }
}

onMounted(() => {
  const storedMinimizedState = localStorage.getItem('isMinimized')
  if (storedMinimizedState !== null) {
    isMinimized.value = storedMinimizedState === 'true'
  }
  document.addEventListener('click', handleClickOutside)
})

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside)
})
watch(isMinimized, (newValue) => {
  localStorage.setItem('isMinimized', newValue.toString())
})

const props = defineProps({
  user: {
    type: Object,
    required: true
  },
  navitems: {
    type: Object,
    required: true,
    default: () => ({})
  }
})
const toggleDropdown = (index) => {
  openDropDown.value = openDropDown.value === index ? null : index
}
</script>

<template>
  <section class="relative flex min-h-screen w-full items-start bg-[#F6F6FC]">
    <div
        :class="[
        sidebarOpen ? '-translate-x-full' : 'translate-x-0',
        isMinimized ? 'w-20' : 'w-[300px]'
      ]"
        class="z-10 fixed left-0 top-0 flex h-screen bg-white flex-col justify-between overflow-y-auto bg-netlinking_da shadow-testimonial-6 duration-200 xl:translate-x-0"
    >
      <div>
        <div v-if="sidebarOpen == true" class="flex items-center justify-between px-4 pt-5 pb-4 bg-netlinking_dark_2">

          <button
              class="p-[3px] rounded-lg text-white hover:bg-gray-800 transition-colors ml-auto"
              @click="isMinimized = !isMinimized"
          >
            <svg v-if="!isMinimized" class="lucide lucide-chevrons-left" fill="none" height="30" stroke="currentColor"
                 stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="30"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="m11 17-5-5 5-5"/>
              <path d="m18 17-5-5 5-5"/>
            </svg>
            <svg v-else class="lucide lucide-chevrons-right" fill="none" height="30" stroke="currentColor" stroke-linecap="round"
                 stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="30"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="m6 17 5-5-5-5"/>
              <path d="m13 17 5-5-5-5"/>
            </svg>
          </button>
        </div>

        <nav class="mt-5 ml-2">
          <div class="w-full flex justify-center">
            <img
                v-if="sidebarOpen == false"
                :src="'/images/logo-netlinking-noir.svg'"
                alt="logo Netlinking"
                class="h-12 mb-4 mt-24 lg:hidden block"
            />
          </div>

          <ul>
            <li
                v-for="(item, index) in navitems"
                :key="index"
                class="relative"
            >
              <template v-if="item.divider">
                <div v-if="!isMinimized" class="mx-4 my-5 h-px bg-gray-200"></div>
              </template>
              <template v-if="item.dividerText">
                <div v-if="!isMinimized"  class="mt-5 text-xs/6 font-semibold text-gray-400">
                  {{item.dividerText}}
                </div>
              </template>
              <template v-if="item.children">
                <div class="relative">
                  <a
                      :class="{
                  'border-netlinking bg-primary/5': openDropDown === index,
                  'border-transparent': openDropDown !== index,
                  'justify-center': isMinimized
                  }"
                      class="relative flex items-center border-r-4 py-[10px] px-4 text-base font-medium text-netlinking_text duration-200 hover:border-netlinking hover:bg-netlinking/5"
                      href="javascript:void(0)"
                      @click="isMinimized ? null : toggleDropdown(index)"
                      @mouseenter="isMinimized ? openDropDown = index : null"
                      @mouseleave="isMinimized ? openDropDown = null : null"
                  >
                    <span class="flex-shrink-0" v-html="item.icon"></span>
                    <span v-if="!isMinimized" class="ml-3">{{ item.label }}</span>
                    <span
                        v-if="!isMinimized"
                        :class="{
          'rotate-0': openDropDown !== index,
          'rotate-180': openDropDown === index
        }"
                        class="absolute top-1/2 right-4 -translate-y-1/2"
                    >
        <svg
            class="fill-current"
            fill="none"
            height="20"
            viewBox="0 0 20 20"
            width="20"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path
              d="M10 14.25C9.8125 14.25 9.65625 14.1875 9.5 14.0625L2.3125 7C2.03125 6.71875 2.03125 6.28125 2.3125 6C2.59375 5.71875 3.03125 5.71875 3.3125 6L10 12.5312L16.6875 5.9375C16.9688 5.65625 17.4062 5.65625 17.6875 5.9375C17.9688 6.21875 17.9688 6.65625 17.6875 6.9375L10.5 14C10.3437 14.1562 10.1875 14.25 10 14.25Z"/>
        </svg>
      </span>

                  </a>

                  <!-- Submenu -->
                  <div
                      v-show="openDropDown === index"
                      :class="{
        'absolute left-full top-0 ml-1 w-48 rounded-lg bg-black shadow-lg': isMinimized,
        'relative w-full': !isMinimized
      }"
                      @mouseenter="isMinimized ? openDropDown = index : null"
                      @mouseleave="isMinimized ? openDropDown = null : null"
                  >
                    <ul :class="[
        'py-[6px]',
        isMinimized ? 'px-4' : 'pl-[50px] pr-10'
      ]">
                      <li v-for="(childItem, childIndex) in item.children" :key="childIndex">
                        <a
                            class="flex items-center border-r-4 border-transparent py-[9px] text-base font-medium text-netlinking_text duration-200 hover:text-netlinking"
                            :href="item.path"
                        >
                          {{ childItem.label }}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </template>
              <template v-if="item.btn">
                <a
                    :class="[
        '!p-2 mx-4 my-5 flex items-center justify-between btn btn-netlinking',
        { 'justify-center': isMinimized }
    ]"
                    :href="item.path"
                >
                  <span v-if="!isMinimized" class="ml-3 !text-lg">{{ item.label }}</span>
                  <span v-html="item.icon"></span>

                </a>
              </template>
              <template v-if="!item.children && !item.btn">
                <a
                    :class="[
        'relative flex items-center  border-transparent py-[10px] px-4 text-base font-medium text-netlinking_text duration-200 border-r-4 hover:border-netlinking hover:bg-netlinking/5',
        { 'justify-center': isMinimized },
        item.isCurrent ? 'border-r-4 !border-netlinking bg-netlinking/5' : ''
    ]"
                    :href="item.path"
                >
                  <span class="flex-shrink-0" v-html="item.icon"></span>
                  <span v-if="!isMinimized" class="ml-3 w-full">{{ item.label }}</span>
                  <span v-if="!isMinimized && item.count" class="float-right">
                      <span :class="(item.count_class)?item.count_class:'bg-red-600'" class="ml-[10px] flex h-[18px] w-[18px] items-center justify-center rounded-full text-[10px] font-semibold text-white">
                        {{item.count}}
                        </span>
                    </span>
                </a>
              </template>

            </li>
          </ul>
        </nav>
      </div>


    </div>

    <div
        :class="sidebarOpen ? '-translate-x-full' : 'translate-x-0'"
        class="fixed top-0 left-0 h-screen w-full bg-dark bg-opacity-80 xl:hidden"
        @click="sidebarOpen = !sidebarOpen"
    ></div>

    <div
        :class="[
        isMinimized ? 'ml-20' : 'ml-0 xl:ml-[300px]',
      ]"
        class="w-full transition-all duration-200"
    >
      <!-- Header -->
      <header class="w-full sticky top-0 z-10">

        <div
            class="relative space-x-4 flex items-center justify-end bg-netlinking_dark_2 py-3 xl:py-3 pr-3 pl-[70px] sm:justify-between md:pr-8 md:pl-20 xl:pl-8"
        >
          <img
              v-if="sidebarOpen == true"
              :src="'/images/logo-netlinking-blanc.svg'"
              alt="logo Netlinking"
              class="h-12 lg:block hidden"
          />
          <button
              class="absolute left-4 top-1/2 flex h-[46px] w-[46px] -translate-y-1/2 items-center justify-center rounded-lg border border-stroke bg-white hover:bg-gray text-body-color xl:hidden"
              @click="sidebarOpen = false"
          >
            <svg class="lucide lucide-align-justify" fill="none" height="24" stroke="currentColor" stroke-linecap="round"
                 stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M3 12h18"/>
              <path d="M3 18h18"/>
              <path d="M3 6h18"/>
            </svg>
          </button>

          <div class="flex items-center justify-end space-x-6">
            <div class="text-white flex items-center justify-center text-2xl font-semibold space-x-2 relative">
              <button
                  ref="dropdownButtonRefB"
                  class="text-white inline-flex items-center justify-center gap-2 rounded-lg text-base font-medium"
                  @click="toggleDropdownBNav"
              >
                <div class="flex items-center space-x-2">
              <svg class="size-6" fill="none" stroke="currentColor" stroke-width="1.5"
                   viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
              <span>SOLDE :</span>
              <span>{{ $filters.numberFormatIntl(parseFloat(user.balance)) }} €</span>
                </div>
                <span :class="dropdownBOpen ? '-scale-y-100' : ''" class="duration-100">
              <svg
                  fill="none"
                  height="20"
                  viewBox="0 0 20 20"
                  width="20"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M10 14.25C9.8125 14.25 9.65625 14.1875 9.5 14.0625L2.3125 7C2.03125 6.71875 2.03125 6.28125 2.3125 6C2.59375 5.71875 3.03125 5.71875 3.3125 6L10 12.5312L16.6875 5.9375C16.9688 5.65625 17.4062 5.65625 17.6875 5.9375C17.9688 6.21875 17.9688 6.65625 17.6875 6.9375L10.5 14C10.3437 14.1562 10.1875 14.25 10 14.25Z"
                    fill="currentColor"
                />
              </svg>
            </span>
              </button>
              <div
                  v-show="dropdownBOpen"
                  class="absolute top-[38px] right-0 w-[225px] divide-y divide-stroke overflow-hidden rounded-lg bg-white"
              >
                <div>
                  <a
                      class="flex w-full items-center justify-between px-4 py-2.5 text-sm font-medium text-dark hover:bg-gray-50  "
                      :href="'/'+currentLocale+'/payment/create'"
                  >
                    <span>Créditer le compte</span>
                    <div class="flex items-center space-x-1 !text-gray-500">
                      <svg  class="w-[15px] h-[15px] !text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="#6a7282" d="M64 32C28.7 32 0 60.7 0 96l0 32 576 0 0-32c0-35.3-28.7-64-64-64L64 32zM576 224L0 224 0 416c0 35.3 28.7 64 64 64l448 0c35.3 0 64-28.7 64-64l0-192zM112 352l64 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-64 0c-8.8 0-16-7.2-16-16s7.2-16 16-16zm112 16c0-8.8 7.2-16 16-16l128 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-128 0c-8.8 0-16-7.2-16-16z"/></svg>
                      <svg class="w-[15px] h-[15px] !text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="#6a7282" d="M111.4 295.9c-3.5 19.2-17.4 108.7-21.5 134-.3 1.8-1 2.5-3 2.5H12.3c-7.6 0-13.1-6.6-12.1-13.9L58.8 46.6c1.5-9.6 10.1-16.9 20-16.9 152.3 0 165.1-3.7 204 11.4 60.1 23.3 65.6 79.5 44 140.3-21.5 62.6-72.5 89.5-140.1 90.3-43.4 .7-69.5-7-75.3 24.2zM357.1 152c-1.8-1.3-2.5-1.8-3 1.3-2 11.4-5.1 22.5-8.8 33.6-39.9 113.8-150.5 103.9-204.5 103.9-6.1 0-10.1 3.3-10.9 9.4-22.6 140.4-27.1 169.7-27.1 169.7-1 7.1 3.5 12.9 10.6 12.9h63.5c8.6 0 15.7-6.3 17.4-14.9 .7-5.4-1.1 6.1 14.4-91.3 4.6-22 14.3-19.7 29.3-19.7 71 0 126.4-28.8 142.9-112.3 6.5-34.8 4.6-71.4-23.8-92.6z"/></svg>
                      <svg class="w-[15px] h-[15px] !text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#6a7282" d="M243.4 2.6l-224 96c-14 6-21.8 21-18.7 35.8S16.8 160 32 160l0 8c0 13.3 10.7 24 24 24l400 0c13.3 0 24-10.7 24-24l0-8c15.2 0 28.3-10.7 31.3-25.6s-4.8-29.9-18.7-35.8l-224-96c-8-3.4-17.2-3.4-25.2 0zM128 224l-64 0 0 196.3c-.6 .3-1.2 .7-1.8 1.1l-48 32c-11.7 7.8-17 22.4-12.9 35.9S17.9 512 32 512l448 0c14.1 0 26.5-9.2 30.6-22.7s-1.1-28.1-12.9-35.9l-48-32c-.6-.4-1.2-.7-1.8-1.1L448 224l-64 0 0 192-40 0 0-192-64 0 0 192-48 0 0-192-64 0 0 192-40 0 0-192zM256 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg>
                    </div>
                  </a>
                  <a
                      class="flex w-full items-center justify-between px-4 py-2.5 text-sm font-medium text-dark hover:bg-gray-50  "
                      :href="'/'+currentLocale+'/profile/invoices'"
                  >
                    <span>Mes factures</span>
                    <div>
                      <svg class="w-[15px] h-[15px] text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="#6a7282" d="M64 0C28.7 0 0 28.7 0 64L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-288-128 0c-17.7 0-32-14.3-32-32L224 0 64 0zM256 0l0 128 128 0L256 0zM80 64l64 0c8.8 0 16 7.2 16 16s-7.2 16-16 16L80 96c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64l64 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-64 0c-8.8 0-16-7.2-16-16s7.2-16 16-16zm16 96l192 0c17.7 0 32 14.3 32 32l0 64c0 17.7-14.3 32-32 32L96 352c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32zm0 32l0 64 192 0 0-64L96 256zM240 416l64 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-64 0c-8.8 0-16-7.2-16-16s7.2-16 16-16z"/></svg>
                    </div>
                  </a>
                </div>

                <div>
                  <a
                      class="flex w-full items-center justify-between px-4 py-2.5 text-sm font-medium text-dark hover:bg-gray-50  "
                      :href="'/'+currentLocale+'/orders/list/'"
                  >
                    <span>Mes commandes</span>
                    <div>
                      <svg class="w-[15px] h-[15px] text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="#6a7282" d="M579.8 267.7c56.5-56.5 56.5-148 0-204.5c-50-50-128.8-56.5-186.3-15.4l-1.6 1.1c-14.4 10.3-17.7 30.3-7.4 44.6s30.3 17.7 44.6 7.4l1.6-1.1c32.1-22.9 76-19.3 103.8 8.6c31.5 31.5 31.5 82.5 0 114L422.3 334.8c-31.5 31.5-82.5 31.5-114 0c-27.9-27.9-31.5-71.8-8.6-103.8l1.1-1.6c10.3-14.4 6.9-34.4-7.4-44.6s-34.4-6.9-44.6 7.4l-1.1 1.6C206.5 251.2 213 330 263 380c56.5 56.5 148 56.5 204.5 0L579.8 267.7zM60.2 244.3c-56.5 56.5-56.5 148 0 204.5c50 50 128.8 56.5 186.3 15.4l1.6-1.1c14.4-10.3 17.7-30.3 7.4-44.6s-30.3-17.7-44.6-7.4l-1.6 1.1c-32.1 22.9-76 19.3-103.8-8.6C74 372 74 321 105.5 289.5L217.7 177.2c31.5-31.5 82.5-31.5 114 0c27.9 27.9 31.5 71.8 8.6 103.9l-1.1 1.6c-10.3 14.4-6.9 34.4 7.4 44.6s34.4 6.9 44.6-7.4l1.1-1.6C433.5 260.8 427 182 377 132c-56.5-56.5-148-56.5-204.5 0L60.2 244.3z"/></svg>
                    </div>
                  </a>
                </div>
              </div>
            </div>

            <div ref="domNode" class="relative flex items-center justify-center">
              <button
                  ref="dropdownButtonRef"
                  class="text-white inline-flex items-center justify-center gap-2 rounded-lg text-base font-medium"
                  @click="toggleDropdownHeaderNav"
              >
                <div class="w-full max-w-[48px] cursor-pointer rounded-full">
                  <img
                      v-if="user.profilePhoto"
                      :src="user.profilePhoto"
                      alt="account"
                      class="h-[48px] w-[48px] rounded-full object-cover object-center"
                  />
                  <span v-else :class="user.color"
                        class="inline-flex h-[48px] w-[48px] items-center justify-center rounded-full ">
                      <span class="text-sm font-medium text-white">
                        {{$filters.capitalizeFirstLetter(user.firstName)}}{{$filters.capitalizeFirstLetter(user.lastName)}}
                      </span>
                    </span>

                </div>
                <span :class="dropdownOpen ? '-scale-y-100' : ''" class="duration-100">
              <svg
                  fill="none"
                  height="20"
                  viewBox="0 0 20 20"
                  width="20"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M10 14.25C9.8125 14.25 9.65625 14.1875 9.5 14.0625L2.3125 7C2.03125 6.71875 2.03125 6.28125 2.3125 6C2.59375 5.71875 3.03125 5.71875 3.3125 6L10 12.5312L16.6875 5.9375C16.9688 5.65625 17.4062 5.65625 17.6875 5.9375C17.9688 6.21875 17.9688 6.65625 17.6875 6.9375L10.5 14C10.3437 14.1562 10.1875 14.25 10 14.25Z"
                    fill="currentColor"
                />
              </svg>
            </span>
              </button>
              <div
                  v-show="dropdownOpen"
                  class="absolute right-0 top-full w-[300px] divide-y divide-stroke overflow-hidden rounded-lg bg-white"
              >
                <div class="flex items-center gap-3 px-4 py-3">
                  <div class="relative aspect-square w-10 rounded-full">
                    <img
                        v-if="user.profilePhoto"
                        :src="user.profilePhoto"
                        alt="account"
                        class="size-9 rounded-full object-cover object-center"

                    />
                    <span v-else :class="user.color"
                          class="inline-flex size-9 items-center justify-center rounded-full ">
                      <span class="text-xs font-medium text-white">{{$filters.capitalizeFirstLetter(user.firstName)}}{{$filters.capitalizeFirstLetter(user.lastName)}}</span>
                    </span>

                  </div>
                  <div>
                    <p class="text-sm font-semibold text-dark ">
                      {{ user.firstName }} {{ user.lastName }}
                    </p>
                    <p class="text-sm text-body-color
">{{ user.email }}</p>
                    <p class="mt-2 text-sm font-semibold">
                      <a :href="'/'+currentLocale+'/profile/'" class="link-netlinking">Compte et profil</a>
                    </p>
                  </div>
                </div>
                <div>
                  <a
                      class="flex w-full items-center justify-between px-4 py-2.5 text-sm font-medium text-dark hover:bg-gray-50  "
                      :href="'/'+currentLocale+'/payment/create'"
                  >
                    Créditer le compte
                  </a>
                  <a
                      class="flex w-full items-center justify-between px-4 py-2.5 text-sm font-medium text-dark hover:bg-gray-50  "
                      :href="'/'+currentLocale+'/profile/invoices'"
                  >
                    Gérer la facturation
                  </a>

                </div>
                <div>
                  <a
                      class="flex w-full items-center justify-between px-4 py-2.5 text-sm font-medium text-dark hover:bg-gray-50  "
                      :href="'/'+currentLocale+'/support-team/'"
                  >
                    Contacter le support
                  </a>

                </div>
                <div>
                  <a
                      class="flex w-full items-center justify-between px-4 py-2.5 text-sm font-medium text-dark hover:bg-gray-50  "
                      :href="'/'+currentLocale+'/g-s-c/'"
                      target="_blank"
                  >
                    CGV
                  </a>
                  <a
                      class="flex w-full items-center justify-between px-4 py-2.5 text-sm font-medium text-dark hover:bg-gray-50  "
                      :href="'/'+currentLocale+'/legal-information/'"
                      target="_blank"
                  >
                    Politique de confidentialité
                  </a>

                </div>
                <div>
                  <a
                      class="flex w-full items-center justify-between px-4 py-2.5 text-sm font-medium text-dark hover:bg-gray-50  "
                      href="/logout/"
                  >
                    Se déconnecter
                  </a>
                </div>
              </div>
            </div>
            <!--            <div class="group relative">-->
            <!--              <div class="h-12 w-full max-w-[48px] cursor-pointer rounded-full">-->
            <!--                <img-->
            <!--                    src="https://cdn.tailgrids.com/2.0/image/assets/images/avatar/image-01.jpg"-->
            <!--                    alt="avatar"-->
            <!--                    class="h-full w-full rounded-full object-cover object-center"-->
            <!--                />-->
            <!--              </div>-->

            <!--              <div-->
            <!--                  class="invisible absolute right-0 top-[120%] mt-3 w-[200px] space-y-2 rounded bg-white p-3 opacity-0 shadow-card-2 duration-200 group-hover:visible group-hover:top-full group-hover:opacity-100"-->
            <!--              >-->
            <!--                <template v-for="(item, index) in userDropdownItems" :key="index">-->
            <!--                  <a-->
            <!--                      :href="item.href"-->
            <!--                      class="block rounded px-4 py-2 text-sm font-medium text-body-color hover:bg-gray-2 hover:text-primary"-->
            <!--                  >-->
            <!--                    {{ item.label }}-->
            <!--                  </a>-->
            <!--                </template>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
        </div>
      </header>

      <!-- Main Content -->
      <div class="pt-[16px] pb-[30px] px-[30px] xl:px-[40px]">
        <div>
          <slot name="contains">
          </slot>

        </div>



      </div>
        <div class="fixed bottom-0 left-0 w-full text-center z-10">
          <span class="text-gray-500 !text-xss">{{ new Date().getFullYear() }} © Netlinking.com</span>
        </div>



      <!-- Footer -->

    </div>

  </section>

</template>
