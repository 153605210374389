<template>
  <div class="mt-2 flex flex-col">
    <div
        v-if="loadingStore.loadingPage"
        class="absolute inset-0 bg-white/60 backdrop-blur-sm z-10 flex items-center justify-center"
    >
      <div class="flex flex-col items-center gap-2">
        <div class="animate-spin rounded-full h-8 w-8 border-b-2 border-need-1"></div>
        <span class="text-sm text-gray-600">{{ $t('loading') }}...</span>
      </div>
    </div>
    <!-- Table Section -->

    <template v-if="datas && Object.keys(datas).length > 0">
      <div class="relative overflow-x-auto">
        <table :class="props.classtable">
          <thead>
          <tr>
            <th
                v-for="th in props.ths"
                :key="th.field"
                :class="[
                pagination.sort === th.field ? 'text-need-3' : 'text-need-2',
                th.classTD,
              ]"
                scope="col"
            >
              <template v-if="th.isAction">
                {{ $t(th.label) }}
              </template>
              <a
                  v-else-if="th.field"
                  :class="[
                  th.classTD === 'text-center'
                    ? 'justify-center'
                    : 'content-center',
                  'group inline-flex',
                ]"
                  href="#"
                  @click.prevent.stop="handleSort(th.field)"
              >
                <span>{{ $t(th.label) }}</span>
                <span
                    :class="[
                    pagination.sort === th.field
                      ? ''
                      : 'invisible group-hover:visible group-focus:visible',
                    'float-right text-gray-400',
                  ]"
                >

                  <svg v-if="pagination.direction === 'DESC'" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-down"><path d="m6 9 6 6 6-6"/></svg>
                  <svg  v-else-if="pagination.direction === 'ASC'" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-up"><path d="m18 15-6-6-6 6"/></svg>

                </span>
              </a>
            </th>
          </tr>
          </thead>
          <tbody>
          <slot></slot>
          </tbody>
        </table>
      </div>
    </template>
    <template v-else>
      <div class="rounded-md bg-blue-50">
        <div class="flex">
          <div class="flex-shrink-0">
            <svg xmlns="http://www.w3.org/2000/svg" class="lucide lucide-info h-5 w-5 text-blue-400" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" ><circle cx="12" cy="12" r="10"/><path d="M12 16v-4"/><path d="M12 8h.01"/></svg>
          </div>
          <div class="ml-3 flex-1 md:flex md:justify-between">
            <p class="text-sm text-blue-700">{{ $t('no_data_found') }}</p>
          </div>
        </div>
      </div>
    </template>


    <!-- Pagination Section -->
    <div class="flex space-x-4" v-if="pagination && pagination.total > 0" :class="{'bg-gray-200': loading.unit}">
      <!-- Per Page Selector -->
      <div class="w-1/12 border-t border-gray-200">
        <select
            class="input mt-1 w-full pr-4"
            style="width: 100px"
            v-model="pagination.per_page"
            @change="handlePerPageChange"
        >
          <option disabled :value="null">{{ $t('per_page') }}</option>
          <option v-for="size in [10, 30, 50, 100, 200, 300, 500, 1000]" :key="size" :value="size">
            {{ size }}
          </option>
        </select>
      </div>

      <!-- Page Navigation -->
      <div class="w-11/12">
        <nav class="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
          <!-- Previous Button -->
          <div class="-mt-px flex w-0 flex-1">
            <button
                :disabled="pagination.page == 1 || loading.unit"
                @click.prevent.stop="handlePageChange(pagination.page - 1)"
                class="px-4 mt-1 py-2 bg-gray-300 rounded disabled:opacity-50 flex items-center"

            >
              <svg class="mr-3 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M18 10a.75.75 0 01-.75.75H4.66l2.1 1.95a.75.75 0 11-1.02 1.1l-3.5-3.25a.75.75 0 010-1.1l3.5-3.25a.75.75 0 111.02 1.1l-2.1 1.95h12.59A.75.75 0 0118 10z" clip-rule="evenodd" />
              </svg>
              <template v-if="loading.unit">
                .......
              </template>
              <template v-else>Précédent</template>
            </button>
          </div>

          <!-- Page Numbers -->
          <div class="hidden md:-mt-px md:flex">
            <template v-for="pageNbre in pagination.nbre_pages" :key="pageNbre">
              <button
                  v-if="shouldShowPage(pageNbre)"
                  :class="[
                  'inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  pagination.page == pageNbre ? 'border-need-1 font-bold text-need-1' : 'border-transparent'
                ]"
                  :disabled="loading.unit"
                  @click.prevent.stop="handlePageChange(pageNbre)"
              >
                {{ pageNbre }}
              </button>
            </template>
          </div>

          <!-- Next Button -->
          <div class="-mt-px flex w-0 flex-1 justify-end">
            <button
                @click.prevent.stop="handlePageChange(pagination.page + 1)"
                :disabled="pagination.page == pagination.nbre_pages || loading.unit"
                class="mt-1 px-4 py-2 flex items-center bg-gray-300 rounded disabled:opacity-50"

            >
              <template v-if="loading.unit">
                .......
              </template>
              <template v-else>Suivant</template>
              <svg class="ml-3 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M2 10a.75.75 0 01.75-.75h12.59l-2.1-1.95a.75.75 0 111.02-1.1l3.5 3.25a.75.75 0 010 1.1l-3.5 3.25a.75.75 0 11-1.02-1.1l2.1-1.95H2.75A.75.75 0 012 10z" clip-rule="evenodd" />
              </svg>
            </button>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script setup>
import axios from 'axios';
import { useLoadingStore } from '@/js/stores/loading';
const loadingStore = useLoadingStore()
const props = defineProps({
  classtable: {
    type: String,
    default: 'striped',
  },
  ths: {
    type: Array,
    default: () => [],
  },
  datas: {
    type: Object,
    required: true,
  },
  pagination: {
    type: Object,
    required: true,
  },
  loading: {
    type: Object,
    default: () => ({ unit: false }),
  }
});

const emit = defineEmits(['sort', 'update:pagination', 'update:datas']);
const getFieldValue = (data, fieldName) => {
  // Case 1: data is an array of objects with field property
  if (Array.isArray(data)) {
    const fieldObject = data.find(item => item.field === fieldName);
    if (fieldObject) {
      return fieldObject.value;
    }
  }
  // Case 2: data is an object with arrays as values
  else if (typeof data === 'object' && data !== null) {
    // First try to find direct match (if data is an object with field property)
    if (data.field === fieldName) {
      return data.value;
    }

    // Then loop through properties
    for (const key in data) {
      // If property is an array, search in it
      if (Array.isArray(data[key])) {
        const fieldObject = data[key].find(item => item.field === fieldName);
        if (fieldObject) {
          return fieldObject.value;
        }
      }
      // If property is an object, check if it's the field we're looking for
      else if (typeof data[key] === 'object' && data[key] !== null) {
        if (data[key].field === fieldName) {
          return data[key].value;
        }
      }
    }
  }

  return null; // Return null if field is not found
}
const handleSort = async (field) => {
  loadingStore.setLoadingPage(true);

  // Toggle direction if clicking on same field
  const newDirection = field === props.pagination.sort && props.pagination.direction === 'DESC' ? 'ASC' : 'DESC';

  const url = urlAddParams(
      ['sort', 'direction'],
      [field, newDirection]
  );

  try {
    const response = await axios.post(url.href, { action: 'pagination' });

    if (response.data) {
      // Update URL without refresh
      window.history.pushState({}, '', url.href);
      if(response.data.extra_update_multiple){
        emit('update:datas', getFieldValue(response.data.extra_update_multiple,'datas'));
        emit('update:pagination', getFieldValue(response.data.extra_update_multiple,'pagination'));
      }

    }
  } catch (error) {
    console.error('Sort error:', error);
  }finally {
    loadingStore.setLoadingPage(false);

  }
};

const shouldShowPage = (pageNbre) => {
  const { page, nbre_pages } = props.pagination;
  return (
      nbre_pages <= 9 ||
      pageNbre === page ||
      pageNbre < 3 ||
      pageNbre + 3 > nbre_pages ||
      (pageNbre > page && pageNbre - page < 5) ||
      (pageNbre < page && page - pageNbre < 5)
  );
};

const urlAddParams = (paramNames, paramValues) => {
  const url = new URL(window.location.href);
  for (let i = 0; i < paramNames.length; i++) {
    url.searchParams.set(paramNames[i], paramValues[i]);
  }
  return url;
};

const handlePageChange = async (newPage) => {
  loadingStore.setLoadingPage(true);


  const url = urlAddParams(['page'], [newPage]);

  try {
    const response = await axios.post(url.href, { action: 'pagination' });

    if (response.data) {
      // Update URL without refresh
      if (response.data.replace_url	){
        window.history.pushState({}, '', response.data.replace_url	);

      }
      // Update data and pagination
      if(response.data.extra_update_multiple){
        emit('update:datas', getFieldValue(response.data.extra_update_multiple,'datas'));
        emit('update:pagination', getFieldValue(response.data.extra_update_multiple,'pagination'));
      }
    }
  } catch (error) {
    console.error('Pagination error:', error);
  }finally {
    loadingStore.setLoadingPage(false);

  }
};

const handlePerPageChange = async () => {
  loadingStore.setLoadingPage(true);


  const url = urlAddParams(['per_page', 'page'], [props.pagination.per_page, 1]);

  try {
    const response = await axios.post(url.href, { action: 'pagination' });

    if (response.data) {
      // Update URL without refresh
      if (response.data.replace_url	){
        window.history.pushState({}, '', response.data.replace_url	);

      }
      // Update data and pagination
      if (response.data.datas_update) {
        emit('update:datas', response.data.datas_update);
      }
      if (response.data.extra_update) {
        emit('update:pagination', response.data.extra_update.value);
      }
    }
  } catch (error) {
    console.error('Per page change error:', error);
  }finally {
    loadingStore.setLoadingPage(false);

  }
};
</script>
<style scoped>
.backdrop-blur-sm {
  backdrop-filter: blur(4px);
}

.pointer-events-none {
  pointer-events: none;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}
</style>
