<script setup>
import { computed, defineProps, onMounted, reactive, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useLoadingStore } from '@/js/stores/loading'
import { useLocaleStore } from '@/js/stores/locale'
import LoadingBtn from '@/components/common/LoadingBtn.vue'
import Countryselect from '@/components/common/phone/Countryselect.vue'
import * as yup from 'yup'
import { ErrorMessage, Field, Form } from 'vee-validate'
import axios from 'axios'
import Selectn from '@/components/common/Selectn.vue'

const emit = defineEmits(['all-ok', 'close-model'])
const path = '/payment/ajax/'
const { t } = useI18n()
const localeStore = useLocaleStore()
const loadingStore = useLoadingStore()
const currentLocale = localeStore.currentLocale

const props = defineProps({
  default_total_excl_tax: {
    required: true
  },
  paymentmethods: {
    type: Object,
    required: true
  },
  countries: {
    type: Object,
    required: true
  },
  eucountries: {
    type: Object,
    required: true
  },
  user: {
    type: Object,
    required: true,
    default: { email: '--', firstName: '--', lastName: '--', roles: '--' }
  },
  addressData: {
    type: Object,
    required: false,
    default: null
  }
})
const billingAddress = ref([])
const formValues = reactive({
  billingAddressName: '',
  company: '',
  lastName: '',
  firstName: '',
  billingEmails: [''],
  address: '',
  addressComplement: '',
  postalCode: '',
  city: '',
  country: 'france',
  total_excl_tax: 500,
  vatNumber: '',
  vat: 20,
  total_incl_tax: 600,
  total_vat: 100,
  payment_method: 'bank_card',
  cgv: false,
  making_bank_transfert: false,
  isDefault: false,
  invoice_label: ''
})
const schema_0 = computed(() => {
  return yup.object().shape({
    cgv: yup.boolean().required(t('validation.required')).test('is-true', t('validation.cgv_required_simple'), value => value === true),
    billingAddressName: yup
        .string()
        .required(t('validation.required')),
    company: yup
        .string(),
    lastName: yup
        .string()
        .required(t('validation.required')),
    firstName: yup
        .string()
        .required(t('validation.required')),
    billingEmails: yup.array().of(
        yup
            .string()
            .required(t('validation.required'))
            .email(t('validation.invalid_email_format'))
    ).min(1, t('validation.required')),
    address: yup
        .string()
        .required(t('validation.required')),
    addressComplement: yup
        .string(),
    postalCode: yup
        .string()
        .required(t('validation.required')),
    city: yup
        .string()
        .required(t('validation.required')),
    country: yup
        .string()
        .required(t('validation.required')),
    vatNumber: yup
        .string(),
    isDefault: yup.string(),
    total_excl_tax: yup
        .number(t('validation.must_be_a_number'))
        .transform((value, originalValue) => {
          // Transform empty string to null so Yup can handle it properly
          return originalValue === '' ? null : value
        })
        .required(t('validation.required')).when('payment_method', {
          is: 'paypal',
          then: (schema) => schema.min(50, t('validation.min_value', { value: 50 + '€' })),
          otherwise: (schema) => schema.min(1, t('validation.min_value', { value: 1 + '€' }))
        }),
    making_bank_transfert: yup.boolean().when('payment_method', {
      is: 'bank_transfer',
      then: (schema) => schema.oneOf([true], t('validation.making_bank_transfert_required'))
    })
  })
})
const onSubmit_0 = async (values) => {
  loadingStore.setLoadingPage(true)
  const response = await axios.post(path, {
    action: 'new_bill',
    datas: { values: formValues }
  })
  if (response.status === 200) {
    window.location.href = response.data.redirect
  }

}
const findCountry = (countryCode, countries) => {
  return countryCode in countries
}

const addBillingEmail = () => {
  formValues.billingEmails.push('')
}

const removeBillingEmail = (index) => {
  if (formValues.billingEmails.length > 1) {
    formValues.billingEmails.splice(index, 1)
  }
}
onMounted(async () => {
  await fetchBillingAddress()
  await vatFetch()
  formValues.total_excl_tax = props.default_total_excl_tax
})
const calcultotalInclTax = () => {
  formValues.total_vat = (formValues.vat / 100) * formValues.total_excl_tax
  formValues.total_incl_tax = (formValues.payment_method == 'paypal') ? (1 + ((formValues.vat + 3.5) / 100)) * formValues.total_excl_tax : (1 + (formValues.vat / 100)) * formValues.total_excl_tax
}

watch(
    () => [formValues.billingAddressName],
    async () => {
      await billingAddressFetch()
    }
)
watch(
    () => [formValues.country, formValues.vatNumber],
    async () => {
      await vatFetch()
    }
)
watch(
    () => [formValues.total_excl_tax, formValues.payment_method, formValues.vat],
    () => {
      formValues.total_incl_tax = (formValues.total_excl_tax * (1 + formValues.vat / 100))
      calcultotalInclTax()
    }
)
const vatFetch = async () => {
  loadingStore.setLoading(true)
  const response = await axios.post(path, {
    action: 'fetch_tva',
    datas: { country: formValues.country, vatNumber: formValues.vatNumber }
  })
  if (response.status === 200) {
    formValues.vat = response.data.vat
    calcultotalInclTax()
  }
  loadingStore.setLoading(false)

}

const billingAddressFetch = async () => {
  loadingStore.setLoading(true)
  try {
    const response = await axios.post(path, {
      action: 'fetch_billing_address',
      datas: { id: formValues.billingAddressName }
    })
    if (response.status === 200) {
      let billingAddressInfo = response.data.billingAddress
      if (billingAddressInfo) {
        formValues.company = billingAddressInfo.company ?? ''
        formValues.lastName = billingAddressInfo.lastName ?? ''
        formValues.firstName = billingAddressInfo.firstName ?? ''
        formValues.address = billingAddressInfo.address ?? ''
        formValues.postalCode = billingAddressInfo.postalCode ?? ''
        formValues.vatNumber = billingAddressInfo.vatNumber ?? ''
        formValues.country = billingAddressInfo.country ?? ''
        formValues.city = billingAddressInfo.city ?? ''
        formValues.billingEmails = billingAddressInfo.billingEmails ?? []
      }
    }
  } catch (error) {
    if (error.response && error.response.status === 500) {
    }
  } finally {
    loadingStore.setLoading(false)
  }

}
const fetchBillingAddress = async (values) => {
  loadingStore.setLoading(true)
  try {
  const response = await axios.post(path, {
    action: 'fetch_all_billing_address',
    datas: {}
  })
  if (response.status === 200) {
    billingAddress.value = response.data.billingAddress.map(({ id, billingAddressName }) => ({
      id,
      label: billingAddressName
    }))
    const defaultBilling = response.data.billingAddress.find(({ isDefault }) => isDefault === true)
    if (defaultBilling) {
      formValues.billingAddressName = defaultBilling.id
      formValues.company = defaultBilling.company
      formValues.lastName = defaultBilling.lastName
      formValues.firstName = defaultBilling.firstName
      formValues.address = defaultBilling.address
      formValues.postalCode = defaultBilling.postalCode
      formValues.vatNumber = defaultBilling.vatNumber
      formValues.country = defaultBilling.country
      formValues.city = defaultBilling.city
      formValues.billingEmails = defaultBilling.billingEmails
    }
  }
  } catch (error) {
    if (error.response && error.response.status === 500) {
    }
  } finally {
    loadingStore.setLoading(false)
  }
}
// const fetchBillingAddress = async (values) => {
//   loadingStore.setLoading(true)
//   const response = await axios.post(path, {
//     action: 'fetch_billing_address',
//     datas: {}
//   })
//   if (response.status === 200) {
//     formValues.billingAddressName = response.data.billingAddress.billingAddressName
//     formValues.company = response.data.billingAddress.company
//     formValues.lastName = response.data.billingAddress.lastName
//     formValues.firstName = response.data.billingAddress.firstName
//     formValues.address = response.data.billingAddress.address
//     formValues.postalCode = response.data.billingAddress.postalCode
//     formValues.vatNumber = response.data.billingAddress.vatNumber
//     formValues.country = response.data.billingAddress.country
//     formValues.city = response.data.billingAddress.city
//   }
//   loadingStore.setLoading(false)
// }
</script>

<template>
  <!-- ====== Checkout Section Start -->
  <Form id="userForm" v-slot="{ errors }" :validation-schema="schema_0" @submit="onSubmit_0">

    <div class="lg:flex block lg:flex-wrap -mx-4">
      <div class="w-full px-4 lg:w-7/12">
        <div class="mb-12 lg:mb-0 xl:mr-4 2xl:mr-8">
          <div
              class="bg-white shadow-testimonial-6 mb-10 rounded-[10px] border border-stroke py-4 px-6 sm:px-10"
          >
            <h4
                class="text-lg font-semibold mb-5 text-dark sm:text-2xl lg:text-lg xl:text-xl"
            >
              Adresse de facturation
            </h4>
            <div class="mb-5 space-y-3">
              <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-5">
                <div>
                  <label for="billingAddressName">Sélectionnez une adresse enregistrée ou donnez un nom à cette
                    adresse<sup class="text-red-600">*</sup></label>
                  <div class="relative">
                    <Field
                        v-slot="{ field, errors }"
                        v-model="formValues.billingAddressName"
                        :class="[errors.billingAddressName ? 'ring-2 ring-red-600' : '']"
                        name="billingAddressName"
                    >
                      <Selectn
                          v-model="formValues.billingAddressName"
                          :allowNewOption="true"
                          :options="billingAddress"
                          placeholder="Nom"
                      />
                    </Field>
                    <ErrorMessage class="error-message" name="billingAddressName"/>
                  </div>
                </div>
              </div>
              <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-5">
                <div>
                  <label for="company">Société</label>
                  <div class="relative">
                    <Field
                        v-model="formValues.company"
                        :class="[errors.company ? 'ring-2 ring-red-600' : '']"
                        :placeholder="'Nom de votre société'"
                        name="company"
                        type="text"
                    />
                    <ErrorMessage class="error-message" name="company"/>
                  </div>
                </div>
              </div>
              <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-5">
                <div>
                  <label for="lastName">Nom<sup class="text-red-600">*</sup></label>
                  <div class="relative">
                    <Field
                        v-model="formValues.lastName"
                        :class="[errors.lastName ? 'ring-2 ring-red-600' : '']"
                        :placeholder="'Nom'"
                        name="lastName"
                        type="text"
                    />
                    <ErrorMessage class="error-message" name="lastName"/>
                  </div>
                </div>

                <div>
                  <label for="firstName">Prénom<sup class="text-red-600">*</sup></label>
                  <div class="relative">
                    <Field
                        v-model="formValues.firstName"
                        :class="[errors.firstName ? 'ring-2 ring-red-600' : '']"
                        :placeholder="'Prénom'"
                        name="firstName"
                        type="text"
                    />
                    <ErrorMessage class="error-message" name="firstName"/>
                  </div>
                </div>
              </div>
              <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-5">
                <div>
                  <label class="mb-0" for="billingEmails">Emails de facturation<sup class="text-red-600">*</sup></label>
                  <p class="text-xs text-gray-500 mb-1.5">
                    Tous les e-mails relatifs à la facturation seront envoyés à votre adresse e-mail et ces contacts de
                    facturation
                  </p>
                  <div v-for="(email, index) in formValues.billingEmails" :key="index"
                       class="flex justify-center items-start space-x-4 mb-2">
                    <div class="w-full">
                      <Field
                          v-model="formValues.billingEmails[index]"
                          :class="[errors[`billingEmails[${index}]`] ? 'ring-2 ring-red-600' : '']"
                          :name="`billingEmails.${index}`"
                          placeholder="Cette adresse email recevra la facture"
                          type="email"
                      />
                      <ErrorMessage :name="`billingEmails[${index}]`" class="error-message"/>
                    </div>
                    <div class="w-full flex space-x-2">
                      <button
                          v-if="formValues.billingEmails.length > 1"
                          class="text-gray-500 hover:text-gray-900 mt-2 mr-4"
                          type="button"
                          @click="removeBillingEmail(index)">
                        <svg class="lucide lucide-x" fill="none" height="24" stroke="currentColor"
                             stroke-linecap="round"
                             stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24"
                             xmlns="http://www.w3.org/2000/svg">
                          <path d="M18 6 6 18"/>
                          <path d="m6 6 12 12"/>
                        </svg>
                      </button>
                      <button
                          v-if="index === formValues.billingEmails.length - 1"
                          class="text-dark hover:text-netlinking mt-2"
                          type="button"
                          @click="addBillingEmail">
                        + Ajouter un contact
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-5">
                <div>
                  <label class="mb-0" for="address">Adresse<sup class="text-red-600">*</sup></label>
                  <div class="relative">
                    <Field
                        v-model="formValues.address"
                        :class="[errors.address ? 'ring-2 ring-red-600' : '']"
                        :placeholder="'Adresse postale'"
                        name="address"
                        type="text"
                    />
                    <ErrorMessage class="error-message" name="address"/>
                  </div>
                </div>
              </div>
              <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-5">

                <div>
                  <label class="mb-0" for="addressComplement">Complément d'adresse (facultatif)</label>
                  <div class="relative">
                    <Field
                        v-model="formValues.addressComplement"
                        :class="[errors.addressComplement ? 'ring-2 ring-red-600' : '']"
                        :placeholder="'Entreprise, bâtiment, étage'"
                        name="addressComplement"
                        type="text"
                    />
                    <ErrorMessage class="error-message" name="addressComplement"/>
                  </div>
                </div>
              </div>
              <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-5">

                <div>
                  <label class="mb-0" for="postalCode">Code postal<sup class="text-red-600">*</sup></label>
                  <div class="relative">
                    <Field
                        v-model="formValues.postalCode"
                        :class="[errors.postalCode ? 'ring-2 ring-red-600' : '']"
                        :placeholder="'Code postal'"
                        name="postalCode"
                        type="text"
                    />
                    <ErrorMessage class="error-message" name="postalCode"/>
                  </div>
                </div>

                <div>
                  <label class="mb-0" for="city">Ville<sup class="text-red-600">*</sup></label>
                  <div class="relative">
                    <Field
                        v-model="formValues.city"
                        :class="[errors.city ? 'ring-2 ring-red-600' : '']"
                        :placeholder="'Ville'"
                        name="city"
                        type="text"
                    />
                    <ErrorMessage class="error-message" name="city"/>
                  </div>
                </div>

                <div>
                  <label class="mb-0" for="country">Pays<sup class="text-red-600">*</sup></label>
                  <div class="relative">
                    <Field v-slot="{ field, errors }" v-model="formValues.country" name="country"
                    >
                      <Countryselect v-model="formValues.country" :countries="countries"/>
                    </Field>
                    <ErrorMessage class="error-message" name="country"/>
                  </div>
                </div>

                <div v-if="findCountry(formValues.country,eucountries) && formValues.company !== ''">
                  <label class="mb-0" for="vatNumber">Numéro de TVA</label>
                  <div class="relative">
                    <Field
                        v-model="formValues.vatNumber"
                        :class="[errors.vatNumber ? 'ring-2 ring-red-600' : '']"
                        :placeholder="'N° de TVA intracommunautaire'"
                        name="vatNumber"
                        type="text"
                    />
                    <ErrorMessage class="error-message" name="vatNumber"/>
                  </div>
                </div>


              </div>
              <div class="pt-3">
                <div class="flex gap-3">
                  <div class="flex h-6 shrink-0 items-center">
                    <div class="group grid grid-cols-1">
                      <Field v-slot="{ field, errors }" v-model="formValues.isDefault"
                             :class="[errors.isDefault && Object.keys(errors.isDefault).length > 0 ? 'ring-2 ring-red-600' : '']"
                             name="isDefault">
                        <input id="isDefault" v-model="formValues.isDefault" aria-describedby="comments-description"
                               class="col-start-1 row-start-1 appearance-none rounded border border-gray-300 bg-white checked:border-netlinking checked:bg-netlinking indeterminate:border-netlinking indeterminate:bg-netlinking focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-netlinking disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
                               name="isDefault"
                               type="checkbox"/>
                        <svg
                            class="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-[:disabled]:stroke-gray-950/25"
                            fill="none" viewBox="0 0 14 14">
                          <path class="opacity-0 group-has-[:checked]:opacity-100" d="M3 8L6 11L11 3.5"
                                stroke-linecap="round"
                                stroke-linejoin="round" stroke-width="2"/>
                          <path class="opacity-0 group-has-[:indeterminate]:opacity-100" d="M3 7H11"
                                stroke-linecap="round"
                                stroke-linejoin="round" stroke-width="2"/>
                        </svg>
                      </Field>
                    </div>

                  </div>

                  <div class="text-sm/6">
                    <label class="text-gray-900 font-normal" for="isDefault">Cette adresse est enregistrée
                      automatiquement pour la prochaine fois</label>
                  </div>

                </div>
                <ErrorMessage class="text-red-500 text-xs" name="isDefault"/>

              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="w-full px-4 lg:w-5/12 space-y-4">
        <div
            class="bg-white shadow-testimonial-6 rounded-[10px] border border-stroke py-4 px-6 sm:px-10"
        >
          <div>
            <label class="mb-1" for="invoice_label">Champ facultatif présent sur votre facture</label>
            <div class="relative">
              <Field
                  v-model="formValues.invoice_label"
                  :class="[errors.invoice_label ? 'ring-2 ring-red-600' : '']"
                  name="invoice_label"
                  type="text"
              />
              <ErrorMessage class="error-message" name="invoice_label"/>
              <p class="text-xs text-gray-400">
                Vous pouvez y indiquer un nom de campagne, un client, un site, un mois…
              </p>
            </div>
          </div>
        </div>
        <div
            class="bg-white shadow-testimonial-6 rounded-[10px] border border-stroke py-4 px-6 sm:px-10"
        >
          <div>
            <h4
                class="text-lg font-semibold mb-5 text-dark sm:text-2xl lg:text-lg xl:text-xl"
            >
              Crédit
            </h4>
          </div>
          <div>
            <div
                class="-mx-1 "
            >
              <div class="flex items-center justify-between mb-4 space-x-8">
                <div class="px-1 w-full">
                  <p class="text-base text-dark text-right">MONTANT DE VOTRE CRÉDIT (€ HT)</p>
                </div>
                <div class="px-1 w-[200px]">
                  <p class="text-base font-medium text-dark">
                    <div class="relative">
                      <Field v-model="formValues.total_excl_tax" :class="[errors.total_excl_tax ? 'ring-2 ring-red-600' : '']"
                             name="total_excl_tax" type="text"/>
                      <div class="absolute text-xl top-[5px] right-[9px]">€</div>
                    </div>
                  </p>
                </div>
              </div>
              <p v-if="errors.total_excl_tax" class="text-right -mt-[18px]">
                <ErrorMessage class="error-message" name="total_excl_tax"/>
              </p>
              <div v-if="formValues.payment_method == 'paypal'"
                   class="flex items-center justify-between mb-4 space-x-8">
                <div class="px-1 w-full">
                  <p class="text-sm text-dark text-right">
                    Frais Paypal (3.5%)
                  </p>
                </div>
                <div class="px-1 w-[200px]">
                  <p class="text-sm font-medium text-dark ">
                    {{ $filters.numberFormatIntl(formValues.total_excl_tax * 0.035) }} €
                  </p>
                </div>
              </div>
              <div class="flex items-center justify-between mb-4 space-x-8">
                <div class="px-1 w-full">
                  <p class="text-sm text-dark text-right">
                    TVA ({{ formValues.vat }}%)
                  </p>
                </div>
                <div class="px-1 w-[200px]">
                  <p class="text-sm font-medium text-dark ">
                    {{ $filters.numberFormatIntl(formValues.total_vat) }} €
                  </p>
                </div>
              </div>

              <div class="flex items-center justify-between space-x-8">
                <div class="px-1 w-full">
                  <p class="text-lg text-netlinking text-right">
                    TOTAL TTC
                  </p>
                </div>
                <div class="px-1 w-[200px]">
                  <p class="text-lg font-medium text-dark ">
                    {{ $filters.numberFormatIntl(formValues.total_incl_tax) }} €
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
            class="bg-white shadow-testimonial-6 rounded-[10px] border border-stroke py-4 px-6 sm:px-10"
        >
          <div>
            <h4
                class="text-lg font-semibold mb-5 text-dark sm:text-2xl lg:text-lg xl:text-xl"
            >
              Moyen de paiement
            </h4>
          </div>
          <div class="px-16">
            <div>
              <fieldset aria-label="Privacy setting" class="space-y-2 mt-3 rounded-md bg-white">
                <template v-for="method in paymentmethods" :key="method.id">
                  <label :aria-label="method.label" :value="method.id"
                         class="group flex cursor-pointer border-2 border-gray-200 p-4 first:rounded-tl-md first:rounded-tr-md last:rounded-bl-md last:rounded-br-md focus:outline-none has-[:checked]:relative has-[:checked]:border-2 has-[:checked]:border-netlinking has-[:checked]:bg-netlinking/5">
                    <Field v-model="formValues.payment_method" :value="method.id"
                           class="hidden relative mt-0.5 size-4 shrink-0 appearance-none rounded-full border-2 border-gray-300 bg-white before:absolute before:inset-1 before:rounded-full before:bg-white checked:border-netlinking checked:bg-netlinking focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400 forced-colors:appearance-auto forced-colors:before:hidden [&:not(:checked)]:before:hidden"
                           name="payment_method" type="radio"/>
                    <div class="ml-3 flex items-center justify-beetween w-full space-x-2">
                      <div>
                        <div
                            :class="(formValues.payment_method == method.id)?'border-netlinking bg-netlinking':'bg-white border-gray-500'"
                            class="rounded-full border  w-5 h-5"
                        ></div>
                      </div>
                      <div class="block w-full text-lg font-medium text-dark group-has-[:checked]:text-netlinking">{{
                          method.label
                        }}
                      </div>
                      <div><img :src="method.img" :style="{width:method.width}" class="h-8"/></div>
                    </div>
                  </label>
                  <div v-if="method.id == 'paypal' && formValues.payment_method == 'paypal'">
                    <p class="text-sm mb-1 text-netlinking text-center">
                      <i>Crédit de 50€ min + frais sup de 3.5%</i>
                    </p>
                  </div>
                </template>
              </fieldset>
            </div>
            <div v-if="formValues.payment_method == 'bank_transfer'">
              <div class="flex items-center justify-start mx-3">
                <div class="pt-3">
                  <div class="flex gap-3">
                    <div class="flex h-6 shrink-0 items-center">
                      <div class="group grid grid-cols-1">
                        <Field v-slot="{ field, errors }" v-model="formValues.making_bank_transfert"
                               :class="[errors.making_bank_transfert && Object.keys(errors.making_bank_transfert).length > 0 ? 'ring-2 ring-red-600' : '']"
                               name="making_bank_transfert">
                          <input id="making_bank_transfert" v-model="formValues.making_bank_transfert"
                                 aria-describedby="comments-description"
                                 class="col-start-1 row-start-1 appearance-none rounded border border-gray-300 bg-white checked:border-netlinking checked:bg-netlinking indeterminate:border-netlinking indeterminate:bg-netlinking focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-netlinking disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
                                 name="making_bank_transfert"
                                 type="checkbox"/>
                          <svg
                              class="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-[:disabled]:stroke-gray-950/25"
                              fill="none" viewBox="0 0 14 14">
                            <path class="opacity-0 group-has-[:checked]:opacity-100" d="M3 8L6 11L11 3.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round" stroke-width="2"/>
                            <path class="opacity-0 group-has-[:indeterminate]:opacity-100" d="M3 7H11"
                                  stroke-linecap="round"
                                  stroke-linejoin="round" stroke-width="2"/>
                          </svg>
                        </Field>
                      </div>
                    </div>
                    <div class="text-sm/6">
                      <label class="text-gray-900 font-normal" for="making_bank_transfert">
                        Je m'engage à réaliser le virement bancaire.<br>Les crédits seront ajoutés à réception des
                        fonds.
                      </label>
                    </div>

                  </div>
                </div>
              </div>
              <div class="items-center justify-start flex">
                <ErrorMessage class="text-red-500 text-xs ml-[12px]" name="making_bank_transfert"/>
              </div>
            </div>

            <div :class="(formValues.payment_method == 'bank_transfer')?'':'mt-3'"
                 class="flex items-center mx-3 justify-start">
              <div class="pt-3">
                <div class="flex gap-3">
                  <div class="flex h-6 shrink-0 items-center">
                    <div class="group grid grid-cols-1">
                      <Field v-slot="{ field, errors }" v-model="formValues.cgv"
                             :class="[errors.cgv && Object.keys(errors.cgv).length > 0 ? 'ring-2 ring-red-600' : '']"
                             name="cgv">
                        <input id="cgv" v-model="formValues.cgv" aria-describedby="comments-description"
                               class="col-start-1 row-start-1 appearance-none rounded border border-gray-300 bg-white checked:border-netlinking checked:bg-netlinking indeterminate:border-netlinking indeterminate:bg-netlinking focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-netlinking disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
                               name="cgv"
                               type="checkbox"/>
                        <svg
                            class="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-[:disabled]:stroke-gray-950/25"
                            fill="none" viewBox="0 0 14 14">
                          <path class="opacity-0 group-has-[:checked]:opacity-100" d="M3 8L6 11L11 3.5"
                                stroke-linecap="round"
                                stroke-linejoin="round" stroke-width="2"/>
                          <path class="opacity-0 group-has-[:indeterminate]:opacity-100" d="M3 7H11"
                                stroke-linecap="round"
                                stroke-linejoin="round" stroke-width="2"/>
                        </svg>
                      </Field>
                    </div>
                  </div>
                  <div class="text-sm/6">
                    <label class="text-gray-900 font-normal" for="cgv">J'ai lu et j'accepte les <a class="link-blue" target="_blank" :href="'/'+currentLocale+'/g-s-c/'">CGV</a></label>
                  </div>

                </div>
              </div>
            </div>
            <div class="items-center justify-start flex">
              <ErrorMessage class="text-red-500 text-xs ml-[12px]" name="cgv"/>
            </div>

            <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-1 ">
              <div class="flex justify-center mt-3">
                <button
                    :disabled="loadingStore.isLoading"
                    class="btn btn-sm w-full btn-rounded btn-netlinking"
                >
                  <LoadingBtn :label="$t('Valider et payer')"/>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Form>


  <!-- ====== Checkout Section End -->
</template>
