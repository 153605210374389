<template>
  <div class="relative w-full flex justify-center items-center h-20">
    <transition name="fade-slide" mode="out-in">
      <div :key="currentIndex" class="text-base font-semibold text-netlinking text-center">
        {{ phrases[currentIndex] }}
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';

const phrases = ref([
  "Nous analysons le contenu de votre page avec précision.",
  "Nous recherchons des suggestions parmi nos millions de pages référencées.",
  "Nous collectons et analysons les différentes métriques.",
  "Nous évaluons l’autorité transmise.",
  "Nous mesurons la popularité transmise."
]);

const currentIndex = ref(0);
let interval;

onMounted(() => {
  interval = setInterval(() => {
    currentIndex.value = (currentIndex.value + 1) % phrases.value.length;
  }, 3000);
});

onBeforeUnmount(() => {
  clearInterval(interval);
});
</script>

<style>
.fade-slide-enter-active, .fade-slide-leave-active {
  transition: opacity 0.5s, transform 0.5s;
}
.fade-slide-enter-from {
  opacity: 0;
  transform: translateY(20px);
}
.fade-slide-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}
</style>