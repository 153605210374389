<template>
  <div :class="classname">
    <div>
      <label v-if="!minimized">
        {{ labeltext }}
      </label>
      <div class="relative">
        <label
            for="file"
            class=""
            :class="(!minimized)?'flex  w-full cursor-pointer items-center justify-center rounded-md min-h-[100px] border border-dashed border-netlinking p-2':''"
            @dragover.prevent
            @drop="handleDrop"
        >
          <div v-if="!filePreviews.length">
            <input type="file" id="file" class="sr-only" @change="handleFileUpload" :multiple="multiple" />
            <span
                :class="(!minimized)?'mx-auto mb-3 flex h-[50px] w-[50px] items-center justify-center rounded-full border border-stroke  bg-white':'hover:text-netlinking cursor-pointer'"
                >
              <svg v-if="minimized" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-paperclip"><path d="M13.234 20.252 21 12.3"/><path d="m16 6-8.414 8.586a2 2 0 0 0 0 2.828 2 2 0 0 0 2.828 0l8.414-8.586a4 4 0 0 0 0-5.656 4 4 0 0 0-5.656 0l-8.415 8.585a6 6 0 1 0 8.486 8.486"/></svg>
              <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-download"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"/><polyline points="7 10 12 15 17 10"/><line x1="12" x2="12" y1="15" y2="3"/></svg>
            </span>
            <span class="text-base text-gray-700" v-if="!minimized">
              Glissez-déposez ou <span class="link-dark">téléchargez</span>
            </span>
          </div>
          <div v-else>
            <div v-for="(file, index) in filePreviews" :key="index" class="relative max-w-[100%] break-all whitespace-normal mb-2">
              <img v-if="file.isImage && !minimized" :src="file.preview" class="max-h-[150px] w-auto rounded-md" />
              <div v-else class="flex items-center space-x-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-paperclip"><path d="M13.234 20.252 21 12.3"/><path d="m16 6-8.414 8.586a2 2 0 0 0 0 2.828 2 2 0 0 0 2.828 0l8.414-8.586a4 4 0 0 0 0-5.656 4 4 0 0 0-5.656 0l-8.415 8.585a6 6 0 1 0 8.486 8.486"/></svg>
                <span class="text-sm text-gray-700">{{ file.name }}</span>
              </div>
              <button @click="clearFile(index)" class="absolute top-0 right-0 bg-white text-red rounded-full p-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle-x"><circle cx="12" cy="12" r="10"/><path d="m15 9-6 6"/><path d="m9 9 6 6"/></svg>
              </button>
            </div>
          </div>
        </label>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch } from 'vue';

const props = defineProps({
  modelValue: Array,
  multiple: {
    type: Boolean,
    default: false
  },
  minimized: {
    type: Boolean,
    default: false
  },
  labeltext: {
    type: String,
    default: 'Attachments'
  },
  classname: {
    type: String,
    default: 'w-full md:w-1/2 lg:w-1/2'
  }
});
const emit = defineEmits(['update:modelValue']);

const filePreviews = ref([]);

watch(() => props.modelValue, (newFiles) => {
  filePreviews.value = newFiles.map(file => processFile(file));
});

const handleFileUpload = (event) => {
  const files = Array.from(event.target.files);
  emit('update:modelValue', files);
  filePreviews.value = files.map(file => processFile(file));
};

const handleDrop = (event) => {
  event.preventDefault();
  const files = Array.from(event.dataTransfer.files);
  emit('update:modelValue', files);
  filePreviews.value = files.map(file => processFile(file));
};

const processFile = (file) => {
  const isImage = file.type.startsWith('image/');
  return {
    name: file.name,
    preview: isImage ? URL.createObjectURL(file) : null,
    isImage
  };
};

const clearFile = (index) => {
  filePreviews.value.splice(index, 1);
  emit('update:modelValue', filePreviews.value);
};
</script>
