<script setup>
import { defineProps, onMounted, ref } from 'vue'
import { useAuthStore } from '@/js/stores/auth';
import { useLocaleStore } from '@/js/stores/locale'
import LoadingBtn from '@/components/common/LoadingBtn.vue'
import { useLoadingStore } from '@/js/stores/loading'
const loadingStore = useLoadingStore()
import axios from 'axios'
const localeStore = useLocaleStore()
const path = '/campaign/ajax/'
const error = ref(false)
const currentLocale = localeStore.currentLocale
const authStore = useAuthStore()
onMounted(() => {
  authStore.fetchUser()
})
const props = defineProps({
  price: {
    required: true,
    default: 0
  },
  numberlinks: {
    required: true,
    default: 0
  },
  campaign: {
    type: Object,
    required: true,
    default: { }
  }
})

const onSubmit_0 = async () => {
  try {
    loadingStore.setLoading(true)
    const response = await axios.post(path, {
      action: 'launch',
      datas: {
        id: props.campaign.id
      },
    })

    if (response.status === 200) {
      if(response.data.success){
        window.location.href= response.data.redirect
      }else{
        error.value = true
      }
      emit('all-ok', {})
    }
  } catch (error) {
    if (error.response && error.response.status === 500) {
    }
  } finally {
    loadingStore.setLoading(false)
  }
}
</script>

<template>
      <div class="flex items-center justify-center w-full">
            <div
                class="lg:w-[800px] w-full shadow-testimonial-6 mb-10 overflow-hidden rounded-[10px] border bg-white border-stroke py-10 px-6 sm:px-10"
            >
              <table class="table-simple-border w-full">
                <thead>
                  <tr>
                      <th>NOM DE LA CAMPAGNE</th>
                      <th class="!text-center !max-w-[100px]">NOMBRE DE LIENS</th>
                      <th class=" !text-center !max-w-[100px]">MONTANT</th>
                  </tr>
                </thead>
                <tbody>
                <tr>
                  <td class="!text-dark">{{ campaign.name }}</td>
                  <td class="!text-dark !text-center">{{numberlinks}}</td>
                  <td class="!text-dark !text-center">{{price}}€</td>
                </tr>
                <tr class="!border-none">
                  <td></td>
                  <td class="border-b border-stroke font-semibold !text-dark !text-center">TOTAL HT</td>
                  <td class="border-b border-stroke !text-dark !text-center">{{price}}€</td>
                </tr>
<!--                <tr class="!border-none">-->
<!--                  <td></td>-->
<!--                  <td class="font-semibold !text-center !text-dark">TOTAL TTC</td>-->
<!--                  <td class="!text-dark !text-center">{{price}}€</td>-->
<!--                </tr>-->
                <tr class="!border-none">
                  <td></td>
                  <td colspan="2" class="!py-5 !px-0">
                    <button @click.prevent.stop="onSubmit_0" v-if="authStore.user" :disabled="(campaign.allocateBudget && campaign.allocateBudget < price) || (authStore.user && parseFloat(authStore.user.balance) < price && !campaign.allocateBudget) || loadingStore.isLoading" class="btn btn-sm btn-dark-netlinking btn-rounded w-full">
                      <LoadingBtn :label="'Lancer ma commande'"/>
                    </button>
                  </td>
                </tr>
                </tbody>

              </table>
              <div
                  v-if="error"
                  class="border-red bg-red-light-6 flex items-center w-full rounded-lg border-l-[6px] px-4 py-4 shadow-[0px_2px_10px_0px_rgba(0,0,0,0.08)] md:p-4"
              >
                <div
                    class="bg-red mr-5 flex h-[34px] w-full max-w-[34px] items-center justify-center rounded-lg"
                >
                  <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_961_15645)">
                      <path
                          d="M9 0.506256C4.30313 0.506256 0.50625 4.30313 0.50625 9.00001C0.50625 13.6969 4.30313 17.5219 9 17.5219C13.6969 17.5219 17.5219 13.6969 17.5219 9.00001C17.5219 4.30313 13.6969 0.506256 9 0.506256ZM9 16.2563C5.00625 16.2563 1.77188 12.9938 1.77188 9.00001C1.77188 5.00626 5.00625 1.77188 9 1.77188C12.9938 1.77188 16.2563 5.03438 16.2563 9.02813C16.2563 12.9938 12.9938 16.2563 9 16.2563Z"
                          fill="white"
                      />
                      <path
                          d="M11.5875 6.38438C11.3344 6.13125 10.9406 6.13125 10.6875 6.38438L9 8.1L7.28438 6.38438C7.03125 6.13125 6.6375 6.13125 6.38438 6.38438C6.13125 6.6375 6.13125 7.03125 6.38438 7.28438L8.1 9L6.38438 10.7156C6.13125 10.9688 6.13125 11.3625 6.38438 11.6156C6.49688 11.7281 6.66563 11.8125 6.83438 11.8125C7.00313 11.8125 7.17188 11.7563 7.28438 11.6156L9 9.9L10.7156 11.6156C10.8281 11.7281 10.9969 11.8125 11.1656 11.8125C11.3344 11.8125 11.5031 11.7563 11.6156 11.6156C11.8688 11.3625 11.8688 10.9688 11.6156 10.7156L9.9 9L11.6156 7.28438C11.8406 7.03125 11.8406 6.6375 11.5875 6.38438Z"
                          fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_961_15645">
                        <rect width="18" height="18" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div class="w-full">
                  <div class="text-base font-normal text-dark">
                    <span>Erreur de traitement de la commande</span>
                  </div>
                </div>
              </div>
              <div
                  v-if="authStore.user && parseFloat(authStore.user.balance) < price && !campaign.allocateBudget"
                  class="border-red bg-red-light-6 flex items-center w-full rounded-lg border-l-[6px] px-4 py-4 shadow-[0px_2px_10px_0px_rgba(0,0,0,0.08)] md:p-4"
              >
                <div
                    class="bg-red mr-5 flex h-[34px] w-full max-w-[34px] items-center justify-center rounded-lg"
                >
                  <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_961_15645)">
                      <path
                          d="M9 0.506256C4.30313 0.506256 0.50625 4.30313 0.50625 9.00001C0.50625 13.6969 4.30313 17.5219 9 17.5219C13.6969 17.5219 17.5219 13.6969 17.5219 9.00001C17.5219 4.30313 13.6969 0.506256 9 0.506256ZM9 16.2563C5.00625 16.2563 1.77188 12.9938 1.77188 9.00001C1.77188 5.00626 5.00625 1.77188 9 1.77188C12.9938 1.77188 16.2563 5.03438 16.2563 9.02813C16.2563 12.9938 12.9938 16.2563 9 16.2563Z"
                          fill="white"
                      />
                      <path
                          d="M11.5875 6.38438C11.3344 6.13125 10.9406 6.13125 10.6875 6.38438L9 8.1L7.28438 6.38438C7.03125 6.13125 6.6375 6.13125 6.38438 6.38438C6.13125 6.6375 6.13125 7.03125 6.38438 7.28438L8.1 9L6.38438 10.7156C6.13125 10.9688 6.13125 11.3625 6.38438 11.6156C6.49688 11.7281 6.66563 11.8125 6.83438 11.8125C7.00313 11.8125 7.17188 11.7563 7.28438 11.6156L9 9.9L10.7156 11.6156C10.8281 11.7281 10.9969 11.8125 11.1656 11.8125C11.3344 11.8125 11.5031 11.7563 11.6156 11.6156C11.8688 11.3625 11.8688 10.9688 11.6156 10.7156L9.9 9L11.6156 7.28438C11.8406 7.03125 11.8406 6.6375 11.5875 6.38438Z"
                          fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_961_15645">
                        <rect width="18" height="18" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div class="w-full">
                  <div class="text-base font-normal text-dark">
                    <span>
                      Le solde de votre compte est insuffisant. Merci de créditer {{ price - parseFloat(authStore.user.balance) }}€ HT pour lancer cette commande.
                    </span>
                    <span>
                      <a :href="'/'+currentLocale+'/payment/create/'+campaign.id" class="link-netlinking">CRÉDITER MON COMPTE</a>
                    </span>
                  </div>
                </div>
              </div>
              <div
                  v-if="campaign.allocateBudget && campaign.allocateBudget < price"
                  class="border-red bg-red-light-6 flex items-center w-full rounded-lg border-l-[6px] px-4 py-4 shadow-[0px_2px_10px_0px_rgba(0,0,0,0.08)] md:p-4"
              >
                <div
                    class="bg-red mr-5 flex h-[34px] w-full max-w-[34px] items-center justify-center rounded-lg"
                >
                  <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_961_15645)">
                      <path
                          d="M9 0.506256C4.30313 0.506256 0.50625 4.30313 0.50625 9.00001C0.50625 13.6969 4.30313 17.5219 9 17.5219C13.6969 17.5219 17.5219 13.6969 17.5219 9.00001C17.5219 4.30313 13.6969 0.506256 9 0.506256ZM9 16.2563C5.00625 16.2563 1.77188 12.9938 1.77188 9.00001C1.77188 5.00626 5.00625 1.77188 9 1.77188C12.9938 1.77188 16.2563 5.03438 16.2563 9.02813C16.2563 12.9938 12.9938 16.2563 9 16.2563Z"
                          fill="white"
                      />
                      <path
                          d="M11.5875 6.38438C11.3344 6.13125 10.9406 6.13125 10.6875 6.38438L9 8.1L7.28438 6.38438C7.03125 6.13125 6.6375 6.13125 6.38438 6.38438C6.13125 6.6375 6.13125 7.03125 6.38438 7.28438L8.1 9L6.38438 10.7156C6.13125 10.9688 6.13125 11.3625 6.38438 11.6156C6.49688 11.7281 6.66563 11.8125 6.83438 11.8125C7.00313 11.8125 7.17188 11.7563 7.28438 11.6156L9 9.9L10.7156 11.6156C10.8281 11.7281 10.9969 11.8125 11.1656 11.8125C11.3344 11.8125 11.5031 11.7563 11.6156 11.6156C11.8688 11.3625 11.8688 10.9688 11.6156 10.7156L9.9 9L11.6156 7.28438C11.8406 7.03125 11.8406 6.6375 11.5875 6.38438Z"
                          fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_961_15645">
                        <rect width="18" height="18" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div class="w-full">
                  <div class="text-base font-normal text-dark">
                    <span>
                      Attention, vous avez fixé une limite de {{campaign.allocateBudget}}€ à la campagne {{campaign.name}}, ce qui bloque la commande
                    </span>
                    <span>
                      <a :href="'/'+currentLocale+'/campaign/list/'" class="link-netlinking">GÉRER MON BUDGET</a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

  <!-- ====== Checkout Section End -->
</template>
