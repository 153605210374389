<template>
  <div class="draw-header">
    <div class="w-full">Campagne : {{ campaign.name }} / {{ campaign.customer_firstName }} {{ campaign.customer_lastName }} <small class="text-netlinking_blue">{{ campaign.company }}</small></div>

  </div>

  <table class="w-full table-auto table-pagination" v-if="Object.keys(orders).length > 0">
    <thead class="!border-t !border-b !border-gray-600">
    <tr>
      <th colspan="4">
        <span class="!text-netlinking !font-semibold">COMMANDE</span>
      </th>
      <th colspan="5">
        <span class="!text-netlinking !font-semibold">ARTICLE</span>
      </th>
      <th colspan="4">
        <span class="!text-netlinking !font-semibold">PARAMÈTRES DE COMMANDE</span>
      </th>
    </tr>
    <tr>
      <th>Date</th>
      <th>Statut</th>
      <th>Lien</th>
      <th>D. pu.</th>
      <th>Titre</th>
      <th>Imp.</th>
      <th>DR</th>
      <th>TF</th>
      <th>Prix</th>
      <th>Exclu</th>
      <th>Etal.</th>
      <th>Ancre</th>
      <th>Cible</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="order in orders">
      <td>{{ $filters.dateFormat(order.createdAt,2) }}</td>
      <td>
        {{states[order.state].label}}
      </td>
      <td class="!whitespace-normal">
        <a v-if="order.urlSource" :href="'https://www.qsssgl.com/?url=' + order.urlSource" class="link-dark">
          {{order.urlSource}}
        </a>

      </td>
      <td>
        <span v-if="order.dateDeliveredDone">{{ $filters.dateFormat(order.dateDeliveredDone) }}</span>
      </td>

      <td class="!whitespace-normal">
        <span v-html="order.datas.title"></span>
      </td>
      <td>{{$filters.numberFormatIntl(order.datas.impressions)}}</td>
      <td>{{ $filters.numberFormatIntl(order.datas.refDomains) }}</td>
      <td class="!text-center">
        <span
            v-if="order.datas.trustFlow"
            v-tippy="{ allowHTML: true,content: order.datas.detailsTtfs,theme:'tooltip-ttfs'}"
            :style="{'background': order.datas.topicalTrustFlowColor}"
            class="w-[35px] block text-center font-bold px-2 py-1 rounded text-white">
                {{ order.datas.trustFlow }}
              </span>
      </td>
      <td>{{$filters.numberFormatIntl(order.price+order.extraPrice)}} €</td>
      <td>{{order.exclusiveDuration}} <small>ans(s)</small></td>
      <td>{{order.spreadPosts}} <small>mois</small></td>
      <td class="!whitespace-normal">
        <span v-if="order.nl">{{order.anchorInProd}}</span>
        <span v-else>{{order.anchor}}</span>
      </td>
      <td class="!whitespace-normal">
        <a v-if="order.urlDestination" :href="'https://www.qsssgl.com/?url=' + order.urlDestination" class="link-dark">
          {{order.urlDestination}}
        </a>
      </td>
    </tr>
    </tbody>
  </table>

</template>
<script setup>
import { computed, defineProps, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useLoadingStore } from '@/js/stores/loading'
import { useLocaleStore } from '@/js/stores/locale'
import { useAuthStore } from '@/js/stores/auth'
import LoadingBtn from '@/components/common/LoadingBtn.vue'
import * as yup from 'yup'
import { ErrorMessage, Field, Form } from 'vee-validate'
import axios from 'axios'

const orders = ref({})
const emit = defineEmits(['all-ok'])

const authStore = useAuthStore()

const path = '/internal/campaign/ajax/'
const { t } = useI18n()
const localeStore = useLocaleStore()
const loadingStore = useLoadingStore()
const selectedRole = ref(null)
const props = defineProps({
  campaign: {
    type: Object,
    required: true,
    default: { name: '' }
  },
  states: {
    type: Object,
    required: true
  },
})
const fetchOrders = async () => {
  axios.post(path, {
    action: 'get_orders',
    datas: {
      id: props.campaign.id
    },
  }).then((response) => {
    if (response.status === 200) {
      orders.value = response.data.orders
    }
  })
}
onMounted(async () => {
  await fetchOrders()
})
// const formValues = ref({
//   gift: 0
// })
//
// const schema_0 = computed(() => {
//   return yup.object().shape({
//     gift: yup
//         .number()
//         .typeError(t('validation.must_be_a_number')) // Vérifie que c'est un nombre
//         .required(t('validation.required')) // Champ obligatoire
//         .moreThan(0, t('validation.must_be_greater_than', { value: 0 })) // Doit être strictement > 50
//   })
// })
// const onSubmit_0 = async (values) => {
//   try {
//     loadingStore.setLoading(true)
//     const response = await axios.post(path, {
//       action: 'add_gift',
//       datas: {
//         gift: formValues.value.gift, id: props.customer.id
//       },
//     })
//
//     if (response.status === 200) {
//       window.location.href = response.data.redirect
//       emit('all-ok', {})
//     }
//   } catch (error) {
//     if (error.response && error.response.status === 500) {
//     }
//   } finally {
//     loadingStore.setLoading(false)
//   }
// }
</script>