<template>
<span class="hidden
    bg-rose-600 bg-pink-600 bg-fuchsia-600 bg-purple-600 bg-violet-600 bg-indigo-600
    bg-blue-600 bg-sky-600 bg-cyan-600 bg-teal-600 bg-emerald-600 bg-green-600
    bg-lime-600 bg-yellow-600 bg-amber-600 bg-orange-600 bg-red-600 bg-stone-600
    bg-neutral-600 bg-zinc-600 bg-gray-600 bg-slate-600
    bg-[#ff6700] bg-[#76d54b] bg-[#7a69cd] bg-[#f33] bg-[#c5c88e] bg-[#f582b9]
    bg-[#89c7cb] bg-[#55355d] bg-[#fc0] bg-[#c84770] bg-[#557832] bg-[#d95]
    bg-[#600] bg-[#009] bg-[#6bd39a] bg-[#577] bg-[#333]">
</span>

  <div>
    <div class="mb-2 flex justify-between border-b-2 border-stroke">
      <h3 class="mb-2 text-lg font-semibold text-dark space-x-4 flex items-center justify-center">
        <button class="btn btn-xs btn-outline-netlinking" @click.prevent.stop="closeModal()">
<!--          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"-->
<!--               fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"-->
<!--               stroke-linejoin="round" class="lucide lucide-chevrons-left">-->
<!--            <path d="m11 17-5-5 5-5"/>-->
<!--            <path d="m18 17-5-5 5-5"/>-->
<!--          </svg>-->
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevrons-right"><path d="m6 17 5-5-5-5"/><path d="m13 17 5-5-5-5"/></svg>
        </button>
        <span>
                            Inviter un profil utilisateur
                        </span>
      </h3>
    </div>
    <div class="mb-6 flex justify-center" v-if="currentStep == 3">
      <div class="mt-10 p-20">
        <p class="w-full flex items-center justify-center space-x-4 mb-3 text-xl font-bold text-dark">
          <span>Une invitation e-mail vient d’être envoyée à votre / vos invité(s)</span>
        </p>
        <p class="text-center text-sm">
          Vous venez d’inviter un ou plusieurs nouveaux membres à votre compte. Ils pourront travailler sur leur
          interface une fois l’invitation acceptée. Ce / ces nouveaux membre(s) apparaitront dans la liste des
          utilisateurs seulement une fois l’invitation acceptée
        </p>
        <div class="flex items-center justify-center mt-6 mb-6">
          <img :src="'/images/send-email.svg'" class="size-40"/>
        </div>
        <p class="text-center border-t border-gray-300 pt-3">
          <slot></slot>
        </p>
      </div>
    </div>
    <div class="-mx-3 flex items-center justify-center sm:-mx-7 md:-mx-10 lg:-mx-[50px]" v-if="currentStep <= 2">
      <div class="relative px-3 text-center sm:px-7 md:px-10 lg:px-[50px]">
      <span
          :class="[
          'absolute -right-[45px] top-[17px] block h-[2px] w-[80px] sm:-right-[60px] sm:top-[25px] sm:w-[120px]',
          currentStep === 1 ? 'bg-gray-200' : 'bg-netlinking'
        ]"
      ></span>
        <span
            :class="[
          'relative z-10 mx-auto mb-[10px] flex h-9 w-9 items-center justify-center rounded-full border-2 text-base font-medium sm:h-[50px] sm:w-[50px] sm:text-xl',
          currentStep >= 1 ? 'border-netlinking bg-netlinking text-white' : 'border-stroke bg-gray-1 text-dark'
        ]"
        >
        1
      </span>
      </div>

      <div class="px-3 text-center sm:px-7 md:px-10 lg:px-[50px]">
      <span
          :class="[
          'relative z-10 mx-auto mb-[10px] flex h-9 w-9 items-center justify-center rounded-full border-2 text-base font-medium sm:h-[50px] sm:w-[50px] sm:text-xl',
          currentStep === 2 ? 'border-netlinking bg-netlinking text-white' : 'border-stroke bg-gray-1 text-dark'
        ]"
      >
        2
      </span>
      </div>
    </div>

    <div class="mt-5">
      <div class="grid grid-cols-1 lg:grid-cols-1 gap-4" v-if="currentStep == 2">
        <div>
          <h3>
            Saisir les informations utilisateur
          </h3>
          <div class="mt-3">
            <Form @submit="onSubmit_0" class="space-y-3" :validation-schema="schema_0" v-slot="{ errors }">
              <div v-for="(user, index) in formValues.users" :key="index" class="relative mb-4">
                <!-- Remove user button -->
                <button
                    v-if="formValues.users.length > 1 && index > 0"
                    @click.prevent="removeUser(index)"
                    class="absolute top-0 right-2 text-gray-500 hover:text-red-500"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                       stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                       class="lucide lucide-x">
                    <path d="M18 6 6 18"/>
                    <path d="m6 6 12 12"/>
                  </svg>
                </button>

                <div class="grid grid-cols-3 gap-4">
                  <div>
                    <label :for="'email-' + index">
                      {{ t('field_names.email') }}
                    </label>
                    <div class="relative">
                      <Field
                          :name="`users[${index}].email`"
                          type="text"
                          v-model="user.email"
                          :class="{'ring-2 ring-red-600': errors?.users?.[index]?.email}"
                      />
                      <ErrorMessage :name="`users[${index}].email`" class="text-red-500 text-sm mt-1"/>
                    </div>
                  </div>

                  <div>
                    <label :for="'firstName-' + index">
                      {{ t('field_names.firstName') }}
                    </label>
                    <div class="relative">
                      <Field
                          :name="`users[${index}].firstName`"
                          type="text"
                          v-model="user.firstName"
                          :class="{'ring-2 ring-red-600': errors?.users?.[index]?.firstName}"
                      />
                      <ErrorMessage :name="`users[${index}].firstName`" class="text-red-500 text-sm mt-1"/>
                    </div>
                  </div>

                  <div>
                    <label :for="'lastName-' + index">
                      {{ t('field_names.lastName') }}
                    </label>
                    <div class="relative">
                      <Field
                          :name="`users[${index}].lastName`"
                          type="text"
                          v-model="user.lastName"
                          :class="{'ring-2 ring-red-600': errors?.users?.[index]?.lastName}"
                      />
                      <ErrorMessage :name="`users[${index}].lastName`" class="text-red-500 text-sm mt-1"/>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Add user button -->
              <div class="mt-3">
                <a
                    @click.prevent="addUser"
                    href="#"
                    class="hover:text-netlinking flex items-center space-x-2"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                       stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                       class="lucide lucide-user-plus text-netlinking">
                    <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"/>
                    <circle cx="9" cy="7" r="4"/>
                    <line x1="19" x2="19" y1="8" y2="14"/>
                    <line x1="22" x2="16" y1="11" y2="11"/>
                  </svg>
                  <span>Inviter un autre utilisateur avec le même niveau de droit</span>
                </a>
              </div>
              <div class="pt-6 mb-1 text-center">
                <button type="submit" class="btn btn-dark btn-sm" :disabled="loadingStore.isLoading">
                  <LoadingBtn :label="'Envoyer l\'invitation'"/>
                </button>
              </div>
              <div class="text-center">
                <a @click.prevent.stop="currentStep = 1" href="" class="text-dark hover:text-gray-500">
                  < Retour
                </a>
              </div>

            </Form>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-4" v-if="currentStep == 1">
        <div>
          <h3>
            Choisir le rôle de l'utilisateur
          </h3>
          <fieldset aria-label="Privacy setting" class="space-y-2 mt-3 rounded-md bg-white">
            <label v-for="setting in roles_customer" :key="setting.label" :value="setting.value" :aria-label="setting.label"
                   :aria-description="setting.description"
                   class="group flex cursor-pointer border-2 border-gray-200 p-4 first:rounded-tl-md first:rounded-tr-md last:rounded-bl-md last:rounded-br-md focus:outline-none has-[:checked]:relative has-[:checked]:border-2 has-[:checked]:border-netlinking has-[:checked]:bg-netlinking/5">
              <input v-model="selectedRole" name="privacy-setting" :value="setting.value" type="radio"
                     :checked="setting.selected"
                     class="hidden relative mt-0.5 size-4 shrink-0 appearance-none rounded-full border-2 border-gray-300 bg-white before:absolute before:inset-1 before:rounded-full before:bg-white checked:border-netlinking checked:bg-netlinking focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400 forced-colors:appearance-auto forced-colors:before:hidden [&:not(:checked)]:before:hidden"/>
              <span class="ml-3 flex flex-col">
        <span class="block text-lg font-medium text-netlinking group-has-[:checked]:text-netlinking">{{
            setting.label
          }}</span>
        <span class="block text-sm text-gray-500 ">{{ setting.description }}</span>
      </span>
            </label>
          </fieldset>
          <button @click.prevent.stop="currentStep = 2" :disabled="!selectedRole"
                  class="btn btn-dark btn-sm w-full mt-3">Valider et passer à l'étape suivante
          </button>
        </div>
        <div>
          <Transition name="drawer">
            <div class="card-gray" v-if="selectedRole && users_roles_explication[selectedRole]">
              <h3 class="text-netlinking">{{ users_roles_explication[selectedRole]['label'] }}</h3>
              <p class="text-sm mb-3">Accès et actions</p>
              <div class="text-sm">
                <p class="flex items-center space-x-2" v-for="p in users_roles_explication[selectedRole]['+']">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none"
                       stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                       class="text-netlinking lucide lucide-check">
                    <path d="M20 6 9 17l-5-5"/>
                  </svg>
                  <span>{{ p }}</span>
                </p>
              </div>
              <div class="text-sm border-t border-gray-200 pt-3 mt-3"
                   v-if="Object.keys(users_roles_explication[selectedRole]['-']).length > 0">
                <p class="flex items-center space-x-2" v-for="p in users_roles_explication[selectedRole]['-']">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none"
                       stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                       class="lucide lucide-x text-dark">
                    <path d="M18 6 6 18"/>
                    <path d="m6 6 12 12"/>
                  </svg>
                  <span>{{ p }}</span>
                </p>
              </div>
            </div>
          </Transition>
        </div>

      </div>


    </div>


  </div>
</template>
<script setup>
import { ErrorMessage, Field, Form } from 'vee-validate'
import { computed, defineProps, reactive, ref } from 'vue'
import axios from 'axios'
import * as yup from 'yup'
import { useI18n } from 'vue-i18n'
import { useLocaleStore } from '@/js/stores/locale'
import { useLoadingStore } from '@/js/stores/loading'
import LoadingBtn from '@/components/common/LoadingBtn.vue'

const emit = defineEmits(['close-model'])
const selectedRole = ref(null)
const currentStep = ref(1)
const { t } = useI18n()
const localeStore = useLocaleStore()
const loadingStore = useLoadingStore()
const currentLocale = localeStore.currentLocale

const path = '/customer/ajax/'
const props = defineProps({
  users_roles_explication: {
    type: Object,
    required: true
  },
  roles_customer: {
    type: Object,
    required: true
  }
})
const formValues = reactive({
  users: [
    {
      email: '',
      firstName: '',
      lastName: ''
    }
  ]
})
const addUser = () => {
  formValues.users.push({
    email: '',
    firstName: '',
    lastName: ''
  })
}
const removeUser = (index) => {
  if (formValues.users.length > 1) {
    formValues.users.splice(index, 1)
  }
}
const closeModal = () => {
  emit('close-model')
}
const settings = [
  {
    name: 'Super Admin',
    value: 'ROOT',
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. ',
    selected: false
  },
  {
    name: 'Responsable',
    value: 'MANAGER',
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. ',
    selected: false,
  },
  {
    name: 'Gestionnaire',
    value: 'HANDLER',
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. ',
    selected: false
  },
]
const schema_0 = computed(() => {
  return yup.object({
    users: yup.array().of(
        yup.object({
          email: yup
              .string()
              .required(t('validation.required'))
              .email(t('validation.invalid_email_format')),
          firstName: yup
              .string()
              .required(t('validation.required')),
          lastName: yup
              .string()
              .required(t('validation.required'))
        })
    ).required()
  })
})
const onSubmit_0 = async (values) => {
  try {
    loadingStore.setLoading(true)

    const response = await axios.post(path, {
      action: 'invitation',
      datas: {
        users: formValues.users,
        code: selectedRole.value
      },
    })

    if (response.status === 200) {
      currentStep.value = 3
    }
  } catch (error) {
    if (error.response && error.response.status === 500) {
    }
  } finally {
    loadingStore.setLoading(false)
  }
}

</script>