<template>
  <div class="mb-5" v-if="loading">
    <div class="relative mx-5 my-10 h-5 overflow-hidden rounded-full bg-netlinkingh shadow-md">
      <div class="relative h-2 w-full overflow-hidden rounded-full bg-netlinking">
        <div class="h-full animate-[progressAnimation_2s_infinite_ease-in-out] bg-white shadow-md"></div>
      </div>
    </div>
  </div>
  <div class="w-full" v-if="!loading && Object.keys(orders).length == 0">
    <div
        class="border-l-cyan-dark flex w-full items-center rounded-md border-l-[6px] bg-white p-5 pl-6"
    >
      <div class="flex w-full items-center justify-between">
        <div>

          <p class="text-body-color text-sm">
            Aucune commande trouvée dans cette campagne.

          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="max-w-full overflow-x-auto">
  <table class="w-full table-auto table-pagination" v-if="!loading && Object.keys(orders).length > 0">
    <thead class="!border-t !border-b !border-gray-600">
    <tr>
      <th colspan="4">
        <span class="!text-netlinking !font-semibold">COMMANDE</span>
      </th>
      <th colspan="5">
        <span class="!text-netlinking !font-semibold">ARTICLE</span>
      </th>
      <th colspan="4">
        <span class="!text-netlinking !font-semibold">PARAMÈTRES DE COMMANDE</span>
      </th>
    </tr>
    <tr>
      <th>Date</th>
      <th>Statut</th>
      <th>Lien</th>
      <th>Publié le</th>
      <th>Titre d'article</th>
      <th>Imp.</th>
      <th>DR</th>
      <th>TF</th>
      <th>Prix</th>
      <th>Exclu</th>
      <th>Etal.</th>
      <th>Votre ancre</th>
      <th>Url cible</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="order in orders">
      <td>{{ $filters.dateFormat(order.createdAt,2) }}</td>
      <td>
          <span v-if="order.state == 'in_progress'" class="badge badge-danger badge-pill">
            En cours
          </span>
        <span v-if="order.state == 'delivered'" class="badge badge-success badge-pill">
            Livré
          </span>
      </td>
      <td>
        <a target="_blank" v-if="order.urlSource" :href="'https://www.qsssgl.com/?url=' + order.urlSource" class="link-dark">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-eye"><path d="M2.062 12.348a1 1 0 0 1 0-.696 10.75 10.75 0 0 1 19.876 0 1 1 0 0 1 0 .696 10.75 10.75 0 0 1-19.876 0"/><circle cx="12" cy="12" r="3"/></svg>
        </a>
      </td>
      <td>
        <span v-if="order.dateDeliveredDone">{{ $filters.dateFormat(order.dateDeliveredDone) }}</span>
      </td>

      <td class="!whitespace-normal">
        <span v-html="order.datas.title"></span>
      </td>
      <td>{{$filters.numberFormatIntl(order.datas.impressions)}}</td>
      <td>{{ $filters.numberFormatIntl(order.datas.refDomains) }}</td>
      <td class="!text-center !h-full !flex !items-center !justify-center">
        <span
            v-if="order.datas.trustFlow"
            v-tippy="{ allowHTML: true,content: order.datas.detailsTtfs,theme:'tooltip-ttfs'}"
            :style="{'background': order.datas.topicalTrustFlowColor}"
            class="w-[35px] block text-center font-bold px-2 py-1 rounded text-white">
                {{ order.datas.trustFlow }}
              </span>
      </td>
      <td>{{$filters.numberFormatIntl(order.price+order.extraPrice)}} €</td>
      <td>{{order.exclusiveDuration}} <small>ans(s)</small></td>
      <td>{{order.spreadPosts}} <small>mois</small></td>
      <td class="!whitespace-normal">
        <span v-if="order.nl">{{order.anchorInProd}}</span>
        <span v-else>{{order.anchor}}</span>
      </td>
      <td class="!whitespace-normal">
        <a v-if="order.urlDestination" :href="'https://www.qsssgl.com/?url=' + order.urlDestination" class="link-dark">
          {{order.urlDestination}}
        </a>
      </td>
    </tr>
    </tbody>
  </table>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import axios from 'axios'
const loading = ref(false)
const path = '/campaign/ajax/'

const orders = ref([])
const props = defineProps({
  campaign: {
    type: Object,
    required: true
  }
})
const fetchData = async()=>{
  try {
    loading.value = true
    const response = await axios.post(path, {
      action: 'order-campaign',
      datas: {
        id: props.campaign.id
      },
    })

    if (response.status === 200) {
      orders.value = response.data.orders
    }
  } catch (error) {
    if (error.response && error.response.status === 500) {
    }
  } finally {
    loading.value = false
  }
}
onMounted(() => {
  fetchData();
});
</script>