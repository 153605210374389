<template>
  <div class="bg-netlinking -mx-[40px] -my-[15px] pt-[16px] pb-[30px] px-[30px] xl:px-[40px]">
    <h1 class="title-simple lg:max-w-[600px] flex items-center justify-between">
      <span>Backlinks checker</span>
      <span class="text-base">{{ count_histories }} / 5</span>
    </h1>
    <Form v-slot="{ errors }" :validation-schema="schema_0" class="space-y-3" @submit="onSubmit_0">
      <div class="block lg:flex lg:space-x-4 space-y-4 lg:space-y-0 mt-4">
        <div class="w-full  lg:max-w-[600px]">
          <label for="url">Renseigner votre URL</label>
          <div class="w-full">
            <Field
                v-model="datas.url"
                :class="[errors.url ? 'ring-2 ring-red-600' : '']"
                :name="'url'"
                :placeholder="'Ex. https://www.leseopourtous.fr'"
                type="text"
            ></Field>
            <ErrorMessage :name="'url'" class="error-message"/>
          </div>
        </div>
        <div class="w-full lg:max-w-[200px] pt-8">
          <!--          @click.prevent.stop="isModalOpen = true"-->
          <button :disabled="max_backlinks <= count_histories || loadingStore.isLoading"
                  class="btn btn-white-netlinking btn-sm w-full btn-rounded" type="submit"

          >
            <Loadingbtn :label="'Analyser'"/>
          </button>
        </div>
      </div>
    </Form>

  </div>
  <div v-if="data_response" class="mt-16 w-full">

  </div>
  <div
      v-if="errorData"
      class="border-red bg-red-light-6 flex items-center w-full rounded-lg border-l-[6px] px-4 py-4 shadow-[0px_2px_10px_0px_rgba(0,0,0,0.08)] md:p-4 mt-16"
  >
    <div
        class="bg-red mr-5 flex h-[34px] w-full max-w-[34px] items-center justify-center rounded-lg"
    >
      <svg
          fill="none"
          height="18"
          viewBox="0 0 18 18"
          width="18"
          xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_961_15645)">
          <path
              d="M9 0.506256C4.30313 0.506256 0.50625 4.30313 0.50625 9.00001C0.50625 13.6969 4.30313 17.5219 9 17.5219C13.6969 17.5219 17.5219 13.6969 17.5219 9.00001C17.5219 4.30313 13.6969 0.506256 9 0.506256ZM9 16.2563C5.00625 16.2563 1.77188 12.9938 1.77188 9.00001C1.77188 5.00626 5.00625 1.77188 9 1.77188C12.9938 1.77188 16.2563 5.03438 16.2563 9.02813C16.2563 12.9938 12.9938 16.2563 9 16.2563Z"
              fill="white"
          />
          <path
              d="M11.5875 6.38438C11.3344 6.13125 10.9406 6.13125 10.6875 6.38438L9 8.1L7.28438 6.38438C7.03125 6.13125 6.6375 6.13125 6.38438 6.38438C6.13125 6.6375 6.13125 7.03125 6.38438 7.28438L8.1 9L6.38438 10.7156C6.13125 10.9688 6.13125 11.3625 6.38438 11.6156C6.49688 11.7281 6.66563 11.8125 6.83438 11.8125C7.00313 11.8125 7.17188 11.7563 7.28438 11.6156L9 9.9L10.7156 11.6156C10.8281 11.7281 10.9969 11.8125 11.1656 11.8125C11.3344 11.8125 11.5031 11.7563 11.6156 11.6156C11.8688 11.3625 11.8688 10.9688 11.6156 10.7156L9.9 9L11.6156 7.28438C11.8406 7.03125 11.8406 6.6375 11.5875 6.38438Z"
              fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_961_15645">
            <rect fill="white" height="18" width="18"/>
          </clipPath>
        </defs>
      </svg>
    </div>
    <div class="w-full">
      <div class="text-base font-normal text-dark">
        <span>La page sélectionnée ne contient aucun contenu. Veuillez en choisir une autre.</span>
      </div>
    </div>
  </div>
  <div v-if="Object.keys(embeddings).length > 0" class="mt-10 bg-white p-4 rounded shadow">
    <div class="max-w-full overflow-x-auto ">
      <table class="w-full table-auto table-pagination" >
        <thead>
        <tr>
          <th></th>
          <th>URL</th>
          <th>Trafic</th>
          <th>MC</th>
          <th>DR</th>
          <th>CF</th>
          <th>TF</th>
          <th>TTF</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <template v-for="(embedding,index) in embeddings">
          <tr>
            <td class="!max-w-[80px] !text-center">
              <div v-if="embedding.state == 'in_progress'" v-tippy="'En cours'"
                   class="animate-spin text-netlinking w-[24px] h-[24px] ml-[10px]">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-square"><rect width="18" height="18" x="3" y="3" rx="2"/></svg>
              </div>
              <button
                  v-if="embedding.state == 'completed'"
                   @click="(embedding.backlinks_list && Object.keys(embedding.backlinks_list).length > 0)?embedding.backlinks_list={}:fetchBacklinks(embedding.id,index)"
                  class="faq-btn flex w-full text-left">
                  <div class="mr-5 flex h-10 w-full max-w-[40px] items-center justify-center rounded-lg bg-netlinking/5 text-netlinking ">
                    <svg :class="{'rotate-90 transition-transform duration-300': embedding.backlinks_list && Object.keys(embedding.backlinks_list).length > 0, 'transition-transform duration-300': !(embedding.backlinks_list && Object.keys(embedding.backlinks_list).length > 0)}"  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-right"><path d="m9 18 6-6-6-6"/></svg>
                  </div>
              </button>
            </td>
            <td>
              {{ embedding.url }}
            </td>
            <td>{{ $filters.numberFormatIntl(embedding.traffic) }}</td>
            <td>{{ $filters.numberFormatIntl(embedding.keyword) }}</td>
            <td>{{ $filters.numberFormatIntl(embedding.referringDomains) }}</td>
            <td>{{ embedding.citationFlow }}</td>
            <td>
            <span
                v-if="embedding.trustFlow"
                v-tippy="{ allowHTML: true,content: embedding.trustFlowRepresentation,theme:'tooltip-ttfs'}"
                :style="{'background': embedding.trustFlowColor}"
                class="w-[37px] block text-center font-bold px-2 py-1 rounded text-white">
              {{ embedding.trustFlow }}
            </span>
            </td>
            <td class="!text-blue-500">
              {{ embedding.trustFlowLabel }}
            </td>

          </tr>
          <tr v-if="embedding.backlinks_list && Object.keys(embedding.backlinks_list).length > 0">
            <td colspan="13" class="bg-netlinkingh !px-10 !py-5">
              <div class="max-w-full overflow-x-auto">
                <table class="w-full table-auto table-pagination">
                  <thead>
                  <tr>
                    <th @click="setSortKey('url')">
          <span class="flex items-center cursor-pointer link-blue-simple">
            URL
            <span v-if="sortConfig.key === 'url'" class="ml-2">
              {{ sortConfig.direction === 'asc' ? '▲' : '▼' }}
            </span>
          </span>
                    </th>
                    <th @click="setSortKey('trustFlow')">
          <span class="flex items-center justify-center cursor-pointer link-blue-simple">
            TF
            <span v-if="sortConfig.key === 'trustFlow'" class="ml-2">
              {{ sortConfig.direction === 'asc' ? '▲' : '▼' }}
            </span>
          </span>
                    </th>
                    <th @click="setSortKey('trustFlowLabel')">
          <span class="flex items-center cursor-pointer link-blue-simple">
            TTF
            <span v-if="sortConfig.key === 'trustFlowLabel'" class="ml-2">
              {{ sortConfig.direction === 'asc' ? '▲' : '▼' }}
            </span>
          </span>
                    </th>
                    <th @click="setSortKey('traffic')">
          <span class="flex items-center cursor-pointer link-blue-simple">
            Trafic
            <span v-if="sortConfig.key === 'traffic'" class="ml-2">
              {{ sortConfig.direction === 'asc' ? '▲' : '▼' }}
            </span>
          </span>
                    </th>
                    <th @click="setSortKey('keyword')">
                    <span class="flex justify-center items-center cursor-pointer link-blue-simple">
            MC
            <span v-if="sortConfig.key === 'keyword'" class="ml-2">
              {{ sortConfig.direction === 'asc' ? '▲' : '▼' }}
            </span>
          </span>
                    </th>
                    <th @click="setSortKey('referringDomains')">
                    <span class="flex justify-center items-center cursor-pointer link-blue-simple">
            DR
            <span v-if="sortConfig.key === 'referringDomains'" class="ml-2">
              {{ sortConfig.direction === 'asc' ? '▲' : '▼' }}
            </span>
          </span>
                    </th>
                    <th @click="setSortKey('trustFlow')">
                    <span class="flex justify-center items-center cursor-pointer link-blue-simple">
            TF
            <span v-if="sortConfig.key === 'trustFlow'" class="ml-2">
              {{ sortConfig.direction === 'asc' ? '▲' : '▼' }}
            </span>
          </span>
                    </th>
                    <th @click="setSortKey('citationFlow')">
                    <span class="flex justify-center items-center cursor-pointer link-blue-simple">
            CF
            <span v-if="sortConfig.key === 'citationFlow'" class="ml-2">
              {{ sortConfig.direction === 'asc' ? '▲' : '▼' }}
            </span>
          </span>
                    </th>
                    <th @click="setSortKey('transferredAuthority')">
                    <span class="flex justify-center items-center cursor-pointer link-blue-simple">
            AT
            <span v-if="sortConfig.key === 'transferredAuthority'" class="ml-2">
              {{ sortConfig.direction === 'asc' ? '▲' : '▼' }}
            </span>
          </span>
                    </th>
                    <th @click="setSortKey('transferredPopularity')">
                    <span class="flex justify-center items-center cursor-pointer link-blue-simple">
            PT
            <span v-if="sortConfig.key === 'transferredPopularity'" class="ml-2">
              {{ sortConfig.direction === 'asc' ? '▲' : '▼' }}
            </span>
          </span>
                    </th>
                    <th @click="setSortKey('website')">
                    <span class="flex items-center cursor-pointer link-blue-simple">
            SITE
            <span v-if="sortConfig.key === 'website'" class="ml-2">
              {{ sortConfig.direction === 'asc' ? '▲' : '▼' }}
            </span>
          </span>
                    </th>
                    <th @click="setSortKey('trafficWebsite')">
                    <span class="flex justify-center items-center cursor-pointer link-blue-simple">
            Trafic
            <span v-if="sortConfig.key === 'trafficWebsite'" class="ml-2">
              {{ sortConfig.direction === 'asc' ? '▲' : '▼' }}
            </span>
          </span>
                    </th>
                    <th @click="setSortKey('keywordWebsite')">
                    <span class="flex justify-center items-center cursor-pointer link-blue-simple">
            MC
            <span v-if="sortConfig.key === 'keywordWebsite'" class="ml-2">
              {{ sortConfig.direction === 'asc' ? '▲' : '▼' }}
            </span>
          </span>
                    </th>
                    <th @click="setSortKey('referringDomainsWebsite')">
                    <span class="flex justify-center items-center cursor-pointer link-blue-simple">
            DR
            <span v-if="sortConfig.key === 'referringDomainsWebsite'" class="ml-2">
              {{ sortConfig.direction === 'asc' ? '▲' : '▼' }}
            </span>
          </span>
                    </th>
                    <th @click="setSortKey('trustFlowWebsite')">
                    <span class="flex justify-center items-center cursor-pointer link-blue-simple">
            TF
            <span v-if="sortConfig.key === 'trustFlowWebsite'" class="ml-2">
              {{ sortConfig.direction === 'asc' ? '▲' : '▼' }}
            </span>
          </span>
                    </th>
                    <th @click="setSortKey('citationFlowWebsite')">
                    <span class="flex justify-center items-center cursor-pointer link-blue-simple">
            CF
            <span v-if="sortConfig.key === 'citationFlowWebsite'" class="ml-2">
              {{ sortConfig.direction === 'asc' ? '▲' : '▼' }}
            </span>
          </span>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="backlink in sortedData(embedding.backlinks_list)">

                    <td class="!whitespace-normal">
                      {{backlink.url}}
                    </td>
                    <td>
                      <span
                          v-if="backlink.trustFlow"
                          v-tippy="{ allowHTML: true,content: backlink.trustFlowRepresentation,theme:'tooltip-ttfs'}"
                          :style="{'background': backlink.trustFlowColor}"
                          class="w-[37px] block text-center font-bold px-2 py-1 rounded text-white">
              {{ backlink.trustFlow }}
            </span>
                    </td>
                    <td class="!whitespace-normal">
                      {{backlink.trustFlowLabel}}
                    </td>
                    <td>{{ $filters.numberFormatIntl(backlink.traffic) }}</td>
                    <td>{{ $filters.numberFormatIntl(backlink.keyword) }}</td>
                    <td>{{ $filters.numberFormatIntl(backlink.referringDomains) }}</td>
                    <td>{{ $filters.numberFormatIntl(backlink.trustFlow) }}</td>
                    <td>{{ $filters.numberFormatIntl(backlink.citationFlow) }}</td>
                    <td>{{ $filters.numberFormatIntl(backlink.transferredAuthority) }}</td>
                    <td>{{ $filters.numberFormatIntl(backlink.transferredPopularity) }}</td>
                    <td class="!whitespace-normal">{{backlink.website}}</td>
                    <td>{{$filters.numberFormatIntl(backlink.trafficWebsite)}}</td>
                    <td>
                      {{$filters.numberFormatIntl(parseInt(backlink.keywordWebsite))}}
                    </td>
                    <td>{{$filters.numberFormatIntl(backlink.referringDomainsWebsite)}}</td>
                    <td><span
                        v-if="backlink.trustFlowWebsite"
                        v-tippy="{ allowHTML: true,content: backlink.trustFlowRepresentationWebsite,theme:'tooltip-ttfs'}"
                        :style="{'background': backlink.trustFlowColorWebsite}"
                        class="w-[37px] block text-center font-bold px-2 py-1 rounded text-white">
              {{ backlink.trustFlowWebsite }}
            </span>
                    </td>
                    <td>{{backlink.citationFlowWebsite}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </template>
        </tbody>
      </table>
    </div>
  </div>

</template>
<script setup>
import { computed, defineProps, reactive, ref, onMounted, onUnmounted } from 'vue'
import { ErrorMessage, Field, Form } from 'vee-validate'
import * as yup from 'yup'
import { useLocaleStore } from '@/js/stores/locale'
import { useLoadingStore } from '@/js/stores/loading'
import { useI18n } from 'vue-i18n'
import axios from 'axios'
import { useSorting } from '@/js/common/sorting' // Adjust path to sorting.js
const { sortConfig, setSortKey, sortedData } = useSorting()

const localeStore = useLocaleStore()
const loadingStore = useLoadingStore()

const { t } = useI18n()
const data_response = ref(null)
const errorData = ref(false)
const intervalId = ref(null) // Stocker l'ID de l'intervalle pour l'arrêter au besoin
const embeddings = ref({})
const datas = reactive({
  url: null,
})

const props = defineProps({
  count_histories: {
    type: Number,
    required: true,
    default: 5
  },
  max_backlinks: {
    type: Number,
    required: true,
    default: 5
  }
})

const schema_0 = computed(() => {
  return yup.object().shape({
    url: yup
        .string()
        .url(t('validation.invalid_url_format'))
        .required(t('validation.required'))
  })
})

const path = '/backlinks-checker/ajax/'

// Fonction pour récupérer les mises à jour des embeddings
const fetchEmbeddings = async () => {
  try {
    const response = await axios.post(path, {
      action: 'check_status',
      datas: {}
    })
    if (response.status === 200) {
      const newEmbeddings = response.data.embeddings;
      if(Object.keys(embeddings.value).length == 0){
        embeddings.value = newEmbeddings
      }else{
        embeddings.value = embeddings.value.map((existingEmbedding) => {
          const updatedEmbedding = newEmbeddings.find(e => e.id === existingEmbedding.id);
          if (updatedEmbedding) {
            // Vérifier si l'état est passé de 'in_progress' à 'completed'
            if (existingEmbedding.state === 'in_progress' && updatedEmbedding.state === 'completed') {
              return updatedEmbedding; // Mettre à jour uniquement cet embedding
            }
            return existingEmbedding; // Sinon, garder l'ancien
          }
          return existingEmbedding;
        });
      }
      // Mise à jour sélective des embeddings


      // Vérifier si tous les embeddings sont "completed"
      const allCompleted = embeddings.value.every(e => e.state === 'completed')
      if (allCompleted) {
        clearInterval(intervalId.value) // Stopper l'intervalle si tout est terminé
      }else if(!intervalId.value){
        intervalId.value = setInterval(fetchEmbeddings, 10000);
      }
    }
  } catch (error) {
    console.error("Erreur lors de la récupération des embeddings:", error)
  }finally {
  }
}

const fetchBacklinks = async (id,index) => {
  try {
    loadingStore.setLoadingPage(true)
    const response = await axios.post(path, {
      action: 'get_backlinks',
      datas: {id:id}
    })
    if (response.status === 200) {
      embeddings.value[index]['backlinks_list'] = response.data.backlinks
    }
  } catch (error) {
    console.error("Erreur lors de la récupération des embeddings:", error)
  }finally {
    loadingStore.setLoadingPage(false)
  }
}

// Vérification périodique dès que le composant est monté
onMounted(async() => {
  intervalId.value = setInterval(fetchEmbeddings, 2000) // Vérifier toutes les 10 sec
  await fetchEmbeddings() // Exécuter immédiatement pour éviter d'attendre 10 sec au départ
})

// Nettoyer l'intervalle lorsque le composant est détruit
onUnmounted(() => {
  if (intervalId.value) {
    clearInterval(intervalId.value)
  }
})

// Fonction appelée au submit pour analyser l'URL
const onSubmit_0 = async (values) => {
  try {
    loadingStore.setLoadingPage(true)
    const response = await axios.post(path, {
      action: 'execute',
      datas: { url: datas.url },
    })
    if (response.status === 200) {
      embeddings.value = response.data.embeddings
      props.count_histories = response.data.count_histories
    }
  } catch (error) {
    errorData.value = true
  } finally {
    loadingStore.setLoadingPage(false)
  }
}
</script>
