// src/i18n.js
import { createI18n } from 'vue-i18n';
import { useLocaleStore } from '@/js/stores/locale';
import { createPinia } from 'pinia';
import axios from 'axios';

// Create and configure Pinia
const pinia = createPinia();

// Load messages from local JSON files
const loadLocaleMessages = async () => {
  const messages = {};
  const response = await fetch('/translations/index.json'); // A JSON file that lists all available translations.
  const availableLocales = await response.json();

  for (const locale of availableLocales) {
    try {
      const res = await fetch(`/translations/${locale}.json`);
      const modulex = await res.json();
      messages[locale] = modulex;
    } catch (error) {
      console.error(`Failed to load locale ${locale}:`, error);
    }
  }

  return messages;
};

// Fetch locale from API using Axios
async function fetchLocale() {
  try {
    const response = await axios.get('/fetch/locale/');
    return response.data.locale;
  } catch (error) {
    console.error('Error fetching locale from API:', error);
    return 'fr'; // fallback to 'en' in case of error
  }
}

async function createI18nInstance() {
  const messages = await loadLocaleMessages();
  // Initialize the Pinia store
  const localeStore = useLocaleStore();

  // Fetch the locale from API
  const apiLocale = await fetchLocale();
  // Set the locale in the store
  localeStore.setLocale(apiLocale);
  // Create the i18n instance
  const i18n = createI18n({
    legacy: false,
    locale: localeStore.currentLocale,
    fallbackLocale: 'fr',
    messages,
  });


  return i18n;
}

export default createI18nInstance;