// app.js
import "./styles/app.css";
import 'tippy.js/dist/tippy.css' // optional for styling
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import { createApp } from "vue/dist/vue.esm-bundler";
import registerPlugins from "./js/plugins";
import registerComponents from "./js/components";
import registerDirectives from './js/directives'
import filters from "./js/filters";
import datas_params from "./js/datas";
import methods from "./js/methods";
import { createPinia } from 'pinia';
import { useLoadingStore } from './js/stores/loading';
import { useLocaleStore } from './js/stores/locale';
const pinia = createPinia();

if (document.getElementById("app") !== null) {
  const app = createApp({
    data() {
      return datas_params;
    },
    mounted() {
      setTimeout(() => {
            this.isLoading = false;
          }, 100); // Adjust the timeout to simulate your loading time
    },
    methods,
    delimiters: ["${", "}"],
    compilerOptions: {
      delimiters: ["${", "}"],
    },
    setup() {
        const loadingStore = useLoadingStore()
        const locale = useLocaleStore()
        const currentLocale = locale.currentLocale
        return { loadingStore, locale,currentLocale }
    }
  });

  (async () => {
    app.use(pinia); // Ensure Pinia is used before any other plugin

    await registerPlugins(app); // Wait for the plugins to be registered
    registerComponents(app); // Register components after plugins
    registerDirectives(app); // Register components after plugins

    app.config.globalProperties.$filters = filters; // Set global filters
    app.mount("#app"); // Mount the app
  })();
}
