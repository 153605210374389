// src/states.js
import { defineStore } from 'pinia';

export const useNavStore = defineStore('nav', {
  state: () => ({
    isMenuOpen: false,
  }),
  actions: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
    openMenu() {
      this.isMenuOpen = true;
    }
  }
});
