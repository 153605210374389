<script setup>
import { onMounted, ref, watch } from 'vue'
import { useLoadingStore } from '@/js/stores/loading'

const loadingStore = useLoadingStore()
// Props
const props = defineProps({
  apiUrl: {
    type: String,
    required: true,
  },
  perPageOptions: {
    type: Array,
    default: () => [10, 25, 50, 100],
  },
  filters: {
    type: Array,
    required: true,
  },
})

// State variables
const data = ref([])
const totalItems = ref(0)
const totalPages = ref(1)
const currentPage = ref(1)
const perPage = ref(props.perPageOptions[0])
const sortField = ref(null)
const sortDirection = ref('asc')
const loading = ref(false)

// Initialize filters from URL or default values
const getFilterDefaults = () => {
  const urlParams = new URLSearchParams(window.location.search)
  return props.filters.reduce((acc, filter) => {
    if (filter.type === 'multiple') {
      const value = urlParams.get(`filters[${filter.key}]`)
      acc[filter.key] = value ? value.split(',') : []
    } else {
      acc[filter.key] = urlParams.get(`filters[${filter.key}]`) || filter.default || ''
    }
    return acc
  }, {})
}

const filterValues = ref(getFilterDefaults())

// Read URL parameters on mount
const initializeFromURL = () => {
  const urlParams = new URLSearchParams(window.location.search)

  currentPage.value = parseInt(urlParams.get('page')) || 1
  perPage.value = parseInt(urlParams.get('limit')) || props.perPageOptions[0]

  let sortFieldV = null
  let sortDirectionV = null

  for (const [key, value] of urlParams.entries()) {
    const match = key.match(/^sort\[(.+)]$/) // Regex pour capturer les clés de tri
    if (match) {
      sortFieldV = match[1] // Extrait "c.name"
      sortDirectionV = value // Extrait "asc" ou "desc"
    }
  }
  sortField.value = sortFieldV || null
  sortDirection.value = sortDirectionV || 'asc'

  // Extraire les filtres depuis l'URL
  urlParams.forEach((value, key) => {
    if (key.startsWith('filters[')) {
      const filterKey = key.replace(/^filters\[(.+)]$/, '$1')
      // Vérifie si le filtre est un "daterange" et convertit correctement les dates
      filterValues.value[filterKey] = value

    }
  })
}

const isStateSelected = (key, state) => {
  const arrya = filterValues.value[key].split(',')
  return arrya.includes(state)
}
const toggleState = (key, state) => {
  // Get current states as array, handling empty string case
  const currentStates = filterValues.value[key] ? filterValues.value[key].split(',') : []

  // Check if state exists in array
  const stateIndex = currentStates.indexOf(state)

  if (stateIndex > -1) {
    // State exists, remove it
    currentStates.splice(stateIndex, 1)
  } else {
    // State doesn't exist, add it
    currentStates.push(state)
  }

  // Update filterValues with new comma-separated string
  // If array is empty, set empty string instead of empty array
  filterValues.value[key] = currentStates.length ? currentStates.join(',') : ''
}
// Update URL with current state
const updateURL = () => {
  const params = new URLSearchParams()
  params.set('page', currentPage.value)
  params.set('limit', perPage.value)

  if (sortField.value) {
    params.set(`sort[${sortField.value}]`, sortDirection.value)
  }

  Object.entries(filterValues.value).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      if (value.length > 0) {
        if (key.toLowerCase().includes('date')) {
          // Convertir chaque date en format ISO 8601
          const isoDates = value.map(date => new Date(date).toISOString())
          params.set(`filters[${key}]`, isoDates.join(','))
        } else {
          params.set(`filters[${key}]`, value.join(','))
        }
      }
    } else if (value !== '') {
      params.set(`filters[${key}]`, value)
    }
  })

  window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`)
}

// Fetch data from API
const fetchData = async () => {
  loadingStore.setLoadingPage(true)
  const params = new URLSearchParams({
    page: currentPage.value,
    limit: perPage.value,
  })
  if (sortField.value) {
    params.append(`sort[${sortField.value}]`, sortDirection.value)
  }

  Object.entries(filterValues.value).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      if (key.toLowerCase().includes('date') && value !== null) {
        console.log(value, key)
        const formattedDates = value.map(date => {
          const d = new Date(date)
          if (isNaN(d.getTime())) {
            // Si la date est invalide, on la retourne telle quelle (ou on peut lever une erreur)
            return date
          }
          const month = (d.getMonth() + 1).toString().padStart(2, '0')
          const day = d.getDate().toString().padStart(2, '0')
          const year = d.getFullYear()
          return `${month}/${day}/${year}`
        })
        // Si l'on a exactement deux dates, on considère qu'il s'agit d'une plage
        if (formattedDates.length === 2) {
          params.append(`filters[${key}][$gte]`, formattedDates[0])
          params.append(`filters[${key}][$lte]`, formattedDates[1])
        } else if (formattedDates.length > 0) {
          // Sinon, on envoie le tableau joint par une virgule
          params.append(`filters[${key}]`, formattedDates.join(','))
        }
      } else if (value.length > 0) {
        params.append(`filters[${key}]`, value.join(','))
      }

    } else if (value !== '' && value !== null) {
      params.append(`filters[${key}]`, value)
    }
  })

  try {
    const response = await fetch(`${props.apiUrl}?${params.toString()}`)
    const result = await response.json()

    data.value = result.data
    data.value = result.data.map(item => ({
      ...item,
      custom_1: false,
      custom_2: false,
      custom_3: false,
      custom_4: false
    }))
    totalItems.value = result.pagination.totalItems
    totalPages.value = result.pagination.totalPages

    updateURL()
  } catch (error) {
    console.error('Error fetching data:', error)
  } finally {
    loadingStore.setLoadingPage(false)
  }
}

// Watch for changes and update
watch([currentPage, perPage, filterValues], fetchData, { deep: true })

// Sorting function
const setSort = (field) => {
  if (sortField.value === field) {
    sortDirection.value = sortDirection.value === 'asc' ? 'desc' : 'asc'
  } else {
    sortField.value = field
    sortDirection.value = 'asc'
  }
  fetchData()
}

// Reset Filters
const resetFilters = () => {
  filterValues.value = props.filters.reduce((acc, filter) => {
    acc[filter.key] = filter.type === 'multiple' ? [] : ''
    return acc
  }, {})
}

// On mount, initialize URL params and fetch data
onMounted(() => {
  initializeFromURL()
  fetchData()
})
</script>

<template>
  <div class="bg-white p-6 rounded-lg shadow-md" >
    <!-- Filters Slot -->
    <slot :filterValues="filterValues" :isStateSelected="isStateSelected" :resetFilters="resetFilters" :toggleState="toggleState"
          name="filters"></slot>

    <div v-if="Object.keys(data).length > 0">
      <table class="w-full table-auto table-pagination">
        <thead>
        <slot :setSort="setSort" :sortDirection="sortDirection" :sortField="sortField" name="thead"></slot>
        </thead>
        <tbody>
        <slot :items="data" name="tbody"></slot>
        </tbody>
      </table>
    </div>

    <!-- Pagination Controls -->
    <div  v-if="Object.keys(data).length > 0" class="mt-6 flex justify-between items-center space-x-4">
      <select v-model="perPage" class="w-[70px]">
        <option v-for="option in props.perPageOptions" :key="option" :value="option">
          {{ option }}
        </option>
      </select>
      <button
          v-if="totalPages > 1"
          :disabled="currentPage === 1"
          class="px-4 py-2 bg-gray-300 rounded disabled:opacity-50"
          @click="currentPage--"
      >
        Précédent
      </button>

      <span v-if="totalPages > 1" class="w-full text-center text-sm">Page {{ currentPage }} sur {{ totalPages }}</span>

      <!-- Select to choose limit per page -->


      <button
          v-if="totalPages > 1"
          :disabled="currentPage === totalPages"
          class="px-4 py-2 bg-gray-300 rounded disabled:opacity-50"
          @click="currentPage++"
      >
        Suivant
      </button>
    </div>
    <div v-if="Object.keys(data).length == 0 && !loadingStore.isLoading" class="my-6">
      <div class="rounded-md bg-blue-100 p-4">
        <p class="flex items-center text-sm font-medium text-netlinkingb">
      <span class="pr-3">
         <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-info"><circle cx="12" cy="12" r="10"/><path d="M12 16v-4"/><path d="M12 8h.01"/></svg>
      </span>
          Aucune information à afficher pour le moment.
        </p>
      </div>
    </div>
  </div>

</template>
