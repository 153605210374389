<template>
  <Form id="userForm" v-slot="{ errors }" :validation-schema="schema_0" class="space-y-3" @submit="onSubmit_0">
  <div class="py-3 pl-[104px] pr-[104px] space-y-4">
    <Field
        v-model="formValues.message"
        class="bg-white"
        name="message"
        as="textarea"
        rows="4"
    ></Field>
    <ErrorMessage class="error-message" name="message"/>
    <div class="space-x-4 flex items-center">
      <div>
        <button :disabled="loadingStore.isLoading" class="btn btn-sm btn-dark" type="submit">
          <LoadingBtn :label="'Envoyer'"/>
        </button>
      </div>
      <div class="w-full flex items-center space-x-4">
        <Field v-slot="{ field, errors }" name="file" v-model="formValues.file">
          <Fileupload :minimized="true" classname="w-full md:w-1/4 lg:w-1/6" labeltext="Pièce jointe" v-bind="field" v-model="formValues.file"/>
        </Field>
        <ErrorMessage class="error-message" name="file"/>
      </div>
    </div>

  </div>
  </Form>
</template>
<script setup>

import { ErrorMessage,Field, Form } from 'vee-validate'
import { defineProps, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useLoadingStore } from '@/js/stores/loading'
import { useLocaleStore } from '@/js/stores/locale'
import LoadingBtn from '@/components/common/LoadingBtn.vue'
import Fileupload from '@/components/common/Fileupload.vue'
import * as yup from 'yup'
import axios from 'axios'
import { computed } from 'vue'
const path = '/support/ajax/'
const { t } = useI18n()
const localeStore = useLocaleStore()
const loadingStore = useLoadingStore()
const props = defineProps({
  ticket_id: {
    required: true
  }
})
const formValues = ref({
  message: "",
  file: null
})
const schema_0 = computed(() => {
  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes
  const ALLOWED_FILE_TYPES = [
    'image/png', 'image/jpeg', 'image/jpg', 'image/webp',
    'application/pdf', 'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ];

  return yup.object({
    message: yup
        .string()
        .trim()
        .required(t('validation.required')),
    file: yup
        .mixed()
        .nullable()
        .test('fileType',
            t('validation.invalid_file_type', { value: 'Word, Excel, PDF et les images' }),
            (value) => {
              if (!value) return true;

              let file;
              if (value instanceof FileList) {
                file = value[0];
              } else if (Array.isArray(value)) {
                file = value[0];
              } else {
                file = value;
              }
              return file ? ALLOWED_FILE_TYPES.includes(file.type) : true;
            }
        )
        .test('fileSize',
            t('validation.max_file_size', { value: '5 Mo' }),
            (value) => {
              if (!value) return true;

              let file;
              if (value instanceof FileList) {
                file = value[0];
              } else if (Array.isArray(value)) {
                file = value[0];
              } else {
                file = value;
              }
              return file ? file.size <= MAX_FILE_SIZE : true;
            }
        )

  });
});
const onSubmit_0 = async (values) => {
  loadingStore.setLoadingPage(true);

  try {
    const formData = new FormData();
    formData.append('action','add-message');
    formData.append('ticket_id',props.ticket_id);
    formData.append('message', formValues.value.message);
    if (values.file) {
      if (Array.isArray(values.file)) {
        values.file.forEach((file, index) => {
          formData.append(`file[${index}]`, file);
        });
      } else {
        formData.append('file', values.file);
      }
    }

    const response = await axios.post(path, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (response.status === 200) {
      window.location.href = response.data.redirect
    }
  } catch (error) {
    loadingStore.setLoadingPage(false);

  } finally {
  }
};

</script>