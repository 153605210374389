<template>
  <!-- ====== Forms Section Start -->

  <section class="min-h-screen relative z-10 bg-netlinking_dark_2 py-10 lg:py-[40px]  flex items-center justify-center">
    <a href="/"><img class="absolute top-5 left-5 w-52" :src="'/images/logo-netlinking-blanc.svg'" alt=""></a>
    <div class="container mx-auto lg:mt-0 md:mt-0 mt-9" v-if="step == 2">
      <div
          class="mx-auto max-w-[900px] justify-between rounded-2xl bg-white px-10 py-10 lg:flex"
      >
        <div class="mx-auto w-full max-w-full">

          <h1 class="mb-2 text-xl font-bold text-dark sm:text-[34px]">
            {{ $t('welcome') }} {{ formValues.firstName }} !
          </h1>
          <span
              class="block mb-2 text-sm font-medium text-body-color sm:text-base"
          >
            {{ $t('getting_to_know') }}
          </span>
          <h2 class="text-netlinking text-lg font-medium mb-3">{{ $t('activity') }}</h2>
          <Form @submit="onSubmit_2" class="space-y-3" :validation-schema="schema_2" v-slot="{ errors }">
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5">
              <div>
                <label for="responsibility">
                  {{ $t('role') }}
                </label>
                <div class="relative">
                  <Field v-model="formValues.responsibility" name="responsibility" v-slot="{ field, errors }"
                  >
                    <select :class="[errors && Object.keys(errors).length > 0 ? 'ring-2 ring-red-600' : '']"
                            id="responsibility"
                            name="role" v-model="formValues.responsibility">
                      <option :value="''" disabled>{{ $t('select') }}</option>
                      <option v-for="role in roles" :value="role.value">{{ $t(role.label) }}</option>
                    </select>
                  </Field>
                  <ErrorMessage name="responsibility" class="error-message"/>
                </div>
              </div>
              <div>
                <label for="expertise_level">
                  {{ $t('expertise_level') }}
                </label>
                <div class="relative">
                  <Field v-model="formValues.expertise_level" name="expertise_level" v-slot="{ field, errors }"
                  >
                    <select :class="[errors && Object.keys(errors).length > 0 ? 'ring-2 ring-red-600' : '']"
                            id="expertise_level"
                            name="expertise_level" v-model="formValues.expertise_level">
                      <option :value="''" disabled>{{ $t('select') }}</option>
                      <option v-for="exper in expertise_level" :value="exper.value">{{ $t(exper.label) }}</option>
                    </select>
                  </Field>
                  <ErrorMessage name="expertise_level" class="error-message"/>
                </div>
              </div>
            </div>

            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5">
              <div>
                <label for="role">
                  {{ $t('has_purchased') }}
                </label>
                <div class="relative">
                  <Field v-model="formValues.has_purchased" name="has_purchased" v-slot="{ field, errors }"
                  >
                    <select :class="[errors && Object.keys(errors).length > 0 ? 'ring-2 ring-red-600' : '']"
                            id="has_purchased"
                            name="has_purchased" v-model="formValues.has_purchased">
                      <option :value="''" disabled>{{ $t('select') }}</option>
                      <option v-for="pursh in purchase_frequency" :value="pursh.value">{{ $t(pursh.label) }}</option>
                    </select>
                  </Field>
                  <ErrorMessage name="has_purchased" class="error-message"/>
                </div>
              </div>
              <div>
                <label for="annual_budget">
                  {{ $t('annual_budget') }}
                </label>
                <div class="relative">
                  <Field v-model="formValues.annual_budget" name="annual_budget" v-slot="{ field, errors }"
                  >
                    <select :class="[errors && Object.keys(errors).length > 0 ? 'ring-2 ring-red-600' : '']"
                            id="annual_budget"
                            name="annual_budget" v-model="formValues.annual_budget">
                      <option :value="''" disabled>{{ $t('select') }}</option>
                      <option v-for="annu in annual_budget" :value="annu.value">{{ $t(annu.label) }}</option>
                    </select>
                  </Field>
                  <ErrorMessage name="annual_budget" class="error-message"/>
                </div>
              </div>
            </div>

            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5">
              <div>
                <label for="role">
                  {{ $t('discovery') }}
                </label>
                <div class="relative">
                  <Field v-model="formValues.discovery" name="discovery" v-slot="{ field, errors }"
                  >
                    <select :class="[errors && Object.keys(errors).length > 0 ? 'ring-2 ring-red-600' : '']"
                            id="discovery"
                            name="discovery" v-model="formValues.discovery">
                      <option :value="''" disabled>{{ $t('select') }}</option>
                      <option v-for="dm in discovery_methods" :value="dm.value">{{ $t(dm.label) }}</option>
                    </select>
                  </Field>
                  <ErrorMessage name="discovery" class="error-message"/>
                </div>
              </div>

            </div>

            <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-1 ">
              <div class="flex justify-center mt-3">
                <button
                    :disabled="loadingStore.isLoading || !formValues.responsibility || !formValues.expertise_level || !formValues.has_purchased || !formValues.annual_budget || !formValues.discovery || Object.keys(errors).length > 0"
                    class="btn btn-lg btn-netlinking"
                >
                  <LoadingBtn :label="$t('validate')"/>
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
    <div class="container mx-auto lg:mt-0 md:mt-0 mt-9" v-if="step == 1">
      <div
          class="mx-auto max-w-[800px] justify-between rounded-2xl bg-white px-10 py-10 lg:flex"
      >
        <div class="mx-auto w-full max-w-full">

          <h1 class="mb-2 text-xl font-bold text-dark sm:text-[34px]">
            {{ $t('welcome') }} {{ formValues.firstName }}!
          </h1>
          <span
              class="block mb-2 text-sm font-medium text-body-color sm:text-base"
          >
            {{ $t('getting_to_know') }}
          </span>
          <h2 class="text-netlinking text-lg font-medium mb-3">{{ $t('company') }}</h2>
          <Form @submit="onSubmit_1" class="space-y-3" :validation-schema="schema_1" v-slot="{ errors }">
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5">
              <div>
                <label for="company">
                  {{ $t('additional_fields.company') }}
                </label>
                <div class="relative">
                  <Field :placeholder="$t('ex_digitalio')" v-model="formValues.company" name="company" type="text"
                         :class="[errors.company ? 'ring-2 ring-red-600' : '']"/>
                  <ErrorMessage name="company" class="error-message"/>
                </div>
              </div>
              <div>
                <label for="organization_type">
                  {{ $t('additional_fields.organization_type') }}
                </label>
                <div class="relative">
                  <Field v-model="formValues.organization_type" name="organization_type" v-slot="{ field, errors }"
                  >
                    <select :class="[errors && Object.keys(errors).length > 0 ? 'ring-2 ring-red-600' : '']" id="sector"
                            name="sector" v-model="formValues.organization_type">
                      <option :value="''" disabled>{{ $t('select') }}</option>
                      <option v-for="sector in entity_types" :value="sector.value">{{ $t(sector.label) }}</option>
                    </select>
                  </Field>
                  <ErrorMessage name="organization_type" class="error-message"/>
                </div>
              </div>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5">
              <div>
                <label for="country">
                  {{ $t('additional_fields.country') }}
                </label>
                <div class="relative">
                  <Field v-model="formValues.country" name="country" v-slot="{ field, errors }"
                  >
                    <select
                        :class="[errors && Object.keys(errors).length > 0 ? 'ring-2 ring-red-600' : '']"
                        id="country"
                        name="country"
                        v-model="formValues.country"
                    >
                      <option :value="''" disabled>{{ $t('select_country') }}</option>
                      <option v-for="country in countries" :value="country.value">{{ $t(country.label) }}</option>

                    </select>
                  </Field>

                  <ErrorMessage name="country" class="error-message"/>
                </div>
              </div>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5">
              <div>
                <label for="website">
                  {{ $t('additional_fields.website') }}
                </label>
                <div class="relative">
                  <Field :placeholder="$t('website_url')" v-model="formValues.website" name="website" type="text"
                         :class="[errors.website ? 'ring-2 ring-red-600' : '']"/>
                  <ErrorMessage name="website" class="error-message"/>
                </div>
              </div>
              <div>
                <label for="sector">
                  {{ $t('additional_fields.sector') }}
                </label>
                <div class="relative">
                  <Field v-model="formValues.sector" name="sector" v-slot="{ field, errors }"
                  >
                    <select :class="[errors && Object.keys(errors).length > 0 ? 'ring-2 ring-red-600' : '']" id="sector"
                            name="sector" v-model="formValues.sector">
                      <option :value="''" disabled>{{ $t('select') }}</option>
                      <option v-for="sector in sectors" :value="sector.value">{{ $t(sector.label) }}</option>
                    </select>
                  </Field>
                  <ErrorMessage name="sector" class="error-message"/>
                </div>
              </div>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-1 ">
              <div class="flex justify-center mt-3">
                <button
                    :disabled="!formValues.company || !formValues.organization_type || !formValues.country || !formValues.website || !formValues.sector || Object.keys(errors).length > 0"
                    class="btn btn-lg btn-netlinking"
                >
                  {{ $t('next') }}
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
    <div class="container mx-auto lg:mt-0 md:mt-0 mt-9" v-if="step == 0">
      <div
          class="mx-auto max-w-[800px] justify-between rounded-2xl bg-white px-10 py-10 lg:flex"
      >
        <div class="mx-auto w-full max-w-full">
          <h1 class="mb-2 text-xl font-bold text-dark sm:text-[34px]">
            {{ $t('welcome') }} !
          </h1>
          <span
              class="block mb-2 text-sm font-medium text-body-color sm:text-base"
          >
            {{ $t('getting_to_know') }}
          </span>
          <Form @submit="onSubmit_0" class="space-y-3" :validation-schema="schema_0" v-slot="{ errors }">
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5">
              <div>
                <label for="firstName">
                  {{ t('field_names.firstName') }}
                </label>
                <div class="relative">
                  <Field v-model="formValues.firstName" name="firstName" type="text"
                         :class="[errors.firstName ? 'ring-2 ring-red-600' : '']"/>
                  <ErrorMessage name="firstName" class="error-message"/>
                </div>
              </div>
              <div>
                <label for="lastName">
                  {{ t('field_names.lastName') }}
                </label>
                <div class="relative">
                  <Field v-model="formValues.lastName" name="lastName" type="text"
                         :class="[errors.lastName ? 'ring-2 ring-red-600' : '']"/>
                  <ErrorMessage name="lastName" class="error-message"/>
                </div>
              </div>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5">
              <div>
                <label for="email">
                  {{ t('field_names.email') }}
                </label>
                <div class="relative">
                  <Field v-model="formValues.email" name="email" type="text"
                         :class="[errors.email ? 'ring-2 ring-red-600' : '']"/>
                  <ErrorMessage name="email" class="error-message"/>
                </div>
              </div>
              <div>
                <label for="phone">{{ t('field_names.phone') }}</label>
                <Field v-model="formValues.phone" name="phone" v-slot="{ field, errors }"
                       :class="[errors.phone ? 'ring-2 ring-red-600' : '']">
                  <PhoneInput
                      @update:format="handleFormat"
                      @update:country="handleCountry"
                      v-model="formValues.phone"
                      locale="currentLocale"
                      :classError="[errors && Object.keys(errors).length > 0 ? 'ring-2 ring-red-600' : '']"
                  />
                </Field>
                <ErrorMessage name="phone" class="text-red-500 text-xs"/>
              </div>
            </div>

            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5">
              <div>
                <label for="password">
                  {{ $t('field_names.password') }}
                </label>
                <div class="relative">
                  <Field v-model="formValues.password" name="password" v-slot="{ field, errors }"
                         :class="[errors.password && Object.keys(errors.password).length > 0 ? 'ring-2 ring-red-600' : '']">
                    <input
                        :type="(showPassword)?'text':'password'"
                        v-model="formValues.password"
                        :class="[errors && Object.keys(errors).length > 0 ? 'ring-2 ring-red-600' : '']"

                    />
                    <span @click.prevent.stop="toggleShowPassword"
                          class="absolute cursor-pointer -translate-y-1/2 top-1/2 right-5">
                    <svg v-if="!showPassword" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                         viewBox="0 0 24 24" fill="none"
                         stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                         class="lucide lucide-eye"><path
                        d="M2.062 12.348a1 1 0 0 1 0-.696 10.75 10.75 0 0 1 19.876 0 1 1 0 0 1 0 .696 10.75 10.75 0 0 1-19.876 0"/><circle
                        cx="12" cy="12" r="3"/></svg>
                      <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"
                           fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                           stroke-linejoin="round" class="lucide lucide-eye-off"><path
                          d="M10.733 5.076a10.744 10.744 0 0 1 11.205 6.575 1 1 0 0 1 0 .696 10.747 10.747 0 0 1-1.444 2.49"/><path
                          d="M14.084 14.158a3 3 0 0 1-4.242-4.242"/><path
                          d="M17.479 17.499a10.75 10.75 0 0 1-15.417-5.151 1 1 0 0 1 0-.696 10.75 10.75 0 0 1 4.446-5.143"/><path
                          d="m2 2 20 20"/></svg>
                  </span>
                  </Field>
                </div>
              </div>
              <div class="mt-0 md:mt-8 lg:mt-8">
                <div class="lg:flex md:flex items-center text-xs lg:space-x-3 md:space-x-3">
                  <div>
                    <div class="space-x-2 flex items-center"
                         :class="passwordRequirements.hasUpperCase?'text-green-600':'text-gray-400'">
                      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none"
                           stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                           class="lucide lucide-check">
                        <path d="M20 6 9 17l-5-5"/>
                      </svg>
                      <span>{{ $t('validation.password_uppercase') }}</span>
                    </div>
                    <div class="space-x-2 flex items-center"
                         :class="passwordRequirements.hasMinLength?'text-green-600':'text-gray-400'">
                      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none"
                           stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                           class="lucide lucide-check">
                        <path d="M20 6 9 17l-5-5"/>
                      </svg>
                      <span>{{ $t('validation.password_length') }}</span>
                    </div>
                  </div>
                  <div>
                    <div class="space-x-2 flex items-center"
                         :class="passwordRequirements.hasNumber?'text-green-600':'text-gray-400'">
                      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none"
                           stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                           class="lucide lucide-check">
                        <path d="M20 6 9 17l-5-5"/>
                      </svg>
                      <span>{{ $t('validation.password_number') }}</span>
                    </div>
                    <div class="space-x-2 flex items-center"
                         :class="passwordRequirements.hasSpecialChar?'text-green-600':'text-gray-400'">
                      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none"
                           stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                           class="lucide lucide-check">
                        <path d="M20 6 9 17l-5-5"/>
                      </svg>
                      <span>{{ $t('validation.password_special_char') }} (@#²!?*^%)</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pt-3">
              <div class="flex gap-3">
                <div class="flex h-6 shrink-0 items-center">
                  <div class="group grid grid-cols-1">
                    <Field v-model="formValues.cgv" name="cgv" v-slot="{ field, errors }"
                           :class="[errors.cgv && Object.keys(errors.cgv).length > 0 ? 'ring-2 ring-red-600' : '']">
                      <input id="cgv" aria-describedby="comments-description" name="cgv" type="checkbox"
                             v-model="formValues.cgv"
                             class="col-start-1 row-start-1 appearance-none rounded border border-gray-300 bg-white checked:border-netlinking checked:bg-netlinking indeterminate:border-netlinking indeterminate:bg-netlinking focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-netlinking disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"/>
                      <svg
                          class="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-[:disabled]:stroke-gray-950/25"
                          viewBox="0 0 14 14" fill="none">
                        <path class="opacity-0 group-has-[:checked]:opacity-100" d="M3 8L6 11L11 3.5" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round"/>
                        <path class="opacity-0 group-has-[:indeterminate]:opacity-100" d="M3 7H11" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </Field>
                  </div>

                </div>

                <div class="text-sm/6">
                  <label for="cgv" class="text-gray-900 font-normal">{{ t('field_names.cgv.acceptance') }} <a
                      href="#" class="link-simple">{{ t('field_names.cgv.terms') }}</a> {{ t('field_names.cgv.and') }}
                    <a href="#" class="link-simple">{{ t('field_names.cgv.privacy_policy') }}</a></label>
                  {{ ' ' }}
                  <span id="comments-description" class="text-gray-500"><span
                      class="sr-only">New comments </span></span>
                </div>

              </div>
              <ErrorMessage name="cgv" class="text-red-500 text-xs"/>

            </div>
            <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-1 ">
              <div class="flex justify-center mt-3">
                <button
                    :disabled="loadingStore.isLoading || !formValues.cgv || !formValues.email || !formValues.firstName || !formValues.lastName || !formValues.phone || !formValues.password || Object.keys(errors).length > 0"
                    class="btn btn-lg btn-netlinking"
                >
                  <LoadingBtn :label="$t('field_names.create_an_account')"/>
                </button>

              </div>
              <p class="text-red-600 text-sm text-center mt-2" v-if="existingEmail">
                {{ $t('already_have_account_email') }}
              </p>

              <p class="text-base text-dark text-center mt-4">
                <span class="pr-0.5">{{ $t('has_account') }} <a :href="`/${currentLocale}/sign-in/`" class="link-simple">{{
                    $t('login')
                  }}</a></span>
              </p>


            </div>


          </Form>
        </div>
      </div>
    </div>
  </section>
  <!-- ====== Forms Section End -->
</template>
<script setup>
import { computed, defineProps, reactive, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import PhoneInput from '@/components/common/phone/PhoneInput.vue'
import { ErrorMessage, Field, Form } from 'vee-validate'
import * as yup from 'yup'
import LoadingBtn from '@/components/common/LoadingBtn.vue'
import { useLoadingStore } from '@/js/stores/loading'
import { useLocaleStore } from '@/js/stores/locale'
import axios from 'axios'

const showPassword = ref(false)
const emit = defineEmits(['form-submitted'])
const { t } = useI18n()
const localeStore = useLocaleStore()
const loadingStore = useLoadingStore()
const currentLocale = localeStore.currentLocale
const step = ref(0)
const existingEmail = ref(false)
const formValues = reactive({
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  password: '',
  company: '',
  organization_type: '',
  country: '',
  website: '',
  sector: '',
  responsibility: '',
  expertise_level: '',
  has_purchased: '',
  annual_budget: '',
  discovery: '',
  cgv: false,
  captcha: '',
  csrf: '',
  selectedCountry: ''
})
const phoneFormat = ref({
  placeholder: '',
  maxLength: 10,
  dialCode: ''
})
const passwordRequirements = ref({
  hasUpperCase: false,
  hasNumber: false,
  hasSpecialChar: false,
  hasMinLength: false,
  isValid: false
})
const hasPasswordRequirements = (password) => {
  console.log('Checking password:', password) // Debug log

  if (!password) {
    return {
      hasUpperCase: false,
      hasNumber: false,
      hasSpecialChar: false,
      hasMinLength: false,
      isValid: false
    }
  }

  const requirements = {
    hasUpperCase: /[A-Z]/.test(password),
    hasNumber: /[0-9]/.test(password),
    hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    hasMinLength: password.length >= 12
  }

  requirements.isValid = requirements.hasUpperCase &&
      requirements.hasNumber &&
      requirements.hasSpecialChar &&
      requirements.hasMinLength

  console.log('Password requirements:', requirements) // Debug log
  return requirements
}

// Fonction de validation appelée lors de la saisie
const validatePassword = () => {
  console.log('Validating password:', formValues.password) // Debug log
  passwordRequirements.value = hasPasswordRequirements(formValues.password)
}

watch(() => formValues.password, (newValue) => {
  console.log('Password changed:', newValue)
  validatePassword()
}, { immediate: true })

const selectedCountry = ref(null)
const handleFormat = (format) => {
  formValues.country = format.dialCode
  phoneFormat.value = format
}

const handleCountry = (country) => {
  selectedCountry.value = country
}
const formatExampleNumber = computed(() => {
  if (!phoneFormat.value?.placeholder) return 'XX XX XX XX XX'
  return phoneFormat.value.placeholder.replace(/\d/g, 'X')
})

const schema_0 = computed(() => {
  return yup.object().shape({
    cgv: yup.boolean().required(t('validation.required')).test('is-true', t('validation.cgv_required'), value => value === true),
    email: yup
        .string()
        .required(t('validation.required'))
        .email(t('validation.invalid_email_format')),
    firstName: yup
        .string()
        .required(t('validation.required')),
    lastName: yup
        .string()
        .required(t('validation.required')),
    phone: yup
        .string()
        .required(t('validation.required'))
        .test('phone-format', () =>
                `Merci de renseigner votre numéro de téléphone au format suivant ${formatExampleNumber.value}`,
            (value) => {
              if (!value || !phoneFormat.value) return false
              const cleaned = value.replace(/\D/g, '')
              return cleaned.length === phoneFormat.value.maxLength
            }
        ),
    password: yup
        .string()
        .required(t('validation.required'))
        .min(12, t('validation.password_length'))
        .test('uppercase', t('validation.password_uppercase'),
            value => /[A-Z]/.test(value)
        )
        .test('digit', t('validation.password_number'),
            value => /\d/.test(value)
        )
        .test('special', t('validation.password_special_char'),
            value => /[!@#$%^&*(),.?":{}|<>]/.test(value)
        )
  })
})
const schema_1 = computed(() => {
  return yup.object().shape({
    company: yup
        .string()
        .required(t('validation.required')),
    organization_type: yup
        .string()
        .required(t('validation.required')),
    country: yup
        .string()
        .required(t('validation.required')),
    website: yup
        .string()
        .url(t('validation.invalid_url_format'))
        .required(t('validation.required')),
    sector: yup
        .string()
        .required(t('validation.required'))
  })
})
const schema_2 = computed(() => {
  return yup.object().shape({
    responsibility: yup
        .string()
        .required(t('validation.required')),
    expertise_level: yup
        .string()
        .required(t('validation.required')),
    has_purchased: yup
        .string()
        .required(t('validation.required')),
    annual_budget: yup
        .string()
        .required(t('validation.required')),
    discovery: yup
        .string()
        .required(t('validation.required'))
  })
})
const props = defineProps({
  captcha: {
    type: String,
    required: true
  },
  csrf: {
    type: String,
    required: true
  },
  googleloginurl: {
    type: String,
    required: true
  },
  countries: {
    type: Object,
    required: true
  },
  sectors: {
    type: Object,
    required: true
  },
  expertise_level: {
    type: Object,
    required: true
  },
  annual_budget: {
    type: Object,
    required: true
  },
  discovery_methods: {
    type: Object,
    required: true
  },
  entity_types: {
    type: Object,
    required: true
  },
  purchase_frequency: {
    type: Object,
    required: true
  },
  roles: {
    type: Object,
    required: true
  },
})
const path = '/security/ajax/'


const onSubmit_0 = async (values) => {
  loadingStore.setLoading(true)
  const response = await axios.post(path, {
    action: 'exist_emailing',
    datas: { email: values.email, captcha: props.captcha, csrf: props.csrf }
  })
  if (response.status === 204) {
    step.value = 1
  } else {
    existingEmail.value = true
  }
  loadingStore.setLoading(false)
}
const onSubmit_1 = (values) => {
  // Handle form submission
  step.value = 2
  // Add your logic here to send the data to the server
}
const onSubmit_2 = (values) => {
  // Handle form submission
  loadingStore.setLoading(true)
  formValues.captcha = props.captcha
  formValues.csrf = props.csrf
  formValues.selectedCountry = selectedCountry
  emit('form-submitted', { formData: formValues, action: 'signup', path: path })
  // Add your logic here to send the data to the server
}
const toggleShowPassword = () => {
  showPassword.value = !showPassword.value
}
const redirectToUrl = (url) => {
  if (url && typeof url === 'string') {
    window.location.href = url
  } else {
    console.error('Invalid URL provided')
  }
}
</script>
<style scoped>
/* Custom styles for MazPhoneNumberInput */
::v-deep(.custom-phone-input input[type="text"],
.custom-phone-input input[type="tel"]) {
  @apply w-full ring-netlinking px-3 py-2 text-base bg-transparent border rounded-md outline-none border-stroke text-body-color transition-all duration-200;
}

::v-deep(.custom-phone-input input:focus) {
  @apply border-netlinking ring-netlinking !important;
}

::v-deep(.custom-phone-input input:focus-visible) {
  @apply shadow-none;
}
</style>