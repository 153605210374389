import { reactive, computed } from "vue";

export function useSorting(initialKey = "", initialDirection = "desc") {
  // State for sorting configuration
  const sortConfig = reactive({
    key: initialKey, // The key to sort by
    direction: initialDirection, // Can be "asc" or "desc"
  });

  /**
   * Updates the sorting configuration when a column header is clicked
   * @param {string} key - The key to sort by
   */
  const setSortKey = (key) => {
    if (sortConfig.key === key) {
      // Toggle direction if the same column is clicked
      sortConfig.direction = sortConfig.direction === "asc" ? "desc" : "asc";
    } else {
      // Set a new key and default to ascending order
      sortConfig.key = key;
      sortConfig.direction = "desc";
    }
  };

  /**
   * Sorts the provided data array based on the current sort configuration
   * @param {Array} data - The array to be sorted
   * @returns {Array} - The sorted array
   */
  const sortedData = (data) => {
    if (!sortConfig.key) return data;

    return [...data].sort((a, b) => {
      const valueA = a[sortConfig.key];
      const valueB = b[sortConfig.key];

      if (valueA < valueB) return sortConfig.direction === "asc" ? -1 : 1;
      if (valueA > valueB) return sortConfig.direction === "asc" ? 1 : -1;
      return 0;
    });
  };

  return {
    sortConfig,
    setSortKey,
    sortedData,
  };
}
