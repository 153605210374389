<script setup>
import { onMounted } from 'vue';
import { useLocaleStore } from '@/js/stores/locale'
const localeStore = useLocaleStore()
const currentLocale = localeStore.currentLocale
onMounted(() => {
  setTimeout(() => {
    window.location.href = `/${currentLocale}/campaign/list/`
  }, 5000);
});
</script>

<template>
  <div class="h-[calc(100vh-150px)] flex items-center justify-center">

<div>
    <div
        class="lg:w-[800px] text-center space-y-4 w-full shadow-testimonial-6 overflow-hidden rounded-[10px] border bg-white border-stroke py-10 px-6 sm:px-10"
    >
      <div class="w-full flex items-center justify-center">
        <img :src="'/images/hight-five.jpg'" alt="" class="w-[200px]">
      </div>
      <h3 class="text-2xl font-weight">Votre commande est partie !</h3>
      <p class="border-b border-t pt-5 pb-5 border-gray-200">
        Surveillez votre boite mail, vous devriez recevoir vos premiers liens d'ici environ 48h ouvrés !
      </p>

    </div>
    <p class="text-blue-500 text-sm text-center mt-4">
      Vous allez être redirigé vers vos campagnes dans quelqes secondes ...
    </p>
  </div>
  </div>
  <!-- ====== Checkout Section End -->
</template>
