<template>
  <div class="draw-header">
    <div class="w-full">Campagne {{ campaign.name }}</div>
    <div class="flex items-center space-x-2">
      <button :disabled="loadingStore.isLoading" class="btn btn-sm btn-netlinking" form="userForm" type="submit">
        <LoadingBtn :label="'Enregistrer'"/>
      </button>
    </div>
  </div>
  <Form id="userForm" v-slot="{ errors }" :validation-schema="schema_0" class="space-y-3" @submit="onSubmit_0">
    <div class="py-4 px-10 sm:px-4">
      <div class="mb-4 font-semibold flex items-start justify-start space-x-6">

    <span class="w-full flex items-center space-x-6 mt-[7px]" v-if="campaign.allocateBudget">
        <span class="w-[600px]">Solde alloué à cette campagne :</span>
      <div class="relative w-full">
          <input v-model="campaign.allocateBudget" class="w-full" readonly type="text">
        <span class="absolute right-2 top-2">€</span>
      </div>
      </span>
      </div>
      <div class="mb-4 font-semibold flex items-start justify-start space-x-6">
      <span class="w-[600px] flex items-center space-x-2 mt-[7px]">
        <svg class="w-[30px]" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path
            d="M64 0C28.7 0 0 28.7 0 64L0 416c0 35.3 28.7 64 64 64l16 0 16 32 64 0 16-32 224 0 16 32 64 0 16-32 16 0c35.3 0 64-28.7 64-64l0-352c0-35.3-28.7-64-64-64L64 0zM224 320a80 80 0 1 0 0-160 80 80 0 1 0 0 160zm0-240a160 160 0 1 1 0 320 160 160 0 1 1 0-320zM480 221.3L480 336c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-114.7c-18.6-6.6-32-24.4-32-45.3c0-26.5 21.5-48 48-48s48 21.5 48 48c0 20.9-13.4 38.7-32 45.3z"/></svg>
        <span v-if="campaign.allocateBudget">Allouer un nouveau budget :</span>
        <span v-else>Allouer un budget :</span>
      </span>
        <div class="relative w-full">
          <div>
            <Field v-model="formValues.allocate_budget" class="w-full" name="allocate_budget" type="text"/>
            <ErrorMessage class="error-message" name="allocate_budget"/>
          </div>
          <span class="absolute right-2 top-2">€</span>
        </div>
      </div>

      <div
          class="w-full px-4 py-4 duration-300 bg-gray-100 rounded-lg shadow-1 border border-gray-300  hover:shadow-3 text-sm"
      >
        <p>
          Vous avez la possibilité d’allouer un budget à votre campagne : cela « bloque » ces crédits à cette campagne
          uniquement. Ainsi, ce budget ne pourra être débité par une autre campagne du compte.
        </p>
        <p class="mb-0 mt-1">
          <span class="block">Attention :</span>
          <i class="block ml-4">
            - Le montant bloqué ne peut être supérieur au solde de votre compte
          </i>
          <i class="block ml-4">
            - Vous ne pourrez effectuer une commande supérieure à ce montant
          </i>
        </p>
      </div>
      <div class="mt-5" v-if="campaign.budgetHistory">
        <h4 class="text-gray-500 mb-3">Historique des opérations</h4>
        <table class="w-full">
          <thead>
          <tr>
            <th>Date</th>
            <th>Utilisateur</th>
            <th>Budget bloqué</th>
            <th>Budget consommé</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="bh in campaign.budgetHistory">
            <td>
              {{ $filters.dateFormat(bh.date) }}
            </td>
            <td>
              {{ bh.user_firstname }} {{ bh.user_lastname }}
            </td>
            <td>
              {{ bh.allocateBudget }}€
            </td>
          </tr>
          </tbody>
        </table>
      </div>


    </div>
  </Form>
</template>
<script setup>
import { computed, defineProps, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useLoadingStore } from '@/js/stores/loading'
import { useLocaleStore } from '@/js/stores/locale'
import { useAuthStore } from '@/js/stores/auth'
import LoadingBtn from '@/components/common/LoadingBtn.vue'
import * as yup from 'yup'
import { ErrorMessage, Field, Form } from 'vee-validate'
import axios from 'axios'

const emit = defineEmits(['all-ok'])

const authStore = useAuthStore()

const path = '/campaign/ajax/'
const { t } = useI18n()
const localeStore = useLocaleStore()
const loadingStore = useLoadingStore()
const selectedRole = ref(null)
const props = defineProps({
  campaign: {
    type: Object,
    required: true,
    default: { name: '' }
  }
})
onMounted(async () => {
  await authStore.fetchUser()
})
const formValues = ref({
  allocate_budget: 0
})

const schema_0 = computed(() => {
  return yup.object().shape({
    allocate_budget: yup
        .number()
        .typeError(t('validation.must_be_a_number')) // Vérifie que c'est un nombre
        .required(t('validation.required')) // Champ obligatoire
        .moreThan(50, t('validation.must_be_greater_than', { value: 50 })) // Doit être strictement > 50
        .test(
            'is-less-than-balance',
            t('validation.insufficient_balance'),
            value => {
              if (!authStore.user || authStore.user.balance === undefined) {
                return true // Ignore la validation si l'utilisateur n'est pas encore chargé
              }
              return value <= authStore.user.balance
            }
        )
  })
})
const onSubmit_0 = async (values) => {
  try {
    loadingStore.setLoading(true)
    const response = await axios.post(path, {
      action: 'allocate_budget',
      datas: {
        allocate_budget: formValues.value.allocate_budget, id: props.campaign.id
      },
    })

    if (response.status === 200) {
      emit('all-ok', {})
    }
  } catch (error) {
    if (error.response && error.response.status === 500) {
    }
  } finally {
    loadingStore.setLoading(false)
  }
}
</script>