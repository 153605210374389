// plugins.js
import Notifications from "@kyvg/vue3-notification";
import loading from "vue3-loading-screen";
import VueTippy from "vue-tippy";
import VueLazyLoad from 'vue3-lazyload';
import slider from "vue3-slider";
import VueSlider from "vue-3-slider-component";
import VueTurnstile from "vue-turnstile";
import vSelect from 'vue-select'
import Nl2br from 'vue3-nl2br'
import Vue3Dropzone from "@jaxtheprime/vue3-dropzone";
import createI18nInstance from './i18n';
import Loading from 'vue3-loading-overlay';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
const registerPlugins = async (app) => { // Make the function async
  const i18n = await createI18nInstance(); // Await the i18n instance
  app.use(i18n);
  app.use(VueLazyLoad)
  app.component('nl2br', Nl2br)
  app.component("vue3-slider", slider);
  app.component("vue-slider", VueSlider);
  app.component("v-dropzone", Vue3Dropzone);
  app.component('vue-date-picker', VueDatePicker);
  app.use(Notifications);
  app.use(loading, {
    bg: "#00000085",
    slot: `
      <div class="loading">
        <div class="circle"></div>
        <div class="middle"></div>
        <div class="circle2"></div>
      </div>
    `,
  });
  app.use(VueTippy, {
    directive: "tippy", // => v-tippy
    component: "tippy", // => <tippy/>
    componentSingleton: "tippy-singleton", // => <tippy-singleton/>,
    defaultProps: {
      placement: "auto-end",
      allowHTML: true,
    },
  });
  app.component("vue-turnstile",VueTurnstile);
  app.component('v-select', vSelect)
};

export default registerPlugins;
