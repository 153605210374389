<template>
  <div class="table-custom-2">
    <div class="max-w-full overflow-x-auto">
      <table class="w-full table-auto">
        <thead>
        <tr>
          <th class="text-left">URL</th>
          <th class="text-center">Trafic</th>
          <th class="text-center">MC</th>
          <th class="text-center">DR</th>
          <th class="text-center">CF</th>
          <th class="text-center w-[35px]">TF</th>
          <th class="text-left">TTF</th>
          <th class="text-left"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="page in editablePages">
          <td>
            <a class="link-blue" target="_blank" :href="`https://www.qsssgl.com/?url=${page.url}`">
              {{ page.url }}
            </a>
          </td>
          <td class="text-center">
            {{ page.traffic }}
          </td>
          <td class="text-center">
            {{ page.keyword }}
          </td>
          <td class="text-center">
            {{ page.backlinks }}
          </td>
          <td class="text-center">
            {{ page.citationFlow }}
          </td>
          <td class="text-center">
            <span
                v-if="page.trustFlow"
                class="w-[35px] block text-center font-bold px-2 py-1 rounded text-white"
                :style="{'background': page.trustFlowColor}"
                v-tippy="{ allowHTML: true,content: page.trustFlowRepresentation,theme:'tooltip-ttfs'}">
              {{ page.trustFlow }}
            </span>
          </td>
          <td>
            {{ page.trustFlowLabel }}
          </td>
          <td class="text-right">
            <button
                v-tippy="'Retirer'"
                class="btn btn-xs btn-outline-gray"
                :disabled="loadingStore.isLoading"
                @click.prevent.stop="deletePage(page.id)"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                   stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                   class="lucide lucide-trash-2">
                <path d="M3 6h18"/>
                <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"/>
                <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"/>
                <line x1="10" x2="10" y1="11" y2="17"/>
                <line x1="14" x2="14" y1="11" y2="17"/>
              </svg>
            </button>
          </td>
        </tr>

        <!-- Add more rows as needed -->
        </tbody>
      </table>
    </div>
  </div>
  <div class="bg-gray-100 shadow p-4 lg:flex md:flex items-center space-x-4 rounded mt-2 relative" v-if="addNewPages">

    <div class="w-full">
      <label><small>Entrez une URL par ligne</small></label>
      <textarea v-model="newPages" class="bg-white"></textarea>
    </div>
    <button
        :disabled="loadingStore.isLoading || !newPages"
        class="btn btn-netlinking btn-sm lg:mt-0 md:mt-0 mt-2"
        @click.prevent.stop="savePages()"
    >
      <LoadingBtn :label="'Ajouter'"/>
    </button>
    <button
        @click.prevent.stop="addNewPages = false"
        class="absolute right-2.5 top-2.5 text-dark-4 duration-200 hover:text-dark"
    >
      <svg
          width="15"
          height="15"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
        <path
            d="M12.0998 11L20.4873 2.61249C20.7967 2.30311 20.7967 1.82186 20.4873 1.51249C20.1779 1.20311 19.6967 1.20311 19.3873 1.51249L10.9998 9.89999L2.6123 1.51249C2.30293 1.20311 1.82168 1.20311 1.5123 1.51249C1.20293 1.82186 1.20293 2.30311 1.5123 2.61249L9.8998 11L1.5123 19.3875C1.20293 19.6969 1.20293 20.1781 1.5123 20.4875C1.6498 20.625 1.85605 20.7281 2.0623 20.7281C2.26855 20.7281 2.4748 20.6594 2.6123 20.4875L10.9998 12.1L19.3873 20.4875C19.5248 20.625 19.7311 20.7281 19.9373 20.7281C20.1436 20.7281 20.3498 20.6594 20.4873 20.4875C20.7967 20.1781 20.7967 19.6969 20.4873 19.3875L12.0998 11Z"
            fill="currentColor"
        />
      </svg>
    </button>
  </div>
  <div class="mt-3">

    <button @click.prevent.stop="addNewPages = true"
            class="btn btn-xs btn-outline-gray space-x-2 flex items-center justify-center" :disabled="addNewPages">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
           stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
           class="lucide lucide-square-plus">
        <rect width="18" height="18" x="3" y="3" rx="2"/>
        <path d="M8 12h8"/>
        <path d="M12 8v8"/>
      </svg>
      <span>Ajouter des pages</span>
    </button>
  </div>
</template>
<script setup>
import { defineProps, ref } from 'vue'
import { useLoadingStore } from '@/js/stores/loading'
import { useLocaleStore } from '@/js/stores/locale'
import LoadingBtn from '@/components/common/LoadingBtn.vue'
import axios from 'axios'

const addNewPages = ref(false)
const newPages = ref('')
const localeStore = useLocaleStore()
const loadingStore = useLoadingStore()
const currentLocale = localeStore.currentLocale
const path = '/customer/ajax/'
const props = defineProps({
  pages: {
    type: Object,
    required: true
  },
  project: {
    type: Object,
    required: true
  }
})
const editablePages = ref([...props.pages]) // Initialize with prop value

const deletePage = async (page_id) => {
  try {
    // Start loading
    loadingStore.setLoading(true)
    // Send the request
    const response = await axios.post(path, {
      action: 'delete-page',
      datas: { project_id: props.project.id, page_id: page_id },
    })
    if (response.status === 200) {
      editablePages.value = response.data.pages
    }
  } catch (error) {

  } finally {
    // End loading
    loadingStore.setLoading(false)
  }
}
const savePages = async () => {
  try {
    // Start loading
    loadingStore.setLoading(true)

    // Send the request
    const response = await axios.post(path, {
      action: 'add-pages',
      datas: { project_id: props.project.id, old: props.pages, pages: newPages.value },
    })

    // Check response status
    console.log(response.status)
    if (response.status === 200) {
      editablePages.value = response.data.pages
      addNewPages.value = false
      newPages.value = ''
    }
  } catch (error) {

  } finally {
    // End loading
    loadingStore.setLoading(false)
  }
}
</script>