<template>
  <div class="relative w-full" ref="dropdown" @click.stop>
    <!-- Dropdown trigger -->
    <div
        class="w-full p-2 !px-3 !py-2 border rounded bg-white flex items-center justify-between cursor-pointer"
        @click.prevent.stop="toggleDropdown"
    >
      <span :class="(selectedLabel)?'text-gray-700':'text-gray-300'">{{ selectedLabel || placeholder }}</span>
      <button
          v-if="selectedLabel"
          @click.stop.prevent="clearSelection"
          class="text-gray-500 hover:text-gray-800"
          aria-label="Clear selection"
      >
        ✕
      </button>
    </div>

    <!-- Dropdown Menu -->
    <div v-if="isDropdownOpen" class="absolute z-30 mt-2 w-full bg-white border rounded shadow-lg"
         style="min-width: 390px;background: #FFF;"
    >
      <!-- Search Input -->
      <div class="px-4 py-2 shadow border-b bg-netlinkingh border-b-gray-400">
        <input
            type="text"
            v-model="searchQuery"
            :placeholder="'Rechercher' + ((allowNewOption)?' ou ajouter':'')"
            class="w-full p-2"
        />
      </div>


      <!-- Dropdown Options -->
      <ul class="max-h-48 overflow-y-auto">
        <li
            v-for="option in filteredOptions"
            :key="option.id || option.label"
            class="p-2 px-4 text-sm hover:text-netlinking hover:bg-netlinkingh cursor-pointer border-b border-gray-200"
            @click.prevent.stop="selectOption(option)"
        >
          {{ option.label }}
        </li>
        <li v-if="filteredOptions.length === 0" class="p-2 text-gray-700">
          Aucun résultat trouvé
        </li>
      </ul>

      <!-- Add New Option -->
      <div v-if="allowNewOption && searchQuery" class="p-2 border-t flex justify-between items-center">
        <span>Ajouter "{{ searchQuery }}"</span>
        <button class="text-blue-600 hover:underline" @click="addNewOption">Ajouter</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Selectn",
  props: {
    options: {
      type: Array,
      required: true,
      default: () => [],
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    placeholder: {
      type: String,
      default: "-- Sélectionnez une option --",
    },
    allowNewOption: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDropdownOpen: false,
      searchQuery: "",
      selectedId: this.modelValue,
      internalOptions: [...this.options],
    };
  },
  computed: {
    normalizedOptions() {
      return this.internalOptions.map((option) =>
          typeof option === "string" ? { id: option, label: option } : option
      );
    },
    filteredOptions() {
      return this.normalizedOptions.filter((option) =>
          option.label.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    selectedLabel() {
      const selectedOption = this.normalizedOptions.find(
          (option) => option.id === this.selectedId
      );
      return selectedOption ? selectedOption.label : "";
    },
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
      if (this.isDropdownOpen) {
        this.searchQuery = "";
      }
    },
    selectOption(option) {
      this.selectedId = option.id;
      this.isDropdownOpen = false;
      this.$emit("update:modelValue", option.id);
    },
    clearSelection() {
      this.selectedId = "";
      this.$emit("update:modelValue", "");
    },
    handleClickOutside(event) {
      if (!this.$refs.dropdown.contains(event.target)) {
        this.isDropdownOpen = false;
      }
    },
    addNewOption() {
      const newOption = { id: this.searchQuery, label: this.searchQuery };
      this.internalOptions.push(newOption);
      this.selectOption(newOption);
    },
  },
  watch: {
    modelValue(newValue) {
      this.selectedId = newValue;
    },
    options(newOptions) {
      this.internalOptions = [...newOptions];
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>
