// datas.js
const datas_params = {
  csrf: null,
  user: user,
  datas: datas,
  extras: extras,
  navigationprofil: navigationprofil,
  breadcrumbs: breadcrumbs,
  defaults: defaults,
  navigation: navigation,
  alerts: {},
  form_url: null,
  load: null,
  editor: null,
  confirmation: { path: null, action: null, datas: null },
  confirmationModal: false,
  captcha: null,
  flashMessage: true,
  isLoading:true,
  search: false
};

export default datas_params;
