<!-- FileUpload.vue -->
<template>
  <div class="flex items-center space-x-6">
    <div class="shrink-0">
      <img
          v-if="previewUrl"
          :src="previewUrl"
          alt="Profile photo preview"
          class="h-16 w-16 object-cover rounded-full ring ring-netlinking/10"
      />
      <span v-else class="ring ring-netlinking/10 inline-block h-16 w-16 overflow-hidden rounded-full bg-gray-100">
        <svg class="size-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
          <path
              d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"/>
        </svg>
      </span>
    </div>
    <label class="block">
      <span class="sr-only">Choisir une photo de profil</span>

      <!-- Bouton personnalisé -->
      <button
          type="button"
          @click="triggerFileInput"
          class="btn btn-rounded btn-sm bg-netlinking_blue_light hover:bg-opacity-65 text-netlinking_blue"
      >
        Choisir un fichier
      </button>

      <!-- Input caché -->
      <input
          ref="fileInput"
          type="file"
          accept="image/*"
          class="hidden"
          @change="handleFileChange"
      />
    </label>
  </div>
  <ErrorMessage :name="name" class="text-red-500 text-xs mt-1"/>
</template>

<script setup>
import { defineEmits, defineProps, ref, watch } from 'vue'
import { ErrorMessage, useField } from 'vee-validate'

const props = defineProps({
  modelValue: {
    type: [File, String, null],
    default: null
  },
  name: {
    type: String,
    required: true
  },
  defaultImage: {
    type: String,
    default: 'https://via.placeholder.com/150'
  },
  errors: {
    type: Object,
    default: () => ({})
  }
})

const emit = defineEmits(['update:modelValue'])
const previewUrl = ref(null)
const fileInput = ref(null)

const { value: fieldValue } = useField(props.name)

const handleFileChange = (event) => {
  const file = event.target.files[0]
  if (!file) return

  fieldValue.value = file
  emit('update:modelValue', file)

  const reader = new FileReader()
  reader.onload = (e) => {
    previewUrl.value = e.target.result
  }
  reader.readAsDataURL(file)
}

const triggerFileInput = () => {
  fileInput.value.click()
}

watch(() => props.modelValue, (newValue) => {
  if (typeof newValue === 'string') {
    previewUrl.value = newValue
  }
}, { immediate: true })
</script>
