<!-- RichTextEditor.vue -->
<template>
  <div class="border rounded-lg shadow-sm bg-white">
    <!-- Editor Toolbar -->
    <BubbleMenu  v-if="editor"
                 :editor="editor"
                 :should-show="shouldShowBubbleMenu"
                 :tip-position-override="'top'"
                 class="bubble-menu bg-white rounded-lg shadow-lg border border-gray-200 flex items-center gap-1 p-2">
      <div class="flex flex-wrap items-center gap-2 p-2 border-b">
      <!-- Text style dropdown -->
      <div class="flex items-center gap-1">
        <button
            v-for="style in textStyles"
            :key="style.label"
            :disabled="isActiveHeading(style)"
            :title="style.label"
            class="btn btn-xs btn-outline-blue btn-rounded"
            @click="toggleHeading(style)"
        >
          <span
              v-html="style.icon"
          ></span>
        </button>
      </div>

      <!-- Divider -->
      <div class="w-px h-6 bg-gray-200"></div>

      <!-- Main toolbar buttons -->
      <button
          v-for="(action, index) in actions"
          :key="index"
          :disabled="action.isActive?.()"
          :title="action.title"
          class="btn btn-xs btn-outline-blue btn-rounded"
          @click="action.action"
      >
        <svg
            :class="{ 'text-blue-600': action.isActive?.() }"
            fill="none"
            height="20"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            viewBox="0 0 24 24"
            width="20"
            xmlns="http://www.w3.org/2000/svg"
            v-html="action.icon"
        />
      </button>

      <!-- Divider -->
      <div class="w-px h-6 bg-gray-200"></div>
      <div v-for="(action, index) in tableActions" :key="index" class="relative">
        <button
            class="btn btn-xs btn-outline-blue btn-rounded"
            :title="action.title"
            @click="action.children ? null : action.action()"
            @mouseenter="action.children ? (action.icon === icons.table ? showTableDropdown = true : action.icon === icons.tableRow ? showRowDropdown = true : showCellDropdown = true) : null"
        >
          <svg
              fill="none"
              height="20"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              viewBox="0 0 24 24"
              width="20"
              xmlns="http://www.w3.org/2000/svg"
              v-html="action.icon"
          />
        </button>

        <!-- Dropdown Menu -->
        <div
            v-if="action.children"
            class="absolute left-0 mt-1 py-1 bg-white rounded-lg shadow-lg border border-gray-200 z-50 min-w-[200px]"
            :class="{
        'hidden': action.icon === icons.table ? !showTableDropdown :
                 action.icon === icons.tableRow ? !showRowDropdown : !showCellDropdown
      }"
            @mouseleave="action.icon === icons.table ? showTableDropdown = false :
                   action.icon === icons.tableRow ? showRowDropdown = false : showCellDropdown = false"
        >
          <button
              v-for="(child, childIndex) in action.children"
              :key="childIndex"
              class="w-full px-4 py-2 text-dark text-left text-sm hover:bg-gray-100"
              :class="child.class"
              @click="child.action(); action.icon === icons.table ? showTableDropdown = false :
                action.icon === icons.tableRow ? showRowDropdown = false : showCellDropdown = false"
          >
            {{ child.title }}
          </button>
        </div>
      </div>

      <!-- Link Modal -->
      <div v-if="showLinkModal" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 ">
        <div class="bg-white rounded-lg w-[800px] p-3  shadow border border-gray-700">
          <h3 class="text-lg font-medium mb-4">{{ isEditingLink ? 'Modifier le lien' : 'Ajouter un lien' }}</h3>

          <div class="space-y-4">
            <div>
              <label class="block text-sm font-medium text-gray-700 mb-1">URL</label>
              <input
                  v-model="linkUrl"
                  class="w-full border rounded px-3 py-2"
                  placeholder="https://"
                  type="text"
              >
            </div>

            <div>
              <label class="block text-sm font-medium text-gray-700 mb-1">Text</label>
              <input
                  v-model="linkText"
                  class="w-full border rounded px-3 py-2"
                  placeholder="Link text"
                  type="text"
              >
            </div>

            <div class="flex justify-between">
              <div>
                <button
                    v-if="isEditingLink"
                    class="btn btn-xs btn-danger"
                    @click="removeLink"
                >
                  Supprimer le lien
                </button>
              </div>
              <div class="space-x-2">
                <button
                    class="btn btn-sm btn-outline-dark"
                    @click="cancelLink"
                >
                  Annuler
                </button>
                <button
                    class="btn btn-sm btn-netlinking"
                    @click="saveLink"
                >
                  {{ isEditingLink ? 'Modifier' : 'Ajouter' }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </BubbleMenu>
    <!-- Editor Content -->
    <editor-content
        :editor="editor"
        class="prose max-w-none min-h-[200px] focus:outline-none"
    />
  </div>
</template>

<script setup>
import { computed, onBeforeUnmount, ref, watch } from 'vue'
import { EditorContent, useEditor, BubbleMenu } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Link from '@tiptap/extension-link'
import Heading from '@tiptap/extension-heading'
import { Extension } from '@tiptap/core'
import Table from '@tiptap/extension-table'
import TableRow from '@tiptap/extension-table-row'
import TableHeader from '@tiptap/extension-table-header'
import TableCell from '@tiptap/extension-table-cell'
import Youtube from '@tiptap/extension-youtube'
import Blockquote from '@tiptap/extension-blockquote'
import Medianode from "@/components/common/tiptap/Medianode";

const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },
  addimage: {
    type: String,
    default: "",
  },
  addvideo: {
    type: String,
    default: "",
  },
})
watch(() => props.addimage, (first, second) => {
  addMedia('image',first)
});
watch(() => props.addvideo, (first, second) => {
  addMedia('youtube',first)
});
const addMedia = (type,url) => {
  if(typeof url == 'undefined'){
    const url = prompt("Url source :");
  }
  if (!url) {
    alert("URL is required");
    return;
  }
  editor.value
      .chain()
      .focus()
      .insertContent({
        type: "media",
        attrs: {
          type: type,
          src: url,
        },
      })
      .run();
}
const emit = defineEmits(['update:modelValue'])

// Lucide SVG paths
const icons = {
  paragraph: 'Paragraphe',
  h2: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-heading-2"><path d="M4 12h8"/><path d="M4 18V6"/><path d="M12 18V6"/><path d="M21 18h-4c0-4 4-3 4-6 0-1.5-2-2.5-4-1"/></svg>',
  h3: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-heading-3"><path d="M4 12h8"/><path d="M4 18V6"/><path d="M12 18V6"/><path d="M17.5 10.5c1.7-1 3.5 0 3.5 1.5a2 2 0 0 1-2 2"/><path d="M17 17.5c2 1.5 4 .3 4-1.5a2 2 0 0 0-2-2"/></svg>',
  h4: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-heading-4"><path d="M12 18V6"/><path d="M17 10v3a1 1 0 0 0 1 1h3"/><path d="M21 10v8"/><path d="M4 12h8"/><path d="M4 18V6"/></svg>',
  bold: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-bold"><path d="M6 12h9a4 4 0 0 1 0 8H7a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h7a4 4 0 0 1 0 8"/></svg>',
  italic: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-italic"><line x1="19" x2="10" y1="4" y2="4"/><line x1="14" x2="5" y1="20" y2="20"/><line x1="15" x2="9" y1="4" y2="20"/></svg>',
  code: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-code"><polyline points="16 18 22 12 16 6"/><polyline points="8 6 2 12 8 18"/></svg>',
  list: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-list"><path d="M3 12h.01"/><path d="M3 18h.01"/><path d="M3 6h.01"/><path d="M8 12h13"/><path d="M8 18h13"/><path d="M8 6h13"/></svg>',
  listOrdered: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-list-ordered"><path d="M10 12h11"/><path d="M10 18h11"/><path d="M10 6h11"/><path d="M4 10h2"/><path d="M4 6h1v4"/><path d="M6 18H4c0-1 2-2 2-3s-1-1.5-2-1"/></svg>',
  quote: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-quote"><path d="M16 3a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2 1 1 0 0 1 1 1v1a2 2 0 0 1-2 2 1 1 0 0 0-1 1v2a1 1 0 0 0 1 1 6 6 0 0 0 6-6V5a2 2 0 0 0-2-2z"/><path d="M5 3a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2 1 1 0 0 1 1 1v1a2 2 0 0 1-2 2 1 1 0 0 0-1 1v2a1 1 0 0 0 1 1 6 6 0 0 0 6-6V5a2 2 0 0 0-2-2z"/></svg>',
  undo: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-undo"><path d="M3 7v6h6"/><path d="M21 17a9 9 0 0 0-9-9 9 9 0 0 0-6 2.3L3 13"/></svg>',
  redo: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-redo"><path d="M21 7v6h-6"/><path d="M3 17a9 9 0 0 1 9-9 9 9 0 0 1 6 2.3l3 2.7"/></svg>',
  link: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-link"><path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"/><path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"/></svg>',
  table: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-table"><path d="M9 3H5a2 2 0 0 0-2 2v4m6-6h10a2 2 0 0 1 2 2v4M9 3v18m-6-6h6m-6 0v4a2 2 0 0 0 2 2h4m-6-6h18m-18 0v4a2 2 0 0 0 2 2h4m6-6h6m-6 0v4a2 2 0 0 0 2 2h4M21 3v18"/></svg>',
  youtube: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-video"><path d="m22 8-6 4 6 4V8Z"/><rect width="14" height="12" x="2" y="6" rx="2" ry="2"/></svg>',
  tableRow: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M3 10h18"/><path d="M3 14h18"/></svg>',
  tableCell: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="4" y="4" width="16" height="16" rx="2" ry="2"/><line x1="4" y1="12" x2="20" y2="12"/><line x1="12" y1="4" x2="12" y2="20"/></svg>'
}
const tableActions = [
  {
    icon: icons.table,
    title: 'Insérer un tableau',
    action: () => editor.value?.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()
  },
  {
    icon: icons.tableRow,
    title: 'Actions de ligne',
    children: [
      {
        title: 'Ajouter une ligne avant',
        action: () => editor.value?.chain().focus().addRowBefore().run()
      },
      {
        title: 'Ajouter une ligne après',
        action: () => editor.value?.chain().focus().addRowAfter().run()
      },
      {
        title: 'Supprimer la ligne',
        action: () => editor.value?.chain().focus().deleteRow().run(),
        class: 'text-red-600'
      }
    ]
  },
  {
    icon: icons.tableCell,
    title: 'Actions de cellule',
    children: [
      {
        title: 'Ajouter une colonne avant',
        action: () => editor.value?.chain().focus().addColumnBefore().run()
      },
      {
        title: 'Ajouter une colonne après',
        action: () => editor.value?.chain().focus().addColumnAfter().run()
      },
      {
        title: 'Supprimer la colonne',
        action: () => editor.value?.chain().focus().deleteColumn().run(),
        class: 'text-red-600'
      },
      {
        title: 'Basculer en-tête de colonne',
        action: () => editor.value?.chain().focus().toggleHeaderColumn().run()
      },
      {
        title: 'Basculer en-tête de ligne',
        action: () => editor.value?.chain().focus().toggleHeaderRow().run()
      },
      {
        title: 'Basculer en-tête de cellule',
        action: () => editor.value?.chain().focus().toggleHeaderCell().run()
      },
      {
        title: 'Fusionner les cellules',
        action: () => editor.value?.chain().focus().mergeCells().run()
      },
      {
        title: 'Diviser la cellule',
        action: () => editor.value?.chain().focus().splitCell().run()
      },
      {
        title: 'Supprimer le tableau',
        action: () => editor.value?.chain().focus().deleteTable().run(),
        class: 'text-red-600'
      }
    ]
  }
]

// 3. Ajoutez les refs nécessaires
const showTableDropdown = ref(false)
const showRowDropdown = ref(false)
const showCellDropdown = ref(false)
// Text styles configuration
const textStyles = [
  { label: 'P', level: 0, icon: icons.paragraph },
  { label: 'H2', level: 2, icon: icons.h2 },
  { label: 'H3', level: 3, icon: icons.h3 },
  { label: 'H4', level: 4, icon: icons.h4 }
]

// Link handling
const showLinkModal = ref(false)
const linkUrl = ref('')
const linkText = ref('')
const isEditingLink = ref(false)
const LinkSelectionHandler = Extension.create({
  name: 'linkSelectionHandler',

  onCreate () {
    this.editor.on('selectionUpdate', ({ editor }) => {
      const { from, to } = editor.state.selection

      // Get all marks at the selection boundaries
      const fromMarks = editor.state.doc.resolve(from).marks()
      const toMarks = editor.state.doc.resolve(to - 1).marks()

      // Check if both ends of selection have link marks
      const fromLinkMark = fromMarks.find(mark => mark.type.name === 'link')
      const toLinkMark = toMarks.find(mark => mark.type.name === 'link')

      // Only trigger if both ends have the same link mark with href
      if (fromLinkMark &&
          toLinkMark &&
          fromLinkMark.attrs.href &&
          fromLinkMark.attrs.href === toLinkMark.attrs.href) {

        // Find the full link mark range
        let linkMarkFrom = from
        let linkMarkTo = to

        // Search backward for the start of the link
        for (let pos = from - 1; pos >= 0; pos--) {
          const marks = this.editor.state.doc.resolve(pos).marks()
          const hasLink = marks.find(mark =>
              mark.type.name === 'link' &&
              mark.attrs.href &&
              mark.attrs.href === fromLinkMark.attrs.href
          )
          if (!hasLink) {
            linkMarkFrom = pos + 1
            break
          }
        }

        // Search forward for the end of the link
        for (let pos = to; pos <= this.editor.state.doc.content.size; pos++) {
          const marks = this.editor.state.doc.resolve(pos - 1).marks()
          const hasLink = marks.find(mark =>
              mark.type.name === 'link' &&
              mark.attrs.href &&
              mark.attrs.href === fromLinkMark.attrs.href
          )
          if (!hasLink) {
            linkMarkTo = pos - 1
            break
          }
        }
        // Only trigger if selection covers the entire link
        if (from <= (linkMarkFrom+2) && to >= (linkMarkTo - 2) && editor.isActive('link')) {
          this.options.onLinkSelect()
        }
      }
    })
  },

  addOptions () {
    return {
      onLinkSelect: () => {},
    }
  },
})
// Initialize editor with Link extension
const editor = useEditor({
  content: props.modelValue,
  extensions: [
    Medianode,
    StarterKit,
    Blockquote,
    BubbleMenu,
    Heading.configure({
      levels: [2, 3, 4],
    }),
    Link.configure({
      openOnClick: false
    }),
    Table.configure({
      resizable: true,
    }),
    TableRow,
    TableHeader,
    TableCell,
    Youtube.configure({
      HTMLAttributes: {
        class: 'w-full aspect-video',
      },
    }),
    LinkSelectionHandler.configure({
      onLinkSelect: (selectedUrl) => {
        if (!showLinkModal.value) {
          openLinkModal(selectedUrl)
        }
      }
    }),
  ],
  autofocus: false,
  onUpdate: ({ editor }) => {
    emit('update:modelValue', editor.getHTML())
  }
})
// Current text style display
const currentTextStyle = computed(() => {
  if (!editor.value) return 'Paragraph'

  for (const style of textStyles) {
    if (isActiveHeading(style)) {
      return style.label
    }
  }

  return 'Paragraph'
})

// Link handling methods
const openLinkModal = (selectedUrl) => {
  if (!editor.value) return

  const { from, to } = editor.value.state.selection
  const text = editor.value.state.doc.textBetween(from, to)

  if (editor.value.isActive('link')) {
    const attrs = editor.value.getAttributes('link')
    isEditingLink.value = true
    linkUrl.value = attrs.href
    linkText.value = text
    showLinkModal.value = true
  } else if (selectedUrl) {
    isEditingLink.value = false
    linkUrl.value = selectedUrl
    linkText.value = text
    showLinkModal.value = true
  } else {
    isEditingLink.value = false
    linkUrl.value = ''
    linkText.value = text
    showLinkModal.value = true
  }
}

const saveLink = () => {
  if (!isValidUrl(linkUrl.value)) {
    alert('Please enter a valid URL')
    return
  }

  if (linkUrl.value) {
    if (isEditingLink.value) {
      editor.value.chain().focus().extendMarkRange('link').setLink({ href: linkUrl.value }).run()
    } else {
      editor.value.chain().focus().setLink({ href: linkUrl.value }).run()
    }
  }
  showLinkModal.value = false
  linkUrl.value = ''
  linkText.value = ''
}

const removeLink = () => {
  editor.value.chain().focus().unsetLink().run()
  showLinkModal.value = false
}

const cancelLink = () => {
  showLinkModal.value = false
  linkUrl.value = ''
  linkText.value = ''
}

// Apply text style
const toggleHeading = (style) => {
  if (!editor.value) return

  if (style.level === 0) {
    editor.value.chain().focus().setParagraph().run()
  } else {
    editor.value.chain().focus().toggleHeading({ level: style.level }).run()
  }
}
const isActiveHeading = (style) => {
  if (!editor.value) return false

  if (style.level === 0) {
    return editor.value.isActive('paragraph')
  }
  return editor.value.isActive('heading', { level: style.level })
}

// Common editor actions
const actions = [
  {
    icon: icons.bold,
    title: 'Bold',
    action: () => editor.value?.chain().focus().toggleBold().run(),
    isActive: () => editor.value?.isActive('bold')
  },
  {
    icon: icons.italic,
    title: 'Italic',
    action: () => editor.value?.chain().focus().toggleItalic().run(),
    isActive: () => editor.value?.isActive('italic')
  },
  {
    icon: icons.link,
    title: 'Link',
    action: () => {
      if (editor.value?.isActive('link')) {
        openLinkModal()
      } else if (editor.value?.state.selection.empty) {
        alert('Please select some text first')
      } else {
        openLinkModal()
      }
    },
    // isActive: () => editor.value?.isActive('link')
  },
  {
    icon: icons.list,
    title: 'Bullet List',
    action: () => editor.value?.chain().focus().toggleBulletList().run(),
    isActive: () => editor.value?.isActive('bulletList')
  },
  {
    icon: icons.listOrdered,
    title: 'Ordered List',
    action: () => editor.value?.chain().focus().toggleOrderedList().run(),
    isActive: () => editor.value?.isActive('orderedList')
  },
  {
    icon: icons.quote,
    title: 'Blockquote',
    action: () => editor.value?.chain().focus().toggleBlockquote().run(),
    isActive: () => editor.value?.isActive('blockquote')
  },
  {
    icon: icons.undo,
    title: 'Undo',
    action: () => editor.value?.chain().focus().undo().run()
  },
  {
    icon: icons.redo,
    title: 'Redo',
    action: () => editor.value?.chain().focus().redo().run()
  },
  {
    icon: icons.youtube,
    title: 'Insert YouTube Video',
    action: () => {
      const url = prompt('Enter YouTube URL')
      if (url) {
        editor.value?.chain().focus().setYoutubeVideo({
          src: url,
          width: 640,
          height: 480,
        }).run()
      }
    }
  }
]
const isValidUrl = (string) => {
  try {
    new URL(string)
    return true
  } catch (_) {
    return false
  }
}
// Watch for external changes to modelValue
watch(
    () => props.modelValue,
    (newValue) => {
      const editorContent = editor.value?.getHTML()
      if (newValue !== editorContent) {
        editor.value?.commands.setContent(newValue, false)
      }
    }
)

// Cleanup
onBeforeUnmount(() => {
  editor.value?.destroy()
})
const addColumnBefore = () => editor.value?.chain().focus().addColumnBefore().run()
const addColumnAfter = () => editor.value?.chain().focus().addColumnAfter().run()
const deleteColumn = () => editor.value?.chain().focus().deleteColumn().run()
const addRowBefore = () => editor.value?.chain().focus().addRowBefore().run()
const addRowAfter = () => editor.value?.chain().focus().addRowAfter().run()
const deleteRow = () => editor.value?.chain().focus().deleteRow().run()
const deleteTable = () => editor.value?.chain().focus().deleteTable().run()
const toggleHeaderColumn = () => editor.value?.chain().focus().toggleHeaderColumn().run()
const toggleHeaderRow = () => editor.value?.chain().focus().toggleHeaderRow().run()
const toggleHeaderCell = () => editor.value?.chain().focus().toggleHeaderCell().run()
const mergeCells = () => editor.value?.chain().focus().mergeCells().run()
const splitCell = () => editor.value?.chain().focus().splitCell().run()
const shouldShowBubbleMenu = ({ editor, view, state, from, to }) => {
  // Only show menu when text is selected
  const { empty } = state.selection

  // Don't show menu for empty selection
  if (empty) return false

  return true
}
</script>
<style scoped>
/* Optional: Style your bubble menu (positioning, arrow, etc.) */
.bubble-menu {
  /* You can customize the bubble menu's appearance and position */
  z-index: 100;
}
</style>