<template>
  <div class="flex justify-center items-center" v-if="loadingStore.isLoading">
    <div class="flex flex-row gap-2">
      Chargement ...
    </div>
  </div>
  <span v-if="!loadingStore.isLoading">{{label}}</span>
</template>
<script setup>
import {  defineProps } from 'vue'
import { useLoadingStore } from '@/js/stores/loading'
const loadingStore = useLoadingStore()
const props = defineProps({
  label: {
    type: String,
    required: true
  }
})
</script>