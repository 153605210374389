// filters.js
import * as Yup from "yup";
import { useLocaleStore } from '@/js/stores/locale' // Adjust the import path as needed
Yup.addMethod(Yup.mixed, "file", function ({ size, types }) {
  return this.test("file", "File is invalid", function (value) {
    const { path, createError } = this;

    // No file provided
    if (!value) return true;

    // Check file size
    if (value && value.size > size) {
      return createError({
        path,
        message: `File size must be smaller than ${size} bytes`,
      });
    }

    // Check file type
    if (value && types && !types.includes(value.type)) {
      return createError({
        path,
        message: `File type must be one of the following: ${types.join(", ")}`,
      });
    }

    // File is valid
    return true;
  });
});

const filters = {
    refer(input,length = 8) {
        if (!input || typeof input !== 'string') {
                return '';
            }
        return input.substring(0, length);
    },
   formatAnchorUrlPairs(data) {
     if (!data || !Array.isArray(data) || data.length === 0) {
         return '';
       }

       return data.map(item =>
         `<p class="border-b border-b-white"><b class="text-blue-300">${item.anchor}</b> ; ${item.url}</p>`
       ).join('\n');
   },
    numberFormatIntl(value, options = {}) {
         if (typeof value !== 'number') {
            return value;
          }

          const localeStore = useLocaleStore();
          const locale = localeStore.currentLocale;

          // Set default options for floats, allowing a maximum of 3 digits after the decimal point
          const defaultOptions = {
              maximumFractionDigits: 3,
              minimumFractionDigits: 0, // Optional: You can adjust this if you want to show a minimum of 3 decimal places
            };

          // Merge the default options with the passed options (if any)
          const formatOptions = { ...defaultOptions, ...options };

          return new Intl.NumberFormat(locale, formatOptions).format(value);
  },
  createYupValidationSchema(fields) {
    const schemaFields = {};
    for (const field in fields) {
      const fieldData = fields[field];
      const rules = fieldData.rules.split(",");
      let validator;
      switch (fieldData.type) {
        case "number":
          validator = Yup.number();
          break;
        case "file":
          validator = Yup.mixed();
          break;
        default:
          validator = Yup.string();
      }
      rules.forEach((rule) => {
        const trimmedRule = rule.trim();
        const ruleParts = trimmedRule.split(":");
        const ruleType = ruleParts[0];
        const ruleValue = ruleParts[1];
        const message = fieldData.messages && fieldData.messages[ruleType];

        switch (ruleType) {
          case "required":
            validator = validator.required(
              message || "This field is required"
            );
            break;
          case "email":
            validator = validator.email(
              message || "This field must be a valid email"
            );
            break;
          case "min":
            validator = validator.min(
              ruleValue,
              message || `Must be at least ${ruleValue} characters`
            );
            break;
          case "max":
            validator = validator.max(
              ruleValue,
              message || `Must be no more than ${ruleValue} characters`
            );
            break;
          case "passwordMatch":
            validator = validator.oneOf(
              [Yup.ref("password"), null],
              message || `Passwords must match`
            );
            break;
          case "url":
            validator = validator.url(
              message || "This field must be a valid URL"
            );
            break;
          case "fileSize":
            validator = validator.file({ size: parseInt(ruleValue) });
            break;
          case "fileType":
            const types = ruleValue.split("|");
            validator = validator.file({ types });
            break;
          // Add more cases for other validation rules as needed
        }
      });
      schemaFields[field] = validator;
    }
    return Yup.object(schemaFields);
  },
  in_array(value, values) {
    values.map((item) => item.trim());
    return values.includes(value.trim());
  },
  percentage(number, number2) {
    if (number == 0 || number2 == 0) {
      return 0 + "%";
    }
    return (
      new Intl.NumberFormat("fr-FR").format(
        parseInt((number * 100) / number2)
      ) + "%"
    );
  },
  planToString(plan) {
    let planString = "";

    // Fonction pour traiter chaque élément du plan
    const processElement = (element) => {
      planString +=
        element.format +
        element.subTitle +
        element.format.replace("<", "</") +
        "\n";

      // Traitement des sous-titres H3, s'ils existent
      if (element.H3s && Array.isArray(element.H3s)) {
        element.H3s.forEach((h3) => {
          planString +=
            h3.format + h3.subTitle + h3.format.replace("<", "</") + "\n";
        });
      }
    };

    // Parcours du tableau de plan
    if (Array.isArray(plan)) {
      plan.forEach(processElement);
    }

    return planString;
  },
  searchPhraseInString(phrase, stringToSearch) {
    // Escape special characters and trim the search phrase
    var escapedPhrase = phrase.replace(/[.*+?^${}()|[\]\\]/g, "\\$&").trim();

    // Create a regular expression with case-insensitive flag
    var regex = new RegExp(escapedPhrase, "i");

    // Perform the search
    return stringToSearch.match(regex) !== null;
  },
  stripTags(str) {
    return str.replace(/<[^>]*>/g, "");
  },
  getDomainFromUrl(url) {
    try {
      const parsedUrl = new URL(url);
      let domain = parsedUrl.hostname;
      if (domain.startsWith("www.")) {
        domain = domain.substring(4);
      }
      return domain;
    } catch (e) {
      return url;
    }
  },
  getStringAfterLastColon(inputString) {
    var lastIndex = inputString.lastIndexOf(":");
    if (lastIndex === -1) return inputString;
    return parseInt(inputString.substring(lastIndex + 1));
  },
  getModColors(number, divider) {
    let colorClasses = [
      "red-500",
      "blue-500",
      "green-500",
      "yellow-500",
      "indigo-500",
    ];
    let res = number % divider;
    return `<span class="w-4 h-4 bg-${
      colorClasses[res] || colorClasses[number - 1]
    } rounded-full inline-block"></span>`;
  },
  divide(number, inputString) {
    let colorClasses = [
      "red-500",
      "blue-500",
      "green-500",
      "yellow-500",
      "indigo-500",
    ];
    if (typeof inputString !== "string" || inputString.length === 0) {
      return `<span class="w-4 h-4 bg-${
        colorClasses[number - 1]
      } rounded-full inline-block"></span>`; // Gray or any default color for invalid input
    }
    let firstChar = inputString[0].toUpperCase();
    let alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
    let indexGroupFirstChar = Math.floor(
      alphabet.indexOf(firstChar) % number
    );

    return `<span class="w-4 h-4 bg-${
      colorClasses[indexGroupFirstChar] || colorClasses[number - 1]
    } rounded-full inline-block"></span>`;
  },
  countLines(value) {
    if (!value) return 0;
    var lines = value.split(/\r*\n/);
    return lines.length;
  },
  formatBoolean(value, msg1, msg2, class1, class2) {
    if (value == true || value == 1) {
      return `<span class="badge ${class1}"><small>${msg1}</small></span>`;
    } else {
      return `<span class="badge ${class2}"><small>${msg2}</small></span>`;
    }
  },
  isOK(values, key) {
    for (let i = 0; i < Object.keys(values).length; i++) {
      if (!values[i][key]) {
        return false;
      }
    }
    return true;
  },
  numberFormat(number) {
    return new Intl.NumberFormat("fr-FR").format(number);
  },
  countValues(values) {
    return Object.keys(values).length;
  },
  replaceString(originalString, stringToReplace, replacementString) {
    return originalString.replace(
      new RegExp(stringToReplace, "g"),
      replacementString
    );
  },
  findFirstUppercaseLetter(inputString) {
    if (inputString !== null) {
      return inputString[0].toUpperCase();
    }
    return "";
  },
  capitalizeFirstLetter(name) {
      if (!name) return '';
      return name.charAt(0).toUpperCase();
  },

  dateFormat(date, format) {
      const localeStore = useLocaleStore();
      const locale = localeStore.currentLocale;

      const dateObj = new Date(date);
      if (isNaN(dateObj.getTime())) {
          return ''; // ou une valeur par défaut comme 'Date invalide'
      }
      const options = { timeZone: 'UTC' }; // Ensure consistent timezone handling

      switch (format) {
          case 1:
              options.dateStyle = 'short';
              options.timeStyle = 'short';
              break;
          case 2:
              options.dateStyle = 'short';
              break;
          case 3:
              options.timeStyle = 'short';
              break;
          case 4:
              return new Intl.DateTimeFormat(locale, { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit' }).format(dateObj);
          case 5:
              return String(dateObj.getUTCMonth() + 1).padStart(2, '0');
          case 6:
              return dateObj.getUTCFullYear().toString();
          case 7:
              options.dateStyle = 'short';
              options.timeStyle = 'medium';
              break;
          default:
              options.dateStyle = 'short';
              options.timeStyle = 'short';
      }

      if (format !== 5 && format !== 6) {
          return new Intl.DateTimeFormat(locale, options).format(dateObj);
      }
  }

};

export default filters;