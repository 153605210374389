<template>
  <div class="draw-header">
    <div class="w-full">{{ isEditing ? 'Modification de l\'adresse de facturation' : 'Ajouter une adresse de facturation' }}</div>
    <div class="flex items-center space-x-2" :class="(isEditing)?'min-w-[220px]':''">
      <button type="submit" form="userForm" :disabled="loadingStore.isLoading" class="btn btn-sm btn-netlinking">
        <LoadingBtn :label="isEditing ? 'Enregistrer les modifications' : 'Valider'"/>
      </button>
    </div>
  </div>
  <div class="py-4 px-4 sm:px-4">
    <Form @submit="onSubmit_0" id="userForm" class="space-y-3" :validation-schema="schema_0" v-slot="{ errors }">
      <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-5">
        <div>
          <label for="billingAddressName">Donner un nom à cette adresse de facturation<sup class="text-red-600">*</sup></label>
          <div class="relative">
            <Field
                :placeholder="'Nom'"
                v-model="formValues.billingAddressName"
                name="billingAddressName"
                type="text"
                :class="[errors.billingAddressName ? 'ring-2 ring-red-600' : '']"
            />
            <ErrorMessage name="billingAddressName" class="error-message"/>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-5">
        <div>
          <label for="company">Société</label>
          <div class="relative">
            <Field
                :placeholder="'Nom de votre société'"
                v-model="formValues.company"
                name="company"
                type="text"
                :class="[errors.company ? 'ring-2 ring-red-600' : '']"
            />
            <ErrorMessage name="company" class="error-message"/>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-5">
        <div>
          <label for="lastName">Nom<sup class="text-red-600">*</sup></label>
          <div class="relative">
            <Field
                :placeholder="'Nom'"
                v-model="formValues.lastName"
                name="lastName"
                type="text"
                :class="[errors.lastName ? 'ring-2 ring-red-600' : '']"
            />
            <ErrorMessage name="lastName" class="error-message"/>
          </div>
        </div>

        <div>
          <label for="firstName">Prénom<sup class="text-red-600">*</sup></label>
          <div class="relative">
            <Field
                :placeholder="'Prénom'"
                v-model="formValues.firstName"
                name="firstName"
                type="text"
                :class="[errors.firstName ? 'ring-2 ring-red-600' : '']"
            />
            <ErrorMessage name="firstName" class="error-message"/>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-5">
        <div>
          <label for="billingEmails" class="mb-0">Emails de facturation<sup class="text-red-600">*</sup></label>
          <p class="text-xs text-gray-500 mb-1.5">
            Tous les e-mails relatifs à la facturation seront envoyés à votre adresse e-mail et ces contacts de
            facturation
          </p>
          <div v-for="(email, index) in formValues.billingEmails" :key="index"
               class="flex justify-center items-start space-x-4 mb-2">
            <div class="w-full">
              <Field
                  :name="`billingEmails.${index}`"
                  type="email"
                  v-model="formValues.billingEmails[index]"
                  placeholder="Cette adresse email recevra la facture"
                  :class="[errors[`billingEmails[${index}]`] ? 'ring-2 ring-red-600' : '']"
              />
              <ErrorMessage :name="`billingEmails[${index}]`" class="error-message"/>
            </div>
            <div class="w-full flex space-x-2">
              <button
                  type="button"
                  @click="removeBillingEmail(index)"
                  v-if="formValues.billingEmails.length > 1"
                  class="text-gray-500 hover:text-gray-900 mt-2 mr-4">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                     stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                     class="lucide lucide-x">
                  <path d="M18 6 6 18"/>
                  <path d="m6 6 12 12"/>
                </svg>
              </button>
              <button
                  type="button"
                  @click="addBillingEmail"
                  v-if="index === formValues.billingEmails.length - 1"
                  class="text-dark hover:text-netlinking mt-2">
                + Ajouter un contact
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-5">
        <div>
          <label for="address" class="mb-0">Adresse<sup class="text-red-600">*</sup></label>
          <div class="relative">
            <Field
                :placeholder="'Adresse postale'"
                v-model="formValues.address"
                name="address"
                type="text"
                :class="[errors.address ? 'ring-2 ring-red-600' : '']"
            />
            <ErrorMessage name="address" class="error-message"/>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-5">

        <div>
          <label for="addressComplement" class="mb-0">Complément d'adresse (facultatif)</label>
          <div class="relative">
            <Field
                :placeholder="'Entreprise, bâtiment, étage'"
                v-model="formValues.addressComplement"
                name="addressComplement"
                type="text"
                :class="[errors.addressComplement ? 'ring-2 ring-red-600' : '']"
            />
            <ErrorMessage name="addressComplement" class="error-message"/>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-5">

        <div>
          <label for="postalCode" class="mb-0">Code postal<sup class="text-red-600">*</sup></label>
          <div class="relative">
            <Field
                :placeholder="'Code postal'"
                v-model="formValues.postalCode"
                name="postalCode"
                type="text"
                :class="[errors.postalCode ? 'ring-2 ring-red-600' : '']"
            />
            <ErrorMessage name="postalCode" class="error-message"/>
          </div>
        </div>

        <div>
          <label for="city" class="mb-0">Ville<sup class="text-red-600">*</sup></label>
          <div class="relative">
            <Field
                :placeholder="'Ville'"
                v-model="formValues.city"
                name="city"
                type="text"
                :class="[errors.city ? 'ring-2 ring-red-600' : '']"
            />
            <ErrorMessage name="city" class="error-message"/>
          </div>
        </div>

        <div>
          <label for="country" class="mb-0">Pays<sup class="text-red-600">*</sup></label>
          <div class="relative">
            <Field v-model="formValues.country" name="country" v-slot="{ field, errors }"
            >
              <Countryselect v-model="formValues.country" :countries="countries"/>
            </Field>
            <ErrorMessage name="country" class="error-message"/>
          </div>
        </div>

        <div v-if="findCountry(formValues.country,eucountries) && formValues.company !== ''">
          <label for="vatNumber" class="mb-0">Numéro de TVA</label>
          <div class="relative">
            <Field
                :placeholder="'N° de TVA intracommunautaire'"
                v-model="formValues.vatNumber"
                name="vatNumber"
                type="text"
                :class="[errors.vatNumber ? 'ring-2 ring-red-600' : '']"
            />
            <ErrorMessage name="vatNumber" class="error-message"/>
          </div>
        </div>
      </div>
    </Form>
  </div>
</template>
<script setup>
import { computed, defineProps, reactive, ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useLoadingStore } from '@/js/stores/loading'
import { useLocaleStore } from '@/js/stores/locale'
import LoadingBtn from '@/components/common/LoadingBtn.vue'
import Countryselect from '@/components/common/phone/Countryselect.vue'
import * as yup from 'yup'
import { ErrorMessage, Field, Form } from 'vee-validate'
import axios from 'axios'

const emit = defineEmits(['all-ok', 'close-model'])
const path = '/customer/ajax/'
const { t } = useI18n()
const localeStore = useLocaleStore()
const loadingStore = useLoadingStore()
const currentLocale = localeStore.currentLocale

const props = defineProps({
  countries: {
    type: Object,
    required: true
  },
  eucountries: {
    type: Object,
    required: true
  },
  user: {
    type: Object,
    required: true,
    default: { email: '--', firstName: '--', lastName: '--', roles: '--' }
  },
  addressData: {
    type: Object,
    required: false,
    default: null
  }
})

const isEditing = computed(() => !!props.addressData)

const formValues = reactive({
  billingAddressName: '',
  company: '',
  lastName: props.user.lastName,
  firstName: props.user.firstName,
  billingEmails: [''],
  address: '',
  addressComplement: '',
  postalCode: '',
  city: '',
  country: 'france',
  vatNumber: ''
})

onMounted(() => {
  if (props.addressData) {
    Object.keys(formValues).forEach(key => {
      if (props.addressData[key] !== undefined) {
        formValues[key] = props.addressData[key]
      }
    })
  }
})

// Rest of the helper functions remain the same
const findCountry = (countryCode, countries) => {
  return countryCode in countries
}

const addBillingEmail = () => {
  formValues.billingEmails.push('')
}

const removeBillingEmail = (index) => {
  if (formValues.billingEmails.length > 1) {
    formValues.billingEmails.splice(index, 1)
  }
}

const schema_0 = computed(() => {
  return yup.object().shape({
    billingAddressName: yup
        .string()
        .required(t('validation.required')),
    company: yup
        .string(),
    lastName: yup
        .string()
        .required(t('validation.required')),
    firstName: yup
        .string()
        .required(t('validation.required')),
    billingEmails: yup.array().of(
        yup
            .string()
            .required(t('validation.required'))
            .email(t('validation.invalid_email_format'))
    ).min(1, t('validation.required')),
    address: yup
        .string()
        .required(t('validation.required')),
    addressComplement: yup
        .string(),
    postalCode: yup
        .string()
        .required(t('validation.required')),
    city: yup
        .string()
        .required(t('validation.required')),
    country: yup
        .string()
        .required(t('validation.required')),
    vatNumber: yup
        .string()
  })
})

// Modified submit handler to handle both create and update
const onSubmit_0 = async (values) => {
  try {
    loadingStore.setLoading(true)

    const action = isEditing.value ? 'update-BillingAddress' : 'add-BillingAddress'
    const payload = {
      action,
      datas: {
        values: formValues
      }
    }

    if (isEditing.value) {
      payload.datas.id = props.addressData.id
    }

    const response = await axios.post(path, payload)

    if (response.status === 200) {
      emit('all-ok', {})
      window.location.reload()
    }
  } catch (error) {
    if (error.response && error.response.status === 500) {
      // Handle error
    }
  } finally {
    loadingStore.setLoading(false)
  }
}


const closeModal = () => {
  emit('close-model')
}
</script>