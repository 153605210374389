<template>
  <div class="tab-content text-base leading-relaxed text-dark py-6"
       data-content="home">
    <div class="grid grid-cols-1 lg:grid-cols-3 gap-4 items-start">
      <div
          class="card-netlinking-2"
      >
        <h2>Votre société</h2>
        <div>
          <Form v-slot="{ errors }" :validation-schema="schema_0" class="space-y-3" @submit="onSubmit_0">
            <div>
              <label for="company">
                {{ $t('additional_fields.company') }}
              </label>
              <div class="relative">
                <Field v-model="formValues_0.company" :class="[errors.company ? 'ring-2 ring-red-600' : '']" :placeholder="$t('ex_digitalio')" name="company"
                       type="text"/>
                <ErrorMessage class="error-message" name="company"/>
              </div>
            </div>
            <div>
              <label for="organization_type">
                {{ $t('additional_fields.organization_type') }}
              </label>
              <div class="relative">
                <Field v-slot="{ field, errors }" v-model="formValues_0.organization_type" name="organization_type"
                >
                  <select id="sector" v-model="formValues_0.organization_type"
                          :class="[errors && Object.keys(errors).length > 0 ? 'ring-2 ring-red-600' : '']" name="sector">
                    <option :value="''" disabled>{{ $t('select') }}</option>
                    <option v-for="sector in entity_types" :value="sector.value">{{ $t(sector.label) }}</option>
                  </select>
                </Field>
                <ErrorMessage class="error-message" name="organization_type"/>
              </div>
            </div>
            <div>
              <label for="country">
                {{ $t('additional_fields.country') }}
              </label>
              <div class="relative">
                <Field v-slot="{ field, errors }" v-model="formValues_0.country" name="country"
                >
                  <Countryselect v-model="formValues_0.country" :countries="countries"/>
                </Field>
                <ErrorMessage class="error-message" name="country"/>
              </div>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-1 ">
              <div class="flex justify-center mt-3">
                <button
                    :disabled="loadingStore.isLoading"
                    class="btn btn-sm btn-dark w-full"
                >
                  <LoadingBtn :label="'Mettre à jour'"/>
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <div
          class="card-netlinking-2"
      >
        <h2>Votre activité</h2>
        <div>
          <Form v-slot="{ errors }" :validation-schema="schema_1" class="space-y-3" @submit="onSubmit_1">
            <div>
              <label for="website">
                {{ $t('additional_fields.website') }}
              </label>
              <div class="relative">
                <Field :placeholder="$t('website_url')" v-model="formValues_1.website" name="website" type="text"
                       :class="[errors.website ? 'ring-2 ring-red-600' : '']"/>
                <ErrorMessage name="website" class="error-message"/>
              </div>
            </div>
            <div>
              <label for="sector">
                {{ $t('additional_fields.sector') }}
              </label>
              <div class="relative">
                <Field v-model="formValues_1.sector" name="sector" v-slot="{ field, errors }"
                >
                  <select :class="[errors && Object.keys(errors).length > 0 ? 'ring-2 ring-red-600' : '']" id="sector"
                          name="sector" v-model="formValues_1.sector">
                    <option :value="''" disabled>{{ $t('select') }}</option>
                    <option v-for="sector in sectors" :value="sector.value">{{ $t(sector.label) }}</option>
                  </select>
                </Field>
                <ErrorMessage name="sector" class="error-message"/>
              </div>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-1 ">
              <div class="flex justify-center mt-3">
                <button
                    :disabled="loadingStore.isLoading"
                    class="btn btn-sm btn-dark w-full"
                >
                  <LoadingBtn :label="'Mettre à jour'"/>
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, defineProps, onMounted, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useLocaleStore } from '@/js/stores/locale'
import { useLoadingStore } from '@/js/stores/loading'
import * as yup from 'yup'
import { ErrorMessage, Field, Form } from 'vee-validate'
import Countryselect from '@/components/common/phone/Countryselect.vue'
import LoadingBtn from '@/components/common/LoadingBtn.vue'
import axios from 'axios'

const selectedRole = ref(null)
const currentStep = ref(1)
const { t } = useI18n()
const localeStore = useLocaleStore()
const loadingStore = useLoadingStore()
const currentLocale = localeStore.currentLocale
const path = '/customer/ajax/'
const props = defineProps({
  user: {
    type: Object,
    required: true
  },
  countries: {
    type: Object,
    required: true
  },
  sectors: {
    type: Object,
    required: true
  },
  entity_types: {
    type: Object,
    required: true
  }
})
const formValues_0 = reactive({
  company: '',
  organization_type: '',
  country: '',
})
const formValues_1 = reactive({
  website: '',
  sector: '',
})
onMounted(() => {
  formValues_0.company = props.user.company
  formValues_0.organization_type = props.user.organizationType
  formValues_0.country = props.user.country
  formValues_1.website = props.user.website
  formValues_1.sector = props.user.sector
})
const schema_0 = computed(() => {
  return yup.object().shape({
    company: yup
        .string()
        .required(t('validation.required')),
    organization_type: yup
        .string()
        .required(t('validation.required')),
    country: yup
        .string()
        .required(t('validation.required')),
  })
})
const schema_1 = computed(() => {
  return yup.object().shape({
    website: yup
        .string()
        .url(t('validation.invalid_url_format'))
        .required(t('validation.required')),
    sector: yup
        .string()
        .required(t('validation.required'))
  })
})

const onSubmit_1=async(values)=>{
  try {
    loadingStore.setLoading(true)

    const response = await axios.post(path, {'action':'change-activity',datas:formValues_1})

    if (response.status === 200) {
      window.location.reload()
    }
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error('Server error:', error.response.data)
    }
  } finally {
    loadingStore.setLoading(false)
  }
}

const onSubmit_0=async(values)=>{
  try {
    loadingStore.setLoading(true)

    const response = await axios.post(path, {'action':'change-company',datas:formValues_0})

    if (response.status === 200) {
      window.location.reload()
    }
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error('Server error:', error.response.data)
    }
  } finally {
    loadingStore.setLoading(false)
  }
}
</script>