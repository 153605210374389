<template>
  <h1 class="h1-public">
    {{ $t('welcome_in') }} netLinking<span class="text-netlinking_blue_green">.com</span>
  </h1>
  <p
      class="text-base mb-2 text-secondary-color xl:mb-2"
  >
    <span class="sm:block text-body-color">
    {{ $t('find_thematic_articles') }}
    </span>
  </p>
  <Form @submit="onSubmit_0" class="space-y-3" :validation-schema="schema_0" v-slot="{ errors }">
    <p class="text-red-600 text-sm font-semibold" v-if="existingError">
      {{$t('login_error')}}
    </p>
    <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-5">
      <div>
        <label for="email">
          {{ t('field_names.email') }}
        </label>
        <div class="relative">
          <Field v-model="formValues.email" name="email" type="text"
                 :class="[errors.email ? 'ring-2 ring-red-600' : '']"/>
          <ErrorMessage name="email" class="error-message"/>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-5">
      <div>
        <label for="email">
          {{ t('field_names.password') }}
        </label>
        <div class="relative">
          <Field v-model="formValues.password" name="password" v-slot="{ field, errors }"
                 :class="[errors.password && Object.keys(errors.password).length > 0 ? 'ring-2 ring-red-600' : '']">
            <input
                :type="(showPassword)?'text':'password'"
                v-model="formValues.password"
                :class="[errors && Object.keys(errors).length > 0 ? 'ring-2 ring-red-600' : '']"

            />
            <span @click.prevent.stop="toggleShowPassword"
                  class="absolute cursor-pointer -translate-y-1/2 top-1/2 right-5">
                    <svg v-if="!showPassword" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                         viewBox="0 0 24 24" fill="none"
                         stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                         class="lucide lucide-eye"><path
                        d="M2.062 12.348a1 1 0 0 1 0-.696 10.75 10.75 0 0 1 19.876 0 1 1 0 0 1 0 .696 10.75 10.75 0 0 1-19.876 0"/><circle
                        cx="12" cy="12" r="3"/></svg>
                      <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"
                           fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                           stroke-linejoin="round" class="lucide lucide-eye-off"><path
                          d="M10.733 5.076a10.744 10.744 0 0 1 11.205 6.575 1 1 0 0 1 0 .696 10.747 10.747 0 0 1-1.444 2.49"/><path
                          d="M14.084 14.158a3 3 0 0 1-4.242-4.242"/><path
                          d="M17.479 17.499a10.75 10.75 0 0 1-15.417-5.151 1 1 0 0 1 0-.696 10.75 10.75 0 0 1 4.446-5.143"/><path
                          d="m2 2 20 20"/></svg>
                  </span>
          </Field>
          <ErrorMessage name="password" class="error-message"/>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-1 ">
      <div class="flex justify-center mt-3">
        <button
            :disabled="loadingStore.isLoading || !formValues.email || !formValues.password"
            class="btn btn-lg btn-netlinking w-full"
        >
          <LoadingBtn :label="$t('sign_in')"/>
        </button>
      </div>
    </div>
    <p class="text-center"><a :href="`/${currentLocale}/reset-password/`" class="link-netlinking">{{$t('forgot_password')}}</a></p>
    <p class="pt-3 text-gray-400 text-center">
      {{$t('not_a_member_yet')}} <a :href="`/${currentLocale}/sign-up/`" class="link-gray">{{$t('create_your_account')}}</a>
    </p>
  </Form>
</template>
<script setup>
import { computed, defineProps, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useLoadingStore } from '@/js/stores/loading'
import { useLocaleStore } from '@/js/stores/locale'
import axios from 'axios'
import * as yup from 'yup'
import { ErrorMessage, Field, Form } from 'vee-validate'
import LoadingBtn from '@/components/common/LoadingBtn.vue'

const showPassword = ref(false)
const emit = defineEmits(['form-submitted'])
const { t } = useI18n()
const localeStore = useLocaleStore()
const loadingStore = useLoadingStore()
const currentLocale = localeStore.currentLocale
const step = ref(0)
const existingError = ref(false)
const path = '/security/ajax/'
const props = defineProps({
  captcha: {
    type: String,
    required: true
  },
  csrf: {
    type: String,
    required: true
  },
})
const toggleShowPassword = () => {
  showPassword.value = !showPassword.value
}
const formValues = reactive({
  email: '',
  password: ''
})
const schema_0 = computed(() => {
  return yup.object().shape({
    email: yup
        .string()
        .required(t('validation.required'))
        .email(t('validation.invalid_email_format')),
    password: yup
        .string()
        .required(t('validation.required'))
  })
})
const onSubmit_0 = async (values) => {
  try {
    // Start loading
    loadingStore.setLoading(true);

    // Send the request
    const response = await axios.post(path, {
      action: 'signin',
      datas: { values: formValues, captcha: props.captcha, csrf: props.csrf },
    });

    // Check response status
    console.log(response.status);
    if (response.status === 200) {
      // Handle successful login
      existingError.value = false; // Reset error if needed
      window.location.href = response.data.redirect
    }
  } catch (error) {
    // Handle errors
    if (error.response && error.response.status === 401) {
      existingError.value = true; // Set error flag
    } else {
    }
    loadingStore.setLoading(false);
  } finally {
    // End loading

  }
};

</script>