// components.js
import Navbar from '@/components/common/Navbar.vue'
import Signup from "@/components/security/Signup.vue";
import Signin from "@/components/security/Signin.vue";
import Invitation from "@/components/security/Invitation.vue";
import Resetpassword from "@/components/security/Resetpassword.vue";
import Resetpasswordaction from "@/components/security/Resetpasswordaction.vue";
import LoadingBtn from '@/components/common/LoadingBtn.vue'

import Modal from '@/components/common/Modal.vue'
import Wizard from '@/components/common/Wizard.vue'
import Pagination from '@/components/common/Pagination.vue'
import Bhdatatable from '@/components/common/Bhdatatable.vue'
import Projectadd from '@/components/customer/Projectadd.vue'
import Profile from '@/components/customer/profil/Profile.vue'
import Company from '@/components/customer/profil/Company.vue'
import Inviteuser from '@/components/customer/profil/Inviteuser.vue'
import Inviteduseredit from '@/components/customer/profil/Inviteduseredit.vue'
import Billingaddress from '@/components/customer/profil/Billingaddress.vue'
import Projecttoppages from '@/components/customer/Projecttoppages.vue'
import Support from '@/components/customer/support/Support.vue'
import Supportresponse from '@/components/customer/support/Supportresponse.vue'
import Createcampaign from '@/components/customer/campaign/Createcampaign.vue'
import Createorder from '@/components/customer/campaign/Createorder.vue'
import Campaignorder from '@/components/customer/campaign/Campaignorder.vue'
import Allocate from '@/components/customer/campaign/Allocate.vue'
import Exportdatas from '@/components/customer/campaign/Exportdatas.vue'
import Checkout from '@/components/customer/campaign/Checkout.vue'
import Checkoutfree from '@/components/customer/campaign/Checkoutfree.vue'
import Checkoutconfirmation from '@/components/customer/campaign/Checkoutconfirmation.vue'
import Payment from '@/components/customer/payment/Payment.vue'
import Navbtn from '@/components/common/Navbtn.vue'
import Inputcleared from '@/components/common/Inputcleared.vue'
import Datepicker from '@/components/common/Datepicker.vue'
import Selectcheck from '@/components/common/Selectcheck.vue'
import Infotext from '@/components/common/Infotext.vue'
import Infoimg from '@/components/common/Infoimg.vue'
import Ttffinder from '@/components/customer/ttffinder/Ttffinder.vue'
import Backlinkschecked from '@/components/customer/backlinkschecked/Backlinkschecked.vue'
import Htmleditor from '@/components/common/Htmleditor.vue'
import Crminformation from '@/components/internal/crm/Crminformation.vue'
import Edituser from '@/components/internal/super/Edituser.vue'
import Orderticket from '@/components/internal/orders/Orderticket.vue'
import Internalorderscampaign from '@/components/internal/campaign/Internalorderscampaign.vue'


const registerComponents = (app) => {
    app.component("Navbar", Navbar);
    app.component("Infotext", Infotext);
    app.component("Infoimg", Infoimg);
    app.component("Signup", Signup);
    app.component("Signin", Signin);
    app.component("Invitation", Invitation);
    app.component("Resetpassword", Resetpassword);
    app.component("Resetpasswordaction", Resetpasswordaction);
    app.component("Loadingbtn", LoadingBtn);
    app.component("Modal", Modal);
    app.component("Pagination", Pagination);
    app.component("Wizard", Wizard);
    app.component("Projectadd", Projectadd);
    app.component("Projecttoppages", Projecttoppages);
    app.component("Support", Support);
    app.component("Supportresponse", Supportresponse);
    app.component("Createcampaign", Createcampaign);
    app.component("Createorder", Createorder);
    app.component("Campaignorder", Campaignorder);
    app.component("Bhdatatable", Bhdatatable);
    app.component("Profile", Profile);
    app.component("Company", Company);
    app.component("Inviteuser", Inviteuser);
    app.component("Inviteduseredit", Inviteduseredit);
    app.component("Billingaddress", Billingaddress);
    app.component("Allocate", Allocate);
    app.component("Exportdatas", Exportdatas);
    app.component("Checkout", Checkout);
    app.component("Checkoutfree", Checkoutfree);
    app.component("Checkoutconfirmation", Checkoutconfirmation);
    app.component("Payment", Payment);
    app.component("Navbtn", Navbtn);
    app.component("Inputcleared", Inputcleared);
    app.component("datepicker", Datepicker);
    app.component("Selectcheck", Selectcheck);
    app.component("Ttffinder", Ttffinder);
    app.component("Backlinkschecked", Backlinkschecked);
    app.component("Htmleditor", Htmleditor);
    app.component("Crminformation", Crminformation);
    app.component("Edituser", Edituser);
    app.component("Orderticket", Orderticket);
    app.component("Internalorderscampaign", Internalorderscampaign);
};

export default registerComponents;
