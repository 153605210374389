<script setup>
import { onMounted, onUnmounted, ref } from 'vue'

// Props definition
const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },
  maxWidth: {
    type: String,
    default: '570px'
  }
})

// Emits definition
const emit = defineEmits(['update:modelValue', 'close'])

const modalContainer = ref(null)

const handleClickOutside = (event) => {
  if (
      modalContainer.value &&
      !modalContainer.value.contains(event.target)
  ) {
    closeModal()
  }
}

const closeModal = () => {
  emit('update:modelValue', false)
  emit('close')
}

// onMounted(() => {
//   document.addEventListener('click', handleClickOutside)
// })
//
// onUnmounted(() => {
//   document.removeEventListener('click', handleClickOutside)
// })
</script>

<template>
  <div
      class="z-50 overflow-y-auto rounded fixed left-0 top-0 flex h-full min-h-screen w-full items-center justify-center bg-dark/90 px-4 py-5"
      :class="{ block: modelValue, hidden: !modelValue }"
  >
    <div
        ref="modalContainer"
        class="relative w-full rounded bg-white max-h-[90vh] overflow-auto"
        :style="{ maxWidth: maxWidth }"
    >
<!--      <button-->
<!--          @click="closeModal()"-->
<!--          class="absolute right-2.5 top-2.5 text-dark-4 duration-200 hover:text-dark"-->
<!--      >-->
<!--        <svg-->
<!--            width="15"-->
<!--            height="15"-->
<!--            viewBox="0 0 22 22"-->
<!--            fill="none"-->
<!--            xmlns="http://www.w3.org/2000/svg"-->
<!--        >-->
<!--          <path-->
<!--              d="M12.0998 11L20.4873 2.61249C20.7967 2.30311 20.7967 1.82186 20.4873 1.51249C20.1779 1.20311 19.6967 1.20311 19.3873 1.51249L10.9998 9.89999L2.6123 1.51249C2.30293 1.20311 1.82168 1.20311 1.5123 1.51249C1.20293 1.82186 1.20293 2.30311 1.5123 2.61249L9.8998 11L1.5123 19.3875C1.20293 19.6969 1.20293 20.1781 1.5123 20.4875C1.6498 20.625 1.85605 20.7281 2.0623 20.7281C2.26855 20.7281 2.4748 20.6594 2.6123 20.4875L10.9998 12.1L19.3873 20.4875C19.5248 20.625 19.7311 20.7281 19.9373 20.7281C20.1436 20.7281 20.3498 20.6594 20.4873 20.4875C20.7967 20.1781 20.7967 19.6969 20.4873 19.3875L12.0998 11Z"-->
<!--              fill="currentColor"-->
<!--          />-->
<!--        </svg>-->
<!--      </button>-->
      <!-- Header slot -->

      <div class="modal-cp sticky rounded-md h-auto px-8 py-12 md:px-[20px] md:py-[20px] text-dark bg-netlinking top-0 z-30">
        <slot name="campaign"></slot>
      </div>
      <div class="px-8 py-12 md:px-[20px] md:py-[20px]">
        <slot></slot>
      </div>
    </div>
  </div>
</template>