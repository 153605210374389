<template>
  <div class="draw-header">
    <div class="w-full">
      {{ isEditMode ? `Utilisateur : ${userData.firstName} ${userData.lastName}` : 'Nouvel utilisateur' }}
    </div>
    <div class="flex items-center space-x-2">
      <button :disabled="loadingStore.isLoading" class="btn btn-sm btn-netlinking" form="userForm" type="submit">
        <LoadingBtn :label="isEditMode ? 'Enregistrer' : 'Ajouter'"/>
      </button>
    </div>
  </div>
  <Form id="userForm" v-slot="{ errors }" :validation-schema="schema" @submit="submitForm">
    <div class="py-4 space-y-4 px-10 sm:px-4">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-5">
        <div class="w-full">
          <label for="firstName">Prénom<sup class="text-red-600">*</sup></label>
          <div class="relative">
            <Field
                v-slot="{ field, errors }"
                v-model="userData.firstName"
                :class="[errors.firstName ? 'ring-2 ring-red-600' : '']"
                name="firstName"
                type="text"
            />
            <ErrorMessage class="error-message" name="firstName"/>
          </div>
        </div>
        <div class="w-full">
          <label for="lastName">Nom<sup class="text-red-600">*</sup></label>
          <div class="relative">
            <Field
                v-slot="{ field, errors }"
                v-model="userData.lastName"
                :class="[errors.lastName ? 'ring-2 ring-red-600' : '']"
                name="lastName"
                type="text"
            />
            <ErrorMessage class="error-message" name="lastName"/>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-5">
        <div>
          <label for="email">Email<sup class="text-red-600">*</sup></label>
          <div class="relative">
            <Field
                v-slot="{ field, errors }"
                v-model="userData.email"
                :class="[errors.email ? 'ring-2 ring-red-600' : '']"
                name="email"
                type="email"
            />
            <ErrorMessage class="error-message" name="email"/>
          </div>
        </div>
        <div>
          <label for="password">
            {{ isEditMode ? 'Nouveau mot de passe' : 'Mot de passe' }}<sup v-if="!isEditMode" class="text-red-600">*</sup>
          </label>
          <div class="relative">
            <Field
                v-slot="{ field, errors }"
                v-model="userData.password"
                :class="[errors.password ? 'ring-2 ring-red-600' : '']"
                name="password"
                type="text"
            />
            <ErrorMessage class="error-message" name="password"/>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-5">
        <div>
          <label class="block mb-2">Rôles<sup class="text-red-600">*</sup></label>
          <div class="space-y-2">
            <Field
                v-slot="{ field, errors }"
                :class="[errors.roles ? 'ring-2 ring-red-600' : '']"
                name="roles"
                type="checkbox"
            >
              <div v-for="(role, index) in availableroles" :key="index" class="flex items-center">
                <input
                    :id="`role-${index}`"
                    v-model="userData.roles"
                    :value="role.id"
                    class="mr-2"
                    type="checkbox"
                />
                <label :for="`role-${index}`">{{ role.label }}</label>
              </div>
            </Field>
            <ErrorMessage class="error-message" name="roles"/>
          </div>
        </div>
      </div>
    </div>
  </Form>
</template>

<script setup>
import { computed, defineEmits, defineProps, ref, watch } from 'vue'
import { ErrorMessage, Field, Form } from 'vee-validate'
import { array, object, string } from 'yup'
import LoadingBtn from '@/components/common/LoadingBtn.vue'
import { useLoadingStore } from '@/js/stores/loading'
const path = '/internal/super/ajax/'
const loadingStore = useLoadingStore()
import axios from 'axios'
const props = defineProps({
  user: {
    type: Object,
    default: () => ({
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      roles: []
    })
  },
  availableroles: {
    type: Object
  },
  showCancelButton: {
    type: Boolean,
    default: true
  }
})

// Create a local copy of the user to work with
const userData = ref({
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  roles: [],
  ...props.user
})

// Determine if we're in edit mode (if the user has an ID)
const isEditMode = computed(() => Boolean(props.user && props.user.id))

const emit = defineEmits(['add', 'update', 'cancel'])

// Validation schema that adjusts based on whether we're adding or editing
const schema = computed(() => {
  // Mot de passe obligatoire en mode ajout, optionnel en mode édition
  const passwordRule = isEditMode.value
      ? string().nullable().test('password-length', 'Le mot de passe doit contenir au moins 6 caractères',
          (value) => !value || value.length >= 6)
      : string().required('Le mot de passe est requis').min(6, 'Le mot de passe doit contenir au moins 6 caractères')

  return object({
    firstName: string().required('Le prénom est requis'),
    lastName: string().required('Le nom est requis'),
    email: string().email('Email invalide').required('L\'email est requis'),
    password: passwordRule,
    roles: array().min(1, 'Sélectionnez au moins un rôle')
  })
})

// Watch for changes in the user prop
watch(() => props.user, (newValue) => {
  if (newValue) {
    userData.value = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      roles: [],
      ...newValue
    }
  }
}, { deep: true, immediate: true })

const submitForm = () => {
  const formData = { ...userData.value }

  // Si le mot de passe est vide, le supprimer de la soumission
  if (!formData.password) {
    delete formData.password
  }

  // Emit the appropriate event based on whether we're adding or editing
  if (isEditMode.value) {
    axios.post(path, {
      action: 'edit_user',
      datas: {
        formData: formData
      },
    }).then((response) => {
      if (response.status === 200) {
        window.location.href = response.data.redirect
      }
    })
  } else {
    axios.post(path, {
      action: 'add_user',
      datas: {
        formData: formData
      },
    }).then((response) => {
      if (response.status === 200) {
        window.location.href = response.data.redirect
      }
    })
  }
}

const cancelForm = () => {
  emit('cancel')
}
</script>