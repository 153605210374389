<template>
  <div class="bg-netlinking/5 -mx-[40px] -my-[15px] pt-[16px] pb-[30px] px-[30px] xl:px-[40px]">
    <h1 class="title-simple">Recherche par URL</h1>
    <Form v-slot="{ errors }" :validation-schema="schema_0" class="space-y-3" @submit="onSubmit_0">
      <div class="block lg:flex lg:space-x-4 space-y-4 lg:space-y-0 mt-4">
        <div class="w-full">
          <label for="url">Renseigner votre URL cible</label>
          <Field
              v-model="order.url"
              :placeholder="'Ex. https://www.leseopourtous.fr'"
              class="bg-white"
              name="url"
              type="text"
          ></Field>
          <ErrorMessage class="error-message" name="url"/>
        </div>
        <div class="w-full lg:max-w-[150px]">
          <label for="">Langue</label>
          <Field v-model="order.language" as="select" class="bg-white" name="language">
            <option :value="''" disabled>{{ $t('select') }}</option>
            <option v-for="language in languages" :key="language.code" :value="language.code">
              {{ $t(language.label) }}
            </option>
          </Field>
          <ErrorMessage class="error-message" name="language"/>
        </div>
        <div class="w-full lg:max-w-[300px] pt-8">
          <!--          @click.prevent.stop="isModalOpen = true"-->
          <button class="btn btn-dark-netlinking-2 btn-sm w-full btn-rounded" type="submit"

          >
            Rechercher
          </button>
        </div>
      </div>
    </Form>
  </div>
  <Modalcampaign
      v-model="isModalOpen"
      max-width="max-w-[80%]"
      @close="handleclose"
  >
    <template #campaign>
      <div class="block lg:flex lg:items-start justify-between lg:space-x-6 space-x-0 text-sm">
        <!-- Left section -->
        <div class="block lg:flex lg:space-x-8 lg:space-y-0 space-y-4 lg:max-w-[700px]">
          <div>
            <label class="flex items-center justify-between space-x-2"><span>Choisissez une durée d'exclusivité</span>
              <Info message="This is a helpful tooltip message!" tooltipclass="netlinking"/>
            </label>
            <div class="flex space-x-5 items-center">
              <span class="text-xs">0</span>
              <div class="w-full">
                <vue3-slider v-model="order.exclusive_duration" :alwaysShowHandle="true" :alwaysShowTooltip="true"
                             :flipTooltip="true" :handleScale="2.2" :height="10"
                             :max="10" :min="0" :step="1"
                             :tooltip="'always'" :tooltipColor="'#000'" :tooltipTextColor="'#FFF'"
                             color="#000" track-color="#FEFEFE"/>
              </div>
              <span class="text-xs">10</span>
            </div>
          </div>
          <div>
            <label
                class="flex items-center justify-between space-x-2"><span>Lissez vos publications dans le temps</span>
              <Info message="This is a helpful tooltip message!" tooltipclass="netlinking"/>
            </label>
            <div class="flex space-x-5 items-center">
              <span class="text-xs">0</span>
              <div class="w-full">
                <vue3-slider v-model="order.spread_posts" :alwaysShowHandle="true" :alwaysShowTooltip="true"
                             :flipTooltip="true" :handleScale="2.2" :height="10"
                             :max="3" :min="0" :step="1"
                             :tooltip="'always'" :tooltipColor="'#000'" :tooltipTextColor="'#FFF'" color="#000"
                             track-color="#FEFEFE"/>
              </div>
              <span class="text-xs w-[55px]">3 mois</span>
            </div>
          </div>
        </div>

        <!-- Right section -->
        <div class="block lg:flex lg:space-x-4 space-x-0 lg:space-y-0 space-y-4 items-center lg:mt-[13px] mt-[20px]">
          <div class="flex space-x-2 items-center">
            <label class="flex mb-0">
              <svg class="lucide lucide-shopping-cart text-netlinking" fill="none" height="18" stroke="currentColor"
                   stroke-linecap="round"
                   stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="18"
                   xmlns="http://www.w3.org/2000/svg">
                <circle cx="8" cy="21" r="1"/>
                <circle cx="19" cy="21" r="1"/>
                <path d="M2.05 2.05h2l2.66 12.42a2 2 0 0 0 2 1.58h9.78a2 2 0 0 0 1.95-1.57l1.65-7.43H5.12"/>
              </svg>
              <span class="pl-2">Nb de liens :</span>
            </label>
            <div :class="{ 'animate-pulse text-netlinking scale-120': isAmountUpdated }"
                 class="text-lg font-bold transition-transform duration-500 ease-in-out">
              <span>{{ order.number_links }}</span>
            </div>
          </div>
          <div class="flex space-x-2 items-center">
            <label class="mb-0">Montant :</label>
            <div :class="{ 'animate-pulse text-netlinking scale-130': isAmountUpdated }"
                 class="text-lg font-bold transition-transform duration-500 ease-in-out">
              <span>{{ order.amount }} €</span>
            </div>
          </div>
          <div>
            <button :disabled="order.amount == 0" @click.prevent.stop="saveOrder" class="btn btn-sm btn-dark-netlinking-2 btn-rounded !pr-[30px] !pl-[30px]">Commander</button>
          </div>
        </div>
      </div>

    </template>
    <div>
      <h2 class="text-netlinking">Rechercher par URL</h2>
      <div class="flex items-center mt-5 pb-5 border-b space-x-6">
        <div class="w-full">
          <input type="text" readonly v-model="order.url">
        </div>
        <div class="min-w-[200px]">
          <select v-model="order.language" disabled>
            <option :value="''" disabled>{{ $t('select') }}</option>
            <option v-for="language in languages" :key="language.code" :value="language.code">
              {{ $t(language.label) }}
            </option>
          </select>
        </div>
      </div>
      <div class="mt-5">
        <Searchbl :campaign="order" :orders="orders" :ttfs="ttfs" :url="order.url"
                  @order="traitementOrder" @rorder="rtraitementOrder">
        </Searchbl>
      </div>

    </div>

  </Modalcampaign>
</template>
<script setup>
import { computed, defineProps, onMounted, reactive, ref, watch } from 'vue'
import { ErrorMessage, Field, Form } from 'vee-validate'
import { useLocaleStore } from '@/js/stores/locale'
import { useLoadingStore } from '@/js/stores/loading'
import Modalcampaign from '@/components/customer/campaign/Modalcampaign.vue'
const localeStore = useLocaleStore()
const loadingStore = useLoadingStore()
const currentLocale = localeStore.currentLocale
const isModalOpen = ref(false)
const path = '/campaign/ajax/'
const { t } = useI18n()
import * as yup from 'yup'
import { useI18n } from 'vue-i18n'
import axios from 'axios'
import Info from '@/components/common/Info.vue'
import Searchbl from '@/components/customer/campaign/block/Searchbl.vue'
const props = defineProps({
  languages: {
    type: Object,
    required: true
  },
  ttfs: {
    type: Object,
    required: true
  }
})
const isAmountUpdated = ref(false)
const orders = ref([])
const order = reactive({
  id: null,
  exclusive_duration: 1,
  spread_posts: 0,
  number_links: 0,
  amount: 0,
  state: 'draft',
  language: 'fr',
  url: '',
  datas: []
})
const schema_0 = computed(() => {
  return yup.object().shape({
    url: yup
        .string()
        .url(t('validation.invalid_url_format'))
        .required(t('validation.required')),
    language: yup
        .string()
        .required(t('validation.required')),
  })
})
const onSubmit_0 = async (values) => {
  try {
    // Start loading
    loadingStore.setLoadingPage(true)
    // Send the request
    // const response = await axios.post(path, {
    //   action: 'url-stat',
    //   datas: { order: order },
    // })
    // // Check response status
    // console.log(response.status)
    // if (response.status === 200) {
      isModalOpen.value = true
      // order.datas = response.data.datas
    //}
  } catch (error) {
    // Handle errors
    if (error.response && error.response.status === 500) {
    } else {
    }
  } finally {
    // End loading
    loadingStore.setLoadingPage(false)
  }

}

const traitementOrder = async (data) => {
  const exists = orders.value.some(order => order.page_id === data.page.page_id)
  if (!exists) {
    orders.value.push(data.page)
  }
}
const rtraitementOrder = async (data) => {
  orders.value = orders.value.filter(order => order.page_id !== data.page.page_id)
}

const updateAmount = () => {
  let totalAmount = orders.value.reduce((total, order) => total + (order.price || 0), 0)

  if (order.exclusive_duration === 0) {
    totalAmount *= 0.9 // Apply 10% discount
  } else if (order.exclusive_duration > 1) {
    totalAmount *= 1 + (0.1 * order.exclusive_duration) // Apply percentage increase
  }

  order.amount = parseFloat(totalAmount.toFixed(2)) // Ensure proper decimal formatting
  order.number_links = Object.keys(orders.value).length
  // Trigger animation
  isAmountUpdated.value = true
  setTimeout(() => {
    isAmountUpdated.value = false
  }, 2000)
}
watch(orders, updateAmount, { deep: true })
watch(() => order.exclusive_duration, updateAmount)
const computedOrderStats = computed(() => {
  return filteredDatas.value.map((data) => {
    const matchedOrders = orders.value.filter(order => order.url_destination === data.url)
    const totalOrders = matchedOrders.length
    const totalAmount = matchedOrders.reduce((sum, order) => sum + (order.price || 0), 0)

    return {
      url: data.url,
      totalOrders,
      totalAmount
    }
  })
})
const saveOrder = async () => {
  try {
    // Start loading
    loadingStore.setLoadingPage(true)
    // Send the request
    const response = await axios.post(path, {
      action: 'save-order-free',
      datas: { order: order, orders: orders.value },
    })
    if (response.status === 200) {
      window.location.href = response.data.redirect
    }
  } catch (error) {
    // Handle errors
    if (error.response && error.response.status === 500) {
    } else {
    }
  } finally {
    // End loading
  }
}
</script>
