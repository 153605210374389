import { Node, mergeAttributes } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-3";
import MediaComponent from "@/components/common/tiptap/Mediacomponent.vue";

export default Node.create({
  name: "media",

  group: "block",

  atom: true,

  addAttributes() {
    return {
      type: {
        default: "image", // or 'youtube'
      },
      src: {
        default: "",
      },
      alt: {
        default: "", // Default alt
      },
      className: {
        default: "float-left-30-img", // Default alt
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "media-component",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["media-component", mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return VueNodeViewRenderer(MediaComponent);
  },
});
