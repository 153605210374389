<template>


  <Form @submit="onSubmit_0" class="space-y-3" :validation-schema="schema_0" v-slot="{ errors }">
    <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-5">
      <div>
        <label for="email">
          {{ t('field_names.email') }}
        </label>
        <div class="relative">
          <Field v-model="formValues.email" name="email" type="text"
                 :class="[errors.email ? 'ring-2 ring-red-600' : '']"/>
          <ErrorMessage name="email" class="error-message"/>
        </div>
      </div>
      <div>
        <label for="password">
          Choisissez un mot de passe
        </label>
        <div class="relative">
          <Field v-model="formValues.password" name="password" v-slot="{ field, errors }"
                 :class="[errors.password && Object.keys(errors.password).length > 0 ? 'ring-2 ring-red-600' : '']">
            <input
                :type="(showPassword)?'text':'password'"
                v-model="formValues.password"
                :class="[errors && Object.keys(errors).length > 0 ? 'ring-2 ring-red-600' : '']"

            />
            <span @click.prevent.stop="toggleShowPassword"
                  class="absolute cursor-pointer -translate-y-1/2 top-1/2 right-5">
                    <svg v-if="!showPassword" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                         viewBox="0 0 24 24" fill="none"
                         stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                         class="lucide lucide-eye"><path
                        d="M2.062 12.348a1 1 0 0 1 0-.696 10.75 10.75 0 0 1 19.876 0 1 1 0 0 1 0 .696 10.75 10.75 0 0 1-19.876 0"/><circle
                        cx="12" cy="12" r="3"/></svg>
                      <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"
                           fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                           stroke-linejoin="round" class="lucide lucide-eye-off"><path
                          d="M10.733 5.076a10.744 10.744 0 0 1 11.205 6.575 1 1 0 0 1 0 .696 10.747 10.747 0 0 1-1.444 2.49"/><path
                          d="M14.084 14.158a3 3 0 0 1-4.242-4.242"/><path
                          d="M17.479 17.499a10.75 10.75 0 0 1-15.417-5.151 1 1 0 0 1 0-.696 10.75 10.75 0 0 1 4.446-5.143"/><path
                          d="m2 2 20 20"/></svg>
                  </span>
          </Field>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-5">
      <div class="mt-0 md:mt-0 lg:mt-0">
        <div class="lg:flex md:flex items-center text-xs lg:space-x-3 md:space-x-3">
          <div>
            <div class="space-x-2 flex items-center"
                 :class="passwordRequirements.hasUpperCase?'text-green-600':'text-gray-400'">
              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none"
                   stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                   class="lucide lucide-check">
                <path d="M20 6 9 17l-5-5"/>
              </svg>
              <span>{{ $t('validation.password_uppercase') }}</span>
            </div>
            <div class="space-x-2 flex items-center"
                 :class="passwordRequirements.hasMinLength?'text-green-600':'text-gray-400'">
              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none"
                   stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                   class="lucide lucide-check">
                <path d="M20 6 9 17l-5-5"/>
              </svg>
              <span>{{ $t('validation.password_length') }}</span>
            </div>
          </div>
          <div>
            <div class="space-x-2 flex items-center"
                 :class="passwordRequirements.hasNumber?'text-green-600':'text-gray-400'">
              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none"
                   stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                   class="lucide lucide-check">
                <path d="M20 6 9 17l-5-5"/>
              </svg>
              <span>{{ $t('validation.password_number') }}</span>
            </div>
            <div class="space-x-2 flex items-center"
                 :class="passwordRequirements.hasSpecialChar?'text-green-600':'text-gray-400'">
              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none"
                   stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                   class="lucide lucide-check">
                <path d="M20 6 9 17l-5-5"/>
              </svg>
              <span>{{ $t('validation.password_special_char') }} (@#²!?*^%)</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <label for="passwordConfirmation">
        Confirmez votre mot de passe
      </label>
      <div class="relative">
        <Field v-model="formValues.passwordConfirmation" name="passwordConfirmation" v-slot="{ field, errors }"
               :class="[errors.passwordConfirmation && Object.keys(errors.passwordConfirmation).length > 0 ? 'ring-2 ring-red-600' : '']">
          <input
              :type="(showPassword_confirmation)?'text':'password'"
              v-model="formValues.passwordConfirmation"
              :class="[errors && Object.keys(errors).length > 0 ? 'ring-2 ring-red-600' : '']"

          />
          <span @click.prevent.stop="toggleShowPassword_confirmation"
                class="absolute cursor-pointer -translate-y-1/2 top-1/2 right-5">
                    <svg v-if="!showPassword_confirmation" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                         viewBox="0 0 24 24" fill="none"
                         stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                         class="lucide lucide-eye"><path
                        d="M2.062 12.348a1 1 0 0 1 0-.696 10.75 10.75 0 0 1 19.876 0 1 1 0 0 1 0 .696 10.75 10.75 0 0 1-19.876 0"/><circle
                        cx="12" cy="12" r="3"/></svg>
                      <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"
                           fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                           stroke-linejoin="round" class="lucide lucide-eye-off"><path
                          d="M10.733 5.076a10.744 10.744 0 0 1 11.205 6.575 1 1 0 0 1 0 .696 10.747 10.747 0 0 1-1.444 2.49"/><path
                          d="M14.084 14.158a3 3 0 0 1-4.242-4.242"/><path
                          d="M17.479 17.499a10.75 10.75 0 0 1-15.417-5.151 1 1 0 0 1 0-.696 10.75 10.75 0 0 1 4.446-5.143"/><path
                          d="m2 2 20 20"/></svg>
                  </span>
        </Field>

      </div>
      <ErrorMessage name="passwordConfirmation" class="error-message"/>

    </div>
    <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-1 ">
      <div class="flex justify-center mt-3">
        <button
            :disabled="loadingStore.isLoading || !formValues.email || !formValues.password || !formValues.passwordConfirmation"
            class="btn btn-lg btn-netlinking w-full space-x-2 flex items-center justify-center"
        >
          <span><LoadingBtn :label="'Créer son compte collaborateur'"/></span>
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                 stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                 class="lucide lucide-move-right"><path d="M18 8L22 12L18 16"/><path d="M2 12H22"/></svg>
          </span>
        </button>
      </div>
    </div>
    <!--        <p class="pt-3 text-gray-400 text-center">-->
    <!--            <a :href="`/${currentLocale}/sign-in/`" class="link-gray">{{ $t('sign_in') }}</a>-->
    <!--        </p>-->
  </Form>
</template>
<script setup>
import { computed, defineProps, reactive, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useLoadingStore } from '@/js/stores/loading'
import { useLocaleStore } from '@/js/stores/locale'
import axios from 'axios'
import * as yup from 'yup'
import { Field, Form, ErrorMessage } from 'vee-validate'
import LoadingBtn from '@/components/common/LoadingBtn.vue'

const showPassword = ref(false)
const showPassword_confirmation = ref(false)
const emit = defineEmits(['form-submitted'])
const { t } = useI18n()
const localeStore = useLocaleStore()
const loadingStore = useLoadingStore()
const currentLocale = localeStore.currentLocale
const existingError = ref(false)
const path = '/security/ajax/'
const formValues = reactive({
  passwordConfirmation: '',
  password: '',
  token: ''
})
const props = defineProps({
  captcha: {
    type: String,
    required: true
  },
  csrf: {
    type: String,
    required: true
  },
  token: {
    type: String,
    required: true
  },
})
const passwordRequirements = ref({
  hasUpperCase: false,
  hasNumber: false,
  hasSpecialChar: false,
  hasMinLength: false,
  passwordsMatch: false,
  isValid: false
})
const hasPasswordRequirements = (password, confirmation) => {
  console.log('Checking password:', password) // Debug log

  if (!password) {
    return {
      hasUpperCase: false,
      hasNumber: false,
      hasSpecialChar: false,
      hasMinLength: false,
      passwordsMatch: false,
      isValid: false
    }
  }

  const requirements = {
    hasUpperCase: /[A-Z]/.test(password),
    hasNumber: /[0-9]/.test(password),
    hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    passwordsMatch: password === confirmation && confirmation !== '',
    hasMinLength: password.length >= 12
  }

  requirements.isValid = requirements.hasUpperCase &&
      requirements.hasNumber &&
      requirements.hasSpecialChar &&
      requirements.hasMinLength

  console.log('Password requirements:', requirements) // Debug log
  return requirements
}

// Fonction de validation appelée lors de la saisie
const validatePassword = () => {
  console.log('Validating password:', formValues.password) // Debug log
  passwordRequirements.value = hasPasswordRequirements(formValues.password, formValues.passwordConfirmation)
}

const toggleShowPassword = () => {
  showPassword.value = !showPassword.value
}
const toggleShowPassword_confirmation = () => {
  showPassword_confirmation.value = !showPassword_confirmation.value
}

const schema_0 = computed(() => {
  return yup.object().shape({
    email: yup
        .string()
        .required(t('validation.required'))
        .email(t('validation.invalid_email_format')),
    password: yup
        .string()
        .required(t('validation.required'))
        .min(12, t('validation.password_length'))
        .test('uppercase', t('validation.password_uppercase'),
            value => /[A-Z]/.test(value)
        )
        .test('digit', t('validation.password_number'),
            value => /\d/.test(value)
        )
        .test('special', t('validation.password_special_char'),
            value => /[!@#$%^&*(),.?":{}|<>]/.test(value)
        ),
    passwordConfirmation: yup
        .string()
        .required('La confirmation du mot de passe est requise')
        .oneOf([yup.ref('password')], 'Les mots de passe ne correspondent pas')
  })
})
const onSubmit_0 = async (values) => {
  try {
    // Start loading
    loadingStore.setLoading(true)
    formValues.token = props.token
    // Send the request
    const response = await axios.post(path, {
      action: 'invitation',
      datas: { values: formValues,token: props.token, captcha: props.captcha, csrf: props.csrf },
    })

    // Check response status
    console.log(response.status)
    if (response.status === 200) {
      // Handle successful login
      window.location.href = response.data.redirect
      existingError.value = false // Reset error if needed
    }
  } catch (error) {
    // Handle errors
    if (error.response && error.response.status === 500) {
      existingError.value = true // Set error flag
    } else {
    }
  } finally {
    // End loading
    loadingStore.setLoading(false)
  }
}
watch([() => formValues.password, () => formValues.passwordConfirmation], () => {
  validatePassword()
}, { immediate: true })

</script>