<!-- PhoneInput.vue -->
<template>
  <div class="relative" v-click-outside="customMethod">
    <div class="flex items-center border input-format focus-within-border" :class="classError">
      <div
          @click="isOpen = !isOpen"
          class="flex-shrink-0 border-none cursor-pointer z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-white border rounded-s-lg hover:bg-gray-50 focus:ring-4 focus:outline-none focus:ring-gray-100"
      >
        <img :src="getFlagUrl(selectedCountry.code)" class="w-4 h-4 me-2" :alt="selectedCountry.name" />
        {{ selectedCountry.dialCode }}
        <svg class="w-2.5 h-2.5 ms-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
        </svg>
      </div>

      <div v-if="isOpen" class="absolute top-full left-0 z-20 mt-1 bg-white rounded-lg shadow-lg">
        <ul class="py-2 max-h-60 overflow-y-auto">
          <li v-for="country in preferredCountries" :key="country.code">
            <button
                type="button"
                @click="selectCountry(country)"
                class="w-full px-4 py-2 text-left hover:bg-gray-100 flex items-center"
            >
              <img :src="getFlagUrl(country.code)" class="w-4 h-4 me-2" :alt="country.name" />
              <span>{{ country.name }} ({{ country.dialCode }})</span>
            </button>
          </li>
          <li class="border-t border-gray-200" v-if="preferredCountries.length">
            <div class="px-4 py-2 text-xs text-gray-500">{{ translations.otherCountries }}</div>
          </li>
          <li v-for="country in otherCountries" :key="country.code">
            <button
                type="button"
                @click="selectCountry(country)"
                class="w-full px-4 py-2 text-left hover:bg-gray-100 flex items-center"
            >
              <img :src="getFlagUrl(country.code)" class="w-4 h-4 me-2" :alt="country.name" />
              <span>{{ country.name }} ({{ country.dialCode }})</span>
            </button>
          </li>
        </ul>
      </div>

      <input
          type="tel"
          v-model="phoneNumber"
          :placeholder="selectedCountry.placeholder"
          @input="formatPhoneNumber"
          class="input-no-border"
      />
    </div>
  </div>
</template>
<script setup>
import { ref, computed, watch,onMounted} from 'vue'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const emitFormat = () => {
  const format = {
    placeholder: selectedCountry.value.placeholder,
    format: selectedCountry.value.format,
    maxLength: selectedCountry.value.maxLength,
    dialCode: selectedCountry.value.dialCode
  }
  emit('update:format', format)
}

// Émet les informations du pays
const emitCountry = () => {
  const country = {
    code: selectedCountry.value.code,
    name: selectedCountry.value.name,
    dialCode: selectedCountry.value.dialCode
  }
  emit('update:country', country)
}

const customMethod = () => {
  isOpen.value = false
}
const props = defineProps({
  modelValue: String,
  classError: null,
  locale: {
    type: String,
    default: 'en',
    validator: (value) => ['en', 'fr'].includes(value)
  },
  preferredCountryCodes: {
    type: Array,
    default: () => ['FR']
  }
})

const emit = defineEmits(['update:modelValue'])

const translations = computed(() => ({
  otherCountries: t('countries.other_countries')
}))

const countries = [
  {
    code: 'AL',
    name: t('countries.albania'),
    dialCode: '+355',
    placeholder: '067 123 4567',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 6)} ${num.slice(6)}`,
    maxLength: 10
  },
  {
    code: 'AD',
    name: t('countries.andorra'),
    dialCode: '+376',
    placeholder: '612 345',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3)}`,
    maxLength: 6
  },
  {
    code: 'AT',
    name: t('countries.austria'),
    dialCode: '+43',
    placeholder: '0664 123 4567',
    format: (num) => `${num.slice(0, 4)} ${num.slice(4, 7)} ${num.slice(7)}`,
    maxLength: 11
  },
  {
    code: 'BE',
    name: t('countries.belgium'),
    dialCode: '+32',
    placeholder: '0470 12 34 56',
    format: (num) => `${num.slice(0, 4)} ${num.slice(4, 6)} ${num.slice(6, 8)}`,
    maxLength: 10
  },
  {
    code: 'BG',
    name: t('countries.bulgaria'),
    dialCode: '+359',
    placeholder: '087 123 4567',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 6)} ${num.slice(6)}`,
    maxLength: 10
  },
  {
    code: 'HR',
    name: t('countries.croatia'),
    dialCode: '+385',
    placeholder: '091 123 4567',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 6)} ${num.slice(6)}`,
    maxLength: 10
  },
  {
    code: 'CY',
    name: t('countries.cyprus'),
    dialCode: '+357',
    placeholder: '96 123456',
    format: (num) => `${num.slice(0, 2)} ${num.slice(2)}`,
    maxLength: 9
  },
  {
    code: 'CZ',
    name: t('countries.czech_republic'),
    dialCode: '+420',
    placeholder: '601 123 456',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 6)} ${num.slice(6)}`,
    maxLength: 10
  },
  {
    code: 'DK',
    name: t('countries.denmark'),
    dialCode: '+45',
    placeholder: '12 34 56 78',
    format: (num) => `${num.slice(0, 2)} ${num.slice(2, 4)} ${num.slice(4, 6)} ${num.slice(6)}`,
    maxLength: 9
  },
  {
    code: 'EE',
    name: t('countries.estonia'),
    dialCode: '+372',
    placeholder: '5123 4567',
    format: (num) => `${num.slice(0, 4)} ${num.slice(4)}`,
    maxLength: 9
  },
  {
    code: 'FI',
    name: t('countries.finland'),
    dialCode: '+358',
    placeholder: '041 234 5678',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 6)} ${num.slice(6)}`,
    maxLength: 11
  },
  {
    code: 'FR',
    name: t('countries.france'),
    dialCode: '+33',
    placeholder: '00 00 00 00 00',
    format: (num) => `${num.slice(0, 2)} ${num.slice(2, 4)} ${num.slice(4, 6)} ${num.slice(6, 8)} ${num.slice(8)}`,
    maxLength: 10
  },
  {
    code: 'DE',
    name: t('countries.germany'),
    dialCode: '+49',
    placeholder: '0171 123 4567',
    format: (num) => `${num.slice(0, 4)} ${num.slice(4, 7)} ${num.slice(7)}`,
    maxLength: 11
  },
  {
    code: 'GR',
    name: t('countries.greece'),
    dialCode: '+30',
    placeholder: '691 234 5678',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 6)} ${num.slice(6)}`,
    maxLength: 10
  },
  {
    code: 'HU',
    name: t('countries.hungary'),
    dialCode: '+36',
    placeholder: '30 123 4567',
    format: (num) => `${num.slice(0, 2)} ${num.slice(2, 5)} ${num.slice(5)}`,
    maxLength: 9
  },
  {
    code: 'IS',
    name: t('countries.iceland'),
    dialCode: '+354',
    placeholder: '611 1234',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3)}`,
    maxLength: 7
  },
  {
    code: 'IE',
    name: t('countries.ireland'),
    dialCode: '+353',
    placeholder: '085 123 4567',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 6)} ${num.slice(6)}`,
    maxLength: 9
  },
  {
    code: 'IT',
    name: t('countries.italy'),
    dialCode: '+39',
    placeholder: '347 123 4567',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 6)} ${num.slice(6)}`,
    maxLength: 10
  },
  {
    code: 'LV',
    name: t('countries.latvia'),
    dialCode: '+371',
    placeholder: '20123456',
    format: (num) => `${num.slice(0, 2)} ${num.slice(2)}`,
    maxLength: 8
  },
  {
    code: 'LT',
    name: t('countries.lithuania'),
    dialCode: '+370',
    placeholder: '612 34567',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3)}`,
    maxLength: 8
  },
  {
    code: 'LU',
    name: t('countries.luxembourg'),
    dialCode: '+352',
    placeholder: '621 123 456',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 6)} ${num.slice(6)}`,
    maxLength: 9
  },
  {
    code: 'NL',
    name: t('countries.netherlands'),
    dialCode: '+31',
    placeholder: '06 12345678',
    format: (num) => `${num.slice(0, 2)} ${num.slice(2, 8)}`,
    maxLength: 10
  },
  {
    code: 'NO',
    name: t('countries.norway'),
    dialCode: '+47',
    placeholder: '412 34 567',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 5)} ${num.slice(5)}`,
    maxLength: 8
  },
  {
    code: 'PL',
    name: t('countries.poland'),
    dialCode: '+48',
    placeholder: '512 345 678',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 6)} ${num.slice(6)}`,
    maxLength: 9
  },
  {
    code: 'PT',
    name: t('countries.portugal'),
    dialCode: '+351',
    placeholder: '912 345 678',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 6)} ${num.slice(6)}`,
    maxLength: 9
  },
  {
    code: 'RO',
    name: t('countries.romania'),
    dialCode: '+40',
    placeholder: '0712 345 678',
    format: (num) => `${num.slice(0, 4)} ${num.slice(4, 7)} ${num.slice(7)}`,
    maxLength: 10
  },
  {
    code: 'ES',
    name: t('countries.spain'),
    dialCode: '+34',
    placeholder: '612 34 56 78',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 5)} ${num.slice(5, 7)} ${num.slice(7)}`,
    maxLength: 9
  },
  {
    code: 'SE',
    name: t('countries.sweden'),
    dialCode: '+46',
    placeholder: '070 123 45 67',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 6)} ${num.slice(6)}`,
    maxLength: 10
  },
  {
    code: 'CH',
    name: t('countries.switzerland'),
    dialCode: '+41',
    placeholder: '079 123 45 67',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 6)} ${num.slice(6)}`,
    maxLength: 9
  },
  {
    code: 'TR',
    name: t('countries.turkey'),
    dialCode: '+90',
    placeholder: '532 123 45 67',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 6)} ${num.slice(6)}`,
    maxLength: 10
  },
  {
    code: 'UA',
    name: t('countries.ukraine'),
    dialCode: '+380',
    placeholder: '067 123 4567',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 6)} ${num.slice(6)}`,
    maxLength: 9
  },
  {
    code: 'GB',
    name: t('countries.united_kingdom'),
    dialCode: '+44',
    placeholder: '07700 900123',
    format: (num) => `${num.slice(0, 4)} ${num.slice(4, 7)} ${num.slice(7)}`,
    maxLength: 10
  },
  {
    code: 'US',
    name: t('countries.united_states'), // United States / États-Unis
    dialCode: '+1',
    placeholder: '(555) 123-4567',
    format: (num) => `${num.slice(0, 3)}-${num.slice(3, 6)}-${num.slice(6)}`,
    maxLength: 10
  },
  {
    code: 'CA',
    name: t('countries.canada'), // Canada
    dialCode: '+1',
    placeholder: '(555) 123-4567',
    format: (num) => `${num.slice(0, 3)}-${num.slice(3, 6)}-${num.slice(6)}`,
    maxLength: 10
  },
  {
    code: 'MX',
    name: t('countries.mexico'), // Mexico / Mexique
    dialCode: '+52',
    placeholder: '55 1234 5678',
    format: (num) => `${num.slice(0, 2)} ${num.slice(2, 6)} ${num.slice(6)}`,
    maxLength: 10
  },
  {
    code: 'CU',
    name: t('countries.cuba'), // Cuba
    dialCode: '+53',
    placeholder: '5 1234567',
    format: (num) => `${num.slice(0, 1)} ${num.slice(1)}`,
    maxLength: 8
  },
  {
    code: 'DO',
    name: t('countries.dominican_republic'), // Dominican Republic / République Dominicaine
    dialCode: '+1',
    placeholder: '(809) 123-4567',
    format: (num) => `(${num.slice(0, 3)}) ${num.slice(3, 6)}-${num.slice(6)}`,
    maxLength: 10
  },
  {
    code: 'GT',
    name: t('countries.guatemala'), // Guatemala
    dialCode: '+502',
    placeholder: '5123 4567',
    format: (num) => `${num.slice(0, 4)} ${num.slice(4)}`,
    maxLength: 8
  },
  {
    code: 'HN',
    name: t('countries.honduras'), // Honduras
    dialCode: '+504',
    placeholder: '9123 4567',
    format: (num) => `${num.slice(0, 4)} ${num.slice(4)}`,
    maxLength: 8
  },
  {
    code: 'PA',
    name: t('countries.panama'), // Panama
    dialCode: '+507',
    placeholder: '6123 4567',
    format: (num) => `${num.slice(0, 4)} ${num.slice(4)}`,
    maxLength: 8
  },
  {
    code: 'CR',
    name: t('countries.costa_rica'), // Costa Rica
    dialCode: '+506',
    placeholder: '8312 3456',
    format: (num) => `${num.slice(0, 4)} ${num.slice(4)}`,
    maxLength: 8
  },
  {
    code: 'JM',
    name: t('countries.jamaica'), // Jamaica / Jamaïque
    dialCode: '+1',
    placeholder: '(876) 123-4567',
    format: (num) => `(${num.slice(0, 3)}) ${num.slice(3, 6)}-${num.slice(6)}`,
    maxLength: 10
  },
  {
    code: 'BZ',
    name: t('countries.belize'), // Belize
    dialCode: '+501',
    placeholder: '622 3456',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3)}`,
    maxLength: 7
  },
  {
    code: 'TT',
    name: t('countries.trinidad_and_tobago'), // Trinidad and Tobago / Trinité-et-Tobago
    dialCode: '+1',
    placeholder: '(868) 123-4567',
    format: (num) => `(${num.slice(0, 3)}) ${num.slice(3, 6)}-${num.slice(6)}`,
    maxLength: 10
  },
  {
    code: 'HT',
    name: t('countries.haiti'), // Haiti / Haïti
    dialCode: '+509',
    placeholder: '34 12 3456',
    format: (num) => `${num.slice(0, 2)} ${num.slice(2, 4)} ${num.slice(4)}`,
    maxLength: 8
  },
  {
    code: 'AF',
    name: t('countries.afghanistan'),
    dialCode: '+93',
    placeholder: '701 234 567',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 6)} ${num.slice(6)}`,
    maxLength: 9
  },
  {
    code: 'AM',
    name: t('countries.armenia'),
    dialCode: '+374',
    placeholder: '771 234 567',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 6)} ${num.slice(6)}`,
    maxLength: 9
  },
  {
    code: 'AZ',
    name: t('countries.azerbaijan'),
    dialCode: '+994',
    placeholder: '401 234 567',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 6)} ${num.slice(6)}`,
    maxLength: 9
  },
  {
    code: 'BH',
    name: t('countries.bahrain'),
    dialCode: '+973',
    placeholder: '3600 1234',
    format: (num) => `${num.slice(0, 4)} ${num.slice(4)}`,
    maxLength: 8
  },
  {
    code: 'BD',
    name: t('countries.bangladesh'),
    dialCode: '+880',
    placeholder: '171 123 4567',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 6)} ${num.slice(6)}`,
    maxLength: 10
  },
  {
    code: 'BT',
    name: t('countries.bhutan'),
    dialCode: '+975',
    placeholder: '17 123 456',
    format: (num) => `${num.slice(0, 2)} ${num.slice(2, 5)} ${num.slice(5)}`,
    maxLength: 8
  },
  {
    code: 'BN',
    name: t('countries.brunei'),
    dialCode: '+673',
    placeholder: '712 3456',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3)}`,
    maxLength: 7
  },
  {
    code: 'KH',
    name: t('countries.cambodia'),
    dialCode: '+855',
    placeholder: '12 345 678',
    format: (num) => `${num.slice(0, 2)} ${num.slice(2, 5)} ${num.slice(5)}`,
    maxLength: 9
  },
  {
    code: 'CN',
    name: t('countries.china'),
    dialCode: '+86',
    placeholder: '138 0013 8000',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 7)} ${num.slice(7)}`,
    maxLength: 11
  },
  {
    code: 'CY',
    name: t('countries.cyprus'),
    dialCode: '+357',
    placeholder: '96 123456',
    format: (num) => `${num.slice(0, 2)} ${num.slice(2)}`,
    maxLength: 8
  },
  {
    code: 'GE',
    name: t('countries.georgia'),
    dialCode: '+995',
    placeholder: '555 123 456',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 6)} ${num.slice(6)}`,
    maxLength: 9
  },
  {
    code: 'IN',
    name: t('countries.india'),
    dialCode: '+91',
    placeholder: '98765 43210',
    format: (num) => `${num.slice(0, 5)} ${num.slice(5)}`,
    maxLength: 10
  },
  {
    code: 'ID',
    name: t('countries.indonesia'),
    dialCode: '+62',
    placeholder: '812 345 678',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 6)} ${num.slice(6)}`,
    maxLength: 10
  },
  {
    code: 'IR',
    name: t('countries.iran'),
    dialCode: '+98',
    placeholder: '912 345 6789',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 6)} ${num.slice(6)}`,
    maxLength: 10
  },
  {
    code: 'IQ',
    name: t('countries.iraq'),
    dialCode: '+964',
    placeholder: '790 123 4567',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 6)} ${num.slice(6)}`,
    maxLength: 10
  },
  {
    code: 'IL',
    name: t('countries.israel'),
    dialCode: '+972',
    placeholder: '50 123 4567',
    format: (num) => `${num.slice(0, 2)} ${num.slice(2, 5)} ${num.slice(5)}`,
    maxLength: 10
  },
  {
    code: 'JP',
    name: t('countries.japan'),
    dialCode: '+81',
    placeholder: '090 1234 5678',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 7)} ${num.slice(7)}`,
    maxLength: 11
  },
  {
    code: 'JO',
    name: t('countries.jordan'),
    dialCode: '+962',
    placeholder: '79 123 4567',
    format: (num) => `${num.slice(0, 2)} ${num.slice(2, 5)} ${num.slice(5)}`,
    maxLength: 10
  },
  {
    code: 'KZ',
    name: t('countries.kazakhstan'),
    dialCode: '+7',
    placeholder: '771 234 5678',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 6)} ${num.slice(6)}`,
    maxLength: 10
  },
  {
    code: 'KR',
    name: t('countries.south_korea'),
    dialCode: '+82',
    placeholder: '010 1234 5678',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 7)} ${num.slice(7)}`,
    maxLength: 11
  },
  {
    code: 'KW',
    name: t('countries.kuwait'),
    dialCode: '+965',
    placeholder: '500 12345',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3)}`,
    maxLength: 8
  },
  {
    code: 'LA',
    name: t('countries.laos'),
    dialCode: '+856',
    placeholder: '20 1234 5678',
    format: (num) => `${num.slice(0, 2)} ${num.slice(2, 6)} ${num.slice(6)}`,
    maxLength: 10
  },
  {
    code: 'MY',
    name: t('countries.malaysia'),
    dialCode: '+60',
    placeholder: '12 345 6789',
    format: (num) => `${num.slice(0, 2)} ${num.slice(2, 5)} ${num.slice(5)}`,
    maxLength: 10
  },
  {
    code: 'MV',
    name: t('countries.maldives'),
    dialCode: '+960',
    placeholder: '771 2345',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3)}`,
    maxLength: 7
  },
  {
    code: 'NP',
    name: t('countries.nepal'),
    dialCode: '+977',
    placeholder: '984 123 4567',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 6)} ${num.slice(6)}`,
    maxLength: 10
  },
  {
    code: 'OM',
    name: t('countries.oman'),
    dialCode: '+968',
    placeholder: '921 23456',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3)}`,
    maxLength: 8
  },
  {
    code: 'PK',
    name: t('countries.pakistan'),
    dialCode: '+92',
    placeholder: '301 234 5678',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 6)} ${num.slice(6)}`,
    maxLength: 10
  },
  {
    code: 'PH',
    name: t('countries.philippines'),
    dialCode: '+63',
    placeholder: '912 345 6789',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 6)} ${num.slice(6)}`,
    maxLength: 10
  },
  {
    code: 'QA',
    name: t('countries.qatar'),
    dialCode: '+974',
    placeholder: '3312 3456',
    format: (num) => `${num.slice(0, 4)} ${num.slice(4)}`,
    maxLength: 8
  },
  {
    code: 'SA',
    name: t('countries.saudi_arabia'),
    dialCode: '+966',
    placeholder: '512 345 678',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 6)} ${num.slice(6)}`,
    maxLength: 9
  },
  {
    code: 'SG',
    name: t('countries.singapore'),
    dialCode: '+65',
    placeholder: '8123 4567',
    format: (num) => `${num.slice(0, 4)} ${num.slice(4)}`,
    maxLength: 8
  },
  {
    code: 'LK',
    name: t('countries.sri_lanka'),
    dialCode: '+94',
    placeholder: '712 345 678',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 6)} ${num.slice(6)}`,
    maxLength: 9
  },
  {
    code: 'SY',
    name: t('countries.syria'),
    dialCode: '+963',
    placeholder: '933 123 456',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 6)} ${num.slice(6)}`,
    maxLength: 9
  },
  {
    code: 'TH',
    name: t('countries.thailand'),
    dialCode: '+66',
    placeholder: '812 345 678',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 6)} ${num.slice(6)}`,
    maxLength: 9
  },
  {
    code: 'TR',
    name: t('countries.turkey'),
    dialCode: '+90',
    placeholder: '532 123 4567',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 6)} ${num.slice(6)}`,
    maxLength: 10
  },
  {
    code: 'AE',
    name: t('countries.united_arab_emirates'),
    dialCode: '+971',
    placeholder: '50 123 4567',
    format: (num) => `${num.slice(0, 2)} ${num.slice(2, 5)} ${num.slice(5)}`,
    maxLength: 9
  },
  {
    code: 'VN',
    name: t('countries.vietnam'),
    dialCode: '+84',
    placeholder: '912 345 678',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 6)} ${num.slice(6)}`,
    maxLength: 9
  },
  {
    code: 'DZ',
    name: t('countries.algeria'),
    dialCode: '+213',
    placeholder: '551 23 45 67',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 5)} ${num.slice(5)}`,
    maxLength: 9
  },
  {
    code: 'AO',
    name: t('countries.angola'),
    dialCode: '+244',
    placeholder: '923 456 789',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 6)} ${num.slice(6)}`,
    maxLength: 9
  },
  {
    code: 'BJ',
    name: t('countries.benin'),
    dialCode: '+229',
    placeholder: '91 23 45 67',
    format: (num) => `${num.slice(0, 2)} ${num.slice(2, 4)} ${num.slice(4)}`,
    maxLength: 8
  },
  {
    code: 'BW',
    name: t('countries.botswana'),
    dialCode: '+267',
    placeholder: '71 234 567',
    format: (num) => `${num.slice(0, 2)} ${num.slice(2, 5)} ${num.slice(5)}`,
    maxLength: 9
  },
  {
    code: 'BF',
    name: t('countries.burkina_faso'),
    dialCode: '+226',
    placeholder: '70 12 34 56',
    format: (num) => `${num.slice(0, 2)} ${num.slice(2, 4)} ${num.slice(4)}`,
    maxLength: 8
  },
  {
    code: 'BI',
    name: t('countries.burundi'),
    dialCode: '+257',
    placeholder: '79 123 456',
    format: (num) => `${num.slice(0, 2)} ${num.slice(2, 5)} ${num.slice(5)}`,
    maxLength: 8
  },
  {
    code: 'CM',
    name: t('countries.cameroon'),
    dialCode: '+237',
    placeholder: '6 71 23 45 67',
    format: (num) => `${num.slice(0, 1)} ${num.slice(1, 3)} ${num.slice(3, 5)} ${num.slice(5)}`,
    maxLength: 9
  },
  {
    code: 'CV',
    name: t('countries.cape_verde'),
    dialCode: '+238',
    placeholder: '991 12 34',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 5)} ${num.slice(5)}`,
    maxLength: 7
  },
  {
    code: 'TD',
    name: t('countries.chad'),
    dialCode: '+235',
    placeholder: '63 12 34 56',
    format: (num) => `${num.slice(0, 2)} ${num.slice(2, 4)} ${num.slice(4)}`,
    maxLength: 8
  },
  {
    code: 'KM',
    name: t('countries.comoros'),
    dialCode: '+269',
    placeholder: '321 23 45',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 5)} ${num.slice(5)}`,
    maxLength: 7
  },
  {
    code: 'CG',
    name: t('countries.congo'),
    dialCode: '+242',
    placeholder: '06 123 4567',
    format: (num) => `${num.slice(0, 2)} ${num.slice(2, 5)} ${num.slice(5)}`,
    maxLength: 9
  },
  {
    code: 'DJ',
    name: t('countries.djibouti'),
    dialCode: '+253',
    placeholder: '77 12 34 56',
    format: (num) => `${num.slice(0, 2)} ${num.slice(2, 4)} ${num.slice(4)}`,
    maxLength: 8
  },
  {
    code: 'EG',
    name: t('countries.egypt'),
    dialCode: '+20',
    placeholder: '10 1234 5678',
    format: (num) => `${num.slice(0, 2)} ${num.slice(2, 6)} ${num.slice(6)}`,
    maxLength: 10
  },
  {
    code: 'ET',
    name: t('countries.ethiopia'),
    dialCode: '+251',
    placeholder: '911 23 45 67',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 5)} ${num.slice(5)}`,
    maxLength: 9
  },
  {
    code: 'GA',
    name: t('countries.gabon'),
    dialCode: '+241',
    placeholder: '06 12 34 56',
    format: (num) => `${num.slice(0, 2)} ${num.slice(2, 4)} ${num.slice(4)}`,
    maxLength: 8
  },
  {
    code: 'GH',
    name: t('countries.ghana'),
    dialCode: '+233',
    placeholder: '24 123 4567',
    format: (num) => `${num.slice(0, 2)} ${num.slice(2, 5)} ${num.slice(5)}`,
    maxLength: 9
  },
  {
    code: 'KE',
    name: t('countries.kenya'),
    dialCode: '+254',
    placeholder: '712 345 678',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 6)} ${num.slice(6)}`,
    maxLength: 9
  },
  {
    code: 'MA',
    name: t('countries.morocco'),
    dialCode: '+212',
    placeholder: '612 34 56 78',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 5)} ${num.slice(5)}`,
    maxLength: 9
  },
  {
    code: 'MZ',
    name: t('countries.mozambique'),
    dialCode: '+258',
    placeholder: '82 123 4567',
    format: (num) => `${num.slice(0, 2)} ${num.slice(2, 5)} ${num.slice(5)}`,
    maxLength: 9
  },
  {
    code: 'NA',
    name: t('countries.namibia'),
    dialCode: '+264',
    placeholder: '81 123 4567',
    format: (num) => `${num.slice(0, 2)} ${num.slice(2, 5)} ${num.slice(5)}`,
    maxLength: 9
  },
  {
    code: 'NG',
    name: t('countries.nigeria'),
    dialCode: '+234',
    placeholder: '803 123 4567',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 6)} ${num.slice(6)}`,
    maxLength: 10
  },
  {
    code: 'RW',
    name: t('countries.rwanda'),
    dialCode: '+250',
    placeholder: '781 234 567',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 6)} ${num.slice(6)}`,
    maxLength: 9
  },
  {
    code: 'SN',
    name: t('countries.senegal'),
    dialCode: '+221',
    placeholder: '70 123 4567',
    format: (num) => `${num.slice(0, 2)} ${num.slice(2, 5)} ${num.slice(5)}`,
    maxLength: 9
  },
  {
    code: 'ZA',
    name: t('countries.south_africa'),
    dialCode: '+27',
    placeholder: '82 123 4567',
    format: (num) => `${num.slice(0, 2)} ${num.slice(2, 5)} ${num.slice(5)}`,
    maxLength: 9
  },
  {
    code: 'TZ',
    name: t('countries.tanzania'),
    dialCode: '+255',
    placeholder: '754 123 456',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 6)} ${num.slice(6)}`,
    maxLength: 9
  },
  {
    code: 'UG',
    name: t('countries.uganda'),
    dialCode: '+256',
    placeholder: '772 123 456',
    format: (num) => `${num.slice(0, 3)} ${num.slice(3, 6)} ${num.slice(6)}`,
    maxLength: 9
  },
  {
    code: 'ZM',
    name: t('countries.zambia'),
    dialCode: '+260',
    placeholder: '95 123 4567',
    format: (num) => `${num.slice(0, 2)} ${num.slice(2, 5)} ${num.slice(5)}`,
    maxLength: 9
  },
  {
    code: 'ZW',
    name: t('countries.zimbabwe'),
    dialCode: '+263',
    placeholder: '71 123 4567',
    format: (num) => `${num.slice(0, 2)} ${num.slice(2, 5)} ${num.slice(5)}`,
    maxLength: 9
  }
]
const sortedCountries = countries.sort((a, b) => a.code.localeCompare(b.code));


const getFlagUrl = (countryCode) => {
  return `https://flagcdn.com/${countryCode.toLowerCase()}.svg`
}

const preferredCountries = computed(() =>
    countries.filter(country => props.preferredCountryCodes.includes(country.code))
)

const otherCountries = computed(() =>
    sortedCountries.filter(country => !props.preferredCountryCodes.includes(country.code))
)

const isOpen = ref(false)
const selectedCountry = ref(countries.find(c => c.code === 'FR'))
const phoneNumber = ref(props.modelValue || '');



const selectCountry = (country) => {
  selectedCountry.value = country
  isOpen.value = false
  formatPhoneNumber()
  emitFormat()
  emitCountry()
}
onMounted(() => {
  emitFormat()
  emitCountry()
})
const formatPhoneNumber = () => {
  let cleaned = phoneNumber.value.replace(/\D/g, '')

  if (cleaned) {
    cleaned = cleaned.slice(0, selectedCountry.value.maxLength)
    if (selectedCountry.value.format) {
      phoneNumber.value = selectedCountry.value.format(cleaned)
    }
  }
  console.log(phoneNumber.value)
  emit('update:modelValue', phoneNumber.value)
}

watch(() => props.modelValue, (newVal) => {
  if (newVal !== phoneNumber.value) {
    phoneNumber.value = newVal
  }
})
</script>