// src/states.js
import { defineStore } from 'pinia';
export const useLoadingStore = defineStore('loading', {
  state: () => ({
    isLoading: false,
    loadingPage: false
  }),
  actions: {
    setLoading(status) {
      this.isLoading = status;
    },
    setLoadingPage(status) {
      this.isLoading = status;
      this.loadingPage = status;
    }
  }
});