import { defineStore } from 'pinia'
import axios from 'axios'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    user: null
  }),

  actions: {
    async fetchUser() {
      try {
        const response = await axios.get('/fetch/user/', { withCredentials: true })
        this.user = response.data.user
      } catch (error) {
        this.user = null
      } finally {
      }
    },
  },
})
