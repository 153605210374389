// methods.js
import axios from 'axios';
import { useLoadingStore } from '@/js/stores/loading';
import { useNavStore } from '@/js/stores/nav';
const methods = {
    changePath(url, title = null) {
      window.history.pushState({}, title || document.title, url);
      if (title) {
        document.title = title;
      }
    },
  captchaTraitement(token) {
    console.log(token);
    this.captcha = token;
  },
  isMenuOpen: function(){
    const navStore = useNavStore();
    return navStore.isMenuOpen
  },
  toggleMenu: function(){
      const navStore = useNavStore();
      navStore.toggleMenu()
   },
  simpleRedirection: function (url) {
      const loadingStore = useLoadingStore();
        loadingStore.setLoadingPage(true);

        window.location = url;
        setTimeout(function () {}, 5000);
  },
  async filterSubmit(formSelector) {
    await this.saveUnit(this.formUpdateUrl(formSelector), { action: "pagination" },true);
  },
   formUpdateUrl(formSelector) {
        const form = document.querySelector(formSelector);
        const inputs = form.querySelectorAll("input, select, textarea");
        let data = {};
        let url = new URL(window.location.href);
        url.searchParams.forEach((value, key) => {
          if (["per_page", "direction", "sort", "page"].includes(key)) {
            url.searchParams.delete(key);
          }
        });
        for (var i = 0; i < inputs.length; i++) {
          let input = inputs[i];
          if (input.type == "select-multiple") {
            url.searchParams.delete(input.name);
            let selectValue = [];
            for (let j = 0; j < input.options.length; j++) {
              if (input.options[j].selected) {
                url.searchParams.append(input.name, input.options[j].value);
              }
            }
          } else {
            url.searchParams.set(input.name, input.value);
          }
        }
        return url.href;
      },
  async handleSort(field){
     this.sort(field)
  },
  async sort(field) {
        let direction =
        this.extras.pagination.direction == "DESC" ? "ASC" : "DESC";
        let path = this.urlAddParams(["direction", "sort"], [direction, field]);
        await this.saveUnit(path.href, { action: "pagination" },true);
  },
  urlAddParams(paramNames, paramValues) {
        var url = new URL(window.location.href);
        for (let i = 0; i < Object.keys(paramNames).length; i++) {
          url.searchParams.set(paramNames[i], paramValues[i]);
        }
        return url;
      },
  async handleFormSubmission(datas){
    await this.saveUnit(datas.path,{action:datas.action,datas:datas.formData},true)
    // You can now send this data to your backend, update state, etc.
  },
  showToast(type, msg, position, width) {
        if (typeof position == "undefined") {
          position = "top right";
        }
        if (typeof width == "undefined") {
          width = 500;
        }
        // warn, error, success
        this.$notify({
          type: type,
          text: msg,
          duration: 5000,
          speed: 500,
          position: position,
          width: width,
        });
      },
  async saveUnit(path, request, loading = false) {
        const loadingStore = useLoadingStore();
        loadingStore.setLoading(true)
        if (loading) {
          loadingStore.setLoadingPage(true);
        }

        try {
          const response = await axios.post(path, request);

          if (response.request.responseURL?.includes("/login")) {
            window.location.href = "/login/";
            return;
          }

          const data = response.data;
          const {
            notification,
            message,
            alert,
            redirect,
            datas_update,
            datas_update_by_index,
            datas_update_by_index_level_1,
            extra_update,
            extra_update_multiple,
            extra_update_key,
            modalName,
            modalDatas,
            replace_url,
            graph,
            editor,
            editors
          } = data;

          if (notification) {
            this.showToast(notification.status, notification.label, "top", "100%");
          }

          if (message) this.showToast(data.status, message);
          if (alert) this.alerts[alert.code] = { alerttype: alert.type, message: alert.message };
          if (redirect) return this.simpleRedirection(redirect);
          if (datas_update) this.datas = datas_update;
          if (datas_update_by_index) this.datas[datas_update_by_index.index] = datas_update_by_index.value;

          if (datas_update_by_index_level_1) {
            const { index, l1, indexl1, value } = datas_update_by_index_level_1;
            this.datas[index][l1][indexl1] = value;
          }

          if (extra_update) this.extras[extra_update.field] = extra_update.value;

          if (extra_update_multiple) {
            Object.entries(extra_update_multiple).forEach(([_, { field, value }]) => {
              this.extras[field] = value;
            });
          }

          if (extra_update_key) {
            const { field, key, value } = extra_update_key;
            this.extras[field][key] = value;
          }

          if (modalName && modalDatas) this.extras.modals[modalName] = modalDatas;
          if (replace_url) window.history.pushState({}, "", replace_url);
          if (graph) this.chartUpdate();
          if (editor) this.editor.commands.setContent(editor);

          if (editors) {
            Object.entries(editors).forEach(([_, { name, value }]) => {
              this.extras.editors[name].commands.setContent(value);
            });
          }
        } catch (error) {
//           this.showToast("error", "Une erreur inattendue est survenue.");
        } finally {
          loadingStore.setLoading(false)
          loadingStore.setLoadingPage(false);

        }
      }
  // Add other methods here
};

export default methods;
