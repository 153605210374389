<template>
  <div class="tab-content text-base leading-relaxed text-dark py-6"
       data-content="home">
    <div class="grid grid-cols-1 lg:grid-cols-3 gap-4 items-start">
      <div
          class="card-netlinking-2"
      >
        <h2>Information de profil</h2>
        <div>
          <Form v-slot="{ errors }" :validation-schema="schema_0" class="space-y-3" @submit="onSubmit_0">
            <div>
              <Imageupload
                  v-model="formValues_0.profilePhoto"
                  :errors="errors.profilePhoto"
                  name="profilePhoto"
              />
            </div>
            <div>
              <label for="firstName">
                {{ t('field_names.firstName') }}
              </label>
              <div class="relative">
                <Field v-model="formValues_0.firstName" :class="[errors.firstName ? 'ring-2 ring-red-600' : '']" name="firstName"
                       type="text"/>
                <ErrorMessage class="error-message" name="firstName"/>
              </div>
            </div>
            <div>
              <label for="lastName">
                {{ t('field_names.lastName') }}
              </label>
              <div class="relative">
                <Field v-model="formValues_0.lastName" :class="[errors.lastName ? 'ring-2 ring-red-600' : '']" name="lastName"
                       type="text"/>
                <ErrorMessage class="error-message" name="lastName"/>
              </div>
            </div>
            <div>
              <label for="phone">{{ t('field_names.phone') }}</label>
              <Field v-slot="{ field, errors }" v-model="formValues_0.phone" :class="[errors.phone ? 'ring-2 ring-red-600' : '']"
                     name="phone">
                <PhoneInput
                    v-model="formValues_0.phone"
                    :classError="[errors && Object.keys(errors).length > 0 ? 'ring-2 ring-red-600' : '']"
                    locale="currentLocale"
                    @update:format="handleFormat"
                    @update:country="handleCountry"
                />
              </Field>
              <ErrorMessage class="text-red-500 text-xs" name="phone"/>
            </div>
            <div>
              <label for="responsibility">
                {{ $t('role') }}
              </label>
              <div class="relative">
                <Field v-slot="{ field, errors }" v-model="formValues_0.responsibility" name="responsibility"
                >
                  <select id="responsibility"
                          v-model="formValues_0.responsibility"
                          :class="[errors && Object.keys(errors).length > 0 ? 'ring-2 ring-red-600' : '']" name="role">
                    <option :value="''" disabled>{{ $t('select') }}</option>
                    <option v-for="role in roles" :value="role.value">{{ $t(role.label) }}</option>
                  </select>
                </Field>
                <ErrorMessage class="error-message" name="responsibility"/>
              </div>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-1 ">
              <div class="flex justify-center mt-3">
                <button
                    :disabled="loadingStore.isLoading"
                    class="btn btn-sm btn-dark w-full"
                >
                  <LoadingBtn :label="'Mettre à jour'"/>
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <div
          class="card-netlinking-2 w-full"
      >
        <h2>Mot de passe</h2>
        <div>
          <Form v-slot="{ errors }" :validation-schema="schema_1" class="space-y-3" @submit="onSubmit_1">
            <div>
              <label for="email">
                Mot de passe actuel
              </label>
              <div class="relative">
                <Field v-model="formValues_1.current_password" :class="[errors.current_password ? 'ring-2 ring-red-600' : '']" name="current_password"
                       type="password"/>
                <ErrorMessage class="error-message" name="current_password"/>
              </div>
            </div>
            <div>
              <label for="password">
                Nouveau mot de passe
              </label>
              <div class="relative">
                <Field v-slot="{ field, errors }" v-model="formValues_1.new_password" :class="[errors.new_password && Object.keys(errors.new_password).length > 0 ? 'ring-2 ring-red-600' : '']"
                       name="new_password">
                  <input
                      v-model="formValues_1.new_password"
                      :class="[errors && Object.keys(errors).length > 0 ? 'ring-2 ring-red-600' : '']"
                      :type="(showPassword)?'text':'password'"

                  />
                  <span class="absolute cursor-pointer -translate-y-1/2 top-1/2 right-5"
                        @click.prevent.stop="toggleShowPassword">
            <svg v-if="!showPassword" class="lucide lucide-eye" fill="none" height="16"
                 stroke="currentColor" stroke-linecap="round"
                 stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="16"
                 xmlns="http://www.w3.org/2000/svg"><path
                d="M2.062 12.348a1 1 0 0 1 0-.696 10.75 10.75 0 0 1 19.876 0 1 1 0 0 1 0 .696 10.75 10.75 0 0 1-19.876 0"/><circle
                cx="12" cy="12" r="3"/></svg>
              <svg v-else class="lucide lucide-eye-off" fill="none" height="16" stroke="currentColor"
                   stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24"
                   width="16" xmlns="http://www.w3.org/2000/svg"><path
                  d="M10.733 5.076a10.744 10.744 0 0 1 11.205 6.575 1 1 0 0 1 0 .696 10.747 10.747 0 0 1-1.444 2.49"/><path
                  d="M14.084 14.158a3 3 0 0 1-4.242-4.242"/><path
                  d="M17.479 17.499a10.75 10.75 0 0 1-15.417-5.151 1 1 0 0 1 0-.696 10.75 10.75 0 0 1 4.446-5.143"/><path
                  d="m2 2 20 20"/></svg>
          </span>
                </Field>
              </div>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-5">
              <div class="mt-0 md:mt-0 lg:mt-0">
                <div class="lg:flex md:flex items-center text-xs lg:space-x-3 md:space-x-3">
                  <div>
                    <div :class="passwordRequirements.hasUpperCase?'text-green-600':'text-gray-400'"
                         class="space-x-2 flex items-center">
                      <svg class="lucide lucide-check" fill="none" height="15" stroke="currentColor" stroke-linecap="round"
                           stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="15"
                           xmlns="http://www.w3.org/2000/svg">
                        <path d="M20 6 9 17l-5-5"/>
                      </svg>
                      <span>{{ $t('validation.password_uppercase') }}</span>
                    </div>
                    <div :class="passwordRequirements.hasMinLength?'text-green-600':'text-gray-400'"
                         class="space-x-2 flex items-center">
                      <svg class="lucide lucide-check" fill="none" height="15" stroke="currentColor" stroke-linecap="round"
                           stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="15"
                           xmlns="http://www.w3.org/2000/svg">
                        <path d="M20 6 9 17l-5-5"/>
                      </svg>
                      <span>{{ $t('validation.password_length') }}</span>
                    </div>
                  </div>
                  <div>
                    <div :class="passwordRequirements.hasNumber?'text-green-600':'text-gray-400'"
                         class="space-x-2 flex items-center">
                      <svg class="lucide lucide-check" fill="none" height="15" stroke="currentColor" stroke-linecap="round"
                           stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="15"
                           xmlns="http://www.w3.org/2000/svg">
                        <path d="M20 6 9 17l-5-5"/>
                      </svg>
                      <span>{{ $t('validation.password_number') }}</span>
                    </div>
                    <div :class="passwordRequirements.hasSpecialChar?'text-green-600':'text-gray-400'"
                         class="space-x-2 flex items-center">
                      <svg class="lucide lucide-check" fill="none" height="15" stroke="currentColor" stroke-linecap="round"
                           stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="15"
                           xmlns="http://www.w3.org/2000/svg">
                        <path d="M20 6 9 17l-5-5"/>
                      </svg>
                      <span>{{ $t('validation.password_special_char') }} (@#²!?*^%)</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <label for="passwordConfirmation">
                Répétez le nouveau mot de passe
              </label>
              <div class="relative">
                <Field  v-slot="{ field, errors }" v-model="formValues_1.passwordConfirmation"
                       :class="[errors.passwordConfirmation && Object.keys(errors.passwordConfirmation).length > 0 ? 'ring-2 ring-red-600' : '']"
                       name="passwordConfirmation">
                  <input
                      v-model="formValues_1.passwordConfirmation"
                      :class="[errors && Object.keys(errors).length > 0 ? 'ring-2 ring-red-600' : '']"
                      :type="(showPassword_confirmation)?'text':'password'"

                  />
                  <span class="absolute cursor-pointer -translate-y-1/2 top-1/2 right-5"
                        @click.prevent.stop="toggleShowPassword_confirmation">
            <svg v-if="!showPassword_confirmation" class="lucide lucide-eye" fill="none" height="16"
                 stroke="currentColor" stroke-linecap="round"
                 stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="16"
                 xmlns="http://www.w3.org/2000/svg"><path
                d="M2.062 12.348a1 1 0 0 1 0-.696 10.75 10.75 0 0 1 19.876 0 1 1 0 0 1 0 .696 10.75 10.75 0 0 1-19.876 0"/><circle
                cx="12" cy="12" r="3"/></svg>
              <svg v-else class="lucide lucide-eye-off" fill="none" height="16" stroke="currentColor"
                   stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24"
                   width="16" xmlns="http://www.w3.org/2000/svg"><path
                  d="M10.733 5.076a10.744 10.744 0 0 1 11.205 6.575 1 1 0 0 1 0 .696 10.747 10.747 0 0 1-1.444 2.49"/><path
                  d="M14.084 14.158a3 3 0 0 1-4.242-4.242"/><path
                  d="M17.479 17.499a10.75 10.75 0 0 1-15.417-5.151 1 1 0 0 1 0-.696 10.75 10.75 0 0 1 4.446-5.143"/><path
                  d="m2 2 20 20"/></svg>
          </span>
                </Field>

              </div>
              <ErrorMessage class="error-message" name="passwordConfirmation"/>

            </div>
            <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-1 ">
              <div class="flex justify-center mt-3">
                <button
                    :disabled="loadingStore.isLoading"
                    class="btn btn-sm btn-dark w-full"
                >
                  <LoadingBtn :label="'Mettre à jour'"/>
                </button>
              </div>
            </div>
          </Form>
        </div>

      </div>
      <div class="w-full">
        <div
            class="card-netlinking-2"
        >
          <h2>Accès au compte</h2>
          <div>
            <Form v-slot="{ errors }" :validation-schema="schema_2" class="space-y-3" @submit="onSubmit_2">
              <div>
                <label for="email">
                  Adresse email
                </label>
                <div class="relative">
                  <Field :readonly="!allowEditEmail" v-model="formValues_2.email" :class="[errors.email ? 'ring-2 ring-red-600' : '']" name="email"
                         type="text"/>
                  <ErrorMessage class="error-message" name="email"/>
                </div>
              </div>
              <div v-if="!allowEditEmail" class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-1 ">
                <div class="flex justify-center mt-3">
                  <button
                      @click.prevent.stop="sendCode()"
                      :disabled="loadingStore.isLoading"
                      class="btn btn-sm btn-dark w-full"
                  >
                    <LoadingBtn :label="'Modifier'"/>
                  </button>
                </div>
              </div>
              <div v-if="allowEditEmail" class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-1 ">
                <div class="flex justify-center mt-3 space-x-3">
                  <button
                      :disabled="loadingStore.isLoading"
                      class="btn btn-sm btn-dark w-full"
                      @click.prevent.stop="allowEditEmail = false"
                  >
                    <LoadingBtn :label="'Annuler'"/>
                  </button>
                  <button
                      :disabled="loadingStore.isLoading"
                      class="btn btn-sm btn-netlinking w-full"
                  >
                    <LoadingBtn :label="'Sauvegarder'"/>
                  </button>
                </div>
              </div>
            </Form>

          </div>
        </div>
        <p class="-mt-7 mx-5 text-xs">
          <b>Fermeture de compte</b><br>
          Vous souhaitez fermer votre compte ? Cette nouvelle nous attriste, mais, comme on dit souvent, ce n’est peut-être qu’un au revoir ? <a href="#" class="link-dark">Supprimer mon compte</a>
        </p>
      </div>

    </div>
  </div>
  <Transition name="drawer">
    <Modal
        v-model="confirmationBeforeEditEmail"
        max-width="500px"
        @close="confirmationBeforeEditEmail = false"
    >
      <!-- Custom header -->
      <template #header>
        Nous avons envoyé un code
      </template>
      <p class="mb-3">
        Pour changer l’adresse email de votre compte, vous devez d’abord saisir le code que nous vous avons envoyé à l’adresse {{formValues_2.email}}
      </p>
      <input v-model="codeSend" placeholder="Entrez le code" type="text" class="mb-3"/>
      <button @click.prevent.stop="verifyCode()" :disabled="loadingStore.isLoading || !codeSend" class="btn btn-sm w-full btn-netlinking mb-7">
        Continuer
      </button>
      <p v-if="errorCode" class="text-sm text-red-600 text-center -mt-4 font-medium">
        Le code que vous avez saisi est incorrect.
      </p>
    </Modal>
  </Transition>

</template>
<script setup>
import PhoneInput from '@/components/common/phone/PhoneInput.vue'
import Imageupload from '@/components/common/phone/Imageupload.vue'
import { computed, defineProps, onMounted, reactive, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useLocaleStore } from '@/js/stores/locale'
import { useLoadingStore } from '@/js/stores/loading'
import * as yup from 'yup'
import { ErrorMessage, Field, Form } from 'vee-validate'
import LoadingBtn from '@/components/common/LoadingBtn.vue'
import axios from 'axios'
import Modal from '@/components/common/Modal.vue'

const emit = defineEmits(['close-model'])
const selectedRole = ref(null)
const currentStep = ref(1)
const { t } = useI18n()
const localeStore = useLocaleStore()
const loadingStore = useLoadingStore()
const currentLocale = localeStore.currentLocale
const showPassword = ref(false)
const confirmationBeforeEditEmail = ref(false)
const allowEditEmail = ref(false)
const codeSend = ref('')
const errorCode = ref(false)
const showPassword_confirmation = ref(false)
const path = '/customer/ajax/'
const props = defineProps({
  user: {
    type: Object,
    required: true
  },
  roles: {
    type: Object,
    required: true
  }
})
const passwordRequirements = ref({
  hasUpperCase: false,
  hasNumber: false,
  hasSpecialChar: false,
  hasMinLength: false,
  passwordsMatch: false,
  isValid: false
})
const formValues_0 = reactive({
  profilePhoto: '',
  phone: '',
  firstName: '',
  lastName: '',
  responsability: '',
})
const formValues_1 = reactive({
  current_password: '',
  new_password: '',
  passwordConfirmation: ''
})
const formValues_2 = reactive({
  email: ''
})
const schema_0 = computed(() => {
  return yup.object().shape({
    profilePhoto: yup
        .mixed()
        .test('fileSize', 'Le fichier doit être inférieur à 1MB', file => !file || file.size <= 1000000)
        .test('fileType', 'Le fichier doit être au format JPEG, PNG ou WebP', file =>
            !file || ['image/jpeg', 'image/png', 'image/webp'].includes(file.type)
        )
    ,
    firstName: yup
        .string()
        .required(t('validation.required')),
    lastName: yup
        .string()
        .required(t('validation.required')),
    phone: yup
        .string()
        .required(t('validation.required')),
    responsibility: yup
        .string()
        .required(t('validation.required')),
  })
})
const schema_1 = computed(() => {
  return yup.object().shape({
    current_password: yup
        .string()
        .required(t('validation.required')),
    new_password: yup
        .string()
        .required(t('validation.required'))
        .min(12, t('validation.password_length'))
        .test('uppercase', t('validation.password_uppercase'),
            value => /[A-Z]/.test(value)
        )
        .test('digit', t('validation.password_number'),
            value => /\d/.test(value)
        )
        .test('special', t('validation.password_special_char'),
            value => /[!@#$%^&*(),.?":{}|<>]/.test(value)
        ),
    passwordConfirmation: yup
        .string()
        .required('La confirmation du mot de passe est requise')
        .oneOf([yup.ref('new_password')], 'Les mots de passe ne correspondent pas')
  })
})
const schema_2 = computed(() => {
  return yup.object().shape({
    email: yup
        .string()
        .required(t('validation.required'))
        .email(t('validation.invalid_email_format'))
  })
})
const phoneFormat = ref({
  placeholder: '',
  maxLength: 10,
  dialCode: ''
})
const selectedCountry = ref(null)
const handleFormat = (format) => {
  formValues_0.country = format.dialCode
  phoneFormat.value = format
}

const handleCountry = (country) => {
  selectedCountry.value = country
}
onMounted(() => {
  formValues_0.profilePhoto = props.user.profilePhoto
  formValues_0.firstName = props.user.firstName
  formValues_0.lastName = props.user.lastName
  formValues_0.phone = props.user.phone
  formValues_0.responsibility = props.user.responsibility
  formValues_2.email = props.user.email
})

const onSubmit_0 = async (values) => {
  try {
    loadingStore.setLoading(true)

    // Create FormData object
    const formData = new FormData()
    formData.append('action', 'edit-profil-0')
    formData.append('firstName', values.firstName)
    formData.append('lastName', values.lastName)
    formData.append('phone', values.phone)
    formData.append('responsibility', values.responsibility)

    // Add file if it exists
    if (formValues_0.profilePhoto) {
      formData.append('profilePhoto', formValues_0.profilePhoto)
    }

    const response = await axios.post(path, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    if (response.status === 200) {
      window.location.reload()
    }
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error('Server error:', error.response.data)
    }
  } finally {
    loadingStore.setLoading(false)
  }
}

const onSubmit_1 = async (values) => {
  try {
    loadingStore.setLoading(true)

    const response = await axios.post(path, {'action':'change-password',datas:formValues_1})

    if (response.status === 200) {
      window.location.reload()
    }
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error('Server error:', error.response.data)
    }
  } finally {
    loadingStore.setLoading(false)
  }
}

const onSubmit_2 = async (values) => {
  try {
    loadingStore.setLoading(true)

    const response = await axios.post(path, {'action':'change-email',datas: formValues_2})

    if (response.status === 200) {
      window.location.reload()
    }
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error('Server error:', error.response.data)
    }
  } finally {
    loadingStore.setLoading(false)
  }
}

const sendCode = async() => {
  try {
    loadingStore.setLoading(true)

    const response = await axios.post(path, {'action':'send-code',datas:{}})

    if (response.status === 200) {
      confirmationBeforeEditEmail.value = true;
    }
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error('Server error:', error.response.data)
    }
  } finally {
    loadingStore.setLoading(false)
  }
}

const verifyCode = async()=>{
  try {
    loadingStore.setLoading(true)

    const response = await axios.post(path, {'action':'verify-code',datas:{codeSend : codeSend.value}})

    if (response.status === 200) {
      confirmationBeforeEditEmail.value = false
      allowEditEmail.value = true
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      errorCode.value = true
    }
  } finally {
    loadingStore.setLoading(false)
  }
}
const toggleShowPassword = () => {
  showPassword.value = !showPassword.value
}
const toggleShowPassword_confirmation = () => {
  showPassword_confirmation.value = !showPassword_confirmation.value
}
const hasPasswordRequirements = (password, confirmation) => {
  console.log('Checking password:', password) // Debug log

  if (!password) {
    return {
      hasUpperCase: false,
      hasNumber: false,
      hasSpecialChar: false,
      hasMinLength: false,
      passwordsMatch: false,
      isValid: false
    }
  }

  const requirements = {
    hasUpperCase: /[A-Z]/.test(password),
    hasNumber: /[0-9]/.test(password),
    hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    passwordsMatch: password === confirmation && confirmation !== '',
    hasMinLength: password.length >= 12
  }

  requirements.isValid = requirements.hasUpperCase &&
      requirements.hasNumber &&
      requirements.hasSpecialChar &&
      requirements.hasMinLength

  console.log('Password requirements:', requirements) // Debug log
  return requirements
}
const validatePassword = () => {
  console.log('Validating password:', formValues_1.new_password) // Debug log
  passwordRequirements.value = hasPasswordRequirements(formValues_1.new_password, formValues_1.passwordConfirmation)
}
watch([() => formValues_1.new_password, () => formValues_1.passwordConfirmation], () => {
  validatePassword()
}, { immediate: true })
</script>