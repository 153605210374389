<template>
  <div class="draw-header">
    <div class="w-full">Nouveau message</div>
    <div class="flex items-center space-x-2">
      <button :disabled="loadingStore.isLoading" class="btn btn-sm btn-netlinking" form="userForm" type="submit">
        <LoadingBtn :label="'Envoyer'"/>
      </button>
    </div>
  </div>
  <div class="mx-5 py-10">
    <Form id="userForm" v-slot="{ errors }" :validation-schema="schema_0" class="space-y-3" @submit="onSubmit_0">
      <div class="w-full">
        <label for="url">Quel est l'objet de votre demande ?</label>
        <Field
            v-model="formValues.subject"
            class="bg-white"
            name="subject"
            type="text"
        ></Field>
        <ErrorMessage class="error-message" name="subject"/>
      </div>
      <div class="w-full">
        <label for="url">Préciser la campagne associés <small>(S'il y en a une)</small></label>
        <Selectn
            v-model="formValues.campaign"
            :allowNewOption="false"
            :options="campaigns"
            placeholder="choisir une campagne"
        />
        <ErrorMessage class="error-message" name="campaign"/>
      </div>
      <div class="w-full">
        <label for="url">Expliquez-nous tout :</label>
        <Field
            v-model="formValues.message"
            class="bg-white"
            name="message"
            as="textarea"
            rows="10"
        ></Field>
        <ErrorMessage class="error-message" name="message"/>
      </div>
      <div class="w-full">
        <Field v-slot="{ field, errors }" name="file" v-model="formValues.file">
          <Fileupload labeltext="Pièce jointe" v-bind="field" v-model="formValues.file"/>
        </Field>
        <ErrorMessage class="error-message" name="file"/>
      </div>

    </Form>
  </div>
</template>
<script setup>
import { computed, defineProps, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useLoadingStore } from '@/js/stores/loading'
import { useLocaleStore } from '@/js/stores/locale'
import { useAuthStore } from '@/js/stores/auth'
import LoadingBtn from '@/components/common/LoadingBtn.vue'
import Fileupload from '@/components/common/Fileupload.vue'
import * as yup from 'yup'
import { ErrorMessage, Field, Form } from 'vee-validate'
import axios from 'axios'
import Selectn from '@/components/common/Selectn.vue'
const path = '/support/ajax/'
const { t } = useI18n()
const localeStore = useLocaleStore()
const loadingStore = useLoadingStore()
const formValues = ref({
  subject: "",
  campaign:null,
  message: "",
  file: null
})
const props = defineProps({
  campaigns: {
    required: true,
    default: () => {}
  }
})
const schema_0 = computed(() => {
  return yup.object().shape({
    subject: yup
        .string()
        .required(t('validation.required')),

    message: yup
        .string()
        .required(t('validation.required')),

    file: yup
        .mixed()
        .notRequired() // Makes file optional
        .test(
            'fileType',
            t('validation.invalid_file_type', { value: 'Word, Excel, PDF et les images' }),
            (file) => {
              if (!file) return true; // Skip validation if no file is provided
              const allowedTypes = [
                'image/png', 'image/jpeg', 'image/jpg', 'image/webp',
                'application/pdf', 'application/msword',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              ];
              return allowedTypes.includes(file.type);
            }
        )
        .test(
            'fileSize',
            t('validation.max_file_size', { value: '5 Mo' }),
            (file) => {
              if (!file) return true; // Skip validation if no file is provided
              return file.size <= 5 * 1024 * 1024; // Max size 5MB
            }
        )
  });
});

const onSubmit_0 = async (values) => {
  loadingStore.setLoadingPage(true);

  try {
    const formData = new FormData();
    formData.append('action','add-support');
    formData.append('subject', formValues.value.subject);
    formData.append('campaign', formValues.value.campaign || '');
    formData.append('message', formValues.value.message);

    if (values.file) {
      if (Array.isArray(values.file)) {
        values.file.forEach((file, index) => {
          formData.append(`file[${index}]`, file);
        });
      } else {
        formData.append('file', values.file);
      }
    }

    const response = await axios.post(path, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (response.status === 200) {
      window.location.href = response.data.redirect
    }
  } catch (error) {
    loadingStore.setLoadingPage(false);

  } finally {
  }
};

</script>