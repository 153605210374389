<template>
  <h1 class="mb-2 text-3xl font-bold text-dark">
    {{ $t('reset_password') }}
  </h1>
  <p
      class="text-base mb-0 text-secondary-color xl:mb-0"
  >
    <span class="sm:block text-body-color">
    {{ $t('enter_your_email') }}
    </span>
  </p>
  <p
      class="text-base mb-2 text-secondary-color xl:mb-2"
  >
    <span class="sm:block text-body-color">
    {{ $t('password_reset_instructions') }}
    </span>
  </p>
  <Form @submit="onSubmit_0" class="space-y-3" :validation-schema="schema_0" v-slot="{ errors }">
    <p class="text-red-600 text-sm font-semibold" v-if="existingError">
      {{$t('error_occurred')}}
    </p>
    <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-5">
      <div>
        <label for="email">
          {{ t('field_names.email') }}
        </label>
        <div class="relative">
          <Field v-model="formValues.email" name="email" type="text"
                 :class="[errors.email ? 'ring-2 ring-red-600' : '']"/>
          <ErrorMessage name="email" class="error-message"/>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-1 ">
      <div class="flex justify-center mt-3">
        <button
            :disabled="loadingStore.isLoading || !formValues.email"
            class="btn btn-lg btn-netlinking w-full space-x-2 flex items-center justify-center"
        >
          <span><LoadingBtn :label="$t('reset_password')"/></span>
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-move-right"><path d="M18 8L22 12L18 16"/><path d="M2 12H22"/></svg>
          </span>
        </button>
      </div>
    </div>
    <p class="pt-3 text-gray-400 text-center">
      <a :href="`/${currentLocale}/sign-in/`" class="link-gray">{{$t('sign_in')}}</a>
    </p>
  </Form>
</template>
<script setup>
import { computed, defineProps, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useLoadingStore } from '@/js/stores/loading'
import { useLocaleStore } from '@/js/stores/locale'
import axios from 'axios'
import * as yup from 'yup'
import { ErrorMessage, Field, Form } from 'vee-validate'
import LoadingBtn from '@/components/common/LoadingBtn.vue'

const showPassword = ref(false)
const emit = defineEmits(['form-submitted'])
const { t } = useI18n()
const localeStore = useLocaleStore()
const loadingStore = useLoadingStore()
const currentLocale = localeStore.currentLocale
const existingError = ref(false)
const path = '/security/ajax/'
const props = defineProps({
  captcha: {
    type: String,
    required: true
  },
  csrf: {
    type: String,
    required: true
  },
})
const toggleShowPassword = () => {
  showPassword.value = !showPassword.value
}
const formValues = reactive({
  email: ''
})
const schema_0 = computed(() => {
  return yup.object().shape({
    email: yup
        .string()
        .required(t('validation.required'))
        .email(t('validation.invalid_email_format')),
  })
})
const onSubmit_0 = async (values) => {
  try {
    // Start loading
    loadingStore.setLoading(true)

    // Send the request
    const response = await axios.post(path, {
      action: 'reset-password',
      datas: { values: formValues, captcha: props.captcha, csrf: props.csrf },
    })

    // Check response status
    console.log(response.status)
    if (response.status === 200) {
      window.location.href = response.data.redirect
      // Handle successful login
      existingError.value = false // Reset error if needed
    }
  } catch (error) {
    // Handle errors
    if (error.response && error.response.status === 500) {
      existingError.value = true // Set error flag
    } else {
    }
  } finally {
    // End loading
    loadingStore.setLoading(false)
  }
}

</script>