<template>
  <div class="draw-header">
    <div class="w-full">
      Tickets
    </div>
    <div class="flex items-center space-x-2">
      <button :disabled="loadingStore.isLoading" class="btn btn-sm btn-netlinking" form="userForm" type="submit">
        <LoadingBtn :label="'Envoyer'"/>
      </button>
    </div>
  </div>
  <Form id="userForm" v-slot="{ errors }" :validation-schema="schema_0" @submit="onSubmit_0">
    <div class="py-4 space-y-4 px-10 sm:px-4">
      <div class="grid grid-cols-1 md:grid-cols-1 gap-5">
        <div class="w-full">
          <label for="firstName">Décrire le problème identifié<sup class="text-red-600">*</sup></label>
          <div class="relative">
            <Field
                v-slot="{ field, errors }"
                v-model="ticket.content"
                :class="[errors.content ? 'ring-2 ring-red-600' : '']"
                as="textarea"
                name="content"
                rows="2"
            />
            <ErrorMessage class="error-message" name="content"/>
          </div>
        </div>
      </div>
    </div>
  </Form>
  <div class="py-4 space-y-4 px-10 sm:px-4" v-if="Object.keys(orders).length > 0">
    <div v-if="orders" class="mt-5">
      <h4 class="text-gray-500 mb-3">Historique des tickets</h4>
      <table class="table-simple-border w-full table-sm">
        <thead>
        <tr class="!text-left"
        >
          <th>Date</th>
          <th>Ajouter par</th>
          <th>Description</th>
          <th>Traité par</th>
          <th>Date</th>
          <th>État</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="order in orders">
          <td>
            {{ $filters.dateFormat(order.createdAt, 2) }}
          </td>

          <td>
            {{ order.addBy_firstName }} {{ order.addBy_lastName }}
          </td>
          <td>
            <p v-nl2br="order.content"></p>
          </td>
          <td>
            {{ order.treatedBy_firstName }} {{ order.treatedBy_lastName }}
          </td>
          <td>
            <template v-if="order.treatedDate">
            {{ $filters.dateFormat(order.treatedDate, 2) }}
            </template>
          </td>
          <td>
            <template v-if="order_ticket_states">
              <span :class="order_ticket_states[order.state].class">
              {{ order_ticket_states[order.state].label }}
            </span>
            </template>

          </td>
          <td>
            <button class="btn btn-outline-danger btn-xs"
                    :disabled="loadingStore.isLoading"
                    @click.prevent.stop="removeTicket(order.id)"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-trash"><path d="M3 6h18"/><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"/><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"/></svg>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script setup>
import { computed, defineProps, onMounted, ref } from 'vue'
import { ErrorMessage, Field, Form } from 'vee-validate'
import * as yup from 'yup'
import LoadingBtn from '@/components/common/LoadingBtn.vue'
import { useLoadingStore } from '@/js/stores/loading'
import axios from 'axios'
import { useI18n } from 'vue-i18n'

const path = '/internal/order/traitement/ajax/'
const loadingStore = useLoadingStore()

const { t } = useI18n()
const ticket = ref({
  content: '',
  category: ''
})
const props = defineProps({
  order: {
    type: Object
  },
  order_ticket_states:{
    type:Object
  }
})
const orders = ref({})

onMounted(async() => {
  await fetchTickets()
})

const schema_0 = computed(() => {
  return yup.object().shape({
    content: yup
        .string()
        .required(t('validation.required'))
  })
})
const fetchTickets = async () => {
  try {
    loadingStore.setLoading(true)
    const response = await axios.post(path, {
      action: 'fetch-tickets',
      datas: {
        order_id: props.order.id
      },
    })

    if (response.status === 200) {
      orders.value = response.data.orders
    }
  } catch (error) {
    if (error.response && error.response.status === 500) {
    }
  } finally {
    loadingStore.setLoading(false)
  }
}
const removeTicket = async (id) => {
  try {
    loadingStore.setLoading(true)
    const response = await axios.post(path, {
      action: 'remove-ticket',
      datas: {
        order_id: props.order.id,
        id: id
      },
    })

    if (response.status === 200) {
      orders.value = response.data.orders
    }
  } catch (error) {
    if (error.response && error.response.status === 500) {
    }
  } finally {
    loadingStore.setLoading(false)
  }
}
const addTicket = async () => {
  try {
    loadingStore.setLoading(true)
    const response = await axios.post(path, {
      action: 'add-ticket',
      datas: {
        order_id: props.order.id,
        ticket: ticket.value
      },
    })

    if (response.status === 200) {
      orders.value = response.data.orders
      ticket.value = ''
    }
  } catch (error) {
    if (error.response && error.response.status === 500) {
    }
  } finally {
    loadingStore.setLoading(false)
  }
}
const onSubmit_0 = async (values) => {
  addTicket()
}
</script>