<script setup>
import { ref, onMounted, onUnmounted, defineProps } from 'vue';

const props = defineProps({
  buttonclass: {
    type: String,
    default: "bg-primary text-white"
  },
  buttonlabel: {
    type: String,
    default: "Dropdown Button"
  }
});

const dropdownOpen = ref(false);
const dropdownButtonRef = ref(null);

const toggleDropdown = () => {
  dropdownOpen.value = !dropdownOpen.value;
};

const handleClickOutside = (event) => {
  if (dropdownButtonRef.value && !dropdownButtonRef.value.contains(event.target)) {
    dropdownOpen.value = false;
  }
};

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});
</script>

<template>
            <div class="relative inline-block text-left">
              <button
                  @click="toggleDropdown"
                  ref="dropdownButtonRef"
                  class="flex items-center"
                  :class="buttonclass"
              >
                {{ buttonlabel }}
                <span class="pl-4">
                  <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="fill-current"
                  >
                    <path
                        d="M10 14.25C9.8125 14.25 9.65625 14.1875 9.5 14.0625L2.3125 7C2.03125 6.71875 2.03125 6.28125 2.3125 6C2.59375 5.71875 3.03125 5.71875 3.3125 6L10 12.5312L16.6875 5.9375C16.9688 5.65625 17.4063 5.65625 17.6875 5.9375C17.9687 6.21875 17.9687 6.65625 17.6875 6.9375L10.5 14C10.3437 14.1563 10.1875 14.25 10 14.25Z"
                    />
                  </svg>
                </span>
              </button>
              <div
                  v-show="dropdownOpen"
                  class="absolute right-0 z-50 mt-2 w-auto rounded-md bg-white py-[10px] shadow-lg transition-all"
                  :class="{
        'top-full opacity-100 visible': dropdownOpen,
        'top-[110%] invisible opacity-0': !dropdownOpen
    }"
              >
                <slot :closeDropdown="() => dropdownOpen = false"></slot>
              </div>

            </div>

</template>