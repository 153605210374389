<template>
  <div class="draw-header">
    <div class="w-full">{{user.firstName}} {{user.lastName}}</div>
    <div class="flex items-center space-x-2">

      <button type="submit" form="userForm"  :disabled="loadingStore.isLoading" class="btn btn-sm btn-netlinking">
        <LoadingBtn :label="'Valider'"/>
      </button>
      <button class="btn btn-sm btn-outline-netlinking" @click.prevent.stop="confirmationBeforeDelete = true">
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-trash text-sm"><path d="M3 6h18"/><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"/><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"/></svg>
      </button>
    </div>
  </div>
  <div class="py-4 px-4 sm:px-4">
    <h3 class="mb-2">Information personnelle</h3>
    <Form id="userForm" @submit="onSubmit_0" class="space-y-3" :validation-schema="schema_0" v-slot="{ errors }">
      <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-5">
        <div>
          <div class="relative">
            <Field :placeholder="$t('field_names.firstName')" v-model="formValues.firstName" name="firstName"
                   type="text"
                   :class="[errors.firstName ? 'ring-2 ring-red-600' : '']"/>
            <ErrorMessage name="firstName" class="error-message"/>
          </div>
        </div>
        <div>
          <div class="relative">
            <Field :placeholder="$t('field_names.lastName')" v-model="formValues.lastName" name="lastName" type="text"
                   :class="[errors.lastName ? 'ring-2 ring-red-600' : '']"/>
            <ErrorMessage name="lastName" class="error-message"/>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-5">
        <div>
          <div class="relative">
            <Field :placeholder="$t('field_names.email')" v-model="formValues.email" name="email" type="text"
                   :class="[errors.email ? 'ring-2 ring-red-600' : '']"/>
            <ErrorMessage name="email" class="error-message"/>
          </div>
        </div>
      </div>
    </Form>
    <h3 class="mt-4">Rôle</h3>
    <fieldset aria-label="Privacy setting" class="space-y-2 mt-2 rounded-md bg-white">
      <label v-for="setting in roles_customer" :key="setting.label" :value="setting.value" :aria-label="setting.name"
             :aria-description="setting.description"
             class="group flex cursor-pointer border-2 border-gray-200 p-4 first:rounded-tl-md first:rounded-tr-md last:rounded-bl-md last:rounded-br-md focus:outline-none has-[:checked]:relative has-[:checked]:border-2 has-[:checked]:border-netlinking has-[:checked]:bg-netlinking/5">
        <input v-model="selectedRole" name="privacy-setting" :value="setting.value" type="radio"
               :checked="setting.selected"
               class="hidden relative mt-0.5 size-4 shrink-0 appearance-none rounded-full border-2 border-gray-300 bg-white before:absolute before:inset-1 before:rounded-full before:bg-white checked:border-netlinking checked:bg-netlinking focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400 forced-colors:appearance-auto forced-colors:before:hidden [&:not(:checked)]:before:hidden"/>
        <span class="ml-3 flex flex-col">
        <span class="block text-lg font-medium text-netlinking group-has-[:checked]:text-netlinking">{{
            setting.label
          }}</span>
        <span class="block text-sm text-gray-500 ">{{ setting.description }}</span>
      </span>
      </label>
    </fieldset>
    <h3 class="mt-4 mb-2">Accès et actions</h3>
    <Transition name="drawer">
      <div class="card-gray" v-if="selectedRole && users_roles_explication[selectedRole]">
        <h3 class="text-netlinking">{{ users_roles_explication[selectedRole]['label'] }}</h3>
        <p class="text-sm mb-3">Accès et actions</p>
        <div class="text-sm">
          <p class="flex items-center space-x-2" v-for="p in users_roles_explication[selectedRole]['+']">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none"
                 stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                 class="text-netlinking lucide lucide-check">
              <path d="M20 6 9 17l-5-5"/>
            </svg>
            <span>{{ p }}</span>
          </p>
        </div>
        <div class="text-sm border-t border-gray-200 pt-3 mt-3"
             v-if="Object.keys(users_roles_explication[selectedRole]['-']).length > 0">
          <p class="flex items-center space-x-2" v-for="p in users_roles_explication[selectedRole]['-']">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none"
                 stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                 class="lucide lucide-x text-dark">
              <path d="M18 6 6 18"/>
              <path d="m6 6 12 12"/>
            </svg>
            <span>{{ p }}</span>
          </p>
        </div>
      </div>
    </Transition>
  </div>


  <Transition name="drawer">
    <Modal
        v-model="confirmationBeforeDelete"
        max-width="500px"
        @close="confirmationBeforeDelete = false"
    >
      <!-- Custom header -->
      <template #header>
        Supprimer les accès de : {{user.firstName}} {{user.lastName}} ?
      </template>
      <p class="text-netlinking font-bold mb-2">Remarque</p>
      <p class="mb-1">
        Attention, en supprimant ses accès :
      </p>
      <ul class="pl-5 list-disc">
        <li>vous ne pourrez plus restaurer ce compte</li>
        <li>tous les projets et campagnes de ce compte seront automatiquement affecté au compte mère (Super Admin)</li>
      </ul>
      <div class="text-right mt-7">
        <button :disabled="loadingStore.isLoading" @click.prevent.stop="confirmationBeforeDelete = false" class="btn btn-sm btn-outline-dark">
          <LoadingBtn :label="'Annuler'"/>
        </button>
        <button :disabled="loadingStore.isLoading" @click.prevent.stop="remove_user()" class="btn btn-sm btn-danger ml-3">
          <LoadingBtn :label="'Supprimer'"/>
        </button>
      </div>
      <!-- Default slot (content) -->

    </Modal>
  </Transition>
</template>
<script setup>
import { computed, defineProps, onMounted, ref } from 'vue'
import Modal from '@/components/common/Modal.vue'
const emit = defineEmits(['all-ok','close-model'])
import { useI18n } from 'vue-i18n'
import { useLoadingStore } from '@/js/stores/loading'
import { useLocaleStore } from '@/js/stores/locale'
import LoadingBtn from '@/components/common/LoadingBtn.vue'
import * as yup from 'yup'
import { ErrorMessage, Field, Form } from 'vee-validate'
import axios from 'axios'
const path = '/customer/ajax/'
const { t } = useI18n()
const localeStore = useLocaleStore()
const loadingStore = useLoadingStore()
const currentLocale = localeStore.currentLocale
const confirmationBeforeDelete = ref(false)
const selectedRole = ref(null)
const props = defineProps({
  users_roles_explication: {
    type: Object,
    required: true
  },
  roles_customer: {
    type: Object,
    required: true
  },
  user: {
    type: Object,
    required: true,
    default: { email: '--', firstName: '--', lastName: '--',roles:'--' }
  }
})
const formValues = ref({
  email: props.user.email,
  firstName: props.user.firstName,
  lastName: props.user.lastName,
  id: props.user.id
})
onMounted(() => {
  formValues.value.email = props.user.email
  formValues.value.firstName = props.user.firstName
  formValues.value.lastName = props.user.lastName
  formValues.value.id = props.user.id
  selectedRole.value = props.roles_customer[props.user.roles[0]]['value']
})

const schema_0 = computed(() => {
  return yup.object().shape({
    email: yup
        .string()
        .required(t('validation.required'))
        .email(t('validation.invalid_email_format')),
    firstName: yup
        .string()
        .required(t('validation.required')),
    lastName: yup
        .string()
        .required(t('validation.required'))
  })
})

const remove_user = async() =>{
  try {
    loadingStore.setLoadingPage(true)
    const response = await axios.post(path, {
      action: 'remove-invitedUser',
      datas: {
        id: props.user.id
      },
    })

    if (response.status === 200) {
      window.location.reload();
    }
  } catch (error) {
    if (error.response && error.response.status === 500) {
    }
  } finally {
    loadingStore.setLoadingPage(false)
  }
}
const onSubmit_0 = async (values) => {
  try {
    loadingStore.setLoading(true)

    const response = await axios.post(path, {
      action: 'edit-invitedUser',
      datas: {
        values: formValues.value,code: selectedRole.value
      },
    })

    if (response.status === 200) {
      emit('all-ok',{})
      window.location.reload();
    }
  } catch (error) {
    if (error.response && error.response.status === 500) {
    }
  } finally {
    loadingStore.setLoading(false)
  }
}
const closeModal = () => {
  emit('close-model')
}
</script>