<template>
  <div v-if="edit == false" class="bg-netlinking/5 -mx-[40px] -my-[15px] pt-[16px] pb-[30px] px-[30px] xl:px-[40px]">
    <h1 class="title-simple">Trouvez des liens pertinents en quelques clics ! 🕵️‍♂️</h1>
    <Form v-if="!isModalOpen" v-slot="{ errors }" :validation-schema="schema_0" class="space-y-3" @submit="onSubmit_0">
      <div class="block lg:flex lg:space-x-4 space-y-4 lg:space-y-0 mt-4">
        <div class="w-full">
          <label for="">Renseigner une ou plusieurs URL(s)</label>
            <div class="w-full">
              <Field
                  v-model="campaign.urls"
                  :class="[errors.urls ? 'ring-2 ring-red-600' : '']"
                  :name="'urls'"
                  :placeholder="'Ex. https://www.leseopourtous.fr'"
                  class="flex-1 bg-white"
                  :rows="campaign.urls ? campaign.urls.split('\n').length : 1"
                  as="textarea"
              ></Field>
              <ErrorMessage :name="'urls'" class="error-message"/>
            </div>
        </div>
        <div class="w-full lg:max-w-[300px]">
          <label for="">Nom de votre campagne</label>
          <Field
              v-model="campaign.name"
              :placeholder="'Ex. Le SEO pour tous'"
              class="bg-white"
              name="name"
              type="text"
          ></Field>
          <ErrorMessage class="error-message" name="name"/>
        </div>
        <div class="w-full lg:max-w-[150px]">
          <label for="">Langue</label>
          <Field v-model="campaign.language" as="select" class="bg-white" name="language">
            <option :value="''" disabled>{{ $t('select') }}</option>
            <option v-for="language in languages" :key="language.code" :value="language.code">
              {{ $t(language.label) }}
            </option>
          </Field>
          <ErrorMessage class="error-message" name="language"/>
        </div>
        <div class="w-full lg:max-w-[300px] pt-8">
          <!--          @click.prevent.stop="isModalOpen = true"-->
          <button class="btn btn-dark-netlinking-2 btn-sm w-full btn-rounded" type="submit"

          >
            Créer la campagne
          </button>
        </div>
      </div>
    </Form>
  </div>

  <Modalcampaign
      v-model="isModalOpen"
      max-width="max-w-[80%]"
      @close="handleclose"
  >
    <template #campaign>
      <div class="block lg:flex lg:items-start justify-between lg:space-x-6 space-x-0 text-sm">
        <!-- Left section -->
        <div class="block lg:flex lg:space-x-8 lg:space-y-0 space-y-4 lg:max-w-[700px]">
          <div>
            <label class="flex items-center justify-between space-x-2"><span>Choisissez une durée d'exclusivité</span>
              <Info message="This is a helpful tooltip message!" tooltipclass="netlinking"/>
            </label>
            <div class="flex space-x-5 items-center">
              <span class="text-xs">0</span>
              <div class="w-full">
                <vue3-slider v-model="campaign.exclusive_duration" :alwaysShowHandle="true" :alwaysShowTooltip="true"
                             :flipTooltip="true" :handleScale="2.2" :height="10"
                             :max="10" :min="0" :step="1"
                             :tooltip="'always'" :tooltipColor="'#000'" :tooltipTextColor="'#FFF'"
                             color="#000" track-color="#FEFEFE"/>
              </div>
              <span class="text-xs">10</span>
            </div>
          </div>
          <div>
            <label
                class="flex items-center justify-between space-x-2"><span>Lissez vos publications dans le temps</span>
              <Info message="This is a helpful tooltip message!" tooltipclass="netlinking"/>
            </label>
            <div class="flex space-x-5 items-center">
              <span class="text-xs">0</span>
              <div class="w-full">
                <vue3-slider v-model="campaign.spread_posts" :alwaysShowHandle="true" :alwaysShowTooltip="true"
                             :flipTooltip="true" :handleScale="2.2" :height="10"
                             :max="3" :min="0" :step="1"
                             :tooltip="'always'" :tooltipColor="'#000'" :tooltipTextColor="'#FFF'" color="#000"
                             track-color="#FEFEFE"/>
              </div>
              <span class="text-xs w-[55px]">3 mois</span>
            </div>
          </div>
        </div>

        <!-- Right section -->
        <div class="block lg:flex lg:space-x-4 space-x-0 lg:space-y-0 space-y-4 items-center lg:mt-[13px] mt-[20px]">
          <div class="flex space-x-2 items-center">
            <label class="flex mb-0">
              <svg class="lucide lucide-shopping-cart text-netlinking" fill="none" height="18" stroke="currentColor"
                   stroke-linecap="round"
                   stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="18"
                   xmlns="http://www.w3.org/2000/svg">
                <circle cx="8" cy="21" r="1"/>
                <circle cx="19" cy="21" r="1"/>
                <path d="M2.05 2.05h2l2.66 12.42a2 2 0 0 0 2 1.58h9.78a2 2 0 0 0 1.95-1.57l1.65-7.43H5.12"/>
              </svg>
              <span class="pl-2">Nb de liens :</span>
            </label>
            <div :class="{ 'animate-pulse text-netlinking scale-120': isAmountUpdated }"
                 class="text-lg font-bold transition-transform duration-500 ease-in-out">
              <span>{{ campaign.number_links }}</span>
            </div>
          </div>
          <div class="flex space-x-2 items-center">
            <label class="mb-0">Montant :</label>
            <div :class="{ 'animate-pulse text-netlinking scale-130': isAmountUpdated }"
                 class="text-lg font-bold transition-transform duration-500 ease-in-out">
              <span>{{ campaign.amount }} €</span>
            </div>
          </div>
          <div>
            <button :disabled="campaign.amount == 0" @click.prevent.stop="saveOrder" class="btn btn-sm btn-white-netlinking btn-rounded !pr-[30px] !pl-[30px]">Commander</button>
          </div>
          <div class="relative">
            <button class="btn btn-sm link-white" @click.prevent.stop="saveDraft">
              <span class="flex space-x-2">
                <svg class="lucide lucide-save" fill="none" height="18" stroke="currentColor" stroke-linecap="round"
                     stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="18"
                     xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M15.2 3a2 2 0 0 1 1.4.6l3.8 3.8a2 2 0 0 1 .6 1.4V19a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2z"/>
                <path d="M17 21v-7a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1v7"/>
                <path d="M7 3v4a1 1 0 0 0 1 1h7"/>
              </svg>
              <span>Enregistrer mon brouillon & fermer</span>
              </span>

            </button>
            <div v-if="edit" class="absolute top-[-34px] right-[-18px]">
              <a href="#" @click.prevent.stop="handleclose" class="link link-dark">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle-x"><circle cx="12" cy="12" r="10"/><path d="m15 9-6 6"/><path d="m9 9 6 6"/></svg>
              </a>
            </div>
          </div>
        </div>
      </div>

    </template>
    <div>
      <h2>Campagne : {{ campaign.name }}</h2>
      <p class="mb-0">
        Vous trouverez ci-dessous la liste des URLs renseignées ainsi que leurs principales métriques.
      </p>
      <p>
        Pour trouver des pages positionnées les plus pertinentes pour faire des liens vers chacune de vos URLs, nous
        vous
        invitons à cliquer sur le bouton « Rechercher des BL ».
      </p>

      <div v-if="!showFilter"
           class="mb-4 mt-2 block lg:flex space-x-0 lg:space-x-4 space-y-4 lg:space-y-0 items-center">
        <button class="link-dark" @click.prevent.stop="showFilter=true">
        <span class="flex items-center">
          <svg class="lucide lucide-chevron-right" fill="none" height="18" stroke="currentColor" stroke-linecap="round"
               stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="18"
               xmlns="http://www.w3.org/2000/svg"><path d="m9 18 6-6-6-6"/></svg>
          <span>Afficher les filtres</span>
        </span>
        </button>
      </div>

      <div v-else class="mb-4 mt-2 block lg:flex space-x-0 lg:space-x-4 space-y-4 lg:space-y-0 items-center">

        <span class="pr-3 whitespace-nowrap">FILTRER VOS RECHERCHES</span>
        <div class="flex items-center space-x-2 min-w-[300px]">
          <label class="mb-0 whitespace-nowrap" for="urlFilter">URL contient</label>
          <input
              id="urlFilter"
              v-model="filterCriteria.urlContains"
              placeholder="Ex. banquezitouna"
              type="text"
          />
        </div>
        <div class="flex items-center space-x-2 min-w-[300px]">
          <label class="mb-0 whitespace-nowrap" for="trustFlowLabelFilter">TTF</label>
          <Selectn
              v-model="filterCriteria.trustFlowLabel"
              :options="ttfs"
              placeholder="-- Sélectionnez un TTF --"
          />
        </div>
        <div>
          <label class="flex items-center cursor-pointer select-none text-dark mb-0">
            <div class="relative">
              <!-- Hidden Checkbox -->
              <input
                  v-model="filterCriteria.checkedOnly"
                  class="sr-only peer"
                  type="checkbox"
              />
              <!-- Switch Background -->
              <div
                  class="block h-8 w-14 rounded-full bg-gray-300 peer-checked:bg-netlinking transition-colors duration-300"
              ></div>
              <!-- Switch Handle -->
              <div
                  class="absolute left-1 top-1 w-6 h-6 bg-white rounded-full transition-transform duration-300 peer-checked:translate-x-full "
              ></div>
            </div>
            <span class="inline-block w-[200px] ml-2">Affichez les URLs cochées</span>
          </label>

        </div>
        <div class="w-full items-end text-right">
          <button class="link-dark" @click.prevent.stop="showFilter=false">
            <span class="flex items-center">
            <svg class="lucide lucide-x" fill="none" height="18" stroke="currentColor" stroke-linecap="round"
                 stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="18"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M18 6 6 18"/>
              <path d="m6 6 12 12"/>
            </svg>
            <span>Masquer les filtres</span>
            </span>
          </button>
        </div>
      </div>

      <div class="max-w-full">
        <table ref="tableContainer" class="w-full table-striped">
          <thead>
          <tr>
            <th colspan="8"></th>
            <th colspan="2" class="!text-center uppercase" v-if="!edit">
              Vos backlinks
            </th>
            <th  class="!text-center uppercase"  v-if="edit">
              Commandes passées
            </th>
            <th colspan="2" class="!text-center uppercase" v-if="edit">
              Commandes en cours
            </th>
            <th></th>
          </tr>
          <tr>
            <th></th>
            <th @click="setSortKey('url')">
      <span class="flex items-center cursor-pointer link-blue-simple">
        Vos URLs cibles
        <span v-if="sortConfig.key === 'url'" class="ml-2">
          {{ sortConfig.direction === 'asc' ? '▲' : '▼' }}
        </span>
      </span>
            </th>
            <th @click="setSortKey('traffic')">
      <span class="flex items-center cursor-pointer link-blue-simple">
        Trafic
        <span v-if="sortConfig.key === 'traffic'" class="ml-2">
          {{ sortConfig.direction === 'asc' ? '▲' : '▼' }}
        </span>
      </span>
            </th>
            <th @click="setSortKey('keywords')">
      <span class="flex items-center cursor-pointer link-blue-simple">
        MC
        <span v-if="sortConfig.key === 'keywords'" class="ml-2">
          {{ sortConfig.direction === 'asc' ? '▲' : '▼' }}
        </span>
      </span>
            </th>
            <th @click="setSortKey('referringDomains')">
      <span class="flex items-center cursor-pointer link-blue-simple">
        DR
        <span v-if="sortConfig.key === 'referringDomains'" class="ml-2">
          {{ sortConfig.direction === 'asc' ? '▲' : '▼' }}
        </span>
      </span>
            </th>
            <th @click="setSortKey('citationFlow')">
      <span class="flex items-center cursor-pointer link-blue-simple">
        CF
        <span v-if="sortConfig.key === 'citationFlow'" class="ml-2">
          {{ sortConfig.direction === 'asc' ? '▲' : '▼' }}
        </span>
      </span>
            </th>
            <th @click="setSortKey('trustFlow')">
      <span class="flex items-center cursor-pointer link-blue-simple">
        TF
        <span v-if="sortConfig.key === 'trustFlow'" class="ml-2">
          {{ sortConfig.direction === 'asc' ? '▲' : '▼' }}
        </span>
      </span>
            </th>
            <th @click="setSortKey('trustFlowLabel')">
      <span class="flex items-center cursor-pointer link-blue-simple">
        TTF
        <span v-if="sortConfig.key === 'trustFlowLabel'" class="ml-2">
          {{ sortConfig.direction === 'asc' ? '▲' : '▼' }}
        </span>
      </span>
            </th>
            <th class="!text-center w-[100px]  !text-xs" v-if="!edit">
              Nb
            </th>
            <th class="!text-center w-[100px] !text-xs" v-if="!edit">
             Tarif
            </th>
            <th class="!text-center w-[170px]" v-if="edit">
              <span class="text-netlinking text-xs">Livrées</span>
              <span class="mx-2">/</span>
              <span class="text-green-600 text-xs">En cours</span>
            </th>
            <th class="!text-center w-[100px] !text-xs"  v-if="edit">
              Nb de BL
            </th>
            <th class="!text-center w-[100px] !text-xs"  v-if="edit">
              Tarif
            </th>

            <th></th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(data,index) in filteredDatas">
            <tr>
              <td class="w-[10px]">
                <label class="flex items-center cursor-pointer select-none text-dark mb-0">
                  <div class="relative">
                    <input
                        v-model="data.checked"
                        class="sr-only"
                        type="checkbox"
                    />
                    <div class="box mr-4 flex h-5 w-5 items-center justify-center rounded border border-stroke">
      <span class="opacity-0">
        <svg
            fill="none"
            height="8"
            viewBox="0 0 11 8"
            width="11"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path
              d="M10.0915 0.951972L10.0867 0.946075L10.0813 0.940568C9.90076 0.753564 9.61034 0.753146 9.42927 0.939309L4.16201 6.22962L1.58507 3.63469C1.40401 3.44841 1.11351 3.44879 0.932892 3.63584C0.755703 3.81933 0.755703 4.10875 0.932892 4.29224L0.932878 4.29225L0.934851 4.29424L3.58046 6.95832C3.73676 7.11955 3.94983 7.2 4.1473 7.2C4.36196 7.2 4.55963 7.11773 4.71406 6.9584L10.0468 1.60234C10.2436 1.4199 10.2421 1.1339 10.0915 0.951972ZM4.2327 6.30081L4.2317 6.2998C4.23206 6.30015 4.23237 6.30049 4.23269 6.30082L4.2327 6.30081Z"
              fill="#C06"
              stroke="#C06"
              stroke-width="1"
          ></path>
        </svg>
      </span>
                    </div>
                  </div>
                </label>

              </td>
              <td class="!whitespace-normal">{{ data.url }}</td>
              <td>{{ $filters.numberFormatIntl(data.traffic) }}</td>
              <td>{{ $filters.numberFormatIntl(data.keywords) }}</td>
              <td>{{ $filters.numberFormatIntl(data.referringDomains) }}</td>
              <td>{{ data.citationFlow }}</td>
              <td>
            <span
                v-if="data.trustFlow"
                v-tippy="{ allowHTML: true,content: data.trustFlowRepresentation,theme:'tooltip-ttfs'}"
                :style="{'background': data.trustFlowColor}"
                class="w-[35px] block text-center font-bold px-2 py-1 rounded text-white">
              {{ data.trustFlow }}
            </span>
              </td>
              <td><span class="text-blue-400">{{ data.trustFlowLabel }}</span></td>
              <td class="!text-center"  v-if="!edit">
                {{ computedOrderStats[index]?.totalOrders || 0 }}
              </td>
      <td class="!text-center"  v-if="!edit">
        {{ computedOrderStats[index]?.totalAmount }}€
      </td>
              <td class="!text-center" v-if="edit">
                <span class="text-netlinking text-xs">{{ data.delivered_orders || 0 }}</span>
                <span class="mx-2">/</span>
                <span class="text-green-600 text-xs">{{ data.in_progress_orders || 0 }}</span>
              </td>
              <td class="!text-center" v-if="edit">{{ data.count_backlinks || 0 }}</td>
              <td class="!text-center" v-if="edit">{{ data.sum_pricing || 0 }}€</td>

              <td class="w-[100px]">
                <div class="flex items-center space-x-2">
                  <button :disabled="loadingStore.isLoading" class="btn btn-sm btn-dark-netlinking-2" @click.prevent.stop="data.bl = true">
                    Rechercher des BL
                  </button>
                  <button v-if="Object.keys(filteredDatas).length > 1" class="btn btn-sm btn-outline-dark-no-border"
                          @click.prevent.stop="removeFromurls(index)">
                    <svg class="lucide lucide-trash-2" fill="none" height="18" stroke="currentColor"
                         stroke-linecap="round"
                         stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="18"
                         xmlns="http://www.w3.org/2000/svg">
                      <path d="M3 6h18"/>
                      <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"/>
                      <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"/>
                      <line x1="10" x2="10" y1="11" y2="17"/>
                      <line x1="14" x2="14" y1="11" y2="17"/>
                    </svg>
                  </button>
                </div>

              </td>
            </tr>
            <tr v-if="data.bl">
              <td colspan="30">
                <Searchbl :campaign="campaign" :orders="orders" :ttfs="ttfs" :url="data.url"
                          @order="traitementOrder" @rorder="rtraitementOrder">
                  <button class="btn btn-xs btn-outline-dark flex items-center space-x-2"
                          @click.prevent.stop="data.bl = false"
                  >
                    <svg class="lucide lucide-move-up" fill="none" height="18" stroke="currentColor"
                         stroke-linecap="round"
                         stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="18"
                         xmlns="http://www.w3.org/2000/svg">
                      <path d="M8 6L12 2L16 6"/>
                      <path d="M12 2V22"/>
                    </svg>
                    <span>Masquer les suggestions</span>
                  </button>
                </Searchbl>
              </td>
            </tr>
          </template>
          <tr v-if="pushUrlModal">
            <td colspan="30">
              <div class="block px-4 py-2 relative">
                <div class="absolute top-0 -right-[8px] cursor-pointer text-gray-400"
                     @click.prevent.stop="pushUrlModal = false">
                  <svg class="lucide lucide-x" fill="none" height="24" stroke="currentColor" stroke-linecap="round"
                       stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24"
                       xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 6 6 18"/>
                    <path d="m6 6 12 12"/>
                  </svg>
                </div>
                <textarea
                    class="mb-2 w-full bg-white"
                    placeholder="Entrez une ou plusieurs URLs, séparées par des retours à la ligne"
                    rows="3"
                ></textarea>
                <div class="flex items-end">
                  <button class="btn btn-sm btn-dark flex space-x-2" @click.prevent.stop="addUrls()">
                    <svg
                        class="lucide lucide-plus"
                        fill="none"
                        height="18"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                        width="18"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M5 12h14"/>
                      <path d="M12 5v14"/>
                    </svg>
                    <span>Ajouter</span>
                  </button>
                </div>

              </div>

            </td>
          </tr>

          </tbody>
        </table>
      </div>
      <p class="mt-3 mb-3">
        <button
            class="btn btn-sm btn-outline-dark-no-border flex space-x-2"
            @click="pushUrlModal = true"
        >
          <svg
              class="lucide lucide-plus"
              fill="none"
              height="18"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              viewBox="0 0 24 24"
              width="18"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M5 12h14"/>
            <path d="M12 5v14"/>
          </svg>
          <span>Ajouter une ou plusieurs URLs manuellement</span>
        </button>
      </p>

      <Suggestion :campaign="campaign" :ttfs="ttfs" @add="addSuggestion"/>
    </div>
  </Modalcampaign>
</template>
<script setup>
import { computed, defineProps, onMounted, reactive, ref, watch, nextTick } from 'vue'
import { useLocaleStore } from '@/js/stores/locale'
import { useLoadingStore } from '@/js/stores/loading'
import axios from 'axios'
import Modalcampaign from '@/components/customer/campaign/Modalcampaign.vue'
import Suggestion from '@/components/customer/campaign/block/Suggestion.vue'
import Info from '@/components/common/Info.vue'
import { useSorting } from '@/js/common/sorting' // Adjust path to sorting.js
import { useI18n } from 'vue-i18n'
import { ErrorMessage, Field, Form } from 'vee-validate'
import * as yup from 'yup'
import Selectn from '@/components/common/Selectn.vue'
import Searchbl from '@/components/customer/campaign/block/Searchbl.vue'

const { sortConfig, setSortKey, sortedData } = useSorting()
const path = '/campaign/ajax/'
const localeStore = useLocaleStore()
const loadingStore = useLoadingStore()
const currentLocale = localeStore.currentLocale

const { t } = useI18n()
const showFilter = ref(false)
const isModalOpen = ref(false)
const pushUrlModal = ref(false)
const isAmountUpdated = ref(false)
const tableContainer = ref(null);
const orders = ref([])
const filterCriteria = reactive({
  checkedOnly: false,
  trustFlowLabel: '',
  urlContains: ''
})
const emit = defineEmits(['handleclose'])

const handleclose = () => {
  emit('handleclose')
}
const filteredDatas = computed(() => {
  let data = campaign.datas.filter((data) => {
    const matchesChecked = filterCriteria.checkedOnly ? data.checked : true
    const matchesTrustFlowLabel = filterCriteria.trustFlowLabel
        ? data.trustFlowLabel == filterCriteria.trustFlowLabel
        : true
    const matchesUrl = filterCriteria.urlContains
        ? data.url.toLowerCase().includes(filterCriteria.urlContains.toLowerCase())
        : true

    return matchesChecked && matchesTrustFlowLabel && matchesUrl
  })

  return sortedData(data)
})
const campaign = reactive({
  id: null,
  exclusive_duration: 1,
  spread_posts: 0,
  number_links: 0,
  amount: 0,
  state: 'draft',
  name: '',
  language: 'fr',
  urls: '', // Initialize with an empty URL field
  datas: []
})
const props = defineProps({
  languages: {
    type: Object,
    required: true
  },
  ttfs: {
    type: Object,
    required: true
  },
  edit: {
    type: Boolean,
    default: false
  },
  campaign: {
    type: Object,
    default: {}
  }
})
const getUrlDestinationInfo = async(url,index)=>{
  try {
    // loadingStore.setLoadingPage(true)
    const response = await axios.post(path, {
      action: 'url-destination-infos',
      datas: { urlDestination: url,campaign_id:campaign.id },
    })
    // Check response status
    if (response.status === 200) {
      campaign.datas.splice(index, 1, {
        ...campaign.datas[index],
        sum_pricing: response.data.infos.sum_pricing,
        count_backlinks: response.data.infos.count_backlinks,
        delivered_orders: response.data.infos.delivered_orders,
        in_progress_orders: response.data.infos.in_progress_orders,
      });
    }
  } catch (error) {
    // Handle errors
    if (error.response && error.response.status === 500) {
    } else {
    }
  } finally {
    // End loading
    // loadingStore.setLoadingPage(false)
  }
}

onMounted(async () => {
  if (props.edit) {
    isModalOpen.value = true
    campaign.id = props.campaign.id
    campaign.number_links = props.campaign.numberLinks
    campaign.amount = props.campaign.amount
    campaign.exclusive_duration = props.campaign.exclusiveDuration
    campaign.spread_posts = props.campaign.spreadPosts
    campaign.state = props.campaign.state
    campaign.name = props.campaign.name
    campaign.language = props.campaign.language
    campaign.urls = props.campaign.urls
    campaign.datas = Object.values(props.campaign.datas)
    orders.value = props.campaign.ordersDraft || []
    campaign.datas.forEach((data,index) => {
      getUrlDestinationInfo(data.url,index)
    })
  }
})
const getDomain = (url) => {
  try {
    const { hostname } = new URL(url)
    return hostname.replace(/^www\./, '') // Remove "www." prefix for consistency
  } catch {
    return null // Return null if the URL is invalid
  }
}
const schema_0 = computed(() => {
  return yup.object().shape({
    urls: yup
        .string()
        .required(t('validation.required')) // Vérifie que le champ n'est pas vide
        .test('valid-urls', t('validation.invalid_urls_format'), (value) => {
          if (!value) return false; // Champ requis
          const urls = value.split('\n').map((url) => url.trim()).filter(Boolean);
          return urls.every((url) => yup.string().url().isValidSync(url)); // Vérifie la validité de chaque URL
        })
        .test('unique-urls', t('validation.unique_urls'), (value) => {
          if (!value) return false;
          const urls = value.split('\n').map((url) => url.trim()).filter(Boolean);
          return new Set(urls).size === urls.length; // Vérifie l'unicité des URLs
        })
        .test('same-domain', t('validation.same_domain'), (value) => {
          if (!value) return false;
          const urls = value.split('\n').map((url) => url.trim()).filter(Boolean);
          if (urls.length < 2) return true; // Si une seule URL, pas besoin de vérifier
          const domains = urls.map(getDomain).filter(Boolean);
          return domains.every((domain) => domain === domains[0]); // Vérifie que tous les domaines sont identiques
        }),
    name: yup.string().nullable(),
    language: yup.string().required(t('validation.required')),
  });
});
// const addUrl = () => {
//   campaign.urls.push('')
// }
//
// const removeUrl = (index) => {
//   campaign.urls.splice(index, 1)
// }

const onSubmit_0 = async (values) => {
  try {
    campaign.urls = campaign.urls.split('\n')
    if(campaign.name == '' || !campaign.name){
      campaign.name = getDomain(campaign.urls[0])
    }
    // Start loading
    loadingStore.setLoadingPage(true)
    // Send the request
    const response = await axios.post(path, {
      action: 'urls-stats',
      datas: { campaign: campaign },
    })
    // Check response status
    console.log(response.status)
    if (response.status === 200) {
      isModalOpen.value = true
      campaign.datas = response.data.datas
    }
  } catch (error) {
    // Handle errors
    if (error.response && error.response.status === 500) {
    } else {
    }
  } finally {
    // End loading
    loadingStore.setLoadingPage(false)
  }

}

const removeFromurls = (index) => {
  campaign.datas.splice(index, 1)
}
const addUrls = async () => {
  // Example text area content: multiple URLs separated by newline
  const textarea = document.querySelector('textarea')
  const newUrlsInput = textarea.value

  if (!newUrlsInput) return

  // Split the input into individual URLs
  const newUrls = newUrlsInput.split(/\r?\n/).map(url => url.trim()).filter(url => url)

  if (newUrls.length === 0) return

  // Get the domains of existing filtered data
  const existingDomains = new Set(
      filteredDatas.value.map(data => getDomain(data.url)).filter(Boolean)
  )

  // Get the existing URLs
  const existingUrls = new Set(
      filteredDatas.value.map(data => data.url)
  )

  // Validate and filter new URLs
  const validUrls = newUrls.filter(url => {
    const domain = getDomain(url)

    // Check if the URL's domain matches any existing domain
    if (!domain || !existingDomains.has(domain)) {
      console.warn(`URL “${url}” does not match any existing domains or is invalid.`)
      return false
    }

    // Check if the URL already exists
    if (existingUrls.has(url)) {
      console.warn(`URL “${url}” already exists in the filtered data.`)
      return false
    }

    return true
  })

  // Add valid URLs to the campaign's filtered data
  for (const url of validUrls) {
    await pageUpdateData(url)
  }

  console.log(`Added ${validUrls.length} URLs to the filtered data.`)

  // Clear the textarea after processing
  textarea.value = ''
  pushUrlModal.value = false
}

const pageUpdateData = async (page) => {
  try {
    // Start loading
    loadingStore.setLoadingPage(true)
    // Send the request
    const response = await axios.post(path, {
      action: 'urls-add',
      datas: { campaign: campaign, page: page },
    })
    if (response.status === 200) {
      campaign.datas.push(response.data.pageData)
    }
  } catch (error) {
    // Handle errors
    if (error.response && error.response.status === 500) {
    } else {
    }
  } finally {
    // End loading
    loadingStore.setLoadingPage(false)
  }
}

const addSuggestion = async (value) => {
  // Check if the URL or domain already exists
  const existingUrls = new Set(campaign.datas.map((data) => data.url))
  if (!existingUrls.has(value.url)) {
    // Add the suggestion if it's not a duplicate
    campaign.datas.push(value)
    console.log(`Suggestion URL added: ${value.url}`)
  } else {
    console.warn(`Suggestion URL already exists: ${value.url}`)
  }
}

const traitementOrder = async (data) => {
  const exists = orders.value.some(order => order.page_id === data.page.page_id)
  if (!exists) {
    orders.value.push(data.page)
  }
}
const rtraitementOrder = async (data) => {
  orders.value = orders.value.filter(order => order.page_id !== data.page.page_id)
}
const updateAmount = () => {
  let totalAmount = orders.value.reduce((total, order) => total + (order.price || 0), 0)

  if (campaign.exclusive_duration === 0) {
    totalAmount = Math.round(totalAmount*0.8)
  } else if (campaign.exclusive_duration > 1) {
    totalAmount = Math.round(totalAmount * (1 + (0.1 * (campaign.exclusive_duration-1)))) // Apply percentage increase
  }
  campaign.amount = parseFloat(totalAmount) // Ensure proper decimal formatting
  campaign.number_links = Object.keys(orders.value).length
  // Trigger animation
  isAmountUpdated.value = true
  setTimeout(() => {
    isAmountUpdated.value = false
  }, 2000)
}
watch(orders, updateAmount, { deep: true })
watch(() => campaign.exclusive_duration, updateAmount)
const computedOrderStats = computed(() => {
  return filteredDatas.value.map((data) => {
    const matchedOrders = orders.value.filter(order => order.url_destination === data.url)
    const totalOrders = matchedOrders.length
    const totalAmount = matchedOrders.reduce((sum, order) => sum + (order.price || 0), 0)

    return {
      url: data.url,
      totalOrders,
      totalAmount
    }
  })
})
const saveOrder = async () => {
  try {
    // Start loading
    loadingStore.setLoadingPage(true)
    // Send the request
    const response = await axios.post(path, {
      action: 'save-order',
      datas: { campaign: campaign, orders: orders.value },
    })
    if (response.status === 200) {
      Object.assign(campaign, response.data.campaign)
      orders.value = response.data.orders
      window.location.href = response.data.redirect
    }
  } catch (error) {
    // Handle errors
    if (error.response && error.response.status === 500) {
    } else {
    }
  } finally {
    // End loading
    loadingStore.setLoadingPage(false)
  }
}
const saveDraft = async () => {
  try {
    // Start loading
    loadingStore.setLoadingPage(true)
    // Send the request
    const response = await axios.post(path, {
      action: 'save-draft',
      datas: { campaign: campaign, orders: orders.value },
    })
    if (response.status === 200) {
      Object.assign(campaign, response.data.campaign)
      orders.value = response.data.orders
      window.location.href=response.data.redirect
    }
  } catch (error) {
    // Handle errors
    if (error.response && error.response.status === 500) {
    } else {
    }
  } finally {
    // End loading
    loadingStore.setLoadingPage(false)
  }

}
</script>