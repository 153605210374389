<template>

    <p v-if="!toggleSuggestion">
      <button class="btn btn-dark-netlinking btn-xs flex space-x-2" @click.prevent.stop="getSuggestions()">
        <svg class="lucide lucide-wand-sparkles" fill="none" height="18" stroke="currentColor" stroke-linecap="round"
             stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="18"
             xmlns="http://www.w3.org/2000/svg">
          <path
              d="m21.64 3.64-1.28-1.28a1.21 1.21 0 0 0-1.72 0L2.36 18.64a1.21 1.21 0 0 0 0 1.72l1.28 1.28a1.2 1.2 0 0 0 1.72 0L21.64 5.36a1.2 1.2 0 0 0 0-1.72"/>
          <path d="m14 7 3 3"/>
          <path d="M5 6v4"/>
          <path d="M19 14v4"/>
          <path d="M10 2v2"/>
          <path d="M7 8H3"/>
          <path d="M21 16h-4"/>
          <path d="M11 3H9"/>
        </svg>
        <span>Suggérez-moi des URLs à travailler</span>
      </button>
    </p>
  <template v-if="Object.keys(suggestions).length > 0">
    <div v-if="toggleSuggestion" class="bg-gray-50 p-4 shadow mt-3 relative rounded">
      <div class="absolute right-0 top-0">
        <button class="btn btn-xs btn-outline-dark flex items-center space-x-2"
                @click.prevent.stop="toggleSuggestion = false">
          <svg class="lucide lucide-move-up" fill="none" height="18" stroke="currentColor" stroke-linecap="round"
               stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="18"
               xmlns="http://www.w3.org/2000/svg">
            <path d="M8 6L12 2L16 6"/>
            <path d="M12 2V22"/>
          </svg>
          <span>Masquer les suggestions</span>
        </button>
      </div>
      <h3 class="text-netlinking">Suggestion de pages pouvant faire l’objet d’une stratégie de netlinking</h3>
      <p>Ces pages sont intégrées et triées par défaut selon leur trafic (datas SEMrush) . Ajouter-les facilement à votre
        campagne en un clic !</p>
      <div v-if="!showFilter"
           class="mb-4 mt-2 block lg:flex space-x-0 lg:space-x-4 space-y-4 lg:space-y-0 items-center">
        <button class="link-dark" @click.prevent.stop="showFilter=true">
        <span class="flex items-center">
          <svg class="lucide lucide-chevron-right" fill="none" height="18" stroke="currentColor" stroke-linecap="round"
               stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="18"
               xmlns="http://www.w3.org/2000/svg"><path d="m9 18 6-6-6-6"/></svg>
          <span>Afficher les filtres</span>
        </span>
        </button>
      </div>

      <div v-else class="mb-4 mt-2 block lg:flex space-x-0 lg:space-x-4 space-y-4 lg:space-y-0 items-center">

        <span class="pr-3 whitespace-nowrap">FILTRER VOS RECHERCHES</span>
        <div class="flex items-center space-x-2 min-w-[300px]">
          <label class="mb-0 whitespace-nowrap" for="urlFilter">URL contient</label>
          <div class="relative flex-1">
            <input
                id="urlFilter"
                v-model="filterCriteria.urlContains"
                class="w-full p-2 pr-8 border rounded"
                placeholder="Ex. banquezitouna"
                type="text"
            />
            <button
                v-if="filterCriteria.urlContains"
                class="absolute inset-y-0 right-2 flex items-center text-gray-400 hover:text-gray-600"
                @click.prevent.stop="filterCriteria.urlContains = ''"
            >
              <svg
                  class="h-4 w-4"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M6 18L18 6M6 6l12 12"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
        <div class="flex items-center space-x-2 min-w-[400px]">
          <label class="mb-0 whitespace-nowrap" for="trustFlowLabelFilter">TTF</label>
          <Selectn
              v-model="filterCriteria.trustFlowLabel"
              :options="ttfs"
              placeholder="-- Sélectionnez un TTF --"
          />
        </div>
        <div class="w-full items-end text-right">
          <button class="link-dark" @click.prevent.stop="showFilter=false">
            <span class="flex items-center">
            <svg class="lucide lucide-x" fill="none" height="18" stroke="currentColor" stroke-linecap="round"
                 stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="18"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M18 6 6 18"/>
              <path d="m6 6 12 12"/>
            </svg>
            <span>Masquer les filtres</span>
            </span>
          </button>
        </div>
      </div>

      <div class="max-w-full overflow-x-auto bg-white">
        <table class="w-full table-striped">
          <thead>
          <tr>
            <th @click="setSortKey('url')">
          <span class="flex items-center cursor-pointer link-blue-simple">
            URLs
            <span v-if="sortConfig.key === 'url'" class="ml-2">
              {{ sortConfig.direction === 'asc' ? '▲' : '▼' }}
            </span>
          </span>
            </th>
            <th @click="setSortKey('traffic')">
          <span class="flex items-center cursor-pointer link-blue-simple">
            Trafic
            <span v-if="sortConfig.key === 'traffic'" class="ml-2">
              {{ sortConfig.direction === 'asc' ? '▲' : '▼' }}
            </span>
          </span>
            </th>
            <th @click="setSortKey('keywords')">
          <span class="flex items-center cursor-pointer link-blue-simple">
            MC
            <span v-if="sortConfig.key === 'keywords'" class="ml-2">
              {{ sortConfig.direction === 'asc' ? '▲' : '▼' }}
            </span>
          </span>
            </th>
            <th @click="setSortKey('referringDomains')">
          <span class="flex items-center cursor-pointer link-blue-simple">
            DR
            <span v-if="sortConfig.key === 'referringDomains'" class="ml-2">
              {{ sortConfig.direction === 'asc' ? '▲' : '▼' }}
            </span>
          </span>
            </th>
            <th @click="setSortKey('citationFlow')">
          <span class="flex items-center cursor-pointer link-blue-simple">
            CF
            <span v-if="sortConfig.key === 'citationFlow'" class="ml-2">
              {{ sortConfig.direction === 'asc' ? '▲' : '▼' }}
            </span>
          </span>
            </th>
            <th @click="setSortKey('trustFlow')">
          <span class="flex items-center cursor-pointer link-blue-simple">
            TF
            <span v-if="sortConfig.key === 'trustFlow'" class="ml-2">
              {{ sortConfig.direction === 'asc' ? '▲' : '▼' }}
            </span>
          </span>
            </th>
            <th @click="setSortKey('trustFlowLabel')">
          <span class="flex items-center cursor-pointer link-blue-simple">
            TTF
            <span v-if="sortConfig.key === 'trustFlowLabel'" class="ml-2">
              {{ sortConfig.direction === 'asc' ? '▲' : '▼' }}
            </span>
          </span>
            </th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(data,index) in filteredDatas">
            <tr v-if="index < showLimit">
              <td class="!whitespace-normal">{{ data.url }}</td>
              <td>{{ data.traffic }}</td>
              <td>{{ data.keywords }}</td>
              <td>{{ data.referringDomains }}</td>
              <td>{{ data.citationFlow }}</td>
              <td>
            <span
                v-if="data.trustFlow"
                v-tippy="{ allowHTML: true,content: data.trustFlowRepresentation,theme:'tooltip-ttfs'}"
                :style="{'background': data.trustFlowColor}"
                class="w-[35px] block text-center font-bold px-2 py-1 rounded text-white">
              {{ data.trustFlow }}
            </span>
              </td>
              <td>{{ data.trustFlowLabel }}</td>
              <td class="text-center w-[230px]">
                <button
                    @click.prevent.stop="addToCampaign(data.url)"
                    :disabled="isInCampaign(data.url)"
                    class="btn btn-xs btn-outline-blue btn-rounded"
                >
                  Ajouter à ma campagne
                </button>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
        <button @click.prevent.stop="showLimit+=10" :disabled="showLimit >= 30 || Object.keys(filteredDatas).length <= showLimit"
                class="link-dark flex space-x-2 items-center mt-2">
          <svg class="lucide lucide-wand-sparkles" fill="none" height="18" stroke="currentColor" stroke-linecap="round"
               stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="18"
               xmlns="http://www.w3.org/2000/svg">
            <path
                d="m21.64 3.64-1.28-1.28a1.21 1.21 0 0 0-1.72 0L2.36 18.64a1.21 1.21 0 0 0 0 1.72l1.28 1.28a1.2 1.2 0 0 0 1.72 0L21.64 5.36a1.2 1.2 0 0 0 0-1.72"/>
            <path d="m14 7 3 3"/>
            <path d="M5 6v4"/>
            <path d="M19 14v4"/>
            <path d="M10 2v2"/>
            <path d="M7 8H3"/>
            <path d="M21 16h-4"/>
            <path d="M11 3H9"/>
          </svg>
          <span>Suggérez-moi encore plus de pages !</span>
        </button>
      </div>
    </div>
  </template>
  <template v-if="toggleSuggestion && Object.keys(suggestions).length == 0 && !loadingStore.loadingPage">
    <p class="mt-2">
      <div
          class="w-full bg-blue-light-6 rounded-lg px-[18px] py-4 shadow-[0px_2px_10px_0px_rgba(0,0,0,0.08)]"
      >
        <p class="text-center text-sm font-medium text-blue-600">
          Aucune page proposée trouvée !
        </p>
      </div>
    </p>
  </template>


</template>

<script setup>
import { computed, reactive, ref, onMounted } from 'vue'
import { useSorting } from '@/js/common/sorting' // Adjust path to sorting.js
import Selectn from '@/components/common/Selectn.vue' // Adjust path to sorting.js
import { useLoadingStore } from '@/js/stores/loading'
const emit = defineEmits(['add'])
import axios from 'axios'
const showFilter = ref(false)
const toggleSuggestion = ref(false)
const showLimit = ref(10)
const path = '/campaign/ajax/'
const loadingStore = useLoadingStore()
const { sortConfig, setSortKey, sortedData } = useSorting()
const suggestions = reactive([])
const props = defineProps({
  ttfs: {
    type: Object
  },
  campaign: {
    type: Object
  },
})
const filterCriteria = reactive({
  checkedOnly: false,
  trustFlowLabel: '',
  urlContains: ''
})
const filteredDatas = computed(() => {
  let data = suggestions.filter((data) => {
    const matchesChecked = filterCriteria.checkedOnly ? data.checked : true
    const matchesTrustFlowLabel = filterCriteria.trustFlowLabel
        ? data.trustFlowLabel.includes(filterCriteria.trustFlowLabel)
        : true
    const matchesUrl = filterCriteria.urlContains
        ? data.url.toLowerCase().includes(filterCriteria.urlContains.toLowerCase())
        : true

    return matchesChecked && matchesTrustFlowLabel && matchesUrl
  })

  return sortedData(data)
})
const addToCampaign = async(url) => {
  const originalData = suggestions.find(data => data.url === url); // Get the original data from props.suggestions
  if (originalData) {
    originalData.checked = true; // Update the checked property directly
    emit("add", originalData); // Emit an event if needed
  }
}

const isInCampaign = (url) => {
  return props.campaign.datas.some((campaignData) => campaignData.url === url);
};

const getSuggestions = async() => {
  try {
    toggleSuggestion.value = true;
    loadingStore.setLoadingPage(true)
    // Send the request
    const response = await axios.post(path, {
      action: 'urls-suggestion',
      datas: { campaign: props.campaign },
    })
    if (response.status === 200) {
      const validSuggestions = response.data.pageDatas.filter((data) => {
        return (
            data.url && // Ensure URL exists
            typeof data.url === 'string' &&
            data.url.trim() !== '' // Check URL is not empty
        );
      });
      if (validSuggestions.length > 0) {
        suggestions.splice(0, suggestions.length, ...validSuggestions);
      }
    }
  } catch (error) {
    // Handle errors
    if (error.response && error.response.status === 500) {
    } else {
    }
  } finally {
    // End loading
    loadingStore.setLoadingPage(false)
  }
}

</script>