<template>
  <Form @submit="onSubmit_0" class="space-y-3" :validation-schema="schema_0" v-slot="{ errors }">
<!--    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5">-->
    <div class="flex space-x-4">
      <div class="w-full">
        <label for="page_site_subdomain_directory">
          {{ t('field_names.page_site_subdomain_directory') }}
        </label>
        <div class="relative">
          <Field v-model="formValues.page_site_subdomain_directory" name="page_site_subdomain_directory" type="text"
                 :class="[errors.page_site_subdomain_directory ? 'ring-2 ring-red-600' : '']"/>
          <ErrorMessage name="page_site_subdomain_directory" class="error-message"/>
        </div>
      </div>
      <div class="w-40">
        <label for="language">
          {{ t('field_names.language') }}
        </label>
        <div class="relative">
          <Field v-model="formValues.language" name="language" v-slot="{ field, errors }"
          >
            <select :class="[errors && Object.keys(errors).length > 0 ? 'ring-2 ring-red-600' : '']"
                    id="language"
                    name="language" v-model="formValues.language">
              <option :value="''" disabled>{{ $t('select') }}</option>
              <option v-for="language in languages" :value="language.code">{{ $t(language.label) }}</option>
            </select>
          </Field>
          <ErrorMessage name="language" class="error-message"/>
        </div>
      </div>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-1 ">
      <div class="flex justify-center mt-3">
        <button
            :disabled="loadingStore.isLoading || !formValues.page_site_subdomain_directory || !formValues.language"
            class="btn btn-lg btn-netlinking w-full space-x-2 flex items-center justify-center"
        >
          <span><LoadingBtn :label="$t('field_names.launch')"/></span>
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-move-right"><path d="M18 8L22 12L18 16"/><path d="M2 12H22"/></svg>
          </span>
        </button>

      </div>
      <p class="text-center text-xs mt-2 text-gray-500" v-if="loadingStore.isLoading">
        Veuillez patienter quelques instants pendant que nous collectons les informations disponibles sur votre site pour identifier vos pages les plus performantes. Cette opération ne prendra que quelques secondes.
      </p>
    </div>
  </Form>
</template>
<script setup>
import { computed, defineProps, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useLoadingStore } from '@/js/stores/loading'
import { useLocaleStore } from '@/js/stores/locale'
import axios from 'axios'
import * as yup from 'yup'
import { ErrorMessage, Field, Form } from 'vee-validate'
import LoadingBtn from '@/components/common/LoadingBtn.vue'

const showPassword = ref(false)
const emit = defineEmits(['form-submitted'])
const { t } = useI18n()
const localeStore = useLocaleStore()
const loadingStore = useLoadingStore()
const currentLocale = localeStore.currentLocale
const existingError = ref(false)
const path = '/customer/ajax/'
const props = defineProps({
  languages: {
    type: Object,
    required: true
  }
})
const toggleShowPassword = () => {
  showPassword.value = !showPassword.value
}
const formValues = reactive({
  page_site_subdomain_directory: '',
  language: 'fr'
})
const schema_0 = computed(() => {
  return yup.object().shape({
    language: yup
        .string()
        .required(t('validation.required'))
        ,
    page_site_subdomain_directory: yup
        .string()
        .url(t('validation.invalid_url_format'))
        .required(t('validation.required'))
    ,
  })
})
const onSubmit_0 = async (values) => {
  try {
    // Start loading
    loadingStore.setLoading(true)

    // Send the request
    const response = await axios.post(path, {
      action: 'project-add',
      datas: { values: formValues},
    })

    // Check response status
    console.log(response.status)
    if (response.status === 200) {
      window.location.href = response.data.redirect
      // Handle successful login
      existingError.value = false // Reset error if needed
    }
  } catch (error) {
    // Handle errors
    if (error.response && error.response.status === 500) {
      existingError.value = true // Set error flag
    } else {
    }
  } finally {
    // End loading
    // loadingStore.setLoading(false)
  }
}

</script>