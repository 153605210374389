import { vAsk } from "@/directives/ask.js";
import vClickOutside from 'click-outside-vue3';

const vNl2br = {
  beforeMount(el, binding) {
    el.innerHTML = binding.value.replace(/\n/g, '<br>');
  },
  updated(el, binding) {
    el.innerHTML = binding.value.replace(/\n/g, '<br>');
  }
};

export default function registerDirectives(app) {
  app.directive('ask', vAsk);
  app.directive('clickOutside', vClickOutside.directive);
  app.directive('nl2br', vNl2br);
}
