<template>
  <NodeViewWrapper>
    <div
        class="relative group"
        v-if="attrs.src"
    >
      <div
          class="absolute p-0 z-10"
          :class="[
          className.includes('right') ? 'right-0' : '',
          className.includes('left') ? 'left-0' : '',
        ]"
      >
        <!-- Controls Panel -->
        <div
            v-if="showControls"
            class="absolute top-12 w-80 bg-white  rounded-lg shadow-lg border border-gray-200 "
        >
          <!-- Header with close button -->
          <div class="flex justify-end p-2 border-b border-gray-200 ">
            <button
                class="p-1 hover:bg-gray-100  rounded-full transition-colors"
                @click="showControls = false"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="text-gray-500 ">
                <circle cx="12" cy="12" r="10"/>
                <path d="m15 9-6 6"/>
                <path d="m9 9 6 6"/>
              </svg>
            </button>
          </div>

          <!-- Controls Content -->
          <div class="p-4 space-y-4">
            <div class="space-y-2">
              <label class="block text-sm font-medium text-gray-700 ">
                Alt Text
              </label>
              <input
                  v-model="alt"
                  @change="updateAlt()"
                  type="text"
                  placeholder="Enter alt text"
                  class="w-full px-3 py-2 border rounded-md border-gray-300  bg-white  text-gray-900  focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>

            <div class="space-y-2">
              <label class="block text-sm font-medium text-gray-700 ">
                Alignment
              </label>
              <select
                  class="w-full px-3 py-2 border rounded-md border-gray-300  bg-white  text-gray-900  focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  v-model="className"
                  @change="updateclassName()"
              >
                <option value="float-left-30-img">Left align 30%</option>
                <option value="float-right-30-img">Right align 30%</option>
                <option value="float-left-40-img">Left align 40%</option>
                <option value="float-right-40-img">Right align 40%</option>
                <option value="full-img">Center 100%</option>
              </select>
            </div>
          </div>
        </div>

        <!-- Media Controls -->
        <div class="flex items-center space-x-2 opacity-0 group-hover:opacity-100 transition-opacity">
          <button
              class="p-2 bg-white  rounded-lg shadow-md hover:bg-red-50  transition-colors group"
              @click.prevent.stop="toggleControls"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-file-pen-line"><path d="m18 5-2.414-2.414A2 2 0 0 0 14.172 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2"/><path d="M21.378 12.626a1 1 0 0 0-3.004-3.004l-4.01 4.012a2 2 0 0 0-.506.854l-.837 2.87a.5.5 0 0 0 .62.62l2.87-.837a2 2 0 0 0 .854-.506z"/><path d="M8 18h1"/></svg>
          </button>
          <button
              @click="removeMedia"
              class="p-2 bg-white  rounded-lg shadow-md hover:bg-red-50  transition-colors group"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="text-gray-500 group-hover:text-red-500  ">
              <path d="M3 6h18"/>
              <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"/>
              <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"/>
              <line x1="10" x2="10" y1="11" y2="17"/>
              <line x1="14" x2="14" y1="11" y2="17"/>
            </svg>
          </button>

          <span
              v-if="(!alt || alt === '') && attrs.type === 'image'"
              class="px-2 py-1 text-xs font-medium text-red-500 bg-red-50   rounded-md"
          >
            Missing alt text
          </span>
        </div>
      </div>

      <!-- Media Display -->
      <img
          v-if="attrs.type === 'image'"
          :src="attrs.src"
          :alt="alt"
          :class="[className, float, 'rounded-lg shadow-md']"
      />

      <iframe
          v-if="attrs.type === 'youtube'"
          :class="[className, float, 'rounded-lg shadow-md']"
          :src="`https://www.youtube.com/embed/${extractYouTubeCode(attrs.src)}`"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
      ></iframe>
    </div>
  </NodeViewWrapper>
</template>

<script>
import { NodeViewWrapper } from "@tiptap/vue-3";

export default {
  props: ["node", "updateAttrs"],
  components: {
    NodeViewWrapper
  },
  data() {
    return {
      showControls: false,
      className: this.node.attrs.className || "float-left-30-img",
      alt: this.node.attrs.alt || "",
      src: this.node.attrs.src || "",
    };
  },
  computed: {
    attrs() {
      return this.node.attrs;
    },
  },
  methods: {
    extractYouTubeCode(url) {
      try {
        const urlObj = new URL(url);
        const searchParams = new URLSearchParams(urlObj.search);
        return searchParams.get("v");
      } catch (error) {
        console.error("Invalid YouTube URL:", error);
        return "";
      }
    },
    toggleControls() {
      this.showControls = !this.showControls;
    },
    updateAlt() {
      this.node.attrs.alt = this.alt;
    },
    updateclassName() {
      this.node.attrs.className = this.className;
    },
    removeMedia() {
      this.src = null;
      this.node.attrs.type = null;
      this.node.attrs.src = null;
    },
  },
};
</script>