<template>
  <div v-if="loadingComponent" class="z-50 bg-white p-5">
    <Animatephrases/>
  </div>
  <div v-if="!loadingComponent" class="bg-gray-50 p-4 shadow mt-3 relative rounded">
    <div class="absolute right-0 top-0">
      <slot></slot>
    </div>
    <h3 class="text-netlinking">
      Liste des articles positionnés les plus pertinents pour votre URL
    </h3>
    <p>
      Vous trouverez ci-dessous la liste des articles positionnés les plus pertinents pour faire un lien vers votre URL
      cible. Ils sont classés par PS (Proximité Sémantique).<br>
      La surbrillance jaune sur un article indique que votre site a déjà un ou plusieurs backlinks sur ce domaine
      (rappelons qu’il est naturel de recevoir plusieurs liens d’un même site dans le temps).
    </p>
    <div
        v-if="showDidYouKnow"
        class="border-l-netlinking my-3 flex max-w-full items-center rounded-md border-l-[6px] bg-gray-200 p-2 pl-6 pr-6 relative"
    >
      <div class="absolute right-0 top-0">
        <a class="link-dark" href="#" @click.prevent.stop="hideDidYouKnow()">
          <svg class="lucide lucide-x" fill="none" height="24" stroke="currentColor" stroke-linecap="round"
               stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24"
               xmlns="http://www.w3.org/2000/svg">
            <path d="M18 6 6 18"/>
            <path d="m6 6 12 12"/>
          </svg>
        </a>
      </div>
      <div class="flex w-full items-center justify-between space-x-4">
        <img :src="'/images/saviez-vous.svg'" class="w-[60px]"/>
        <div>
          <p class="text-dark text-sm whitespace-normal">
            Le saviez-vous ? Nous pouvons nous occuper du choix de votre ancre pour vous ! Nos équipes sélectionneront
            une ancre avec un glissement sémantique fluide et cohérent entre les deux pages, tout en garantissant une
            diversité d’ancres pour un profil naturel et performant. Intéressé(e) ? Il vous suffit de laisser le champ «
            Votre ancre » vide, et nous nous chargerons du reste ! ;-) Si vous préférez renseigner une ancre, ajoutez-la
            simplement dans le champ correspondant !
          </p>
        </div>
      </div>
    </div>
    <div
        class="block lg:flex flex-wrap gap-4 lg:space-y-0 space-y-4 items-center bg-white shadow-2 rounded-lg py-3 px-3 mt-5 max-w-screen">
      <div class="block lg:flex items-center space-x-0 lg:space-x-2 lg:min-w-[250px] min-w-full">
        <label class="!mb-0" for="title">Titre contient</label>
        <input v-model="filterCriteria.title" name="title" type="text">
      </div>
      <div class="block lg:flex items-center space-x-0 lg:space-x-2 lg:min-w-[140px] min-w-full">
        <label class="!mb-0">TTF</label>
        <Selectnm
            v-model="filterCriteria.trustFlowLabel"
            :options="ttfs"
            placeholder="-- Sélectionnez un TTF --"
        />
      </div>
      <div class="block lg:flex items-center space-x-0 lg:space-x-2 lg:min-w-[140px] min-w-full">
        <label class="!mb-0">TF min</label>
        <select v-model="filterCriteria.minTrustFlow">
          <option :value="null">Tous</option>
          <option value="10">10 >=</option>
          <option value="15">15 >=</option>
          <option value="20">20 >=</option>
          <option value="25">25 >=</option>
          <option value="30">30 >=</option>
          <option value="35">35 >=</option>
        </select>
      </div>
      <div class="block lg:flex items-center space-x-0 lg:space-x-2 lg:min-w-[140px] min-w-full">
        <label class="!mb-0" for="minImpressions">Imp.</label>
        <select v-model="filterCriteria.minImpressions" class="minImpressions">
          <option :value="null">Tous</option>
          <option value="10">10 >=</option>
          <option value="50">50 >=</option>
          <option value="100">100 >=</option>
          <option value="500">500 >=</option>
          <option value="1000">1 000 >=</option>
          <option value="5000">5 000 >=</option>
          <option value="10000">10 000 >=</option>
        </select>
      </div>
      <div class="flex items-center space-x-2 min-w-[60px]">
        <label class="!mb-0" for="refDomains">DR</label>
        <label class="flex items-center cursor-pointer select-none text-dark mb-0">
          <div class="relative">
            <input
                v-model="filterCriteria.refDomains"
                class="sr-only"
                name="refDomains"
                type="checkbox"
            />
            <div class="box mr-4 flex h-5 w-5 items-center justify-center rounded border border-stroke">
              <span class="opacity-0">
                <svg
                    fill="none"
                    height="8"
                    viewBox="0 0 11 8"
                    width="11"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M10.0915 0.951972L10.0867 0.946075L10.0813 0.940568C9.90076 0.753564 9.61034 0.753146 9.42927 0.939309L4.16201 6.22962L1.58507 3.63469C1.40401 3.44841 1.11351 3.44879 0.932892 3.63584C0.755703 3.81933 0.755703 4.10875 0.932892 4.29224L0.932878 4.29225L0.934851 4.29424L3.58046 6.95832C3.73676 7.11955 3.94983 7.2 4.1473 7.2C4.36196 7.2 4.55963 7.11773 4.71406 6.9584L10.0468 1.60234C10.2436 1.4199 10.2421 1.1339 10.0915 0.951972ZM4.2327 6.30081L4.2317 6.2998C4.23206 6.30015 4.23237 6.30049 4.23269 6.30082L4.2327 6.30081Z"
                      fill="#C06"
                      stroke="#C06"
                      stroke-width="1"
                  ></path>
                </svg>
              </span>
            </div>
          </div>
        </label>
      </div>
      <div class="block lg:flex items-center space-x-0 lg:space-x-2 lg:min-w-[150px] min-w-full">
        <label class="!mb-0" for="transferredAuthority">AT mini</label>
        <select v-model="filterCriteria.transferredAuthority" name="transferredAuthority">
          <option :value="null">Tous</option>
          <option value="1">1 >=</option>
          <option value="5">5 >=</option>
          <option value="10">10 >=</option>
          <option value="20">20 >=</option>
          <option value="50">50 >=</option>
          <option value="100">100 >=</option>
        </select>
      </div>
      <div class="block lg:flex items-center space-x-0 lg:space-x-2 lg:min-w-[200px] min-w-full">
        <label class="!mb-0" for="category">Catégorie</label>
        <select v-model="filterCriteria.category" name="category">
          <option :value="null">Tous</option>
          <option value="copper">Copper</option>
          <option value="bronze">Bronze</option>
          <option value="silver">Silver</option>
          <option value="gold">Gold</option>
          <option value="platinium">Platinium</option>
        </select>
      </div>

      <div class="flex items-center space-x-2 min-w-[90px]">
        <label class="!mb-0" for="theme">Théma</label>
        <label class="flex items-center cursor-pointer select-none text-dark mb-0">
          <div class="relative">
            <input
                v-model="filterCriteria.theme"
                class="sr-only"
                name="theme"
                type="checkbox"
            />
            <div class="box mr-4 flex h-5 w-5 items-center justify-center rounded border border-stroke">
              <span class="opacity-0">
                <svg
                    fill="none"
                    height="8"
                    viewBox="0 0 11 8"
                    width="11"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M10.0915 0.951972L10.0867 0.946075L10.0813 0.940568C9.90076 0.753564 9.61034 0.753146 9.42927 0.939309L4.16201 6.22962L1.58507 3.63469C1.40401 3.44841 1.11351 3.44879 0.932892 3.63584C0.755703 3.81933 0.755703 4.10875 0.932892 4.29224L0.932878 4.29225L0.934851 4.29424L3.58046 6.95832C3.73676 7.11955 3.94983 7.2 4.1473 7.2C4.36196 7.2 4.55963 7.11773 4.71406 6.9584L10.0468 1.60234C10.2436 1.4199 10.2421 1.1339 10.0915 0.951972ZM4.2327 6.30081L4.2317 6.2998C4.23206 6.30015 4.23237 6.30049 4.23269 6.30082L4.2327 6.30081Z"
                      fill="#C06"
                      stroke="#C06"
                      stroke-width="1"
                  ></path>
                </svg>
              </span>
            </div>
          </div>
        </label>
      </div>
      <div class="flex items-center space-x-2 lg:min-w-[300px] min-w-full pt-[3rem] lg:pt-0">
        <label class="lg:block hidden mr-[10px] !mb-0" for="minPrice">Prix</label>
        <vue-slider v-model="filterCriteria.price" :dotSize="20"
                    :dotStyle="{ backgroundColor: '#E5E7EB' }"
                    :max="700"
                    :min="0"
                    :processStyle="{ backgroundColor: '#1fbfb8' }"
                    :railStyle="{ backgroundColor: '#E5E7EB' }"
                    :step="10"
                    :tooltip="'always'"
                    :tooltipFormatter="formatTooltip"
                    :tooltipStyle="{ backgroundColor: '#1fbfb8', color: 'white' }"
                    :width="'100%'"
                    tooltipClass="custom-tooltip"/>
      </div>
    </div>

    <div class="max-w-full overflow-x-auto bg-white" style="max-height: 70vh">
      <table class="w-full table-striped">
        <thead class="!shadow" style="position: sticky; top:0px;z-index: 10; background:white !important">
        <tr class="bg-white">
          <th colspan="11"></th>
          <th colspan="1" class="!text-center !border !border-l !border-l-netlinking_gray_dark">SITE</th>
          <th colspan="2"></th>
          <th colspan="2" class="!text-center">CHOIX DE L'ANCRE</th>
          <th></th>
        </tr>
        <tr>
          <th class="!text-center" @click="setSortKey('score')">
        <span class="flex items-center cursor-pointer link-blue-simple">
          PS
          <span v-if="sortConfig.key === 'score'" class="ml-2">
            {{ sortConfig.direction === 'asc' ? '▲' : '▼' }}
          </span>
        </span>
          </th>
          <th class="!text-center" @click="setSortKey('title')">
        <span class="flex items-center cursor-pointer link-blue-simple">
          Titre
          <span v-if="sortConfig.key === 'title'" class="ml-2">
            {{ sortConfig.direction === 'asc' ? '▲' : '▼' }}
          </span>
        </span>
          </th>
          <th class="!text-center" @click="setSortKey('trustFlow')">
        <span class="flex justify-center items-center cursor-pointer link-blue-simple">
          TF
          <span v-if="sortConfig.key === 'trustFlow'" class="ml-2">
            {{ sortConfig.direction === 'asc' ? '▲' : '▼' }}
          </span>
        </span>
          </th>
          <th class="!text-center" @click="setSortKey('topicalTrustFlowTopic')">
        <span class="flex items-center cursor-pointer link-blue-simple">
          TTF
          <span v-if="sortConfig.key === 'topicalTrustFlowTopic'" class="ml-2">
            {{ sortConfig.direction === 'asc' ? '▲' : '▼' }}
          </span>
        </span>
          </th>
          <th class="!text-center" @click="setSortKey('impressions')">
        <span class="flex items-center cursor-pointer link-blue-simple">
          <div class="flex items-center space-x-1">
            <span>Imp.</span>
            <img :src="'/images/google.svg'" alt="" class="w-4"/>
          </div>
          <span v-if="sortConfig.key === 'impressions'" class="ml-2">
            {{ sortConfig.direction === 'asc' ? '▲' : '▼' }}
          </span>
        </span>
          </th>
          <th class="!text-center" @click="setSortKey('refDomains')">
        <span class="flex items-center cursor-pointer link-blue-simple">
          DR
          <span v-if="sortConfig.key === 'refDomains'" class="ml-2">
            {{ sortConfig.direction === 'asc' ? '▲' : '▼' }}
          </span>
        </span>
          </th>
          <th class="!text-center" @click="setSortKey('trustFlow')">
        <span class="flex items-center justify-center cursor-pointer link-blue-simple">
          TF
          <span v-if="sortConfig.key === 'trustFlow'" class="ml-2">
            {{ sortConfig.direction === 'asc' ? '▲' : '▼' }}
          </span>
        </span>
          </th>
          <th class="!text-center" @click="setSortKey('citationFlow')">
        <span class="flex items-center cursor-pointer link-blue-simple">
          CF
          <span v-if="sortConfig.key === 'citationFlow'" class="ml-2">
            {{ sortConfig.direction === 'asc' ? '▲' : '▼' }}
          </span>
        </span>
          </th>
          <th class="!text-center" @click="setSortKey('transferredAuthority')">
        <span class="flex items-center cursor-pointer link-blue-simple justify-center !text-center">
          AT
          <span v-if="sortConfig.key === 'transferredAuthority'" class="ml-2">
            {{ sortConfig.direction === 'asc' ? '▲' : '▼' }}
          </span>
        </span>
          </th>
          <th class="!text-center" @click="setSortKey('transferredPopularity')">
        <span class="flex justify-center items-center cursor-pointer link-blue-simple">
          PT
          <span v-if="sortConfig.key === 'transferredPopularity'" class="ml-2">
            {{ sortConfig.direction === 'asc' ? '▲' : '▼' }}
          </span>
        </span>
          </th>
          <th class="!text-center" @click="setSortKey('w_classification')">
        <span class="flex items-center justify-center cursor-pointer link-blue-simple">
          Cat.
          <span v-if="sortConfig.key === 'w_classification'" class="ml-2">
            {{ sortConfig.direction === 'asc' ? '▲' : '▼' }}
          </span>
        </span>
          </th>
          <th @click="setSortKey('w_trustFlow')">
        <span class="flex justify-center items-center cursor-pointer link-blue-simple">
          TF
          <span v-if="sortConfig.key === 'w_trustFlow'" class="ml-2">
            {{ sortConfig.direction === 'asc' ? '▲' : '▼' }}
          </span>
        </span>
          </th>
          <th class="!text-center" @click="setSortKey('thematique')">
        <span class="flex items-center cursor-pointer link-blue-simple">
          Théma
          <span v-if="sortConfig.key === 'thematique'" class="ml-2">
            {{ sortConfig.direction === 'asc' ? '▲' : '▼' }}
          </span>
        </span>
          </th>
          <th class="!text-center" @click="setSortKey('price')">
        <span class="flex justify-center items-center cursor-pointer link-blue-simple">
          Prix
          <span v-if="sortConfig.key === 'price'" class="ml-2">
            {{ sortConfig.direction === 'asc' ? '▲' : '▼' }}
          </span>
        </span>
          </th>
          <th class="!text-center"  @click="setSortKey('nl')">
            <span class="flex items-center justify-center cursor-pointer link-blue-simple">
         NL
          <span v-if="sortConfig.key === 'nl'" class="ml-2">
            {{ sortConfig.direction === 'asc' ? '▲' : '▼' }}
          </span>
        </span>
          </th>
          <th class="!text-center"  @click="setSortKey('anchor')">
            <span class="flex items-center justify-center cursor-pointer link-blue-simple">
          Votre ancre
          <span v-if="sortConfig.key === 'anchor'" class="ml-2">
            {{ sortConfig.direction === 'asc' ? '▲' : '▼' }}
          </span>
        </span>
          </th>
          <th class="!text-center" @click="setSortKey('ordered')">
        <span class="flex items-center justify-center cursor-pointer link-blue-simple">
          Cmd
          <span v-if="sortConfig.key === 'ordered'" class="ml-2">
            {{ sortConfig.direction === 'asc' ? '▲' : '▼' }}
          </span>
        </span>
          </th>
        </tr>
        </thead>
        <tbody>
        <template v-for="(sug,index) in reactiveFilteredDatas">
          <tr
              v-if="!verifyIfOrderExistInAll(sug.page_id)"
              :class="{
                  '!bg-netlinkingh': verifyIfOrderExist(sug.page_id),
                  '!bg-gray-100': orderedWIds.has(sug.w_id) && !verifyIfOrderExist(sug.page_id),
                  '!bg-white': !verifyIfOrderExist(sug.page_id) && !orderedWIds.has(sug.w_id)
                 }"
          >
            <td class="!text-center">
              {{ sug.score }}%
            </td>
            <td class="!whitespace-normal">
              <span v-html="sug.title"></span>
            </td>
            <td class="!text-center">
              <span
                  v-if="sug.trustFlow"
                  v-tippy="{ allowHTML: true,content: sug.detailsTtfs,theme:'tooltip-ttfs'}"
                  :style="{'background': sug.topicalTrustFlowColor}"
                  class="w-[35px] block text-center font-bold px-2 py-1 rounded text-white">
                {{ sug.trustFlow }}
              </span>
            </td>
            <td class="!whitespace-normal">
              <span class="text-blue-400">{{ sug.topicalTrustFlowTopic }}</span>
            </td>
            <td class="!text-center">{{ sug.impressions }}</td>
            <td class="!text-center">{{ sug.refDomains }}</td>
            <td class="!text-center">{{ sug.trustFlow }}</td>
            <td class="!text-center">{{ sug.citationFlow }}</td>
            <td class="!text-center">{{ sug.transferredAuthority }}</td>
            <td class="!text-center">{{ sug.transferredPopularity }}</td>
            <td class="!text-center capitalize">{{ sug.w_classification }}</td>
            <td class="!text-center">
              <span
                  v-if="sug.w_trustFlow"
                  v-tippy="{ allowHTML: true,content: sug.w_detailsTtfs,theme:'tooltip-ttfs'}"
                  :style="{'background': sug.w_ttfColors}"
                  class="w-[35px] block text-center font-bold px-2 py-1 rounded text-white">
                {{ sug.w_trustFlow }}
              </span>
            </td>
            <td class="!text-center">
              <div class="flex items-center justify-center">


              <span v-if="sug.thematique" class="text-green-500 ">
                <svg class="lucide lucide-circle-check" fill="none" height="24" stroke="currentColor"
                     stroke-linecap="round"
                     stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24"
                     xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="10"/><path d="m9 12 2 2 4-4"/></svg>
              </span>
                <span v-else class="text-gray-400 ">
                  <svg class="lucide lucide-circle-minus" fill="none" height="24" stroke="currentColor"
                       stroke-linecap="round"
                       stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24"
                       xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="10"/><path d="M8 12h8"/></svg>
              </span>
              </div>
            </td>
            <td class="!text-center">{{ $filters.numberFormatIntl(calculExtraPrice(sug.price)) }} €</td>
            <td class="!text-center">
              <label class="w-[40px] flex items-center justify-center cursor-pointer select-none text-dark mb-0">
              <div class="relative">
                <input
                    v-model="sug.nl"
                    :disabled="verifyIfOrderExist(sug.page_id)"
                    :false-value="false"
                    :true-value="true"
                    type="checkbox"
                />
              </div>
              </label>
            </td>
            <td class="!text-center">
              <div class="max-w-[200px] block">
                <input v-model="sug.anchor" :disabled="verifyIfOrderExist(sug.page_id)" :readonly="sug.nl"
                       class="w-full" type="text">
                <span v-if="sug.error" class="text-red-600 text-xs block whitespace-normal">
                  Veuillez choisir votre ancre ou laissez-nous la choisir pour vous
                </span>
              </div>
            </td>
            <td class="!text-center">
              <button v-if="verifyIfOrderExist(sug.page_id)" class="btn btn-order-remove btn-sm"
                      @click.prevent.stop="removeOrder(index)">
                <svg class="lucide lucide-circle-x" fill="none" height="24" stroke="currentColor" stroke-linecap="round"
                     stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24"
                     xmlns="http://www.w3.org/2000/svg">
                  <circle cx="12" cy="12" r="10"/>
                  <path d="m15 9-6 6"/>
                  <path d="m9 9 6 6"/>
                </svg>
              </button>
              <button v-else class="btn btn-order btn-sm" @click.prevent.stop="addOrder(index)">
                <svg class="lucide lucide-shopping-cart" fill="none" height="24" stroke="currentColor"
                     stroke-linecap="round"
                     stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24"
                     xmlns="http://www.w3.org/2000/svg">
                  <circle cx="8" cy="21" r="1"/>
                  <circle cx="19" cy="21" r="1"/>
                  <path d="M2.05 2.05h2l2.66 12.42a2 2 0 0 0 2 1.58h9.78a2 2 0 0 0 1.95-1.57l1.65-7.43H5.12"/>
                </svg>
              </button>
            </td>
          </tr>
        </template>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script setup>
import { computed, defineEmits, onMounted, reactive, ref, watchEffect } from 'vue'
import { useSorting } from '@/js/common/sorting' // Adjust path to sorting.js
import { useLoadingStore } from '@/js/stores/loading'
import Animatephrases from '@/components/common/Animatephrases.vue'
import axios from 'axios'
import Selectnm from '@/components/common/Selectnm.vue'

const emit = defineEmits(['add', 'order', 'rorder'])
const formatTooltip = (value) => `${value} €`
const loadingComponent = ref(true)
const showFilter = ref(false)
const toggleSuggestion = ref(false)
const showDidYouKnow = ref(localStorage.getItem('showDidYouKnow') !== 'false')
const hideDidYouKnow = () => {
  showDidYouKnow.value = false
  localStorage.setItem('showDidYouKnow', 'false')
}
const path = '/campaign/ajax/'
const loadingStore = useLoadingStore()
const { sortConfig, setSortKey, sortedData } = useSorting()
const suggestions = ref([])
const props = defineProps({
  ttfs: {
    type: Object
  },
  orders: {
    type: Object
  },
  campaign: {
    type: Object
  },
  url: {
    type: String
  },
  cacheExpirationHours: {
    type: Number,
    default: 3
  }
})
const calculExtraPrice = (basePrice) => {
  if (!props.campaign || typeof props.campaign.exclusive_duration !== 'number') {
    return Math.round(basePrice) // Return base price if the exclusive duration is not valid
  }
  const duration = props.campaign.exclusive_duration
  if (duration === 0) {
    return Math.round(basePrice * 0.8) // Reduce by 10%
  }
  return Math.round(basePrice * (1 + ((duration - 1) * 0.1))) // Increase by 10% per year
}
const filterCriteria = reactive({
  title: '', // Nouveau filtre "Titre contient"
  transferredAuthority: null, // Nouveau filtre "AT mini"
  minTrustFlow: null,
  minImpressions: null,
  refDomains: false,
  category: null,
  theme: false,
  trustFlowLabel: [],
  price: [0, 700] // Range de prix sélectionné
})

const filteredDatas = computed(() => {
  let suggestion_filtered = suggestions.value.filter(data => {
    return (
        (!filterCriteria.minTrustFlow || data.trustFlow >= filterCriteria.minTrustFlow) &&
        (!filterCriteria.minImpressions || data.impressions >= filterCriteria.minImpressions) &&
        (!filterCriteria.transferredAuthority || data.transferredAuthority >= filterCriteria.transferredAuthority) && // AT mini
        (!filterCriteria.refDomains || data.refDomains > 0) && // Vérifie si refDomains est activé
        (!filterCriteria.category || data.w_classification.toLowerCase().includes(filterCriteria.category.toLowerCase())) &&
        (!filterCriteria.theme || data.thematique) && // Si activé, garde seulement ceux qui ont une thématique
        (
            // Si aucun label n'est sélectionné, le filtre est ignoré,
            // sinon, on vérifie que data.topicalTrustFlowTopic contient au moins un des labels sélectionnés
            !filterCriteria.trustFlowLabel.length ||
            (data.topicalTrustFlowTopic &&
                filterCriteria.trustFlowLabel.some(label =>
                    data.topicalTrustFlowTopic.toLowerCase().includes(label.toLowerCase())
                )
            )
        ) &&
        (data.price >= filterCriteria.price[0] && data.price <= filterCriteria.price[1]) && // Vérifie que le prix est dans la plage sélectionnée
        (!filterCriteria.title || data.title.toLowerCase().includes(filterCriteria.title.toLowerCase())) // Nouveau filtre "Titre contient"
    )
  })
  suggestion_filtered = suggestion_filtered.map(data => ({
    ...data,
    ordered: verifyIfOrderExist(data.page_id, props.url)
  }))
  return sortedData(suggestion_filtered)
})
const cacheNlAndAnchor = ref({})
const saveCurrentNlAndAnchor = () => {
  cacheNlAndAnchor.value = {}

  reactiveFilteredDatas.value.forEach(item => {
    cacheNlAndAnchor.value[item.page_id] = {
      nl: item.nl,
      anchor: item.anchor
    }
  })
}
const restoreNlAndAnchor = () => {
  reactiveFilteredDatas.value.forEach(item => {
    if (cacheNlAndAnchor.value[item.page_id]) {
      item.nl = cacheNlAndAnchor.value[item.page_id].nl
      item.anchor = cacheNlAndAnchor.value[item.page_id].anchor
    }
  })
}

const reactiveFilteredDatas = ref([])
watchEffect(() => {
  saveCurrentNlAndAnchor() // Avant que le tri modifie les données
  reactiveFilteredDatas.value = filteredDatas.value.map(data => reactive({ ...data }))
  restoreNlAndAnchor() // Réappliquer après le tri
})
const getPropositions = async () => {
  const storedData = localStorage.getItem(`suggestions_${props.url}`)
  const storedTimestamp = localStorage.getItem(`suggestions_timestamp_${props.url}`)
  const now = Date.now()
  const expirationTime = Math.min(props.cacheExpirationHours, 0.5) * 60 * 60 * 1000 // 30 minutes max en ms
  if (storedData && storedTimestamp) {
    const elapsedTime = now - parseInt(storedTimestamp, 10)

    if (elapsedTime < expirationTime) {
      let parsedData
      try {
        parsedData = JSON.parse(storedData)
      } catch (error) {
        console.warn('Failed to parse stored data:', error)
        // Optionally, clear the corrupted data from localStorage:
        localStorage.removeItem(`suggestions_${props.url}`)
        parsedData = null
      }

      if (parsedData) {
        suggestions.value = parsedData
        return
      }
    } else {
      suggestions.value = []
      // Remove expired cache
      localStorage.removeItem(`suggestions_${props.url}`)
      localStorage.removeItem(`suggestions_timestamp_${props.url}`)
    }
  }

  try {
    loadingStore.setLoadingPage(true)
    // Fetch new data if cache is expired or does not exist
    const response = await axios.post(path, {
      action: 'get-propositions',
      datas: { campaign: props.campaign, url: props.url },
    })

    if (response.status === 200) {
      suggestions.value = response.data.propositions
      try {
        // Store new data and update timestamp
        localStorage.setItem(`suggestions_${props.url}`, JSON.stringify(response.data.propositions))
        localStorage.setItem(`suggestions_timestamp_${props.url}`, now.toString())
      } catch (e) {
        console.warn('LocalStorage quota exceeded, skipping caching', e)
      }
    }
  } catch (error) {
    console.warn('Erreur lors de la récupération des propositions', error)
  } finally {
    loadingStore.setLoadingPage(false)
  }
}
const updateNLAnchor = () => {
  reactiveFilteredDatas.value.forEach(item => {
    const order = props.orders.find(
        o => o.page_id === item.page_id && o.url_destination === props.url
    )
    if (order) {
      item.nl = order.nl
      item.anchor = order.anchor
    }
  })
}
onMounted(async () => {
  await getPropositions()
  updateNLAnchor()
  loadingComponent.value = false
})
const addOrder = async (index) => {
  loadingStore.setLoadingPage(true)

  // Récupérer l'élément concerné depuis filteredDatas
  const page = reactiveFilteredDatas.value[index]

  // Trouver son équivalent dans suggestions.value
  const originalIndex = suggestions.value.findIndex(item => item.page_id === page.page_id)
  suggestions.value[originalIndex] = page
  if (originalIndex !== -1) {
    const originalItem = suggestions.value[originalIndex]

    if (page.anchor === '' && !page.nl) {
      page.error = true
    } else {
      page.error = false

      // Émettre l'événement avec la page concernée
      await emit('order', { 'page': page })
      updateNLAnchor()
    }
  }

  loadingStore.setLoadingPage(false)
}

const removeOrder = async (index) => {
  loadingStore.setLoadingPage(true)

  // Récupérer l'élément concerné depuis filteredDatas
  const page = reactiveFilteredDatas.value[index]

  // Trouver son équivalent dans suggestions.value
  const originalIndex = suggestions.value.findIndex(item => item.page_id === page.page_id)

  if (originalIndex !== -1) {
    await emit('rorder', { 'page': page })
    updateNLAnchor()
  }

  loadingStore.setLoadingPage(false)
}

const verifyIfOrderExist = (page_id) => {
  return props.orders.some(order => order.page_id === page_id && order.url_destination === props.url)
}

const verifyIfOrderExistInAll = (page_id) => {
  return props.orders.some(order => order.page_id === page_id && order.url_destination !== props.url)
}

const orderedWIds = computed(() => {
  return new Set(props.orders.map(order => order.w_id))
})


</script>
<style>
::v-deep(.custom-tooltip) {
  background-color: #1fbfb8 !important;
  color: white !important;
  border-radius: 8px;
}

::v-deep(.custom-tooltip:before) {
  border-top-color: #1fbfb8 !important; /* Flèche du tooltip */
}

::v-deep(.vue-slider-dot-tooltip:before),
::v-deep(.vue-slider-dot-tooltip:after) {
  border-top-color: #1fbfb8 !important; /* Couleur de l'arrow */
  border-bottom-color: #1fbfb8 !important; /* Au cas où l'orientation change */
}

.vue-slider-dot-tooltip-inner-top::after {
  border-top-color: #1fbfb8 !important;
}

</style>